import { useQuery } from '@tanstack/react-query';

import { getQuestionnaireInvestorResponse } from '@arrived/api_v2';
import { QuestionnaireId, User } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { questionnaireInvestorResponsesKeyFn } from './questionnaire.keys';

export function useGetQuestionnaireInvestorResponses(
  { questionnaireId, userCid }: { questionnaireId: QuestionnaireId; userCid?: User['cid'] },
  options?: UseAwaitedQueryOptions<typeof getQuestionnaireInvestorResponse>,
) {
  return useQuery({
    queryKey: questionnaireInvestorResponsesKeyFn(questionnaireId, userCid!),
    queryFn: () => getQuestionnaireInvestorResponse({ questionnaireId, userCid: userCid! }),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && userCid != null,
  });
}

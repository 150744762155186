import { useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint } from '@arrived/bricks';
import { getTotalPropertyAmount } from '@arrived/common';
import { Offering } from '@arrived/models';

import { ProductInfoBox, ProductInfoBoxProps } from '../../../../productInfoBox';
import { DetailDataPoint } from './DetailDataPoint';

export type TotalPropertyAmountProps = ProductInfoBoxProps & {
  offering: Offering;
};

export const TotalPropertyAmount = ({ offering, ...rest }: TotalPropertyAmountProps) => {
  const totalPropertyAmount = useMemo(() => getTotalPropertyAmount(offering), [offering]);

  return (
    <ProductInfoBox variant="highlightAlt" {...rest}>
      <DetailDataPoint
        alignment="center"
        flex={false}
        tooltip={{
          content: <FormattedMessage id="product-details.total-property-amount.tooltip" />,
          title: <FormattedMessage id="product-details.total-property-amount" />,
        }}
      >
        <DataPoint.Value>
          <FormattedNumber
            style="currency"
            currency="USD"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            value={totalPropertyAmount}
          />
        </DataPoint.Value>
        <DataPoint.Label>
          <FormattedMessage id="product-details.total-property-amount" />
        </DataPoint.Label>
      </DetailDataPoint>
    </ProductInfoBox>
  );
};

import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { LayoutChangeEvent, PixelRatio } from 'react-native';

import Animated, {
  Easing,
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { BlurView, Image, ImageProps, Stack, StackProps, TamaguiElement, composeEventHandlers } from '@arrived/bricks';
import { ImageKitCrop, cdnToImageUrl, ikClient } from '@arrived/imagekit';

const AnimatedStack = Animated.createAnimatedComponent(Stack);

/**
 * Gets the image source for an image that is optimized for ImageKit
 */
export const getImageKitSource = ({
  uri,
  height,
  width,
  blur,
  ratio,
  crop = 'maintain_ratio',
  quality = 100,
  sizeTransform = PixelRatio.getPixelSizeForLayoutSize,
}: {
  uri: string;
  height?: number;
  width?: number;
  quality?: number;
  ratio?: string;
  blur?: number;
  crop?: ImageKitCrop;
  sizeTransform?: (pixels: number) => number;
}) => {
  const transformation = [
    Object.fromEntries(
      [
        ['height', height ? sizeTransform(height).toString() : undefined],
        ['width', width ? sizeTransform(width).toString() : undefined],
        ['ratio', ratio],
        ['quality', quality.toString()],
        ['crop', crop],
        ['blur', blur?.toString()],
      ].filter(([_, value]) => value !== '0' && value !== undefined),
    ),
  ];

  return ikClient.url({
    path: cdnToImageUrl(uri),
    transformation,
  });
};

export type LazyImageProps = Omit<StackProps, 'opacity'> & {
  source: { uri: string };
  ImageProps?: Omit<
    ImageProps,
    'placeholderContentFit' | 'transition' | 'source' | 'placeholder' | 'width' | 'height'
  > & {
    /**
     * The blur value of the image from 0 (no blur) - 100 (lots of blur)
     */
    blur?: number;
    /**
     * Quality of the image from 0 (lowest quality) - 100 (highest quality)
     */
    quality?: number;
    /**
     * The dimensions ratio of the image
     */
    ratio?: `${number}:${number}`;
    /**
     * Crop mode
     */
    crop?: ImageKitCrop;
    /**
     * Transformation function to invoke on the height/width of the image before it is requested from the CDN. Defaults
     * to PixelRatio.getPixelSizeForLayoutSize
     */
    sizeTransform?: (pixel: number) => number;
  };
};

/**
 * Progressive image loading for Images. This does not allow
 * for `require` images, only `uri` images.
 *
 * This works by assuming we have a cached image by default,
 * this allows us to instant load an image from the cache if it is there. Otherwise,
 * the images can take a while to load. So the thumbnail will always load anyway and if we
 * don't have a cached version of the image, set the animation state to `0` until the image
 * is ready.
 */
export const LazyImage = forwardRef<TamaguiElement, LazyImageProps>(
  ({ enterStyle, exitStyle, source, ImageProps, onLayout, ...rest }, ref) => {
    const { blur, crop, contentFit, quality, ratio, onLoad, sizeTransform, ...ImagePropsRest } = ImageProps || {};

    const [isOverlayVisible, setIsOverlayVisible] = useState(true);

    const [width, setWidth] = useState<number>();
    const [height, setHeight] = useState<number>();

    const [images, setImages] = useState<{ full: string; placeholder: string }>();

    const updateImages = useCallback(
      ({ width, height }: { width: number; height: number }) => {
        setImages({
          full: getImageKitSource({ uri: source.uri, crop, height, width, ratio, blur, quality, sizeTransform }),
          placeholder: getImageKitSource({
            uri: source.uri,
            crop,
            height,
            width,
            ratio,
            blur: 35,
            quality: 10,
            sizeTransform,
          }),
        });
      },
      [blur, crop, quality, ratio, sizeTransform, source.uri],
    );

    /**
     * This effect basically does a debounce of updating the image source values unless there are currently
     * no images set. It will only update the images iff the layout value has been set. If there is no layout
     * it doesn't know what dimensions to request the images at.
     */
    useEffect(() => {
      let cancelled = false;

      if (!width || !height) {
        return;
      }

      if (images == null) {
        updateImages({ width, height });
      } else {
        setTimeout(() => {
          if (!cancelled) {
            updateImages({ width, height });
          }
        }, 1000);
      }

      return () => {
        cancelled = true;
      };
    }, [width, height]);

    /**
     * Used to track the image load state so we can animate the overlay ontop of the image and hide it once an image has
     * been loaded
     */
    const imageLoadState = useSharedValue<0 | 1>(0);

    useEffect(() => {
      imageLoadState.value = 0;
      setIsOverlayVisible(true);
    }, [source.uri]);

    const overlayStyle = useAnimatedStyle(
      () => ({
        opacity: withTiming(
          interpolate(imageLoadState.value, [0, 1], [1, 0]),
          {
            duration: 280,
            easing: Easing.bezier(0.25, 0.1, 0.25, 1),
          },
          () => {
            runOnJS(setIsOverlayVisible)(false);
          },
        ),
      }),
      [imageLoadState],
    );

    const handleOnLayout = useMemo(
      () =>
        composeEventHandlers<LayoutChangeEvent>((e) => {
          setWidth(e.nativeEvent.layout.width);
          setHeight(e.nativeEvent.layout.height);
        }, onLayout),
      [onLayout],
    );

    const handleOnLoad = useMemo(
      () =>
        composeEventHandlers(() => {
          imageLoadState.value = 1;
        }, onLoad),
      [onLoad],
    );

    useEffect(() => {
      // ! This is needed for Flash List to properly reset the image animation !
      imageLoadState.value = 0;
    }, [source.uri, imageLoadState]);

    return (
      <Stack
        animation="quick"
        opacity={1}
        enterStyle={{ opacity: 0, ...enterStyle }}
        exitStyle={{ opacity: 0, ...exitStyle }}
        ref={ref}
        onLayout={handleOnLayout}
        {...rest}
      >
        <Image
          source={images?.full}
          // Placeholder images appear not to be working, haven't dug into it much but there's
          // a nice transition animation that Image adds between empty and the image here
          placeholder={images?.placeholder}
          transition={300}
          onLoad={handleOnLoad}
          width={width}
          height={height}
          contentFit={contentFit ?? 'cover'}
          placeholderContentFit={contentFit ?? 'cover'}
          {...ImagePropsRest}
        />
        {isOverlayVisible && (
          <AnimatedStack fullscreen pointerEvents="none" style={overlayStyle}>
            <BlurView />
          </AnimatedStack>
        )}
      </Stack>
    );
  },
);

/**
 * A list of keys used to store data in MMKV, our mobile key-value store.
 * @link [react-native-mmkv](https://github.com/mrousavy/react-native-mmkv)
 *
 * @note These keys are only used within native app (mobile-app) and are not shared with the web.
 */
export const MMKV_KEY_NAMES = Object.freeze({
  PORTFOLIO_ACCOUNT_VALUE: 'accountValue',
  PORTFOLIO_DIVIDEND_VALUE: 'dividendValue',
  IS_PORTFOLIO_PRIVACY_MODE_ENABLED: 'isPortfolioPrivacyModeEnabled',
  /**
   * Use this to get the current portfolio ROI value selected
   * @alias roiMetricDisplayType
   */
  PORTFOLIO_ROI_VALUE: 'roiValue',
  AUTH0_CREDENTIALS: 'authContext',
  AUTH0_USER: 'authUser',
  IS_DISCLAIMER_ACCORDION_OPEN: 'isDisclaimerAccordionOpen',
  ONBOARDING_FLOW: 'onboardingFlow',
  HIDE_NEW_TO_ARRIVED_LEARNING_CENTER: 'hideNewToArrivedLearningCenter',
  APP_STATE: 'appState',
  /**
   * Used for the "lifetime" data of the app, meaning when
   * a user uninstalls the app, this data is cleared.
   */
  APP_LIFETIME: 'appLifetime',
  APP_STATE_CURRENT: 'currentAppState',
  QUERY_CLIENT_STORAGE: 'queryClientStorage',
  /**
   * We use this to show a feedback notifications after
   * the user submitted a crash report.
   */
  HAS_RECENTLY_SUBMITTED_CRASH_FEEDBACK: 'hasRecentlySubmittedCrashFeedback',
  HAS_DISMISSED_NOTIFY_ME_CALLOUT: 'hasDismissedNotifyMeCallout',

  /**
   * Current Selected Environment from developer menu
   * @developmentOnly
   */
  DEVELOPMENT_ENVIRONMENT: 'developmentEnvironment',

  /**
   * Use this to check if biometrics are enabled on device within
   * the application settings
   */
  IS_BIOMETRICS_ENABLED: 'isBiometricsEnabled',

  /**
   * Use this to handle authentication with biometrics, this is set to true
   * when the user uses biometrics to log in and is set to false when the
   * timeout is reached (currently 3 seconds as of 7/10/2024)
   */
  IS_BIOMETRICS_AUTHENTICATED: 'isBiometricsAuthenticated',

  /**
   * Use this to check if the user has seen the biometrics modal
   * after their first login
   */
  HAS_SEEN_BIOMETRICS_MODAL: 'hasSeenBiometricsModal',

  /**
   * If the app should be hidden, it will add a blur layer to the app
   */
  IS_APP_HIDDEN: 'isAppHidden',
} as const);

export type MMKV_KEY_NAMES = (typeof MMKV_KEY_NAMES)[keyof typeof MMKV_KEY_NAMES];

import { memo, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { DataPoint } from '@arrived/bricks';
import { OfferingTransactState, enUS, getAvailableOfferingFunds, isOfferingSoldOut } from '@arrived/common';
import { useIsOfferingBlocked, useOfferingTransactState } from '@arrived/hooks';
import { Offering, OfferingStatus } from '@arrived/models';

import { useProductWidgetContext } from '../ProductWidgetContext';
import { FormattedPrimaryDataPoint } from './FormattedPrimaryDataPoint';
import { StyledDataPoint, StyledDataPointProps } from './StyledDataPoint';

const useHelperTextId = (offering: Offering): keyof typeof enUS => {
  const offeringTransactState = useOfferingTransactState(offering);

  const isOfferingBlocked = useIsOfferingBlocked()(offering.shortName);

  return useMemo(() => {
    if (isOfferingBlocked || offering.status === OfferingStatus.PAUSED) {
      return 'common.paused';
    }

    switch (offeringTransactState) {
      case OfferingTransactState.CLOSED:
        return 'common.invested';
      case OfferingTransactState.IN_PROGRESS:
        return 'common.available.remaining-investment-amount';
      default:
        return 'common.coming-soon';
    }
  }, [offeringTransactState, offering.status, isOfferingBlocked]);
};

export const IPODataPoint = memo((props: StyledDataPointProps) => {
  const { offering } = useProductWidgetContext();

  const value = useMemo(
    () => (isOfferingSoldOut(offering) ? offering.targetRaiseAmount : getAvailableOfferingFunds(offering)),
    [offering],
  );

  return (
    <StyledDataPoint {...props}>
      <DataPoint.Value>
        <FormattedPrimaryDataPoint value={value} />
      </DataPoint.Value>
      <DataPoint.Label color="$onSurface.neutral.muted" flexWrap="wrap">
        <FormattedMessage id={useHelperTextId(offering)} />
      </DataPoint.Label>
    </StyledDataPoint>
  );
});

import { useQuery } from '@tanstack/react-query';

import { GetSalesQueryParams, getSales } from '@arrived/api_v2';

import { useGetPrimaryAccountQuery } from '../users';
import { UseAwaitedQueryOptions } from '../utils';
import { salesQueryKeyFn } from './sales.keys';

export function useGetAccountSalesQuery(
  queryParams?: GetSalesQueryParams,
  options?: UseAwaitedQueryOptions<typeof getSales>,
) {
  const { data: accountData } = useGetPrimaryAccountQuery();

  return useQuery({
    queryKey: salesQueryKeyFn(accountData?.cid!, queryParams),
    queryFn: () => getSales({ accountCid: accountData?.cid, ...queryParams }),
    ...options,
    enabled: (options?.enabled ?? true) && accountData?.cid != null,
  });
}

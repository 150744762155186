import { KeyboardEvent, useCallback, useRef } from 'react';

import { Controller, FieldValues, Path } from 'react-hook-form';
import PlacesAutocomplete, {
  PropTypes as PlacesAutocompleteProps,
  Suggestion,
  geocodeByPlaceId,
} from 'react-places-autocomplete';

import clsx from 'clsx';

import Search from '@mui/icons-material/Search';
import { List, ListItem, ListItemText, Paper, Popper, TextField } from '@mui/material';

import { parseAddressFromGoogle } from '@arrived/common';
import { Address, OmitBetter } from '@arrived/models';

import { FormTextFieldProps } from '../../../form';

// TODO: We should just get rid of this library react-places-autocomplete as it's pretty old and these other hook-based
//  replacements that are less imposing of their will. This could be one option https://github.com/wellyshen/use-places-autocomplete
//  or we can just write something in-house.
export const AddressAutocomplete = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
  controllerProps,
  onAutocompleteAddressSelected,
  ...textFieldProps
}: OmitBetter<FormTextFieldProps<TFieldValues, TName>, 'autoComplete'> & {
  onAutocompleteAddressSelected?: (address: Address) => void;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const renderInput: PlacesAutocompleteProps['children'] = useCallback(
    ({ getInputProps, getSuggestionItemProps, suggestions, loading }) => {
      const { onKeyDown, autoComplete: _1, 'aria-autocomplete': _2, ...inputProps } = getInputProps();

      const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onKeyDown(event);
      };

      return (
        <>
          <TextField
            id="a-text" // Note: Intentionally cryptic to avoid triggering autocomplete functionality
            inputRef={inputRef}
            InputProps={{ startAdornment: <Search />, ...textFieldProps.InputProps }}
            onKeyDown={handleOnKeyDown}
            autoComplete="new-password"
            {...textFieldProps}
            {...inputProps}
          />
          <Popper
            anchorEl={inputRef.current}
            open={suggestions.length > 0 || loading}
            placement="bottom-start"
            sx={{
              zIndex: 1500 /* zIndex here was a quick fix 04/23/2024. Not sure why it broke but should be revisited since its MUI */,
            }}
          >
            <Paper sx={{ overflow: 'hidden', background: '' }}>
              <List sx={{ p: 0 }}>
                {suggestions.map((suggestion) => {
                  const { key, ...props } = getSuggestionItemProps(suggestion, {
                    className: clsx('suggestion', { active: suggestion.active }),
                  });

                  return (
                    <ListItem
                      {...props}
                      key={key}
                      sx={{
                        backgroundColor: ({ palette }) => (suggestion.active ? palette.background.iceGray : undefined),
                        '&:hover': { cursor: 'pointer' },
                      }}
                    >
                      <ListItemText
                        primary={suggestion.formattedSuggestion.mainText}
                        primaryTypographyProps={{
                          variant: 'body1.semibold',
                        }}
                        secondary={suggestion.formattedSuggestion.secondaryText}
                        secondaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </Popper>
        </>
      );
    },
    [textFieldProps],
  );

  return (
    <Controller
      {...controllerProps}
      render={({ field: { value, ...rest } }) => {
        const handleOnSelect = async (_: string, placeId: string, suggestion?: Suggestion) => {
          if (!suggestion || !onAutocompleteAddressSelected) {
            return;
          }

          const addressesFromPlaceId = await geocodeByPlaceId(placeId);
          const addressFromPlaceId = addressesFromPlaceId[0];
          const parsedAddress = parseAddressFromGoogle(suggestion, addressFromPlaceId);

          onAutocompleteAddressSelected(parsedAddress);
        };

        return (
          <PlacesAutocomplete onSelect={handleOnSelect} value={value ?? ''} {...rest}>
            {renderInput}
          </PlacesAutocomplete>
        );
      }}
    />
  );
};

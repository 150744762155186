import { QueryClient, useQuery } from '@tanstack/react-query';

import { getSharesAvailableForUser } from '@arrived/api_v2';
import { OfferingId } from '@arrived/models';

import { fetchCurrentUserQuery, useGetCurrentUserQuery } from '../users';
import { UseAwaitedQueryOptions } from '../utils';
import { userSharesAvailableKeyFn } from './sharesAvailable.keys';

export function useSharesAvailableForUserQuery(
  offeringId?: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getSharesAvailableForUser>,
) {
  const { data } = useGetCurrentUserQuery();
  const userId = data?.id;

  return useQuery({
    queryKey: userSharesAvailableKeyFn(offeringId!, userId!),
    queryFn: () => getSharesAvailableForUser(userId!, offeringId!),
    ...options,
    enabled: (options?.enabled ?? true) && offeringId != null && userId != null,
  });
}

export const fetchSharesAvailableForUserQuery = async (queryClient: QueryClient, offeringId: OfferingId) => {
  const user = await fetchCurrentUserQuery(queryClient);
  return queryClient.fetchQuery({
    queryKey: userSharesAvailableKeyFn(offeringId, user.id),
    queryFn: () => getSharesAvailableForUser(user.id, offeringId),
  });
};

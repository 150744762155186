import { memo, useCallback } from 'react';
import { LayoutChangeEvent } from 'react-native';

import { SafeAreaView } from 'react-native-safe-area-context';

import { GetProps, Stack, composeEventHandlers, isWeb, styled, withStaticProperties } from '@tamagui/core';

import { TitleText } from '../../atoms';
import { CloseIcon, LeftIcon } from '../../icons';
import { Button, ButtonProps } from '../button';
import { useSheetImperativeContext } from './SheetImperativeContext';
import { SheetStyledContext } from './SheetStyledContext';

export const SHEET_HEADER_NAME = 'SheetHeader';
export const SHEET_HEADER_ADORNMENT_NAME = 'SheetHeaderAdornment';
export const SHEET_HEADER_TITLE_NAME = 'SheetHeaderTitle';

const SheetHeaderTitleText = styled(TitleText, {
  name: SHEET_HEADER_TITLE_NAME,
  id: 'bricks-sheet-title',

  token: 'title.heading.large',
  textAlign: 'center',
  flex: 1,
});

const SheetHeaderBackIcon = memo(({ onPress, ...rest }: ButtonProps) => {
  const context = useSheetImperativeContext();

  return (
    <Button
      Icon={LeftIcon}
      variant="ghost"
      condensed
      position="absolute"
      left={0}
      zIndex={1}
      onPress={composeEventHandlers(onPress, context.onBack)}
      {...rest}
    />
  );
});

const SheetHeaderCloseIcon = memo(({ onPress, ...rest }: ButtonProps) => {
  const context = useSheetImperativeContext();

  return (
    <Button
      Icon={CloseIcon}
      data-e2e="sheet-header-close-icon"
      variant="ghost"
      condensed
      position="absolute"
      right={0}
      top={0}
      zIndex={1}
      onPress={composeEventHandlers(onPress, context.onClose)}
      {...rest}
    />
  );
});

const SheetHeaderTitleFrame = styled(Stack, {
  name: 'SheetHeaderTitleFrame',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const SheetHeaderTitle = withStaticProperties(SheetHeaderTitleFrame, {
  Text: SheetHeaderTitleText,
});

const SheetHeaderFrame = styled(Stack, {
  name: SHEET_HEADER_NAME,
  pt: '$4',
  px: '$4',
  pb: '$3',

  $gtXxs: {
    pt: '$6',
    px: '$6',
  },

  $gtSm: {
    pt: '$10',
    px: '$10',
  },
});

export type SheetHeaderProps = GetProps<typeof SheetHeaderFrame> & { ignoreSafeArea?: boolean };

/**
 * The header container of the sheet. This takes a `Title` and `Divider` children.
 */
export const SheetHeader = withStaticProperties(
  SheetHeaderFrame.styleable<SheetHeaderProps>((propsIn, ref) => {
    // Avoiding spreading `ignoreSafeArea` onto the header frame, and throwing a console error
    const { ignoreSafeArea, ...props } = propsIn;

    const { setHeaderDimensions } = useSheetImperativeContext();
    const { ignoreSafeAreaTop } = SheetStyledContext.useStyledContext();

    const handleHeaderHeight = useCallback(
      (e: LayoutChangeEvent) => {
        setHeaderDimensions?.(e.nativeEvent.layout);
      },
      [setHeaderDimensions],
    );

    return (
      // We need to watch for devices with the notch
      <SafeAreaView
        edges={ignoreSafeAreaTop || ignoreSafeArea ? [] : ['top']}
        onLayout={!isWeb ? handleHeaderHeight : undefined}
      >
        <SheetHeaderFrame ref={ref} {...props} />
      </SafeAreaView>
    );
  }),
  {
    Title: SheetHeaderTitle,
    BackIcon: SheetHeaderBackIcon,
    CloseIcon: SheetHeaderCloseIcon,
  },
);

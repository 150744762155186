import { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getReferralCode } from '@arrived/api_v2';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { referralsQueryKey, referralsQueryKeyFn } from './referrals.keys';

export function useGetReferralCodeQuery(options?: UseAwaitedQueryOptions<typeof getReferralCode>) {
  const queryClient = useQueryClient();

  const referralCodeState = useQuery({
    queryKey: referralsQueryKey,
    queryFn: () => getReferralCode(),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });

  useEffect(() => {
    if (referralCodeState.isSuccess && referralCodeState.data) {
      queryClient.setQueryData(referralsQueryKeyFn(referralCodeState.data), referralCodeState.data!);
    }
  }, [referralCodeState.isSuccess, referralCodeState.data]);

  return referralCodeState;
}

import { SVGProps } from 'react';

export interface ParallelogramProps extends SVGProps<SVGSVGElement> {
  color: string;
}

export const Parallelogram = ({ color, ...rest }: ParallelogramProps) => {
  return (
    <svg width={40} height={138} viewBox="0 0 40 138" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d={`M40 0L40 098L0 138L0 40L40 0`} fill={color} />
    </svg>
  );
};

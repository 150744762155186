import { useQuery } from '@tanstack/react-query';

import { CONFIG } from '@arrived/config';

import { ArrivedBuilderModels, ArrivedBuilderSymbols } from '../models';
import { FetchOneEntryParams, fetchEntries, fetchOneEntry } from '../sdk';
import { builderSymbolsQueryKeyFn } from './keys';

type FetchBuilderSymbolParams = {
  symbolId: ArrivedBuilderSymbols;
} & FetchOneEntryParams;

export const fetchBuilderSymbol = ({ symbolId, query, ...rest }: FetchBuilderSymbolParams) =>
  fetchOneEntry({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.SYMBOL,
    query: {
      id: {
        $eq: symbolId,
      },
      ...query,
    },
    ...rest,
  });

/**
 * Used to get a singular Builder symbol
 */
export const useGetBuilderSymbolQuery = (params: FetchBuilderSymbolParams) =>
  useQuery({
    queryKey: builderSymbolsQueryKeyFn(params),
    queryFn: () => fetchBuilderSymbol(params),
  });

export const fetchAllBuilderSymbols = ({ symbolId, query, ...rest }: FetchBuilderSymbolParams) =>
  fetchEntries({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.SYMBOL,
    query: {
      id: {
        $eq: symbolId,
      },
      ...query,
    },
    ...rest,
  });

/**
 * Used to get a list of Builder symbols (ie: Webinar recordings)
 */
export const useGetArrivedBuilderSymbolsQuery = (params: FetchBuilderSymbolParams) =>
  useQuery({
    queryKey: builderSymbolsQueryKeyFn(params),
    queryFn: () => fetchAllBuilderSymbols(params),
  });

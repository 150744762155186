import { Fragment, useMemo } from 'react';

import { Stack, useMedia } from '@arrived/bricks';
import { AssetType, Offering } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../content';
import { LeverageDataPoint } from './LeverageDataPoint';
import { PurchasePriceDataPoint } from './PurchasePriceDataPoint';
import { RentDataPoint, propertyHasRent } from './RentDataPoint';
import { PreFormattedDataPointProps } from './types';

export type AssetValueSectionProps = ProductDetailContentProps & {
  offering: Offering;
};

export const AssetValueSection = ({ offering, ...rest }: AssetValueSectionProps) => {
  const dataPoints = useMemo(() => {
    const dataPoints = [
      (props: PreFormattedDataPointProps) => <PurchasePriceDataPoint offering={offering} {...props} />,
    ];

    const property = offering.properties[0];
    if (offering.assetType === AssetType.LTR && property && propertyHasRent(property)) {
      dataPoints.push((props: PreFormattedDataPointProps) => <RentDataPoint property={property} {...props} />);
    }

    dataPoints.push((props: PreFormattedDataPointProps) => <LeverageDataPoint offering={offering} {...props} />);

    return dataPoints;
  }, [offering]);

  const media = useMedia();

  return (
    <ProductDetailContent
      border="always"
      br="$2"
      $platform-native={{
        flex: 1,
      }}
      {...rest}
    >
      <ProductDetailContent.Body>
        <Stack row gap="$6" alignItems="center">
          {dataPoints.slice(0, !media.gtXs ? 2 : undefined).map((DataPoint, idx) => (
            <Fragment key={idx}>
              {idx !== 0 && <ProductDetailContent.Divider.Vertical solid alt height={36} />}
              <DataPoint alignSelf="flex-start" flexBasis={0} flexGrow={1} flexShrink={1} />
            </Fragment>
          ))}
        </Stack>
        {!media.gtXs && dataPoints.length > 2 && (
          <Stack row gap="$6" alignItems="center">
            {dataPoints.slice(2).map((DataPoint, idx) => (
              <Fragment key={idx}>
                {idx !== 0 && <ProductDetailContent.Divider.Vertical solid alt height={36} />}
                <DataPoint alignSelf="flex-start" flexBasis={0} flexGrow={1} flexShrink={1} />
              </Fragment>
            ))}
          </Stack>
        )}
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};

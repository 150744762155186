import { RefObject, forwardRef } from 'react';

import { RemoveScroll } from '@tamagui/remove-scroll';
import { ScrollView, ScrollViewProps } from '@tamagui/scroll-view';

import { Stack } from '../../../atoms';
import { useSheetImperativeContext } from '../SheetImperativeContext';
import { SheetScrollViewImpl } from './SheetScrollViewImpl';

// We just want a standard ScrollView, there is nothing really special about `SheetScrollViewFrame`
// If we do not use this, TS claims that it is infinitely instantiating the type
export type SheetScrollViewProps = ScrollViewProps & {
  /**
   * Enable or disable the scroll fade effect on the bottom of the sheet.
   * @default true
   */
  useScrollFade?: boolean;

  /**
   * Optionally switch to a standard `ScrollView` instead of the `Sheet.ScrollView`.
   *
   * This is to accommodate using Sheet component innards on native within a react navigation
   * modal, instead of a bottom sheet portal.
   */
  useStandardScrollView?: boolean;

  bottomPadding?: boolean;

  removeScrollProps?: {
    /**
     * disables "event isolation" (suppressing of events happening outside of the Lock)
     * @default false
     */
    noIsolation?: boolean;
    /**
     * enabled complete Lock isolation using `pointer-events:none` for anything outside the Lock
     * you probably don't need it, except you do
     * @default false
     */
    inert?: boolean;
    /**
     * allows pinch-zoom, however might work not perfectly for normal scroll
     * @default true
     */
    allowPinchZoom?: boolean;

    /**
     * switches on/off the behavior of the component
     */
    enabled?: boolean;

    /**
     * Controls the body scroll bar removal
     * @default true
     */
    removeScrollBar?: boolean;

    /**
     * array of refs to other Elements, which should be considered as a part of the Lock
     */
    shards?: Array<RefObject<unknown> | HTMLElement>;
  };
};

/**
 * `SheetScrollView` represents the `Sheet.ScrollView` element that can be used to wrap inner content
 * of the sheet. This has a fading effect attached to it that will fade out the bottom of the sheet.
 * On web this uses `WebkitMaskImage` and on native this uses a `LinearGradient` with a `MaskedView`.
 *
 * We split this up above and have details on how it all works for each component.
 */
export const SheetScrollView = forwardRef<ScrollView, SheetScrollViewProps>(({ removeScrollProps, ...props }, ref) => {
  const { open } = useSheetImperativeContext();

  const {
    noIsolation = false,
    inert,
    allowPinchZoom = true,
    enabled = open,
    // From tamagui devs: causes lots of bugs on touch web on site
    // From bricks devs: I enabled it because it works for us :)
    removeScrollBar = true,
    shards,
  } = removeScrollProps ?? {};

  return (
    // This exists here as the children need to be able to scroll
    // but everything else should be disabled
    <RemoveScroll
      ref={ref as never}
      forwardProps
      noIsolation={noIsolation}
      inert={inert}
      allowPinchZoom={allowPinchZoom}
      enabled={enabled}
      removeScrollBar={removeScrollBar}
      shards={shards}
    >
      {/*
        For some reason, the props don't get correctly forwarded onto the
        SheetContentFrame, so we need to add a quick wrapper to fix it
      */}
      <Stack flex={1}>
        <SheetScrollViewImpl ref={ref as never} {...props} />
      </Stack>
    </RemoveScroll>
  );
});

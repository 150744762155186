import { useEffect, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getOfferings } from '@arrived/api_v2';
import { OfferingQueryParams, OfferingQuerySort } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';
import { offeringKeyFn, offeringsKeyFn } from './offerings.keys';

export function useOfferingsQuery(query?: OfferingQueryParams, options?: UseAwaitedQueryOptions<typeof getOfferings>) {
  const newQuery = useMemo(
    () => ({ size: 1000, sort: OfferingQuerySort.OFFERINGS_SORT_PROPERTY_RANK_DESC, ...query }),
    [query],
  );

  const queryClient = useQueryClient();

  const offeringsState = useQuery({
    queryKey: offeringsKeyFn(newQuery),
    queryFn: () => getOfferings(newQuery),
    ...options,
  });

  useEffect(() => {
    if (offeringsState.data) {
      offeringsState.data?.forEach((offering) => {
        queryClient.setQueryData(offeringKeyFn(offering.id), offering, { updatedAt: Date.now() });
        queryClient.setQueryData(offeringKeyFn(offering.shortName), offering, { updatedAt: Date.now() });
      });
    }
  }, [offeringsState.data]);

  return offeringsState;
}

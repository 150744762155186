import { useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint } from '@arrived/bricks';
import { useInvestmentProductType } from '@arrived/hooks';
import { AssetType, InvestmentProductType, Offering, STRMetadata } from '@arrived/models';

import { ProductInfoBox, ProductInfoBoxProps } from '../../../../productInfoBox';
import { DetailDataPoint } from './DetailDataPoint';
import { InfoBoxRow } from './InfoBoxRow';

export type AdditionalDetailsProps = ProductInfoBoxProps & { strMetadata?: STRMetadata; offering: Offering };

export const AdditionalDetails = ({ offering, strMetadata, ...rest }: AdditionalDetailsProps) => {
  const investmentProductType = useInvestmentProductType(offering);

  const aumGrossRevenues = useMemo(() => {
    if (strMetadata?.feeData?.aumGrossRevenues) {
      return parseInt(strMetadata.feeData.aumGrossRevenues) / 100;
    }

    // Return `undefined` here to indicate that we don't have a value for this
    return;
  }, [strMetadata?.feeData?.aumGrossRevenues]);

  /**
   * Whether or not to show `{strMetadata.feeData.aumGrossRevenues}% of Gross Rents` or just the dollar amount for the AUM fee
   * - `true`: Show `{strMetadata.feeData.aumGrossRevenues}% of Gross Rents`
   * - `false`: Show dollar amount
   */
  const showAumPercentageFee = useMemo(() => {
    if (investmentProductType === InvestmentProductType.VACATION_RENTAL_IPO && aumGrossRevenues != null) {
      return true;
    }

    return offering.quarterlyAumFeeAmount != null;
  }, [investmentProductType, strMetadata?.feeData?.aumGrossRevenues, offering.quarterlyAumFeeAmount]);

  return (
    <ProductInfoBox {...rest}>
      <InfoBoxRow>
        <DetailDataPoint
          tooltip={{
            content: <FormattedMessage id="product-details.ipo-price-per-share.tooltip" />,
            title: <FormattedMessage id="product-details.ipo-price-per-share" />,
          }}
        >
          <DataPoint.Value>
            <FormattedNumber
              style="currency"
              currency="USD"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              // TODO: Should be pulled from the API somehow
              value={10}
            />
          </DataPoint.Value>
          <DataPoint.Label>
            <FormattedMessage id="product-details.ipo-price-per-share" />
          </DataPoint.Label>
        </DetailDataPoint>
        <InfoBoxRow.Divider />
        <DetailDataPoint
          tooltip={{
            content: <FormattedMessage id="product-details.total-shares.tooltip" />,
            title: <FormattedMessage id="product-details.total-shares" />,
          }}
        >
          <DataPoint.Value>
            <FormattedNumber minimumFractionDigits={0} maximumFractionDigits={0} value={offering.totalShares} />
          </DataPoint.Value>
          <DataPoint.Label>
            <FormattedMessage id="product-details.total-shares" />
          </DataPoint.Label>
        </DetailDataPoint>
      </InfoBoxRow>
      <InfoBoxRow>
        <DetailDataPoint
          tooltip={{
            content: (
              <FormattedMessage
                id={
                  offering.assetType === AssetType.LTR
                    ? 'product-details.hold-period.tooltip.ltr'
                    : 'product-details.hold-period.tooltip.str'
                }
              />
            ),
            title: <FormattedMessage id="product-details.hold-period" />,
          }}
        >
          <DataPoint.Value>
            <FormattedMessage
              id="product-details.hold-period.range"
              values={{ min: offering.holdPeriodYearsMin, max: offering.holdPeriodYearsMax }}
            />
          </DataPoint.Value>
          <DataPoint.Label>
            <FormattedMessage id="product-details.hold-period" />
          </DataPoint.Label>
        </DetailDataPoint>
        {offering.quarterlyAumFeeAmount != null && (
          <>
            <InfoBoxRow.Divider />
            <DetailDataPoint
              tooltip={{
                content: <FormattedMessage id="product-details.asset-management-fee.tooltip" />,
                title: <FormattedMessage id="product-details.asset-management-fee" />,
              }}
            >
              <DataPoint.Value>
                {showAumPercentageFee && aumGrossRevenues && !isNaN(aumGrossRevenues) ? (
                  <FormattedMessage
                    id="product-details.asset-management-fee-gross-rents"
                    values={{
                      aumGrossRevenues: (
                        <FormattedNumber
                          style="percent"
                          minimumFractionDigits={0}
                          maximumFractionDigits={2}
                          value={aumGrossRevenues}
                        />
                      ),
                    }}
                  />
                ) : (
                  <FormattedNumber
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                    value={offering.quarterlyAumFeeAmount}
                  />
                )}
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage id="product-details.asset-management-fee" />
              </DataPoint.Label>
            </DetailDataPoint>
          </>
        )}
      </InfoBoxRow>
    </ProductInfoBox>
  );
};

import { ReactNode, useCallback, useMemo } from 'react';

import { Box, BoxProps } from '@mui/material';

import { ArrivedLogoIcon, ArrivedLogoImage, ArrivedLogoWhiteIcon, ArrivedLogoWhiteImage } from '@arrived/images';

export interface ArrivedLogoProps extends BoxProps {
  iconOnly?: boolean;
  whiteLogo?: boolean;
}

const LOGO_ICON_ONLY_MAP: Record<string, ReactNode> = {
  standard: <ArrivedLogoIcon />,
  white: <ArrivedLogoWhiteIcon />,
};

const LOGO_MAP: Record<string, ReactNode> = {
  standard: <ArrivedLogoImage />,
  white: <ArrivedLogoWhiteImage />,
};

export const ArrivedLogo = ({ whiteLogo, iconOnly, sx, ...rest }: ArrivedLogoProps) => {
  const logoKey = useMemo(() => (whiteLogo ? 'white' : 'standard'), [whiteLogo]);

  const Icon = useCallback(
    () => <>{iconOnly ? LOGO_ICON_ONLY_MAP[logoKey] : LOGO_MAP[logoKey]}</>,
    [iconOnly, logoKey],
  );

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      py={2}
      width={iconOnly ? '36px' : '154px'}
      sx={{
        '& svg': {
          width: 'auto',
          height: iconOnly ? '36px' : '32px',
        },
        ...sx,
      }}
      {...rest}
    >
      <Icon />
    </Box>
  );
};

export default {
  'sheets.diversify-with-funds.title': 'Instant Diversification and Investment Liquidity',

  'sheets.diversify-with-funds.diversification':
    '**Instant diversification**: diversify across multiple properties and markets.',

  'sheets.diversify-with-funds.liquidity':
    '**Investment liquidity**: liquidity options available six months post-investment.',

  'sheets.diversify-with-fund.cta': 'See the Fund',

  'sheets.diversify-with-fund.new': 'New!',

  'sheets.private-credit-fund.new': 'New!',
  'sheets.private-credit-fund.header': '7-9% Annual Dividend Expected',
  'sheets.private-credit-fund.higher-yield':
    '**Higher-yield, income focus**: the Arrived Private Credit Fund aims to generate annual dividends of 7-9%, after factoring in all expenses and fees.',
  'sheets.private-credit-fund.capital-preservation':
    '**Capital preservation**: loans in the fund are secured with residential property as collateral, providing security and capital preservation.',
  'sheets.private-credit-fund.complements-equity':
    '**Complement to equity investing**: investing in both debt and equity can be important in building a well-diversified portfolio.',
  'sheets.private-credit-fund.access-to-liquidity':
    '**Access to liquidity**: the fund allows investors to request quarterly redemption of their shares after six months, subject to certain limitations.',
  'sheets.private-credit-fund.cta': 'See the {fund}',

  'sheets.investors-like-you.header.newbies': 'Build Real Estate Equity',
  'sheets.investors-like-you.header.ex-landlord': 'Invest Passively, Earn Actively',
  'sheets.investors-like-you.header.wsj': 'Strategic Portfolio Expansion',
  'sheets.investors-like-you.header': 'Real Estate, Real Simple',

  'sheets.investors-like-you.why-fund.newbies':
    '**Instant diversification**: with one investment diversify across multiple properties and markets\n\n**Liquidity**: enjoy the flexibility of quarterly liquidity options',
  'sheets.investors-like-you.why-fund.ex-landlord':
    '**Diversification**: the Fund diversifies your investment across a large number of Single Family Residential properties and markets',
  'sheets.investors-like-you.why-fund.wsj':
    '**Diversification**: the Fund diversifies your investment across a large number of Single Family Residential properties and markets',
  'sheets.investors-like-you.why-fund':
    '**Instant diversification**: with one investment diversify across multiple properties and markets\n\n**Liquidity**: enjoy the flexibility of quarterly liquidity options',

  'sheets.investors-like-you.why-fund.header': 'Fund Investing Benefits',

  'sheets.investors-like-you.why-ipo.newbies':
    '**Build your portfolio**: hand-picked properties to build your own real estate portfolio with just a click',
  'sheets.investors-like-you.why-ipo.ex-landlord':
    "**Customization**: leverage your real estate knowledge to handpick properties and markets\n\n**Forget the 3T's**: enjoy the benefits of direct property investment without the pain of toilets, taxes, and tenants",
  'sheets.investors-like-you.why-ipo.wsj':
    '**Customization**: build a handpicked portfolio of properties and markets\n\n**Transparency**: leverage your experience and our detailed property information to make investment decisions',
  'sheets.investors-like-you.why-ipo':
    '**Build your portfolio**: hand-picked properties to build your own real estate portfolio\n\n**Customization**: leverage our detailed property information to handpick properties and markets',

  'sheets.investors-like-you.why-ipo.header': 'Individual Property Investing Benefits',

  'sheets.investors-like-you.investment-range.newbies':
    'Based on your responses, investors like you typically start by investing between {low} - {high}:',
  'sheets.investors-like-you.investment-range.ex-landlord':
    'Based on your responses, experienced real estate investors like you typically start by investing between {low} - {high}:',
  'sheets.investors-like-you.investment-range.wsj':
    'Based on your responses, experienced investors like you typically start by investing between {low} - {high}:',
  'sheets.investors-like-you.investment-range':
    'Based on your responses, investors like you typically start by investing between {low} - {high}:',

  'sheets.investors-like-you.ipo-sellout': "Our individual properties sell out fast, and they're all currently funded!",
  'sheets.investors-like-you.ipo-sellout-notify-me':
    "Our individual properties sell out fast, and they're all currently funded! To get notified when new properties drop, <link>sign up for notifications</link>.",

  'sheets.join-a-webinar': 'Join a Webinar',
  'sheets.join-a-webinar.pick-a-date': 'Pick a Date',
  'sheets.join-a-webinar.youre-registered': "You're Registered",

  'sheets.join-a-webinar.header-name.newbies':
    "{name}, we'd love to help you kickstart your Arrived investment journey!",
  'sheets.join-a-webinar.header.newbies': "We'd love to help you kickstart your Arrived investment journey!",
  'sheets.join-a-webinar.description.newbies':
    'Get your questions answered live and discover how easy real estate investing can be, or <a>see a recording</a> at your convenience. Transform curiosity into confidence with our experienced team.',

  'sheets.join-a-webinar.header-name.ex-landlord':
    "{name}, we'd love to help you transition into effortless investing!",
  'sheets.join-a-webinar.header.ex-landlord': "We'd love to help you transition into effortless investing!",
  'sheets.join-a-webinar.description.ex-landlord':
    'Get your questions answered live and refine your investment strategy, or <a>see a recording</a> at your convenience. See how Arrived simplifies real estate and dive into the future of hassle-free property ownership!',

  'sheets.join-a-webinar.header-name.wsj': "{name}, we'd love to help you enhance your investing strategy!",
  'sheets.join-a-webinar.header.wsj': "We'd love to help you enhance your investing strategy!",
  'sheets.join-a-webinar.description.wsj':
    'Get your questions answered live and enhance your portfolio diversification strategy, or <a>see a recording</a> at your convenience. Dive into what drives successful real estate investments!',

  'sheets.join-a-webinar.recording': 'Recording',
  'sheets.join-a-webinar.reserve-your-spot': 'Reserve Your Spot',

  'sheets.join-a-webinar.youll-hear-from': "You'll hear from",
  'sheets.join-a-webinar.ryan-description': 'Co-founder & CEO',
  'sheets.join-a-webinar.korin-description': 'Head of Investor Relations',
  'sheets.join-a-webinar.cameron-description': 'VP of Investments',
  'sheets.join-a-webinar.jake-description': 'Director of Operations',

  'sheets.join-a-webinar.register': 'Register',
  'sheets.join-a-webinar.confirm-date': 'Confirm Your Date',
  'sheets.join-a-webinar.continue-sign-up': 'Continue Sign Up',

  'sheets.join-a-webinar.cofounder-ceo': 'Co-founder & CEO',
  'sheets.join-a-webinar.vp-investments': 'VP of Investments',
  'sheets.join-a-webinar.head-investor-relations': 'Head of Investor Relations',
  'sheets.join-a-webinar.director-operations': 'Director of Operations',

  'sheets.join-a-webinar.excited-to-talk': "We're excited to talk to you!",
  'sheets.join-a-webinar.excited-to-talk-name': "We're excited to talk to you, {name}!",
  'sheets.join-a-webinar.access-link': 'You will receive an email with your webinar access link.',

  'sheets.join-a-webinar.maybe-later': 'Maybe Later',
  'sheets.join-a-webinar.header-old': 'We love talking to investors like you!',
  'sheets.join-a-webinar.header-old-name': 'We love talking to investors like you, {name}!',
  'sheets.join-a-webinar.description-old':
    "Join Arrived's weekly webinars on rental property investing. Gain valuable insights and strategies from our CEO and VP of Investments, Ryan Frazier and Cameron Wu. Engage with industry experts and get your questions answered.\n\nTake the first step towards investment success with Arrived.",

  'sheets.join-a-webinar.topic': 'Topic',
  'sheets.join-a-webinar.date': 'Date',
  'sheets.join-a-webinar.time': 'Time',

  'sheets.app-install.header': "We've got an app for this",
  'sheets.app-install.streamline-experience': 'Streamline your experience',
  'sheets.app-install.description':
    'Experience seamless real estate investing at your fingertips. Download our app for a faster, easier way to explore investment opportunities.',
  'sheets.app-install.cta': 'Download on the app store',
} as const;

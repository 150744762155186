import { ReactNode, forwardRef, useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { TamaguiElement } from '@arrived/bricks';
import { Property, isLeaseStatusRented } from '@arrived/models';

import { useIPOPropertyLocation } from '../../../../../../hooks';
import { ProductListItem, ProductListItemDetailProps, ProductListItemProps } from '../../../../../../productListItem';
import { usePropertyLabel } from './usePropertyLabel';

export type FundPropertyListItemProps = ProductListItemProps & { property: Property; tags?: ReactNode };

export const FundPropertyListItem = forwardRef<TamaguiElement, FundPropertyListItemProps>(
  ({ property, tags, ...rest }, ref) => {
    const helperText = useIPOPropertyLocation(property);

    const details = useMemo(
      () =>
        [
          property.purchasePrice
            ? {
                productDetailValue: (
                  <FormattedNumber
                    value={property.purchasePrice}
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                    notation="compact"
                  />
                ),
                productDetailLabel: <FormattedMessage id="product-details.purchase-price" />,
              }
            : null,
          property.rent
            ? {
                productDetailValue: (
                  <FormattedNumber
                    value={property.rent}
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                ),
                productDetailLabel: isLeaseStatusRented(property.leaseStatus) ? (
                  <FormattedMessage id="product-details.rent" />
                ) : (
                  <FormattedMessage id="product-details.anticipated-rent" />
                ),
              }
            : null,
        ].filter(Boolean) as ProductListItemDetailProps[],
      [property.leaseStatus, property.purchasePrice, property.rent],
    );

    return (
      <ProductListItem ref={ref} {...rest}>
        <ProductListItem.Image imageSource={property.photos[0]?.url ?? null} />
        <ProductListItem.Title>{usePropertyLabel(property)}</ProductListItem.Title>
        {helperText && <ProductListItem.Subtitle>{helperText}</ProductListItem.Subtitle>}
        <ProductListItem.Details details={details} />
        {tags && <ProductListItem.Tags>{tags}</ProductListItem.Tags>}
      </ProductListItem>
    );
  },
);

import { Stack as BricksStack, StackProps } from '@arrived/bricks';

import { BuilderBlock, BuilderDataProps, GenericBuilderIcons, RegisteredComponent } from '../../sdk';
import { getSpacingInputs } from '../../utils';

type BuilderStackProps = StackProps &
  BuilderDataProps & {
    /**
     * Additional attributes to add to the button element from
     * `noWrap` within input options.
     */
    attributes?: Record<string, string>;
    wordWrap?: boolean;
  };

export const Stack = ({
  children,
  builderBlock: __,
  builderContext: _,
  attributes,
  wordWrap = true,
  ...props
}: BuilderStackProps) => {
  return (
    <BricksStack
      flex={1}
      py={0}
      $platform-web={{ wordWrap: wordWrap ? 'break-word' : undefined, ...props['$platform-web'] }}
      {...attributes}
      {...props}
    >
      {children}
    </BricksStack>
  );
};

export const StackBlockElement = (options?: BuilderStackProps) =>
  ({
    '@type': '@builder.io/sdk:Element',
    component: {
      name: 'Core:Section',
      options,
    },
  }) satisfies BuilderBlock;

Stack.registerComponent = {
  component: Stack,
  name: 'Core:Section',
  friendlyName: 'Stack',
  image: GenericBuilderIcons.Stack,
  canHaveChildren: true,
  fragment: true,
  noWrap: true,
  inputs: [
    // For the future, and still needs native support
    // https://github.com/BuilderIO/builder/blob/23878e4ebf02923dd401b479ee1bcef1ad852c07/packages/react/src/blocks/Section.tsx#L122
    // {
    //   name: 'lazyLoad',
    //   type: 'boolean',
    //   defaultValue: false,
    //   advanced: true,
    //   description: 'Only render this section when in view',
    // },
    {
      name: 'row',
      friendlyName: 'Row',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'centered',
      friendlyName: 'Centered',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'wordWrap',
      friendlyName: 'Word Wrap',
      type: 'boolean',
      defaultValue: true,
    },
    ...getSpacingInputs(),
  ],
} satisfies RegisteredComponent;

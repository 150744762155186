import { useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Button, DataPoint, Stack, styled } from '@arrived/bricks';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { AccountOfferingBalance, Offering, OfferingType } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../content';
import { ProductInfoBox } from '../../../../productInfoBox';
import { StyledDataPointLabel } from '../components';
import { Breakout } from './Breakout';
import { useHandleRedemptionsFlowNavigation } from './useHandleRedemptionsFlowNavigation';

export type YourPositionSectionProps = ProductDetailContentProps & {
  offering: Offering;
  offeringBalance: AccountOfferingBalance;
};

const Row = styled(Stack, {
  gap: '$3',
  flex: 1,
  $gtXxs: { row: true },
});

const StyledInfoBox = styled(ProductInfoBox, {
  flexGrow: 1,
  flexShrink: 1,
  $gtXxs: { flexBasis: 0 },
});

export const YourPositionSection = ({ offering, offeringBalance, ...rest }: YourPositionSectionProps) => {
  const isRedemptionsEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.HAS_REDEMPTIONS_UI);
  const navigationPropsForRedemptionFlow = useHandleRedemptionsFlowNavigation(offering.id.toString());

  const hasRedemptionsButton = useMemo(
    () => isRedemptionsEnabled && offering.type === OfferingType.FUND,
    [isRedemptionsEnabled, offering.type],
  );

  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.your-position" />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body gap="$3">
        <Row>
          <StyledInfoBox>
            <ProductInfoBox.Header>
              <FormattedMessage id="product-details.equity" />
            </ProductInfoBox.Header>
            <Breakout>
              <DataPoint colors="dark">
                <DataPoint.Value>
                  <FormattedNumber
                    value={offeringBalance.totalValue}
                    currency="USD"
                    style="currency"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </DataPoint.Value>
                <StyledDataPointLabel>
                  <FormattedMessage id="product-details.your-equity" />
                </StyledDataPointLabel>
              </DataPoint>
              <Breakout.Divider />
              <DataPoint
                colors="dark"
                variant="minimized"
                alignment="right"
                $gtXxs={{
                  alignment: 'left',
                }}
              >
                <DataPoint.Value>
                  <FormattedNumber
                    value={offeringBalance.initialInvestment}
                    currency="USD"
                    style="currency"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </DataPoint.Value>
                <StyledDataPointLabel>
                  <FormattedMessage id="product-details.initial-investment" />
                </StyledDataPointLabel>
              </DataPoint>
            </Breakout>
            {hasRedemptionsButton && (
              <Button w="100%" variant="outlined" {...navigationPropsForRedemptionFlow}>
                <FormattedMessage id="product-details.fund-performance.request-redemption" />
              </Button>
            )}
          </StyledInfoBox>
          <StyledInfoBox>
            <ProductInfoBox.Header>
              <FormattedMessage id="product-details.appreciation" />
            </ProductInfoBox.Header>
            <Breakout>
              <DataPoint
                colors="dark"
                tooltip={{
                  title: <FormattedMessage id="product-details.unrealized-gain-loss" />,
                  content: <FormattedMessage id="product-details.unrealized-gain-loss.tooltip" />,
                }}
              >
                <DataPoint.Value>
                  <FormattedNumber
                    value={offeringBalance.appreciation.totalAppreciation}
                    currency="USD"
                    style="currency"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </DataPoint.Value>
                <StyledDataPointLabel>
                  <FormattedMessage id="product-details.unrealized-gain-loss" />
                </StyledDataPointLabel>
              </DataPoint>
              <Breakout.Divider />
              <DataPoint
                colors="dark"
                variant="minimized"
                alignment="right"
                $gtXxs={{
                  alignment: 'left',
                }}
              >
                <DataPoint.Value>
                  <FormattedNumber
                    value={offeringBalance.appreciation.allTimeAppreciationPercent / 100}
                    style="percent"
                    minimumFractionDigits={0}
                    maximumFractionDigits={1}
                  />
                </DataPoint.Value>
                <StyledDataPointLabel>
                  <FormattedMessage id="product-details.total-appreciation-percentage" />
                </StyledDataPointLabel>
              </DataPoint>
            </Breakout>
          </StyledInfoBox>
        </Row>
        <Row>
          <StyledInfoBox>
            <ProductInfoBox.Header>
              <FormattedMessage id="product-details.shares" />
            </ProductInfoBox.Header>
            <DataPoint
              colors="dark"
              tooltip={{
                title: <FormattedMessage id="product-details.current-share-price" />,
                content: <FormattedMessage id="product-details.current-share-price.tooltip" />,
              }}
            >
              <DataPoint.Value>
                <FormattedNumber
                  value={offering.sharePrice}
                  currency="USD"
                  style="currency"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </DataPoint.Value>
              <StyledDataPointLabel>
                <FormattedMessage id="product-details.current-share-price" />
              </StyledDataPointLabel>
            </DataPoint>
          </StyledInfoBox>
          <StyledInfoBox>
            <ProductInfoBox.Header>
              <FormattedMessage id="product-details.dividend" />
            </ProductInfoBox.Header>
            <DataPoint colors="dark">
              <DataPoint.Value>
                <FormattedNumber
                  value={offeringBalance.rentalIncome.annualizedDividendPercent / 100}
                  style="percent"
                  minimumFractionDigits={0}
                  maximumFractionDigits={1}
                />
              </DataPoint.Value>
              <StyledDataPointLabel>
                <FormattedMessage id="product-details.annualized-yield" />
              </StyledDataPointLabel>
            </DataPoint>
          </StyledInfoBox>
        </Row>
        <Row>
          <StyledInfoBox
            $gtXxs={{
              // Design doesn't want full width. 50% width minus the 12px gap divided by 2.
              maxWidth: `calc(50% - (var(--space-3) / 2))`,
            }}
          >
            <ProductInfoBox.Header>
              <FormattedMessage id="product-details.net-return" />
            </ProductInfoBox.Header>
            <DataPoint
              colors="dark"
              tooltip={{
                title: <FormattedMessage id="product-details.net-return" />,
                content: <FormattedMessage id="product-details.net-return.tooltip" />,
              }}
            >
              <DataPoint.Value>
                <FormattedNumber
                  value={offeringBalance.totalReturns}
                  currency="USD"
                  style="currency"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </DataPoint.Value>
              <StyledDataPointLabel>
                <FormattedMessage id="product-details.unrealized-gain-loss-plus-dividends" />
              </StyledDataPointLabel>
            </DataPoint>
          </StyledInfoBox>
        </Row>
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};

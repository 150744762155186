import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteCancelOfferingTransaction } from '@arrived/api_v2';
import { Account } from '@arrived/models';

import { balanceKeyFn, offeringBalancesKeyFn } from '../accounts';
import { offeringKeyFn, offeringsKeyFn } from '../offerings';
import { offeringSharesAvailableKeyFn } from '../sharesAvailable';
import { accountsPrimaryKeyFn } from '../users';
import { UseAwaitedMutationOptions } from '../utils';
import { accountTradesQueryKeyFn, tradeQueryKeyFn, userTradesQueryKeyFn } from './trades.keys';

export function useDeleteTradeMutation(options?: UseAwaitedMutationOptions<typeof deleteCancelOfferingTransaction>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCancelOfferingTransaction,
    ...options,
    onSuccess: (data, variables, context) => {
      const { id: tradeId, cid, accountId, userId, offeringId } = data;

      queryClient.invalidateQueries({ queryKey: offeringSharesAvailableKeyFn(offeringId) });
      queryClient.invalidateQueries({ queryKey: offeringsKeyFn() });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(offeringId) });

      queryClient.invalidateQueries({ queryKey: balanceKeyFn(accountId) });
      queryClient.invalidateQueries({ queryKey: offeringBalancesKeyFn(accountId) });

      queryClient.invalidateQueries({ queryKey: tradeQueryKeyFn(cid) });
      queryClient.invalidateQueries({ queryKey: tradeQueryKeyFn(tradeId) });
      queryClient.invalidateQueries({ queryKey: userTradesQueryKeyFn(userId) });
      queryClient.invalidateQueries({ queryKey: accountTradesQueryKeyFn(accountId) });

      const account = queryClient.getQueryData<Account>(accountsPrimaryKeyFn(userId));

      if (account) {
        /**
         * Gives the DB a chance to reflect the updated Wallet balances, if applicable. Doesn't
         * guarantee that it'll be updated but it covers some bases.
         *
         * Primary 'behavior' that covers this is the fact that the Wallet balances are refetched
         * whenever a related component mounts or the tab is refocused.
         */
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ['cash-accounts'].concat(account.cashAccountCid ? [account.cashAccountCid] : []),
          });
        }, 2000);
      }

      options?.onSuccess?.(data, variables, context);
    },
  });
}

import { useState } from 'react';

import clsx from 'clsx';

import { Box, Skeleton } from '@mui/material';

import { getCDNOptimizedImage } from '@arrived/common';
import { ArrivedLogoIcon } from '@arrived/images';
import { Offering } from '@arrived/models';

export interface PropertyThumbnailProps {
  className?: string;
  dataE2e?: string;
  fundedPercentage?: number;
  isForcedHeight?: boolean;
  isScalableImage?: boolean;
  offering?: Offering;
  propertyName?: string;
  thumbnailHeight?: string;
  thumbnailRatio?: string;
  thumbnailSrc?: string;
  thumbnailWidth?: string;
  wrapperHeight?: string;
  wrapperWidth?: string;
}

export const PropertyThumbnail = ({
  className,
  dataE2e,
  isForcedHeight,
  isScalableImage,
  propertyName,
  thumbnailHeight,
  thumbnailRatio,
  thumbnailSrc,
  thumbnailWidth,
  wrapperHeight,
  wrapperWidth,
}: PropertyThumbnailProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const doubleSizedWidth = isScalableImage && thumbnailWidth ? `${parseInt(thumbnailWidth) * 2}px` : thumbnailWidth;

  return (
    <Box
      flex={1}
      width={wrapperWidth ?? 'auto'}
      height={wrapperHeight ?? 'auto'}
      lineHeight={0}
      className={clsx('property-thumbnail-wrapper', className)}
    >
      <Box
        position="relative"
        minWidth={isImageLoading ? thumbnailWidth : undefined}
        minHeight={isImageLoading ? thumbnailHeight : undefined}
      >
        {thumbnailSrc ? (
          <>
            <Box
              component="img"
              width="100%"
              height={isForcedHeight ? thumbnailHeight : 'auto'}
              m={0}
              sx={{
                objectFit: 'cover',
              }}
              alt={propertyName}
              onLoad={() => {
                setIsImageLoading(false);
              }}
              src={getCDNOptimizedImage({
                imgUrl: thumbnailSrc,
                ratio: thumbnailRatio,
                width: doubleSizedWidth,
              })}
              className="property-thumbnail-image"
              visibility={isImageLoading ? 'hidden' : undefined}
              data-e2e={dataE2e}
            />
            {isImageLoading ? (
              <Skeleton
                variant="rectangular"
                className="property-thumbnail-image-placeholder"
                sx={{
                  objectFit: 'cover',
                  zIndex: 2,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            ) : null}
          </>
        ) : (
          <ArrivedLogoIcon height={thumbnailHeight} width={thumbnailWidth} />
        )}
      </Box>
    </Box>
  );
};

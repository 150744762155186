import { useMemo } from 'react';

import { GetProps, VariantSpreadFunction, styled, useProps } from '@tamagui/core';

import { UtilityText } from '../../../text';
import { TAG_LABEL_NAME, TagStyledContext, TagStyledContextType, TagVariant } from '../Tag.constants';
import { getTagLabelPressableColor } from './getTagLabelPressableColor';
import { getTagVariantFontColor } from './getTagVariantFontColor';

const getTagLabelVariantProps: VariantSpreadFunction<
  GetProps<typeof UtilityText> & Partial<TagStyledContextType>,
  TagVariant | undefined
> = (variant, { props: { disabled, pressable } }) => {
  return {
    color: getTagVariantFontColor({ disabled, pressable, variant }),
  };
};

const TagLabelText = styled(UtilityText, {
  name: TAG_LABEL_NAME,
  context: TagStyledContext,

  token: 'utility.helper.small',
  color: '$onSurface.neutral.defaultInverted',
  textAlign: 'center',
  // This is necessary to get the text to render over the BlurView used on the default variant
  zIndex: 1,

  variants: {
    condensed: {
      true: {},
    },
    iconOnly: {
      true: {},
    },
    pressable: {
      true: {},
    },
    square: {
      true: {},
    },
    variant: getTagLabelVariantProps,
  } as const,
});

export const TagLabel = TagLabelText.styleable((propsIn, ref) => {
  const { children, ...rest } = useProps(propsIn);

  const { disabled, isHovered, isKeyDown, isPressed, pressable, variant } = TagStyledContext.useStyledContext();

  // Not using `group` here since it doesn't trigger a press event when "enter" is pressed
  const pressableProps = useMemo(() => {
    const color = getTagLabelPressableColor(variant, pressable);

    if (disabled) {
      return {};
    }

    return (isHovered || isKeyDown || isPressed) && color ? { color } : {};
  }, [isHovered, isKeyDown, isPressed, pressable, variant]);

  return (
    <TagLabelText disabled={disabled} ref={ref} pressable={pressable} {...pressableProps} {...rest}>
      {children}
    </TagLabelText>
  );
});

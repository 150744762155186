import { useQuery } from '@tanstack/react-query';

import { GetLearnBlogArticleParams, getLearnBlogArticle } from '@arrived/api_v2';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { learnBlogArticleKey } from './learnBlog.keys';

export function useGetLearnBlogArticleQuery(
  params: GetLearnBlogArticleParams,
  options?: UseAwaitedQueryOptions<typeof getLearnBlogArticle>,
) {
  return useQuery({
    // Don't know if this is best way to bust this one
    queryKey: learnBlogArticleKey(params),

    queryFn: () => getLearnBlogArticle(params),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && (params?.id != null || params?.slug != null),
  });
}

import { RefObject, createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { LayoutAnimation, Linking, PixelRatio } from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Gallery, GalleryProps, Image, Stack, isWeb } from '@arrived/bricks';
import { LazyImage, getImageKitSource } from '@arrived/bricks-common';
import { OfferingPhoto, useProductPhotos } from '@arrived/hooks';
import { InvestmentProductType, Offering } from '@arrived/models';

import { ProductVideo, ProductVideoProps } from '../productVideo';

export type ProductGalleryProps<T> = Omit<GalleryProps<T>, 'items'> & { offering: Offering };

type GalleryVideoRef = { setIsPlaying: (isPlaying: boolean) => void };

const GalleryVideo = forwardRef<GalleryVideoRef, Omit<ProductVideoProps, 'isPlaying' | 'setIsPlaying'>>(
  (props: Omit<ProductVideoProps, 'isPlaying' | 'setIsPlaying'>, ref) => {
    const [isPlaying, setIsPlaying] = useState(false);

    useImperativeHandle(ref, () => ({ setIsPlaying }), [setIsPlaying]);

    return <ProductVideo {...props} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />;
  },
);

export const ProductGallery = <T,>({ offering, ...rest }: ProductGalleryProps<T>) => {
  const { top } = useSafeAreaInsets();

  const offeringPhotoUrls = useProductPhotos({
    cid: offering?.cid,
    marketId: offering?.market?.id,
    offeringId: offering?.id,
    defaultOfferingPhotos: offering?.properties[0]?.photos,
    includeExternalSources: offering.investmentProductType !== InvestmentProductType.VACATION_RENTAL_IPO,
  });

  const videoRefs = useRef<Record<string, RefObject<GalleryVideoRef>>>({});

  const handleSnap = useCallback(() => {
    Object.values(videoRefs.current).forEach((videoRef) => videoRef.current?.setIsPlaying(false));
  }, []);

  useEffect(() => {
    if (offeringPhotoUrls.length > 0) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }
  }, [offeringPhotoUrls.length]);

  /**
   * ! NOTE: I will fix this later...
   * Native has an issue where nesting `View` or `Stack` on the `LazyImage` will cause insane
   * lag on the scroll view. There is a property we don't know about to optimize performance for this as
   * we do not see this issue consistently.
   */

  // On native, this needs to have space to allow for the header to be shown
  if (!offeringPhotoUrls.length) {
    return <Stack flex={1} $platform-native={{ height: top * 2 }} />;
  }

  return (
    <Gallery items={offeringPhotoUrls} {...rest}>
      <Gallery.Carousel<OfferingPhoto>
        $gtSm={{ br: '$2' }}
        flex={1}
        ReanimatedCarouselProps={{
          onSnapToItem: handleSnap,
          enabled: offeringPhotoUrls.length > 1,
        }}
        renderItem={({ index, item, dimensions }) => {
          const priority = index === 0 ? 'high' : undefined;

          if (!isWeb) {
            const backgroundImage = getImageKitSource({
              uri: item.uri,
              width: dimensions.width,
              height: dimensions.height,
              blur: 50,
              quality: 50,
            });

            const imageSource = getImageKitSource({
              uri: item.uri,
              width: dimensions.width,
              height: dimensions.height,
              quality: 100,
            });

            return (
              <Stack
                asChild
                onPress={() => {
                  if (!item.youtubeId) {
                    return;
                  }

                  Linking.openURL(`https://www.youtube.com/watch?v=${item.youtubeId}`);
                }}
              >
                <Image
                  source={imageSource}
                  placeholder={backgroundImage}
                  transition={300}
                  priority={priority}
                  placeholderContentFit="fill"
                  contentFit="cover"
                />
              </Stack>
            );
          }

          if (item.youtubeId) {
            let videoRef;
            if (!Object.keys(videoRefs.current).includes(item.youtubeId)) {
              videoRef = createRef<GalleryVideoRef>();
              videoRefs.current[item.youtubeId] = videoRef;
            } else {
              videoRef = videoRefs.current[item.youtubeId];
            }
            return (
              <GalleryVideo
                propertyName={offering?.name ?? 'Unknown'}
                offeringId={offering?.id ?? -1}
                videoId={item.youtubeId}
                flex={1}
                ref={videoRef}
              />
            );
          }

          const backgroundImage = getImageKitSource({
            uri: item.uri,
            width: dimensions.width,
            height: dimensions.height,
            blur: 100,
            quality: 50,
          });

          return (
            <>
              <Image
                position="absolute"
                height="100%"
                width="100%"
                source={{
                  uri: backgroundImage,
                }}
                contentFit="cover"
                priority={priority}
                transform={[{ scale: 1.1 }]}
              />
              <LazyImage
                flex={1}
                source={item}
                bg="rgba(0, 0, 0, 0.15)"
                ImageProps={{ crop: 'at_max', contentFit: 'contain', priority }}
              />
            </>
          );
        }}
      >
        {offeringPhotoUrls.length > 1 && (
          <>
            <Gallery.Carousel.Arrows
              className="gallery-arrows"
              $platform-web={{ display: 'flex' }}
              $platform-native={{ display: 'none' }}
            />
            <Gallery.Carousel.Pagination />
          </>
        )}
      </Gallery.Carousel>

      {/* TODO figure out how we could fix this typing so it knows what it is from context */}
      {offeringPhotoUrls.length > 1 && (
        <Gallery.Sidebar<OfferingPhoto>
          SidebarItemProps={{ aspectRatio: 1.5, br: '$2' }}
          display="none"
          $gtSm={{ display: 'flex' }}
          gap="$2"
          width={300}
          renderItem={({ item, dimensions }) => {
            const backgroundImage = getImageKitSource({
              uri: item.uri,
              width: PixelRatio.getPixelSizeForLayoutSize(dimensions.width),
              height: PixelRatio.getPixelSizeForLayoutSize(dimensions.height),
              blur: 100,
              quality: 50,
            });

            if (item.youtubeId) {
              return <Image flex={1} source={item.uri} contentFit="cover" />;
            }

            return (
              <>
                <Image
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  source={{
                    uri: backgroundImage,
                  }}
                  contentFit="cover"
                />
                <LazyImage flex={1} source={item} ImageProps={{ contentFit: 'contain' }} />
              </>
            );
          }}
        />
      )}
    </Gallery>
  );
};

import { QueryClient, UseQueryOptions } from '@tanstack/react-query';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { OfferingId, TotalAvailableSharesResponse } from '@arrived/models';

import { fetchPrimaryAccountQuery, useGetPrimaryAccountQuery } from '../users';
import {
  fetchSharesAvailableForAccountQuery,
  useSharesAvailableForAccountQuery,
} from './useSharesAvailableForAccountQuery';
import { fetchSharesAvailableForUserQuery, useSharesAvailableForUserQuery } from './useSharesAvailableForUserQuery';

/**
 * Indicates how many shares of an Offering are available to the current user.
 */
export function useSharesAvailableQuery(
  offeringId?: OfferingId,
  options?: Pick<UseQueryOptions<TotalAvailableSharesResponse>, 'staleTime' | 'enabled'>,
) {
  const { isAuthenticated } = useArrivedAuth0();

  const accountState = useGetPrimaryAccountQuery({ enabled: isAuthenticated });

  const accountStateError = accountState.error;

  const sharesAvailableForAccountState = useSharesAvailableForAccountQuery(offeringId, {
    ...options,
    enabled: (options?.enabled ?? true) && isAuthenticated,
  });

  const sharesAvailableForUserState = useSharesAvailableForUserQuery(offeringId, {
    ...options,
    enabled: (options?.enabled ?? true) && isAuthenticated && accountState.isError && accountStateError?.status === 404,
  });

  if (accountState.isError && accountStateError?.status === 404) {
    return sharesAvailableForUserState;
  }

  return sharesAvailableForAccountState;
}

export const fetchSharesAvailableQuery = async (queryClient: QueryClient, offeringId: OfferingId) => {
  const account = await fetchPrimaryAccountQuery(queryClient);
  const sharesAvailableForAccount = await fetchSharesAvailableForAccountQuery(queryClient, offeringId);
  const sharesAvailableForUser = await fetchSharesAvailableForUserQuery(queryClient, offeringId);

  return account ? sharesAvailableForAccount : sharesAvailableForUser;
};

import { ForwardedRef, forwardRef, useMemo, useRef, useState } from 'react';

import { useSharedValue } from 'react-native-reanimated';

import { GetProps, TamaguiElement, composeEventHandlers, styled, withStaticProperties } from '@tamagui/core';

import { ICarouselInstance } from '@mikehuebner/react-native-reanimated-carousel';

import { Stack } from '../../atoms';
import { Carousel } from './Carousel';
import { GALLERY_FRAME_NAME } from './consants';
import { GalleryItem, GalleryStateContext, GalleryStateContextProps } from './GalleryStateContext';
import { GalleryStyledContext } from './GalleryStyledContext';
import { Sidebar } from './Sidebar';

export type { CarouselProps as GalleryCarouselProps } from './Carousel';

const GalleryFrame = styled(Stack, {
  name: GALLERY_FRAME_NAME,

  row: true,
  gap: '$2',
});

export type GalleryProps<T> = GetProps<typeof GalleryFrame> & {
  items: GalleryItem<T>[];
};

const GalleryImpl = <T,>(
  { items, children, onHoverIn, onHoverOut, ...props }: GalleryProps<T>,
  ref: ForwardedRef<TamaguiElement>,
) => {
  const carouselRef = useRef<ICarouselInstance>(null);

  const [isHovered, setIsHovered] = useState(false);

  const currentIndex = useSharedValue(0);
  const snapIndex = useSharedValue(0);
  const swipeProgress = useSharedValue(0);

  const contextValue = useMemo<GalleryStateContextProps<T>>(
    () => ({
      items,

      carouselRef,

      currentIndex,
      snapIndex,
      swipeProgress,
    }),
    [items, carouselRef, currentIndex, snapIndex, swipeProgress],
  );

  return (
    <GalleryStyledContext.Provider isHovered={isHovered}>
      <GalleryStateContext.Provider value={contextValue}>
        <GalleryFrame
          onHoverIn={composeEventHandlers(() => setIsHovered(true), onHoverIn)}
          onHoverOut={composeEventHandlers(() => setIsHovered(false), onHoverOut)}
          ref={ref}
          {...props}
        >
          {children}
        </GalleryFrame>
      </GalleryStateContext.Provider>
    </GalleryStyledContext.Provider>
  );
};

export const Gallery = withStaticProperties(
  forwardRef(GalleryImpl) as unknown as <T>(
    props: GalleryProps<T> & { ref?: ForwardedRef<ICarouselInstance> },
  ) => JSX.Element,
  {
    Carousel,
    Sidebar,
  },
);

export type CarouselRef = ICarouselInstance;

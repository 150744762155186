import { StackProps } from '../../atoms';

export const getOutlinedStyles = (isInverted?: boolean, isLoading?: boolean, isDisabled?: boolean): StackProps => {
  if (isLoading) {
    return {
      disabled: true,
      pointerEvents: 'none',
      bg: '$transparent',
      borderWidth: '$0.5',
      borderColor: isInverted ? '$interactive.neutral.focusInverted' : '$interactive.neutral.focus',
    };
  }

  if (isDisabled) {
    return {
      bg: '$transparent',
      borderWidth: '$0.5',
      borderColor: isInverted ? '$interactive.neutral.disabledInverted' : '$interactive.neutral.disabled',
      disabled: true,
      pointerEvents: 'none',
    };
  }

  return {
    bg: '$transparent',
    borderWidth: '$0.5',
    borderColor: isInverted ? '$interactive.neutral.restedInverted' : '$interactive.neutral.rested',

    focusStyle: {
      borderColor: isInverted ? '$interactive.neutral.focusInverted' : '$interactive.neutral.focus',
    },
    hoverStyle: {
      borderWidth: '$1',
      borderColor: isInverted ? '$interactive.neutral.hoveredInverted' : '$interactive.neutral.hovered',
      // TODO: Is there a way to use the $4 token instead of `var(--space-4)`?
      px: 'calc(var(--space-4) - 2px)',
    },
    pressStyle: {
      borderColor: isInverted ? '$interactive.neutral.focusInverted' : '$interactive.neutral.focus',
    },
  };
};

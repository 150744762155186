import { useCallback, useEffect, useState } from 'react';
import { Linking } from 'react-native';

import { Callout } from '@arrived/bricks';
import { useGetBuilderBannerQuery } from '@arrived/builder.io';

import { BannerProps } from './banner.schema';
import { BannerComponent } from './BannerComponent';
import { useIsBannerDismissed, useProcessShortCodes, useShouldShowBanner } from './hooks';
import { BuilderBannerIcons } from './models';
import { getBannerIconSize, getIllustrationSource } from './utils';

export const Banner = (_: BannerProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const bannerDataState = useGetBuilderBannerQuery();

  const bannerData = bannerDataState.data?.data! ?? '';
  const bannerId = bannerDataState.data?.id! ?? '';
  const [processedTitle, isTitleLoading] = useProcessShortCodes(bannerData?.title);
  const [processedDescription, isDescriptionLoading] = useProcessShortCodes(bannerData?.description);
  const illustrationSource = getIllustrationSource(bannerData?.icon);

  const [isBannerDismissed, setIsBannerDismissed] = useIsBannerDismissed(bannerId);

  const meetsTargetingConditions = useShouldShowBanner({
    description: processedDescription,
    title: processedTitle,
    targetAccredited: bannerData?.userTargeting?.[0]?.accredited,
    targetAuthenticated: bannerData?.userTargeting?.[0]?.authenticated,
    targetUserIds: bannerData?.userTargeting?.[0]?.userIds?.ids || [],
    targetAdmins: bannerData?.userTargeting?.[0]?.admins,
  });

  const handleClose = useCallback(() => {
    setIsVisible(false);
    setIsBannerDismissed(bannerId, true);
  }, [bannerId]);

  const handleCtaPress = useCallback(() => {
    setIsBannerDismissed(bannerId, true);

    // This is a hacky solution to handle this, but basically because
    // this can exist within React Router or Next, we need to just open the CTA.
    // And RNW Linking works cross-platform to accomplish this :)
    Linking.openURL(bannerData?.callToActionUrl!);
  }, [bannerData]);

  useEffect(() => {
    if (bannerDataState.isSuccess) {
      setIsLoading(false);
    }
  }, [bannerDataState.isSuccess]);

  useEffect(() => {
    if (
      !isLoading &&
      !isTitleLoading &&
      !isDescriptionLoading &&
      !isBannerDismissed &&
      bannerData &&
      processedTitle &&
      processedDescription
    ) {
      setIsVisible(meetsTargetingConditions);
    } else {
      setIsVisible(false);
    }
  }, [
    isLoading,
    meetsTargetingConditions,
    processedDescription,
    processedTitle,
    isBannerDismissed,
    isTitleLoading,
    isDescriptionLoading,
  ]);

  const isNativeOnly = bannerData?.userTargeting?.[0].targetNativeOnly;

  if (!isVisible || isNativeOnly) return null;

  return (
    <BannerComponent onClose={handleClose}>
      <Callout.Illustration
        source={illustrationSource}
        imageProps={{
          ...getBannerIconSize(bannerData.icon),
          borderRadius: bannerData.icon === BuilderBannerIcons.KORIN ? 28 : 0,
        }}
      />
      {processedTitle && <Callout.Heading>{processedTitle}</Callout.Heading>}

      {processedDescription && (
        <Callout.Content>
          <Callout.Content.Text>{processedDescription}</Callout.Content.Text>
          {bannerData.callToActionText && bannerData.callToActionUrl && (
            <Callout.Content.Text
              color="$onSurface.neutral.defaultInverted"
              textDecorationLine="underline"
              tag="a"
              href={bannerData?.callToActionUrl}
              onPress={handleCtaPress}
            >
              {bannerData.callToActionText}
            </Callout.Content.Text>
          )}
        </Callout.Content>
      )}
    </BannerComponent>
  );
};

import { baseMediaBreakpoints } from '@arrived/bricks';

import { GenericBuilderIcons, RegisteredComponent } from '../../../sdk';
import { ImageComponent } from './ImageComponent';

const ResponsiveImage = ImageComponent as typeof ImageComponent & { registerComponent: RegisteredComponent };

ResponsiveImage.registerComponent = {
  component: ImageComponent,
  name: 'ResponsiveImage',
  description:
    'Provides a way to serve different images based on screen size and DPR. https://imagekit.io/responsive-images/',
  friendlyName: 'Image',
  noWrap: true,
  image: GenericBuilderIcons.Action,
  inputs: [
    {
      name: 'defaultImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
    },
    {
      name: 'entries',
      type: 'list',
      subFields: [
        {
          name: 'targetSize',
          type: 'enum',
          description:
            'The breakpoint for the given image to be used. Higher breakpoints take priority if they are loaded already as they are treated as being higher resolution.',
          required: true,
          enum: Object.keys(baseMediaBreakpoints),
        },
        {
          name: 'images',
          type: 'list',
          subFields: [
            {
              name: 'url',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
              required: true,
              defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
            },
            {
              name: 'density',
              type: 'number',
              helperText:
                'Ensure a `1x` density is always supplied. Different mobile devices have different pixel densities (dpr) and to serve the appropriate image to each one, this field can be specified.',
            },
            {
              name: 'height',
              helperText:
                '(MOBILE ONLY) This will control the space this image takes up vertically on mobile usage. This has no effect on the web side but is required on the mobile side.',
              type: 'number',
            },
          ],
        },
      ],
    },
    {
      name: 'alt',
      description: 'Image description for screen readers.',
      type: 'text',
      required: true,
    },
  ],
} satisfies RegisteredComponent;

export { ResponsiveImage };

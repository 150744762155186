import { useQuery } from '@tanstack/react-query';

import { getPromotionCodes } from '@arrived/api_v2';

import { UseAwaitedQueryOptions } from '../utils';
import { promotionCodesKeyFn } from './rewards.keys';

export function useGetPromotionCodesQuery(
  promotionUuid?: string,
  options?: UseAwaitedQueryOptions<typeof getPromotionCodes>,
) {
  return useQuery({
    queryKey: promotionCodesKeyFn(promotionUuid!),
    queryFn: () => getPromotionCodes(promotionUuid!),
    ...options,
    enabled: (options?.enabled ?? true) && promotionUuid != null,
  });
}

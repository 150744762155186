import { NextRequest, userAgent } from 'next/server';

/**
 * Adapts the request URL to the viewport of the request's user agent. If a URL is provided, that one will be modified
 * and returned, otherwise the request URL will be cloned and returned.
 */
export const adaptUrlToViewport = (req: NextRequest, reqUrl?: URL) => {
  const url = reqUrl ?? req.nextUrl.clone();

  const { device } = userAgent(req);
  const viewport = device.type === 'mobile' ? 'mobile' : 'desktop';

  url.pathname = `_viewport/${viewport}${url.pathname}`;

  return url;
};

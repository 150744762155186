import { useQuery } from '@tanstack/react-query';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { getExternalBankAccounts } from '../../api/external-bank-accounts';
import { externalBankAccountKeyFn } from './externalBankAccounts.keys';

export function useExternalBankAccount(
  externalBankCid?: string | null,
  options?: UseAwaitedQueryOptions<typeof getExternalBankAccounts>,
) {
  return useQuery({
    queryKey: externalBankAccountKeyFn(externalBankCid!),
    queryFn: () => {
      if (!externalBankCid) {
        throw Error('No brokerage account CID specified');
      }

      return getExternalBankAccounts(externalBankCid);
    },
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
    enabled: (options?.enabled ?? true) && !!externalBankCid,
  });
}

import { AccountCid } from '../account';
import { Offering, OfferingCid } from '../offerings';
import { RedemptionPeriod } from './RedemptionPeriod';
import { SaleCid } from './SaleCid';
import { SalePayment } from './SalePayment';
import { SaleTrade } from './SaleTrade';

export const SaleType = {
  /**
   * Fund redemptions (at least, until IPO redemptions are supported)
   */
  REDEMPTION: 'REDEMPTION',
  /**
   * Short term note payouts
   */
  MATURITY: 'MATURITY',
  //   SECONDARY_MARKET: 'SECONDARY_MARKET', // hypothetical
} as const;
/**
 * The type of Sale; effectively, what offering type is being "sold"
 */
export type SaleType = (typeof SaleType)[keyof typeof SaleType];

export const SaleStatus = {
  /**
   * Redemption has been created by the investor; also accounts for the review period.
   */
  REQUESTED: 'REQUESTED',
  /**
   * Redemption has been reviewed, and async payment processing has begun.
   */
  PROCESSING: 'PROCESSING',
  /**
   * Redemption payments have settled.
   */
  SETTLED: 'SETTLED',
  /**
   * Redemption has been disapproved by the review committee, or cancelled by the investor.
   */
  CANCELLED: 'CANCELLED',
} as const;
/**
 * Processing state of the Sale
 */
export type SaleStatus = (typeof SaleStatus)[keyof typeof SaleStatus];

export const SaleLifecycleState = {
  /**
   * Redemption has been created by the investor; also accounts for the review period.
   */
  REQUESTED: 'REQUESTED',
  /**
   * Redemption has been reviewed, and async payment processing has begun.
   */
  PROCESSING: 'PROCESSING',
  /**
   * Redemption payments have settled.
   */
  SETTLED: 'SETTLED',
  /**
   * Redemption has been disapproved by the review committee, or cancelled by the investor.
   */
  CANCELLED: 'CANCELLED',
} as const;
/**
 * `SaleLifecycleState` has the same values as `SaleStatus` for now. Its purpose is to provide support for
 * multistep status transitions, and we can expand the values here as we flesh out our sale processing jobs.
 */
export type SaleLifecycleState = (typeof SaleLifecycleState)[keyof typeof SaleLifecycleState];

export const SaleApprovalStatus = {
  /**
   * Typically only for MATURITY SaleTypes where there is no review step.
   */
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  APPROVED: 'APPROVED',
  DISAPPROVED: 'DISAPPROVED',
} as const;
/**
 * Whether redemption has been approved by ops during the review window. Not applicable to STN
 * payouts.
 *
 * Defaults to APPROVED for redemptions per ops request. Can add PENDING value later to enforce
 * explicit approval.
 */
export type SaleApprovalStatus = (typeof SaleApprovalStatus)[keyof typeof SaleApprovalStatus];

export const SaleCancelReason = {
  /**
   * Investor chose to cancel
   */
  INVESTOR_CANCELLED: 'INVESTOR_CANCELLED',
  /**
   * Admin chose to cancel. The `Sale`'s `internalNotes` field may have more information.
   */
  ADMIN_CANCELLED: 'ADMIN_CANCELLED',
  /**
   * Review committee chose to disapprove the Sale. The `Sale`'s `internalNotes` field may have
   * more information.
   */
  DISAPPROVED: 'DISAPPROVED',
  /**
   * A redemption request was cancelled because the investor's account is blocked from requesting
   * redemptions, e.g. due to fraudulent activity.
   */
  ACCOUNT_BLOCKED: 'ACCOUNT_BLOCKED',
} as const;
/**
 * Reason for the cancellation of the Sale.
 */
export type SaleCancelReason = (typeof SaleCancelReason)[keyof typeof SaleCancelReason];
export interface SaleOfferingProjection extends Offering {
  projections?: {
    primaryImageUrl?: string;
  }
}

export interface SaleProjections {
  offering?: SaleOfferingProjection;
  saleTrades?: SaleTrade[];
  redemptionPeriod?: RedemptionPeriod;
  payments?: SalePayment[];
}

/**
 * Primary object for redemptions and payouts; the 'inverse' transaction of a Trade.
 *
 * These are either created by the investor, or automatically generated at the time an offering reaches maturity.
 */
export interface Sale {
  cid: SaleCid;
  /**
   * Offering associated with the Sale.
   */
  offeringCid: OfferingCid;
  /**
   * Brokerage account associated with the Sale.
   */
  accountCid: AccountCid;
  /**
   * The type of Sale; effectively, what offering type is being "sold"
   */
  type: SaleType;
  /**
   *
   */
  lifecycleState: SaleLifecycleState;
  /**
   * Processing state of the Sale
   */
  status: SaleStatus;
  /**
   * Whether redemption has been approved by ops during the review window. Not applicable to STN
   * payouts.
   *
   * Defaults to APPROVED for redemptions per ops request. Can add PENDING value later to enforce
   * explicit approval.
   */
  approvalStatus: SaleApprovalStatus;
  /**
   * Whether the review committee has agreed to waive fees for the Sale based on the reason and supporting documents.
   */
  feeWaived: Boolean;
  /**
   * Number of shares requested by the investor in the Sale.
   */
  requestedSharesCount: number;
  /**
   * Actual number of shares included in the Sale. Sum of all SaleTrade share counts.
   *
   * May differ from `requestedSharesCount` if pro-rata is in effect and redemption volume is
   * adjusted by the review committee.
   */
  sharesCount: number;
  /**
   * Price of the share at the time of the sale.
   */
  sharePrice: number;
  /**
   * Actual amount paid to the investor.
   *
   * Equal to ((sharesCount * sharePrice) + interestAmount - feeAmount).
   */
  paymentAmount: number;
  /**
   * For interest-generating offerings, the amount of interest paid as part of the sale.
   *
   * Expected to be 0 for all other offerings.
   */
  interestAmount: number;
  /**
   * Cumulative fee amount of all related SaleTrade entries.
   */
  feeAmount: number;
  /**
   * Free-form investor-supplied text explaining their reason for a redemption.
   *
   * Defaults to an empty string. May be accompanied by a "reason" enum in the future.
   */
  reasonNotes: string;
  /**
   * Datetime that the cancellation was requested by either the investor or the review committee.
   */
  cancelRequestedAt?: string;
  /**
   * Reason for the cancellation of the Sale.
   */
  cancelReason?: SaleCancelReason;
  /**
   * Admin-only, free-form notes to be used for documentation. Defaults to an empty string.
   */
  internalNotes: string;
  /**
   * When the Sale record was created.
   */
  createdAt: string;
  /**
   * Official/legal time that we consider the associated shares to be "sold."
   */
  soldAt?: string;
  deleted: boolean;
  projections: SaleProjections;
}

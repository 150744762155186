import { GetProps, styled, useMedia, withStaticProperties } from '@tamagui/core';

import { Stack, UtilityText } from '../../../atoms';
import { NavItemStyledContext } from './NavItemStyledContext';

const NavItemFrame = styled(Stack, {
  context: NavItemStyledContext,

  tag: 'button',
  px: '$4',
  py: '$4',
  width: '100%',
  backgroundColor: 'transparent',
  bw: 0,
  group: 'NavItem',
  cursor: 'pointer',
  row: true,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$2',

  style: {
    containerType: 'normal',
    textDecorationLine: 'none',
  },

  hoverStyle: {
    backgroundColor: '$surface.primary.default',
  },
  pressStyle: {
    backgroundColor: '$interactive.primary.focus',
  },
  focusStyle: {
    backgroundColor: '$surface.primary.default',
    outlineWidth: 0,
  },

  $gtXs: {
    width: 'auto',
    justifyContent: 'flex-start',
    focusStyle: {
      outlineWidth: 1,
      backgroundColor: 'transparent',
    },
  },

  variants: {
    active: {
      true: {},
    },
    focusable: {
      false: {
        tabIndex: -1,
      },
    },
  } as const,
  defaultVariants: {
    active: false,
    focusable: true,
  },
});

const NavItemText = styled(UtilityText, {
  context: NavItemStyledContext,

  token: 'utility.label.button.small',
  animation: 'quick',
  color: '$onSurface.primary.default',

  '$group-NavItem-press': {
    color: '$onSurface.neutral.defaultInverted',
  },

  variants: {
    active: {
      true: {
        color: '$interactive.primary.rested',
      },
    },
  } as const,
});

export const NavItem = withStaticProperties(
  NavItemFrame.styleable((props, ref) => (
    <NavItemFrame
      ref={ref}
      // These didn't appear to work as definitions in `$gtXs` in the `styled` definition, but they
      // do here... will add an issue marker with Tamagui here once we get one filed.
      $gtXs={{
        hoverStyle: {
          backgroundColor: 'transparent',
        },
        pressStyle: {
          backgroundColor: 'transparent',
        },
      }}
      {...props}
    />
  )),
  {
    Text: NavItemText.styleable((props, ref) => {
      const media = useMedia();

      return (
        <NavItemText
          ref={ref}
          // This seems to be the only way to get this to work correctly with group styles, I also
          // tried:
          // - Using $gtXs in the `styled` definition
          // - Using $gtXs as an inline media style
          // - Using `$group-NavItem-gtXs-hover` in both the inline and styled flavors
          // I will make a tamagui issue as soon as I can get their Sandbox working for me.
          //
          // In general this approach works, except for SSR, in which case it needs JavaScript in
          // order to have the correct hover color.
          {...(media.gtXs && {
            '$group-NavItem-hover': {
              color: '$interactive.primary.hovered',
            },
            '$group-NavItem-press': {
              color: '$interactive.primary.focus',
            },
          })}
          {...props}
        />
      );
    }),
  },
);

export type NavItemProps = GetProps<typeof NavItem>;

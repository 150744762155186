import { useQuery } from '@tanstack/react-query';

import { getOfferingTransactionDocumentsForUser } from '@arrived/api_v2';
import { UserId } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';
import { userTradeDocumentsQueryKeyFn } from './trades.keys';

export function useUserTradeDocumentsQuery(
  userId?: UserId,
  options?: UseAwaitedQueryOptions<typeof getOfferingTransactionDocumentsForUser>,
) {
  return useQuery({
    queryKey: userTradeDocumentsQueryKeyFn(userId!),
    queryFn: () => getOfferingTransactionDocumentsForUser(userId!),
    ...options,
    enabled: (options?.enabled ?? true) && userId != null,
  });
}

import { FC } from 'react';

import { GetProps, styled, useProps } from '@tamagui/core';

import { IconProps } from '../../icons';
import { Stack } from '../layout';
import { PIN_ICON_NAME } from './constants';
import { PinStyledContext } from './PinStyledContext';

const PinIconContainer = styled(Stack, {
  name: PIN_ICON_NAME,
});

export const PinIcon = PinIconContainer.styleable<
  GetProps<typeof PinIconContainer> & {
    Icon: FC<IconProps>;
  }
>((propsIn, ref) => {
  const { Icon, ...rest } = useProps(propsIn);

  const { variant } = PinStyledContext.useStyledContext();

  return (
    <PinIconContainer ref={ref} {...rest}>
      {Icon && (
        <Icon
          color={variant === 'alt' ? '$onSurface.neutral.defaultInverted' : '$onSurface.neutral.default'}
          size="xs"
        />
      )}
    </PinIconContainer>
  );
});

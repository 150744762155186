import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteFixedRateInvestment } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';
import { fixedRateInvestmentKey } from './fixedRateInvestments.keys';

export function useDeleteFixedRateInvestmentMutation(
  options?: UseAwaitedMutationOptions<typeof deleteFixedRateInvestment>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteFixedRateInvestment,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: fixedRateInvestmentKey(args[0].cid!),
      });

      options?.onSuccess?.(...args);
    },
  });
}

import { DefaultError } from '@tanstack/react-query';

import { Loading } from '@arrived/bricks';

import { StaticErrorView } from '../StaticErrorView';
import { DataStateHandlerProps, queriesAreNotError, queriesAreSuccess } from './DataStateHandler.types';

/**
 * @deprecated Handle query states granularly instead.
 */
export function DataStateHandler<T1, T2, T3, T4, T5, T6, T7, T8>({
  children,
  queries,
}: DataStateHandlerProps<T1, T2, T3, T4, T5, T6, T7, T8>) {
  if (queriesAreNotError(queries)) {
    if (queriesAreSuccess(queries)) {
      return children(...queries);
    } else {
      return (
        <Loading fullscreen centered>
          <Loading.Indicator variant="colored" />
        </Loading>
      );
    }
  } else {
    return <StaticErrorView errors={queries.map((query) => query.error as DefaultError)} />;
  }
}

import { useQuery } from '@tanstack/react-query';

import { getUserDocumentRaw } from '@arrived/api_v2';
import { UserDocumentId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { userDocumentRawKeyFn } from './userDocuments.keys';

export function useUserDocumentRawQuery(
  userDocumentId: UserDocumentId,
  options?: UseAwaitedQueryOptions<typeof getUserDocumentRaw>,
) {
  return useQuery({
    queryKey: userDocumentRawKeyFn(userDocumentId),
    queryFn: () => getUserDocumentRaw(userDocumentId),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}

import { useQuery } from '@tanstack/react-query';

import { getOfferingTransactionDocuments } from '@arrived/api_v2';
import { OfferingTransactionId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { tradeDocumentsQueryKeyFn } from './trades.keys';

export function useGetTradeDocumentsQuery(
  tradeId: OfferingTransactionId,
  options?: UseAwaitedQueryOptions<typeof getOfferingTransactionDocuments>,
) {
  return useQuery({
    queryKey: tradeDocumentsQueryKeyFn(tradeId),
    queryFn: () => getOfferingTransactionDocuments(tradeId),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}

import { forwardRef } from 'react';

import { TamaguiElement, styled } from '@tamagui/core';

import { Skeleton, Tag as TagAtom, TagProps } from '../../atoms';
import { DATA_POINT_TAG_NAME } from './constants';
import { DataPointStyledContext } from './DataPointStyledContext';

const TagSkeleton = styled(Skeleton.Rect, {
  context: DataPointStyledContext,

  display: 'none',

  h: '$4',
  w: '$15',
  br: '$1',

  variants: {
    inline: {
      true: {
        display: 'flex',
      },
    },
  } as const,
});

export const Tag = styled(
  forwardRef<TamaguiElement, TagProps>(({ children, ...rest }, ref) => {
    const { loading } = DataPointStyledContext.useStyledContext();

    return loading ? (
      <TagSkeleton />
    ) : (
      <TagAtom ref={ref} {...rest}>
        <TagAtom.Label>{children}</TagAtom.Label>
      </TagAtom>
    );
  }),
  {
    name: DATA_POINT_TAG_NAME,

    condensed: true,
  },
);

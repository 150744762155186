import { useCallback, useMemo } from 'react';

import { useRouter } from 'next/router';

export const useDeviceType = () => {
  const router = useRouter();
  return useMemo(() => (router.pathname.startsWith('/_viewport/mobile') ? 'mobile' : 'desktop'), [router.asPath]);
};

export const useDeviceTypePath = () => {
  const deviceType = useDeviceType();
  return useCallback((path: string) => `/_viewport/${deviceType}${path}`, [deviceType]);
};

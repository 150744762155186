import { GetProps, withStaticProperties } from '@tamagui/core';

import { Stack } from '../layout';
import { Indicator } from './indicator';

export const Loading = withStaticProperties(Stack, {
  Indicator,
});

export type LoadingProps = GetProps<typeof Loading>;

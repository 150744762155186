import { GetProps, styled, withStaticProperties } from '@tamagui/core';

import { Stack } from '../layout';
import { PIN_FRAME_NAME } from './constants';
import { PinIcon } from './PinIcon';
import { PinStyledContext } from './PinStyledContext';

const PinFrame = styled(Stack, {
  name: PIN_FRAME_NAME,
  context: PinStyledContext,

  // TODO: Make tag: 'button' and role: 'button' once interactable

  h: '$6',
  w: '$6',
  borderWidth: '$0.5',
  borderColor: '$onSurface.neutral.defaultInverted',
  bg: '$onSurface.neutral.zebraAlt',
  centered: true,
  br: '$full',

  variants: {
    variant: {
      alt: {
        borderColor: '$onSurface.neutral.default',
        bg: '$onSurface.neutral.tooltip',
      },
      default: {},
    },
  } as const,
  defaultVariants: {
    variant: 'default',
  },
});

export const Pin = withStaticProperties(PinFrame, { Icon: PinIcon });
export type PinProps = GetProps<typeof Pin>;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { cancelSale } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';
import { redemptionPreviewQueryKeyFn, saleQueryKeyFn, salesQueryKeyFn } from './sales.keys';

export function usePostCancelSaleMutation(options?: UseAwaitedMutationOptions<typeof cancelSale>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: cancelSale,
    ...options,
    onSuccess: (data, variables, context) => {
      const { cid, offeringCid, accountCid } = data;

      queryClient.invalidateQueries({ queryKey: saleQueryKeyFn(cid) });
      queryClient.invalidateQueries({ queryKey: salesQueryKeyFn(accountCid) });
      queryClient.invalidateQueries({ queryKey: redemptionPreviewQueryKeyFn({ accountCid, offeringCid }) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}

import { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getAccountVouchers } from '@arrived/api_v2';
import { Rewards } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';
import { voucherAppliedKey, voucherKey, voucherUnusedKey } from './rewards.keys';
import { getOrCreateRewardsAccountId } from './utils/getOrCreateRewardsAccountId';

export function useGetVouchersQuery(
  userInfo: Rewards.RewardsUserInfo,
  options?: UseAwaitedQueryOptions<typeof getAccountVouchers>,
) {
  const queryClient = useQueryClient();

  const vouchersState = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: voucherKey,
    queryFn: async () => {
      const rewardsAccountUuid = await getOrCreateRewardsAccountId(queryClient, userInfo);
      return getAccountVouchers(rewardsAccountUuid);
    },
    ...options,
    enabled: (options?.enabled ?? true) && (userInfo.accountId != null || userInfo.userId != null),
  });

  useEffect(() => {
    if (vouchersState.data) {
      queryClient.setQueryData(
        voucherAppliedKey,
        vouchersState.data.vouchers?.filter((voucher) => voucher.state === 'APPLIED') || [],
      );
      queryClient.setQueryData(
        voucherUnusedKey,
        vouchersState.data.vouchers?.filter((voucher) => voucher.state === 'CREATED') || [],
      );

      vouchersState.data.vouchers?.forEach((voucher) => {
        queryClient.setQueryData([voucherKey, voucher.uuid], voucher);
      });
    }
  }, [vouchersState.data]);

  return vouchersState;
}

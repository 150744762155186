import { useQuery } from '@tanstack/react-query';

import { getOfferingDocuments } from '@arrived/api_v2';
import { OfferingId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { offeringDocumentsKeyFn } from './offerings.keys';

export function useOfferingDocumentsQuery(
  offeringId?: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getOfferingDocuments>,
) {
  return useQuery({
    queryKey: offeringDocumentsKeyFn(offeringId!),
    queryFn: () => getOfferingDocuments(offeringId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && offeringId != null,
  });
}

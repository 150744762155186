import { useMemo } from 'react';

import { Stack } from '@arrived/bricks';
import { useGetSortedOfferingHistoryEvents } from '@arrived/features-shared';
import {
  AccountOfferingBalance,
  Offering,
  OfferingStatus,
  OfferingTransaction,
  RegDIPOMetadata,
  SecurityType,
} from '@arrived/models';

import { ProductDetailsView } from '../..';
import { ProductHeader, ProductHeaderProps } from '../../productHeader';
import { ReturnsCalculatorSection, ReturnsCalculatorSectionProps } from '../../returns';
import {
  AboutSection,
  AssetDividendSection,
  AssetValueSection,
  CommonQuestionsProps,
  CommonQuestionsSection,
  DocumentsSection,
  HowAreDividendsDetermined,
  InvestmentStrategyHistoricalReturnsSection,
  LocationSection,
  MarketSection,
  MaxInvestmentCallout,
  OfferingDetailsSection,
  OfferingHistorySection,
  PropertyLeverageVolatilitySection,
  TenantLeasingSection,
  TransactionHistorySection,
  WhichPropertySection,
  YourPositionSection,
} from '../components';
import { getMetadataCommonQuestions } from '../components/commonQuestions/getMetadataCommonQuestions';
import { HeaderDetailsStackProps } from '../ProductDetailParts';
import { useProductDetailsViewContext } from '../ProductDetailsViewContext';
import { commonQuestions } from './commonQuestions';

export type SingleFamilyResidentialHeaderProps = ProductHeaderProps & {
  offering: Offering;
};

export const SingleFamilyResidentialHeader = ({ children, offering, ...rest }: SingleFamilyResidentialHeaderProps) => (
  <ProductHeader {...rest}>
    <ProductHeader.Title>{offering.name}</ProductHeader.Title>
    <ProductHeader.Tag offering={offering} />
    {offering.properties[0] && <ProductHeader.Details property={offering.properties[0]} />}
    {children}
  </ProductHeader>
);

export type SingleFamilyResidentialHeaderDetailsProps = HeaderDetailsStackProps & {
  offering: Offering;
};

export const SingleFamilyResidentialHeaderDetails = ({
  offering,
  ...rest
}: SingleFamilyResidentialHeaderDetailsProps) => {
  const { view } = useProductDetailsViewContext();

  const showMaxInvesmentCallout = useMemo(
    () => offering.maxSharesPerAccount != null && offering.status !== OfferingStatus.FUNDED,
    [offering.maxSharesPerAccount, offering.status],
  );

  return (
    <>
      {(showMaxInvesmentCallout || view === ProductDetailsView.DETAILS) && (
        <Stack {...rest}>
          {showMaxInvesmentCallout && <MaxInvestmentCallout offering={offering} />}
          {view === ProductDetailsView.DETAILS && (
            <>
              <AssetValueSection offering={offering} />
              {offering.properties[0] && <LocationSection property={offering.properties[0]} />}
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export interface SingleFamilyResidentialContentProps {
  offering: Offering;
  offeringBalance?: AccountOfferingBalance;
  regDIPOMetadata?: RegDIPOMetadata;
  transactions?: OfferingTransaction[];
  Table: ReturnsCalculatorSectionProps['TableComponent'];
}

export const SingleFamilyResidentialContent = ({
  offering,
  offeringBalance,
  regDIPOMetadata,
  transactions,
  Table,
}: SingleFamilyResidentialContentProps) => {
  const { view } = useProductDetailsViewContext();
  const { sortedEvents: events } = useGetSortedOfferingHistoryEvents(offering);

  const questions = useMemo((): CommonQuestionsProps['questions'] => {
    if (offering.securityType === SecurityType.REG_D) {
      return getMetadataCommonQuestions(regDIPOMetadata?.commonQuestions ?? []);
    } else {
      return commonQuestions;
    }
  }, [offering.securityType, regDIPOMetadata?.commonQuestions]);

  switch (view) {
    case ProductDetailsView.DETAILS:
      return (
        <>
          <AboutSection offering={offering} />
          <TenantLeasingSection offering={offering} />
          {offering.market && (
            <MarketSection description={offering.market.description} name={offering.market.title} offering={offering} />
          )}
          <DocumentsSection offering={offering} />
          {questions.length > 0 && <CommonQuestionsSection questions={questions} />}
          <WhichPropertySection offering={offering} />
        </>
      );
    case ProductDetailsView.FINANCIALS:
      return (
        <>
          <InvestmentStrategyHistoricalReturnsSection offering={offering} />
          {offering.projectedAnnualDividendYield && (
            <ReturnsCalculatorSection
              offering={offering}
              sx={{
                overflow: 'hidden',
                px: 3,
                py: 3.5,
                borderRadius: 1,
              }}
              TableComponent={Table}
            />
          )}
          <PropertyLeverageVolatilitySection offering={offering} />
          <OfferingDetailsSection offering={offering} />
        </>
      );
    case ProductDetailsView.PERFORMANCE:
      return (
        <>
          <OfferingHistorySection offering={offering} events={events} />
          <HowAreDividendsDetermined offering={offering} />
          {offeringBalance && <YourPositionSection offering={offering} offeringBalance={offeringBalance} />}
          {offeringBalance && <AssetDividendSection offering={offering} offeringBalance={offeringBalance} />}
          {transactions && Boolean(transactions.length) && <TransactionHistorySection transactions={transactions} />}
        </>
      );
  }
};

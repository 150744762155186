import { useQuery } from '@tanstack/react-query';

import { getUserNetWorth } from '@arrived/api_v2';
import { UserId } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';
import { userNetWorthKeyFn } from './users.keys';

export function useGetUserNetWorthQuery(userId?: UserId, options?: UseAwaitedQueryOptions<typeof getUserNetWorth>) {
  return useQuery({
    queryKey: userNetWorthKeyFn(userId!),
    queryFn: () => getUserNetWorth(userId!),
    ...options,
    enabled: (options?.enabled ?? true) && userId != null,
  });
}

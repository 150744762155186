import { addSentryBreadcrumb } from './addSentryBreadcrumb';
import { BreadcrumbCategories } from './BreadcrumbCategories';
import { captureSentryException } from './captureSentryException';
import { setSentryUser } from './setSentryUser';

/**
 * Augmented, cross-platform Sentry client.
 */
export const Sentry = {
  addBreadcrumb: addSentryBreadcrumb,
  captureException: captureSentryException,
  setUser: setSentryUser,

  BreadcrumbCategories,
};

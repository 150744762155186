import { ButtonProps } from '@mui/material';

import { ApplyFn, NavItemProps } from '../navigation.types';
import {
  aboutNavMultipleItem,
  handleApplySubItems,
  investNavItemPublic,
  learnNavPublicMultipleItem,
  loginNavItem,
  signUpButtonItem,
  signUpItem,
} from './items';

export const PublicNavItems = (apply: ApplyFn): NavItemProps[] => {
  return [investNavItemPublic, learnNavPublicMultipleItem, aboutNavMultipleItem].map(handleApplySubItems(apply));
};

export const PublicSecondaryItems = (
  apply: ApplyFn,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- will be deprecated
  loginButtonProps: ButtonProps<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- will be deprecated
  signUpButtonProps: ButtonProps<any>,
  isMobile?: boolean,
): NavItemProps[] => {
  const signUp: NavItemProps = isMobile ? signUpItem(signUpButtonProps) : signUpButtonItem(signUpButtonProps);

  return [loginNavItem(loginButtonProps), signUp].map(apply);
};

import { useQuery } from '@tanstack/react-query';

import { getOfferingBreakdown } from '@arrived/api_v2';
import { AccountId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { offeringBreakdownKeyFn } from './taxDocuments.keys';

export function useGetOfferingBreakdownQuery(
  year: number,
  accountId?: AccountId,
  options?: UseAwaitedQueryOptions<typeof getOfferingBreakdown>,
) {
  return useQuery({
    queryKey: offeringBreakdownKeyFn(accountId!, year),
    queryFn: () => getOfferingBreakdown(accountId!, year),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && accountId != null,
  });
}

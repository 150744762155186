import { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import _merge from 'lodash/merge';

import { getTradeBrokerApprovalInfo } from '@arrived/api_v2';
import { TradeBrokerApprovalInfoQuery } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { tradeBrokerApprovalInfoFiltersQueryKey, tradeBrokerApprovalInfoTradesQueryKeyFn } from './trades.keys';

/**
 * Showing 1000 trades by default
 */
const defaultFilters = {
  size: 1000,
};

/**
 * Loads trade info required for broker approval purposes. Each item contains a subset of trade, user, and account info.
 */
export function useGetTradeBrokerApprovalInfo(
  filters?: TradeBrokerApprovalInfoQuery,
  options?: UseAwaitedQueryOptions<typeof getTradeBrokerApprovalInfo>,
) {
  const queryClient = useQueryClient();
  const passedFilters = _merge({}, defaultFilters, filters);

  const tradeBrokerApprovalQueryState = useQuery({
    queryKey: tradeBrokerApprovalInfoTradesQueryKeyFn(passedFilters),
    queryFn: () => getTradeBrokerApprovalInfo(passedFilters),
    staleTime: MAX_STALE_TIME,
    ...options,
  });

  /**
   * While the resulting trades are stored with their filters as keys, we also need to store the
   * 'active' filter set alongside them.
   *
   * When we update approvals, we need to reference the current set of filters to know how to
   * record the 'optimistic update' once the POST succeeds. We then remove all inactive queries
   * from all other past filter combinations; future filter changes refetch and 'lock in' those
   * 'optimistic updates.'
   */
  useEffect(() => {
    if (tradeBrokerApprovalQueryState.isSuccess) {
      queryClient.setQueryData(tradeBrokerApprovalInfoFiltersQueryKey, passedFilters);
    }
  }, [tradeBrokerApprovalQueryState.isSuccess, tradeBrokerApprovalQueryState.data]);

  return tradeBrokerApprovalQueryState;
}

import { useQuery } from '@tanstack/react-query';

import { getOfferingDividends } from '@arrived/api_v2';
import { OfferingId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { offeringDividendKeyFn } from './offerings.keys';

export function useOfferingDividendsQuery(
  offeringId?: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getOfferingDividends>,
) {
  return useQuery({
    queryKey: offeringDividendKeyFn(offeringId!),
    queryFn: () => getOfferingDividends(offeringId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && offeringId != null,
  });
}

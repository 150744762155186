import { Fragment, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { SFRFundData } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../../content';
import { InvestmentStrategy } from './InvestmentStrategy';
import { Markets } from './Markets';

export type FundInvestmentStrategySectionProps = ProductDetailContentProps & {
  investmentStrategy: SFRFundData['investmentStrategy'];
  markets: SFRFundData['markets'];
};

export const FundInvestmentStrategySection = ({
  investmentStrategy,
  markets,
  ...rest
}: FundInvestmentStrategySectionProps) => {
  const sections = useMemo(
    () =>
      [
        investmentStrategy && <InvestmentStrategy investmentStrategy={investmentStrategy} />,
        markets && <Markets markets={markets} />,
      ].filter(Boolean),
    [investmentStrategy, markets],
  );

  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.investment-strategy" />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body>
        {sections.map((content, idx) => (
          <Fragment key={idx}>
            {idx >= 1 && <ProductDetailContent.Divider />}
            {content}
          </Fragment>
        ))}
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};

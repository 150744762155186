import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteUserDocument } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';
import { userDocumentsKeyFn } from './userDocuments.keys';

export function useDeleteUserDocumentMutation(options?: UseAwaitedMutationOptions<typeof deleteUserDocument>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUserDocument,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: userDocumentsKeyFn(data.userId) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}

import 'mapbox-gl/dist/mapbox-gl.css';

import MapboxMap from 'react-map-gl';

import { LinearGradient, Stack, styled } from '@arrived/bricks';
import { CONFIG } from '@arrived/config';

import { MapName } from '../constants';
import { MapProps } from './MapProps';

const LocationMapFrame = styled(Stack, {
  name: MapName,

  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 0,
});

export const Map = LocationMapFrame.styleable<MapProps>(
  ({ children, coordinates, gradient, interactive = false, zoom, ...rest }, ref) => (
    <LocationMapFrame ref={ref} position="relative" {...rest}>
      <MapboxMap
        initialViewState={{ zoom, ...coordinates }}
        style={{ height: '100%', width: '100%' }}
        mapStyle="mapbox://styles/mapbox/light-v10"
        mapboxAccessToken={CONFIG.mapboxAccessKey}
        interactive={interactive}
        reuseMaps
      >
        {children}
      </MapboxMap>
      {gradient && (
        <LinearGradient
          position="absolute"
          width="100%"
          top={0}
          left={0}
          bottom={0}
          locations={[0, 0.65]}
          start={[1, 0.5]}
          end={[0, 0.5]}
          colors={['$onSurface.neutral.zebraAlt', '$transparent']}
        />
      )}
    </LocationMapFrame>
  ),
);

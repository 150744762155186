import { useQuery } from '@tanstack/react-query';

import { postCreateRedemptionPreview } from '@arrived/api_v2';
import { CreateRedemptionPreviewRequest } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';
import { redemptionPreviewQueryKeyFn } from './sales.keys';

export function usePostRedemptionPreview(
  request: CreateRedemptionPreviewRequest,
  options?: UseAwaitedQueryOptions<typeof postCreateRedemptionPreview>,
) {
  return useQuery({
    queryKey: redemptionPreviewQueryKeyFn(request),
    queryFn: () => postCreateRedemptionPreview(request),
    ...options,
    enabled: (options?.enabled ?? true) && Boolean(request.accountCid) && Boolean(request.offeringCid),
  });
}

import { useQuery } from '@tanstack/react-query';

import { getPrivateCreditFundMetadata } from '@arrived/api_v2';
import { AssetType, OfferingCid } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { assetMetadataKey } from './assetMetadata.keys';

export function useGetPrivateCreditFundAssetMetadata(
  offeringCid?: OfferingCid,
  options?: UseAwaitedQueryOptions<typeof getPrivateCreditFundMetadata>,
) {
  return useQuery({
    queryKey: assetMetadataKey(offeringCid!, AssetType.PRIVATE_CREDIT),
    queryFn: () => getPrivateCreditFundMetadata(offeringCid!),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}

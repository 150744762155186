import { memo, useMemo } from 'react';

import { useInvestmentProductType } from '@arrived/hooks';
import { InvestmentProductType } from '@arrived/models';

import { useProductWidgetContext } from '../ProductWidgetContext';
import { FundDataPoint } from './FundDataPoint';
import { IPODataPoint } from './IPODataPoint';
import { StyledDataPointProps } from './StyledDataPoint';

export const PrimaryDataPoint = memo((props: StyledDataPointProps) => {
  const { offering } = useProductWidgetContext();
  const investmentProductType = useInvestmentProductType(offering);

  return useMemo(
    () =>
      investmentProductType &&
      (
        [
          InvestmentProductType.PRIVATE_CREDIT_FUND,
          InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND,
        ] as InvestmentProductType[]
      ).includes(investmentProductType) ? (
        <FundDataPoint {...props} />
      ) : (
        <IPODataPoint {...props} />
      ),
    [props, investmentProductType],
  );
});

import { GetProps, isWeb, styled } from '@tamagui/core';

import { Stack } from '../../atoms';
import { SheetImperativeContext } from './SheetImperativeContext';
import { SheetStyledContext } from './SheetStyledContext';

const animation = isWeb ? 'smoothSpring' : 'unset';

export const SHEET_CONTENT_FRAME_NAME = 'SheetContentFrame';

export const SheetContentFrame = styled(Stack, {
  name: SHEET_CONTENT_FRAME_NAME,
  context: SheetStyledContext,

  role: 'dialog',
  'aria-modal': true,
  'aria-labelledby': 'bricks-sheet-title',

  backgroundColor: '$foundation.neutral.yang',

  tabIndex: -1,
  animation,

  '$platform-web': {
    top: 0,
    right: 0,
    bottom: 0,

    elevation: '$10',
    shadowColor: 'rgba(0, 0, 0, 0.25)',

    overflow: 'hidden',
  },

  variants: {
    /**
     * Sheet variants are used to represent different type of sheet
     * that can be used in our experience.
     * @default form
     */
    variant: {
      /**
       * Sheet skeleton represent the bare bones of our sheet
       * component that is subsequently used in our sheet variants.
       */
      skeleton: {},

      /**
       * Sheet form a key piece of our experience.
       * They allow our investors to enter information in our app for onboarding, buying shares or moving funds.
       *
       * **Best Practices:**
       * 1. Instruction needs to be simple and use intuitive copy
       * 2. Minimize cognitive load by reducing visual clutter
       * 3. Be predictable by using commons pattern
       * 4. Anticipate the decision and execute on behalf of the user (When possible)
       */
      form: {},

      /**
       * Sheet macro are used mainly to surface detailed information
       * such as product or transaction details.
       *
       * @todo
       *
       * **Best Practices:**
       * 1. Minimize cognitive load by reducing visual clutter
       * 2. Be predictable by using commons pattern
       */
      macro: {},

      /**
       * More complex structure mainly use for product details page and sheet requiring more complex level of representation.
       * TODO: Figure out a better way for this one
       */
      'macro-alt': {},

      /**
       * Sheet represent moment of delight and are critical for sequence of interaction,
       * they provide visual feedback that they completed a sequence and celebrate it.
       *
       * **Best Practices:**
       * 1. Provide clarity on where they go next by using clear and intuitive copy and actions.
       * 2. Minimize cognitive load by reducing visual clutter
       */
      completion: {},
    },

    /**
     * Represents when a sheet needs to cover the screen or not, this is mainly a web
     * property as native will always be fullscreen. When fullscreen, we adjust the animations
     * to match what happens on native for mobile-web.
     */
    fullscreenSheet: {
      true: {
        enterStyle: { scale: 0.97, opacity: 0.8, top: '100%' },
        exitStyle: { scale: 0.97, opacity: 0.8, top: '100%' },

        top: 0,
        opacity: 1,
        scale: 1,

        w: '100%',
      },

      false: {
        '$platform-web': {
          br: '$2',

          my: '$4',
          mx: '$4',

          maxWidth: 600,
          w: '50%',
        },

        enterStyle: { right: '-50%' },
        exitStyle: { right: 0 },
      },
    },

    /**
     * Enables being able to drag to close the sheet
     */
    enablePanDownToClose: {
      false: {},
    },
  } as const,
});

export type SheetProps = Partial<SheetImperativeContext> &
  GetProps<typeof SheetContentFrame> & {
    /**
     * Override the base zIndex for the Sheet, this is for 3rd
     * party libraries that can render Sheets on top of ours.
     */
    zIndex?: number;

    /**
     * The initial index of the bottom sheet. Only applies when using `BottomSheet` for native.
     * @native
     * @default -1
     */
    index?: number;

    /**
     * Whether the sheet should animate on mount.
     * @native
     * @default false
     */
    animateOnMount?: boolean;

    /**
     * Disable using `Portal` to render the sheet. Generally when
     * using navigation, we want to allow `react-navigation` to handle any portalling.
     * So by disabling it you can allow your sheet to be renderable as a navigation screen.
     *
     * @default true
     */
    disablePortal?: boolean;

    roundedEdges?: boolean;

    /**
     * On native, this will remove the safe area top for sheets
     */
    ignoreSafeAreaTop?: boolean;
  };

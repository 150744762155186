import { useEffect } from 'react';

import { QueryClient, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getCurrentUser } from '@arrived/api_v2';
import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { GENERIC_ERROR } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { currentUserKeyFn } from './users.keys';

export function useGetCurrentUserQuery(options?: UseAwaitedQueryOptions<typeof getCurrentUser>) {
  const { isAuthenticated, clearSession } = useArrivedAuth0();

  const currentUserState = useQuery({
    queryKey: currentUserKeyFn(),
    queryFn: getCurrentUser,
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && isAuthenticated,
  });

  useEffect(() => {
    const logoutOnError = async () => {
      if (currentUserState.error) {
        const usedError = currentUserState.error as AxiosError;

        // If the user is not authenticated according to abacus, logout
        if (usedError.response?.status === 401 && usedError.response?.statusText === GENERIC_ERROR.UNAUTHORIZED) {
          clearSession();
        }
      }
    };

    logoutOnError();
  }, [currentUserState.error]);

  return currentUserState;
}

export async function fetchCurrentUserQuery(queryClient: QueryClient) {
  return await queryClient.fetchQuery({
    queryKey: currentUserKeyFn(),
    queryFn: () => getCurrentUser(),
    staleTime: MAX_STALE_TIME,
  });
}

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postUserResetMFA } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../../utils';
import { userMFADetailsKeyFn } from './users.mfa.keys';

export function usePostResetMFAMutation(options?: UseAwaitedMutationOptions<typeof postUserResetMFA>) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postUserResetMFA,

    ...options,

    onSuccess: (...result) => {
      queryClient.invalidateQueries({ queryKey: userMFADetailsKeyFn(result[1]) });
      options?.onSuccess && options.onSuccess(...result);
    },
  });
}

import { styled } from '@tamagui/core';

import { Skeleton, UtilityText } from '../../atoms';
import { DATA_POINT_LABEL_NAME } from './constants';
import { DataPointStyledContext } from './DataPointStyledContext';

const LabelText = styled(UtilityText, {
  name: DATA_POINT_LABEL_NAME,

  context: DataPointStyledContext,

  color: '$onSurface.neutral.defaultInverted',
  token: 'utility.helper.medium',

  variants: {
    alignment: {
      left: {},
      right: {
        textAlign: 'right',
      },
      center: {
        textAlign: 'center',
      },
    },
    colors: {
      dark: {
        color: '$onSurface.neutral.default',
      },
      muted: {
        color: '$onSurface.neutral.muted',
      },
    },
  } as const,
});

const LabelSkeleton = styled(Skeleton.Rect, {
  context: DataPointStyledContext,

  h: '$2',
  w: '$8',

  variants: {
    inline: {
      true: {
        w: '$10',
      },
    },
  } as const,
});

export const Label = LabelText.styleable((props, ref) => {
  const { loading } = DataPointStyledContext.useStyledContext();

  return loading ? <LabelSkeleton /> : <LabelText ref={ref} {...props} />;
});

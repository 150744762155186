import { styled } from '@tamagui/core';

import { ValueText } from '../text';
import { ROW_VALUE_NAME } from './constants';

export const Value = styled(ValueText, {
  name: ROW_VALUE_NAME,

  token: 'value.medium',
  flexShrink: 0,
});

import capitalize from 'lodash/capitalize';
import memoize from 'lodash/memoize';

import { normalize } from '../utilities';
import { Breakpoint } from './breakpoints';

/**
 * Core Typography Values
 *
 * This is where we define the core typography values for the theme, if a change is made
 * here it is made for NativeBase and MUI.
 */
export const typography = {
  letterSpacings: {
    xs: '-0.05em',
    sm: '-0.02em',
    md: 0,
    lg: '0.01em',
    xl: '0.02em',
    '2xl': '0.05em',
    '3xl': '0.1em',
    '4xl': '0.15em',
    '5xl': '0.2em',
  },
  lineHeights: {
    '3xs': '0.875rem',
    '2xs': '1rem',
    xs: '1.125rrem',
    sm: '1.25rem',
    md: '1.375rem',
    lg: '1.5rem',
    xl: '1.75rem',
    '2xl': '2rem',
    '2.5xl': '2.125rem',
    '3xl': '2.5rem',
    '4xl': '3rem',
    '5xl': '3.5rem',
    '6xl': '4rem',
    '7xl': '4.5rem',
    '8xl': '5rem',
    '9xl': '5.5rem',
  },
  // These need to be defined separately for NativeBase,
  // since we are using `as const`
  fontConfig: {
    Calibre: {
      300: {
        normal: 'Calibre-Light',
      },
      400: {
        normal: 'Calibre-Regular',
      },
      500: {
        normal: 'Calibre-Medium',
      },
      600: {
        normal: 'Calibre-Semibold',
      },
      700: {
        normal: 'Calibre-Bold',
      },
    },
  },
  fonts: {
    heading: 'Calibre',
    body: 'Calibre',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  fontSizes: {
    '2xs': '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.375rem',
    '5xl': '3rem',
    '6xl': '3.5rem',
    '7xl': '4.375rem',
    '8xl': '5.625rem',
    '9xl': '6rem',
    '10xl': '8rem',
  },
} as const;

/**
 * Core heading values, these only apply to NativeBase variants as
 * they support the custom tokens for values.
 *
 * @deprecated
 */
export const typographyHeadings = {
  h1: {
    letterSpacing: 'lg',
    fontSize: { base: '4xl', md: '8xl' },
    lineHeight: { base: '5xl', md: '9xl' },
  },
  h2: {
    letterSpacing: 'lg',
    fontSize: { base: '3xl', md: '7xl' },
    lineHeight: { base: '4xl', md: '7xl' },
  },
  h3: {
    letterSpacing: 'lg',
    fontSize: { base: '2xl', md: '6xl' },
    lineHeight: { base: '3xl', md: '5xl' },
  },
  h4: {
    letterSpacing: 'lg',
    fontSize: { base: 'xl', md: '3xl' },
    lineHeight: { md: '2xl' },
  },
  h5: {
    letterSpacing: 'lg',
    fontSize: { base: 'lg', md: '2xl' },
    lineHeight: { md: '2.5xl' },
  },
  h6: {
    letterSpacing: 'lg',
    fontSize: { base: 'md', md: 'xl' },
    lineHeight: { md: 'lg' },
  },
  body1: {
    letterSpacing: 'xl',
    fontSize: { base: 'md', md: 'xl' },
    lineHeight: { md: 'lg' },
    strong: {
      fontWeight: 'semibold',
    },
  },
  body2: {
    letterSpacing: 'xl',
    fontSize: 'sm',
    lineHeight: 'sm',
  },
  button: {
    letterSpacing: 'lg',
    fontSize: { base: 'md', md: 'xl' },
    lineHeight: { base: 'sm', md: 'sm' },
    textTransform: 'none',
  },
  caption: {
    letterSpacing: 'xl',
    fontSize: 'xs',
    lineHeight: { md: '3xs' },
  },
} as const;

export type Typography = typeof typography;
export type FontSize = keyof Typography['fontSizes'];
export type LetterSpacing = keyof Typography['letterSpacings'];
export type LineHeight = keyof Typography['lineHeights'];
export type FontWeight = keyof Typography['fontWeights'];
export type Fonts = Typography['fonts'];
export type TypographyHeadings = typeof typographyHeadings;

/**
 * These are pre-converted sizes for NativeBase as they don't support REMs,
 * to keep it consistent in the codebase we are making sure the values do not change
 */

export const normalizedFontSizes = Object.entries(typography.fontSizes).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: normalize(value),
  }),
  {} as Typography['fontSizes'],
);

export const normalizedLineHeights = Object.entries(typography.lineHeights).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: normalize(value),
  }),
  {} as Typography['lineHeights'],
);

/**
 * Related types that generate the proper variants for all weights.
 * We predefine these as an easier way of referencing them during
 * the transformation.
 *
 * These also help Typescript autocomplete the variants.
 */

export type TypographyVariantWeights = {
  [key in `${keyof TypographyHeadings}.${FontWeight}`]: TypographyHeadings[keyof TypographyHeadings];
};

export type TypographyBreakpoints<T> = {
  [k in Breakpoint]?: T;
};

export type HeadingProps = {
  fontSize: TypographyBreakpoints<FontSize> | FontSize | string | number;
  lineHeight: TypographyBreakpoints<LineHeight> | string;
  letterSpacing: TypographyBreakpoints<LetterSpacing> | string;
  fontWeight?: FontWeight | string | number;
  [key: string]: unknown;
};

export type Headings = {
  [k in keyof TypographyHeadings]: HeadingProps;
};

export type TypographyVariants = {
  [key in keyof TypographyHeadings | `${keyof TypographyHeadings}.${FontWeight}`]: HeadingProps;
};

/**
 * This is a function that creates all the relevant typography weights using our
 * design system. Pass in any headings you need to have weights injected.
 */
export const createTypographyVariants = memoize(<T extends Headings>(headings: T): TypographyVariants => {
  const typographyHeadingsKeys = Object.keys(typographyHeadings) as [keyof TypographyHeadings];
  const typographyWeights = Object.keys(typography.fontWeights) as [FontWeight];

  return typographyHeadingsKeys.reduce((acc, heading) => {
    const headingVariants = typographyWeights.reduce(
      (tacc, weight) => ({
        ...tacc,
        [`${heading}.${weight}`]: {
          // MUI doesn't interpolate this value correctly, so we need to do it manually.
          // However, you can still use the `fontWeight="semibold" prop.
          ...headings[heading],
          fontWeight: typography.fontWeights[weight],
        },
      }),
      {} as TypographyVariantWeights,
    );

    return {
      ...acc,
      [heading]: headings[heading],
      ...headingVariants,
    };
  }, {} as TypographyVariants);
});

/**
 * This converts the font weights to their MUI counterpart. When
 * using the `sx` prop you can't directly use custom fontWeight values
 * without prepending them with `fontWeight`, this solves for that. Generating
 * `fontWeightLight: 300` etc.
 */

export type MuiTypographyKeys = {
  [key in keyof Typography['fontWeights'] as `fontWeight${Capitalize<key>}`]: Typography['fontWeights'][key];
};

export const muiFontWeights = Object.entries(typography.fontWeights).reduce(
  (acc, [key, value]) => ({
    [`fontWeight${capitalize(key)}`]: value,
    ...acc,
  }),
  {} as MuiTypographyKeys,
);

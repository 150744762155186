import { QueryClient, useQuery } from '@tanstack/react-query';

import { getSharesAvailableForAccount } from '@arrived/api_v2';
import { OfferingId } from '@arrived/models';

import { fetchPrimaryAccountQuery, useGetPrimaryAccountQuery } from '../users';
import { UseAwaitedQueryOptions } from '../utils';
import { accountSharesAvailableKeyFn } from './sharesAvailable.keys';

export function useSharesAvailableForAccountQuery(
  offeringId?: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getSharesAvailableForAccount>,
) {
  const { data } = useGetPrimaryAccountQuery();
  const accountId = data?.id;

  return useQuery({
    queryKey: accountSharesAvailableKeyFn(offeringId!, accountId!),
    queryFn: () => getSharesAvailableForAccount(accountId!, offeringId!),
    ...options,
    enabled: (options?.enabled ?? true) && offeringId != null && accountId != null,
  });
}

export const fetchSharesAvailableForAccountQuery = async (queryClient: QueryClient, offeringId: OfferingId) => {
  const primaryAccount = await fetchPrimaryAccountQuery(queryClient);
  return queryClient.fetchQuery({
    queryKey: accountSharesAvailableKeyFn(offeringId, primaryAccount.id),
    queryFn: () => getSharesAvailableForAccount(primaryAccount.id, offeringId),
  });
};

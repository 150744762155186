export default {
  'product-details.common-questions.how-arrived-works.header': 'How Arrived works',
  'product-details.common-questions.how-arrived-works.description':
    'Arrived acquires rental properties into an LLC and sells shares in that LLC to the general public. Arrived then manages the day to day operations including finding tenants and completing repairs. Investors receive cash dividends from rental income each quarter and capture any property value appreciation.',

  'product-details.common-questions.expected-returns.header': 'What returns can I expect',
  'product-details.common-questions.expected-returns.description':
    'Investing in Arrived rental properties can deliver returns to investors in two different ways:\n\n1) **Expected dividends** from the rental income on each property; currently paid out to investors monthly\n\n2) **Appreciation** from the change in property value that will be realized at the end of the investment hold period.',

  'product-details.common-questions.investing-horizon.header': 'Investing horizon & liquidity',
  'product-details.common-questions.investing-horizon.description':
    'Currently investors will need to plan to hold their shares for the full investment period until the property is sold and investors are paid their proportional proceeds from the sale. We anticipate establishing a mechanism to facilitate secondary trading in accordance with the rules and regulations of the U.S. Securities & Exchange Commission (SEC) as an option for liquidity during the investment period, however there can be no guarantee when that will be available. Please [submit your email](https://forms.gle/Y6YsgTuD6vXuD5MN8) if you would like to be notified of future developments.\n\nArrived strives to give investors the opportunity to build wealth through real estate. Historically, real estate returns have been maximized when treated as a long-term investment over multiple years. Arrived property offerings typically have a 5 year minimum investment period before a property would be sold.\n\n**Disclaimer:**\n\nThere is currently no public trading market for our Interests, and an active market may not develop or be sustained. There is no guarantee that appropriate regulatory approval to permit such secondary trading will ever be obtained. If an active public trading market for our securities does not develop or is not sustained, it may be difficult or impossible for you to resell your shares at any price before the end of the investment period. Even if a public market does develop, the market price could decline below the amount you paid for your shares, and there may be fees involved. Please refer to our offering circular for more details regarding potential distributions.',

  'product-details.common-questions.seeking-tenant.header': 'What to expect if my property is still seeking a tenant',
  'product-details.common-questions.seeking-tenant.description':
    "We are currently focused on marketing the rental homes to prospective tenants and will email you when a new lease has been signed. Arrived's strategy for seeking tenants is focused on signing 2 year leases, achieving market rent, and thoroughly vetting applicants. Though it may take a bit more time to lease out the homes, we believe these standards provide our investors the best way to maximize returns over the long term.",

  'product-details.common-questions.preparing-vacation-rental.header':
    'What is the process to prepare a vacation rental for bookings',
  'product-details.common-questions.preparing-vacation-rental.description':
    'Preparing a newly-funded vacation rental for bookings can take an average of 1-3 months depending on a number of factors for each property. We work with experienced design & furnishing partners that have a strong track record of standing up and operating top performing vacation rentals quickly and efficiently. Together with these partners we develop a property theme, set a budget and timeline, and work with local contractors, furniture suppliers, and our property managers to ready not only the property but also all the additional services (cleaning, customer support, pricing optimization, …etc).',

  'product-details.common-questions.diversification.header': 'Diversification',
  'product-details.common-questions.diversification.description':
    'With real estate, it can be beneficial to invest in multiple properties and markets to achieve portfolio diversification. Diversifying your portfolio can be a good way to reduce exposure to risk from an individual property, tenant, or market forces.',

  'product-details.amenities': 'Amenities',
  'product-details.common-questions': 'Common Questions',

  'product-details.program-thesis': 'Program Thesis',

  'product-details.purchase-price': 'Purchase Price',
  'product-details.purchase-price.tooltip':
    'The price paid to acquire the property. This does not include the offering fees or cash reserve.',

  'product-details.returns': 'Returns',
  'product-details.intro-to-debt.title': 'Intro to Real Estate Debt',
  'product-details.debt-vs-equity': 'Real Estate Debt vs. Equity',

  'product-details.property-leverage': 'Property Leverage',
  'product-details.property-leverage.tooltip':
    'Leverage uses borrowed capital or debt to increase the potential return of an investment. Leverage works to your advantage when real estate values rise, but it can also lead to losses if values decline.',

  'product-details.how-are-dividends-determined.header': 'How Are Monthly Dividends Determined for {offeringName}?',
  'property-details.how-are-dividends-determined.description':
    "Properties start paying dividends once they start generating income and may vary month to month. The monthly dividend is determined by analyzing several factors, including rental status, the amount of cash in the property's reserves, last quarter's cash flow, projected expenses, and lease renewal timing.\n\nFor more information on why dividends may be increased, decreased or paused, please see [our detailed FAQ](https://help.arrived.com/en/articles/6500012-how-are-dividend-amounts-determined).  The rental history and dividend payments for each property can be found on the performance tab.",

  'product-details.investment-strategy': 'Investment Strategy',
  'product-details.investment-strategy-hypothetical-returns': 'Investment Strategy Hypothetical Returns',
  'product-details.investment-strategy.strategy-return-description':
    'Investing with Arrived can generate returns in 2 ways:\n\n1) **Annual cash flow** from the rental income on each property. The property intends to pay out excess cash to investors monthly.\n\n2) **Annual appreciation returns** from any changes in property value. Any appreciation returns net of disposition costs will be paid out upon the sale of the property.',
  'product-details.investment-strategy.annual-cash-flow': 'Annual Cash Flow',
  'product-details.investment-strategy.appreciation': 'Appreciation',
  'product-details.investment-strategy.anticipated-annual-cash-flow': 'Anticipated Annual Cash Flow',
  'product-details.investment-strategy.annual-appreciation': 'Annual Appreciation',

  'product-details.investment-strategy.annual-rent': 'Annual Rent',
  'product-details.investment-strategy.annual-rent.description':
    'Annual Rent is calculated using the Anticipated Monthly Rent amount if the property is leased for a full 12 months.',

  'product-details.investment-strategy.gross-yield': '{percent} Gross Yield',
  'product-details.investment-strategy.operating-finance-legal-fees':
    'Operating, Financing, Legal & Management Expenses',
  'product-details.investment-strategy.operating-finance-legal-fees.description':
    'Estimated expenses include property taxes, property insurance, management services, tax/audit expenses, LLC registration expenses, and interest if leveraged. Additionally, our model accounts for estimated repairs and maintenance costs equal to 6% of rent collected, and a vacancy expense allocation that assumes 15 days per year, whether incurred or not.',

  'product-details.investment-strategy.cash-flow-dividend': 'Cash Flow / Dividend',

  'product-details.investment-strategy.raise-amount': 'Raise Amount',

  'product-details.investment-strategy.anticipated-annual-cash-flow.description':
    'This represents the Pro Forma estimate for annualized free cash flow for this property after fees. We aim to pay out dividends monthly based on generated cash flow.',

  'product-details.investment-strategy.vacation-rental-j-curve-strategy.header':
    'Vacation Rental Investment J-Curve Strategy',
  'product-details.investment-strategy.vacation-rental-j-curve-strategy.content':
    'Investing in vacation rentals is much like starting a small hospitality business. Each property requires a substantial upfront investment in renovation, design, and furnishing to attract local travelers. Once the property is ready for bookings, the focus shifts to building its brand through positive reviews and repeat guests.\n\nThis process follows the investment J-curve pattern. Larger upfront investments in design and furnishings, alongside initially slower revenue, while building ratings and reviews, can be accompanied by initially lower dividends and share prices. This initial dip represents the low point of the J-Curve. As the property stabilizes and builds its reputation, returns can increase, reflecting the growth period of the curve.',

  'product-details.investment-strategy.vacation-rental-j-curve-stages.header': 'Vacation Rental J-Curve Stages',
  'product-details.investment-strategy.vacation-rental-j-curve-stages.content':
    "1) **Initial Investment**: The property is funded on Arrived.\n\n2) **Renovation, Design, and Furnishing**: Significant investment is allocated to renovate, design, and furnish the property to attract local travelers and stand out in a competitive market.\n\n3) **Increasing Bookings & Reviews**: The property may initially be listed at a competitive daily rate to attract bookings and generate positive reviews quickly. This is done with the aim of achieving 5-star ratings and accolades like 'Airbnb Guest Favorite,' which can greatly enhance the property's performance.\n\n4) **Stabilization and Long-Term Growth**: As the property accumulates more 5-star reviews, its reputation solidifies. Gradual increases in daily rates will align with the property's growing market position, optimizing revenue over time.",
  'product-details.investment-strategy.vacation-rental-j-curve.alt-tag':
    "A line graph illustrating the financial performance over time for a vacation rental, starting with 'Initial Investment,' dipping down during a phase of 'Renovation, Design & Furnishing,' rising through a phase of 'Growing Bookings & Reviews,' and finally stabilizing with  a phase of'Stabilized & Long Term Growth.",

  'product-details.annual-appreciation.part-1':
    'Based on data from the Zillow Value Home Index (ZHVI) Single Family Homes Time Series, single family homes have appreciated **4.5%** per year on average for the last 20 years (March 31, 2003 - March 31, 2023).',
  'product-details.annual-appreciation.part-2':
    "This data is based on a national average for Single family homes in the 35th to 65th percentile range by home prices according to the Zillow Home Value Index. This is a national average and may not represent actual performance of this property's zipcode. In addition to property appreciation, equity returns also depend on real estate investment costs, hold period, and leverage. For more information, refer to the [Offering Circular](https://arrived.com/circulars).",
  'product-details.annual-appreciation.part-3':
    'Use our returns calculator below to see how hypothetical property performance may impact your investment.',

  'product-details.property-management': 'Property Management',

  'product-details.rent-ready-date': 'Rent-Ready Date',
  'product-details.rent-ready-date.tooltip':
    'The estimated launch date when this property is expected to begin accepting bookings from guests.',

  'product-details.dividend-eligibility-sfr':
    'This property is eligible to start receiving dividends after its first lease is signed.',
  'product-details.dividend-eligibility-vr':
    'This property is eligible to start receiving dividends after it is rent ready.',
  'product-details.dividend-eligibility-sfr-fund':
    'Currently, we expect to pay out dividends for the Single Family Residential fund monthly, with subsequent dividends being paid out at the end of each month. Please note that depending on the timing of your investment, it may take up to 60 days to receive your first dividend.',

  'product-details.the-market': 'The Market',
  'product-details.markets': 'Markets',

  'product-details.property-leverage-volatility': 'Property Leverage & Volatility',
  'product-details.property-equity-volatility': 'Property Equity Volatility',
  'product-details.property-equity-volatility.tooltip':
    'Investment volatility refers to the range of values that a particular asset might move between. As an example, higher volatility might mean higher potential movements up or down in the value of the investment over time.',
  'product-details.property-equity-volatility.low':
    'Relative to other properties on the Arrived platform, this property may carry **lower** potential for equity returns, but also carries **lower** potential for volatility given the amount of leverage used on the property.',
  'product-details.property-equity-volatility.average':
    'Relative to comparable properties on the Arrived platform, this property may carry **average** potential for equity returns, but also carries **average** potential for volatility given the amount of leverage used on the property.',
  'product-details.property-equity-volatility.high':
    'Relative to comparable properties on the Arrived platform, this property may carry **higher** potential for equity returns than similar rental homes with no leverage, but also carries **higher** potential for volatility given the amount of leverage used on the property.',
  'product-details.property-equity-volatility.higher': 'Higher',
  'product-details.property-equity-volatility.lower': 'Lower',

  'product-details.details': 'Details',
  'product-details.properties': 'Properties',
  'product-details.offering': 'Offering',
  'product-details.intro-to-debt': 'Intro to Debt',

  'product-details.inception-date': 'Inception Date',
  'product-details.inception-date.tooltip':
    'The Inception Date is the first day the fund was qualified with the SEC and became available for investment.',

  'product-details.current-dividend-yield': 'Current Dividend Yield',
  'product-details.current-dividend-yield.tooltip':
    'Current Dividend Yield is the annualized dividend rate for the Fund, based off its most recent monthly dividend and current share price',

  'product-details.portfolio-leverage': 'Portfolio Leverage',
  'product-details.portfolio-leverage.tooltip':
    'The Arrived Single Family Residential Fund intends to add leverage over the life of the fund at times when the investment team believes long-term interest rates are favorable to do so.\n\nLeverage is the utilization of borrowed capital or debt to magnify the potential return of an investment. While leverage can be advantageous when real estate values rise, it can also lead to losses if values decline.',

  'product-details.liquidity-and-fees': 'Liquidity & Fees',
  'product-details.liquidity': 'Liquidity',
  'product-details.management-and-other-fees': 'Management & Other Fees',
  'product-details.asset-management-fees': 'Asset Management Fees',
  'product-details.offering-fees-and-other-expenses': 'Offering Fees & Other Expenses',
  'product-details.liquidity-and-fees.table-header': 'Age of Investment',
  'product-details.liquidity-and-fees.table-header-value': 'Redemption Cost',
  'product-details.liquidity-and-fees.table-header-value.description': '% of total share value',

  'product-details.documents': 'Documents',
  'product-details.open-document': 'Open document',

  'product-details.aum-fee': 'Asset Management Fee',
  'product-details.aum-fee.label': '{percent} of Net Assets (Per Quarter)',
  'product-details.aum-fee.label-monthly': '{percent} of Net Assets (Per Month)',
  'product-details.sourcing-fee': 'Sourcing Fee',
  'product-details.sourcing-fee.label': '{percent} of Property Price (One-time)',

  'product-details.total-annualized-rent': 'Total Annualized Rent',
  'product-details.total-annualized-rent.tooltip':
    'Total Annualized Rent is calculated as the total and anticipated monthly rent for all properties in the Fund multiplied by 12 months.',
  'product-details.stabilized-occupancy': 'Stabilized Occupancy',
  'product-details.stabilized-occupancy.tooltip':
    'Stabilized Occupancy refers to the percentage of portfolio properties that are rented compared to the total number of stabilized properties. This includes all properties that have been rented or have been rent-ready for 90+ days.',

  'product-details.active-loans': 'Active Loans',
  'product-details.active-loans.tooltip': 'This is the number of loans currently owned by the Private Credit Fund.',
  'product-details.completed-loans': 'Completed Loans',
  'product-details.completed-loans.tooltip':
    'This is the number of loans previously owned by the Private Credit Fund that have since been completed.',

  'product-details.recently-acquired': 'Recently Acquired',
  'product-details.stabilized': 'Stabilized',

  'product-details.property': 'Property',
  'product-details.rent': 'Rent',
  'product-details.rent-monthly': 'Rent / Mo',
  'product-details.anticipated-rent': 'Anticipated Rent',
  'product-details.anticipated-rent-monthly': 'Anticipated Rent / Mo',
  'product-details.rent.tooltip': 'The gross monthly rent for the property.',
  'product-details.anticipated-rent.tooltip': 'The anticipated gross monthly rent for the property.',

  'product-details.type': 'Type',
  'product-details.type.single-family': 'Single Family',

  'product-details.status': 'Status',
  'product-details.rental-status': 'Rental Status',

  'product-details.market-spotlight': 'Market Spotlight',
  'product-details.market-spotlight-x': 'Market Spotlight: {label}',

  'product-details.offering-details': 'Offering Details',

  'product-details.property-purchase-price': 'Property Purchase Price',
  'product-details.property-purchase-price.tooltip':
    'The price paid to acquire the property. This does not include the offering fees or cash reserve.',

  'product-details.property-improvements-cash-reserves': 'Property Improvements & Cash Reserves',
  'product-details.property-improvements-cash-reserves.tooltip':
    'The amount of money set aside for improvements to the property and a cash reserve balance for supporting long-term operations.',

  'product-details.closing-offering-holding-costs': 'Closing, Offering & Holding Costs',
  'product-details.closing-offering-holding-costs.tooltip':
    'These expenses include the costs associated with closing on the property, offering the shares for investment, and financing the property alongside or after closing.',

  'product-details.arrived-sourcing-fee': 'Arrived Sourcing Fee (One-time)',
  'product-details.arrived-sourcing-fee.tooltip':
    "This is the fee Arrived charges for our work involved with sourcing the property and preparing it for investment. It's a one-time fee that is already included in the Equity Raise from Investors and share price.",

  'product-details.total-property-amount': 'Total Property Amount',
  'product-details.total-property-amount.tooltip':
    'The total costs for the property, improvements, cash reserve, and fees.',

  'product-details.property-loan-amount': 'Property Loan Amount',
  'product-details.property-loan-amount.tooltip':
    'The amount of the Total Cost funded by a long-term mortgage loan. Mortgages are placed on the property to provide investors with leverage.',

  'product-details.financing': 'Financing',
  'product-details.financing.tooltip.strategic':
    'The percent of the purchase price that is funded by the loan. This property may seek to add up to 70% in leverage through a strategic refinancing.',
  'product-details.financing.tooltip': 'The percent of the purchase price that is funded by the loan.',

  'product-details.interest-rate': 'Interest Rate',
  'product-details.interest-rate.tooltip':
    'The interest rate for the Home Loan used to acquire the property. The loan is non-recourse, which means investors are not liable for the debt. The interest expense gets paid out of rental income from the property.',

  'product-details.equity-raised-from-investors': 'Equity Raised from Investors',
  'product-details.equity-raised-from-investors.tooltip':
    'The amount of capital being contributed by investors. It is equal to the total cost minus the loan amount.',

  'product-details.ipo-price-per-share': 'IPO Price Per Share',
  'product-details.ipo-price-per-share.tooltip': 'The price per share at the first time of sale to investors.',

  'product-details.total-shares': 'Total Shares',
  'product-details.total-shares.tooltip': 'The total number of shares being offered to investors.',

  'product-details.hold-period': 'Hold Period',
  'product-details.hold-period.tooltip.ltr':
    "Each property has a unique anticipated hold period, but we typically estimate a timeline of 5-7 years for long term rentals. During the hold period, we intend to distribute any Free Cash Flow in the form of dividend payments. The determination of when to sell a property is made based on a series of relevant factors, including the prevailing and projected economic conditions, whether the property is anticipated to appreciate or decline substantially, and how any existing lease may impact the sales price we may receive. As a result, we may decide that it's in the best interest of shareholders to sell a property earlier than five years or to hold a property for more than seven years.",
  'product-details.hold-period.tooltip.str':
    'Each property has a unique anticipated hold period, but we typically estimate a timeline of 5-15 for vacation rentals. During the hold period, we intend to distribute any Free Cash Flow in the form of dividend payments. The determination of when to sell a property is made based on a series of relevant factors, including the prevailing and projected economic conditions, whether the property is anticipated to appreciate or decline substantially, and how any existing lease may impact the sales price we may receive. As a result, we may decide that it’s in the best interest of shareholders to sell a property earlier than five years or to hold a property for more than seven years.',
  'product-details.hold-period.range': '{min}-{max} years',

  'product-details.asset-management-fee': 'Asset Management Fee',
  'product-details.asset-management-fee-gross-rents': '{aumGrossRevenues} of Gross Rents',
  'product-details.asset-management-fee.tooltip':
    'The Asset Management fee is a quarterly fee paid to Arrived out of the rental income, and helps cover the preparation of tax forms for investors, the distribution of dividends to all investors, procuring insurance policies and filing claims when applicable, ensuring property taxes and loan payments are paid, overseeing financial accounting for properties, and overseeing the third party property manager (competitive analysis of rental rate, review of property improvements, rehab & turn work, and expense management & approval).',

  'product-details.which-property-to-chose': "Don't Know Which Property to Choose?",
  'product-details.which-property-to-chose.details':
    'No need to worry, all Arrived rental properties go through our rigorous selection process and have been pre-vetted for their investment potential by our acquisitions team ([more info](https://arrived.com/blog/how-to-make-money-investing-in-sfrs-the-arrived-process/)). Rather than focusing on selecting individual properties to invest in, many Arrived investors simply distribute their investment across several available properties to achieve portfolio diversification.',
  'product-details.which-property-to-chose.we-make-it-easy.header': 'We make it easy with funds',
  'product-details.which-property-to-chose.we-make-it-easy.details':
    'For those looking to build or add to their portfolio without investing in individual rental properties, the Arrived Single Family Residential Fund is an ideal option due to its always-available investment experience, immediate diversification, and access to liquidity.',

  'product-details.other-questions': 'Other Questions?',
  'product-details.visit-our-help-center': 'Visit our help center',
  'product-details.chat-with-us-1-on-1': 'Chat with us 1:1',

  'product-details.tenant-leasing-process': 'Tenant Leasing Process',
  'product-details.tenant-leasing-process.description':
    'The time needed to sign the first tenant lease is variable and can depend on several factors including seasonality and whether the property requires any sort of renovation. Historically the average time to lease has been 45 days and has ranged from a minimum of 7 days to over 90 days.\n\nFor more details on each status, check out [the different rental statuses here](https://help.arrived.com//en/articles/7971205-what-do-the-single-family-residential-rental-statuses-mean).',

  'product-details.strategic-refinance-candidate': 'Strategic Refinance Candidate',
  'product-details.refinance-candidate': 'Refinance Candidate',
  'product-details.strategic-refinance-candidate.description':
    'This property may seek to add up to 70% in leverage through a strategic refinancing. [Learn More](https://help.arrived.com/articles/6843245-will-arrived-refinance-properties-in-the-future).',

  'product-details.max-investment-amount-disclaimer':
    'Due to high demand, we have limited the maximum investment amount in {offeringName} to {maxInvestment} so more people have the opportunity to invest. As a reminder, this will only be the case for some properties moving forward. We will have more properties available in the coming weeks.',

  'product-details.total-net-assets': 'Total Net Assets',
  'product-details.total-net-assets.tooltip': 'Total value of shares owned by investors.',

  'product-details.share-price': 'Share Price',
  'product-details.sfr-share-price-tooltip': 'This is the current value per share of the SFR Fund',

  'product-details.cta.trading-paused': 'Trading Paused',
  'product-details.cta.wallet.label': 'Your Cash Balance',
  'product-details.cta.wallet.tooltip':
    'The available cash balance you have to invest. Investments can be made with your cash balance, in cojunction with a linked bank and your cash balance, or with just your linked bank.',
  'product-details.cta.invest': 'Invest',
  'product-details.cta.reinvest-dividends': 'Dividends available to reinvest',
  'product-details.cta.signup-to-invest': 'Invest',
  'product-details.cta.notify-me': 'Notify Me',
  'product-details.cta.maxed-out': 'Maxed Out',
  'product-details.cta.complete-account-setup': 'Complete Account Setup',
  'product-details.cta.unaccredited': 'Trading Restricted (Reg D)',
  'product-details.cta.update-status': 'Update Status',
  'product-details.cta.coming-date': 'Coming {date}',

  'product-details.your-position': 'Your Position',
  'product-details.equity': 'Equity',
  'product-details.your-equity': 'Your Equity',
  'product-details.initial-investment': 'Initial Investment',
  'product-details.appreciation': 'Unrealized Appreciation',
  'product-details.total-appreciation': 'Total Appreciation',
  'product-details.total-appreciation-percentage': 'Total Appreciation %',
  'product-details.shares': 'Shares',
  'product-details.dividend': 'Dividend',
  'product-details.current-share-price': 'Current Share Price',
  'product-details.current-share-price.tooltip':
    'Share prices are determined by taking the current property value estimate and factoring in the LLC’s assets and liabilities, such as cash reserves and loan balance. Reduction in cash reserves from starting operations and servicing loans can negatively impact the share valuation initially. We also take into account the pro-rated upfront fees involved in the investment, including the Closing, Offering, Holding costs, & Arrived sourcing fee. The Share Price value may go up or down from quarter to quarter, and the actual investment returns will largely depend on the property’s eventual sale price at the end of the investment period. Learn more about how share prices are calculated in our Learn blog.',
  'product-details.annualized-yield': 'Annualized Yield',
  'product-details.annualized-yield.tooltip':
    'The anticipated profit to be earned on the investment if it was held for one year expressed as a percentage of the initial investment amount. See the Term length for the length of the investment, which may be shorter or longer than a year.',
  'product-details.net-return': 'Net Return',
  'product-details.net-return.tooltip':
    'Net return is calculated by adding the total dividends paid by an investment to its realized and unrealized gain or loss. Dividends are realized upon payment, while appreciation remains unrealized until the related offerings are sold.',
  'product-details.unrealized-gain-loss': 'Unrealized Gain/Loss',
  'product-details.unrealized-gain-loss.tooltip':
    'The unrealized gain or loss of an investment reflects the difference between the initial investment and current equity based on share value. All appreciation returns remain unrealized until the related offerings are sold.',
  'product-details.unrealized-gain-loss-plus-dividends': 'Gain/Loss + Dividends Paid',
  'product-details.minimum-investment': 'Minimum Investment',
  'product-details.term': 'Term',
  'product-details.term.tooltip':
    'The targeted length of the investment. Investors will receive their monthly interest and principal repayment at the end of the term. The term begins the day that interest starts accruing.',
  'product-details.term-label': '{noteDuration} {suffix}',

  'product-details.maturity-date': 'Maturity Date',

  'product-details.asset-and-dividend': 'Asset & Dividend',
  'product-details.asset': 'Asset',
  'product-details.your-shares-count': 'Your Shares Count',
  'product-details.your-last-payment': 'Your Last Payment',
  'product-details.your-all-time-payment': 'Your All Time Payment',

  'product-details.date-added': 'Date Added',

  'product-details.dividend-payment-history': 'Dividend Payment History',
  'product-details.dividend-payment-name': 'Dividend payment - {offeringName}',
  'product-details.no-dividends-yet': 'You have no dividends yet.',

  'product-details.transaction-history': 'Transaction History',
  'product-details.x-shares': '{count} Shares',
  'product-details.purchase': 'Purchase',
  'product-details.loans': 'Loans',

  'product-details.rental-data': 'Rental Data',

  'product-details.all-properties': 'All Properties',

  'product-details.rental-status.property-tenant-issue.title': 'Property / Tenant Issue',
  'product-details.rental-status.property-tenant-issue.subtitle':
    'There is an issue at this property or with the tenant. We are working with our property manager to resolve this issue in a timely manner.',

  'product-details.rental-status.market-preparation.title': 'Market Preparation',
  'product-details.rental-status.market-preparation.subtitle':
    'The property management team is preparing the property for the market (rehab/turn work, maintenance, photos, etc.)',

  'product-details.rental-status.approved-application.title': 'Approved Application',
  'product-details.rental-status.approved-application.subtitle':
    "An approved lease application has been signed and is awaiting the resident's move-in.",

  'product-details.rental-status.marketed-for-rent.title': 'Marketed for Rent',
  'product-details.rental-status.marketed-for-rent.subtitle':
    'This property is currently listed for rent on Zillow and other major rental sites. The majority of Arrived properties are rented within 90 days of acquisition.',

  'product-details.rental-status.rented.title': 'Rented',
  'product-details.rental-status.rented.subtitle': 'This property has a signed lease and is occupied by a resident.',

  'product-details.historical-fund-performance': 'Historical Fund Performance',

  'product-details.historical-fund-performance.appreciation': 'Appreciation',
  'product-details.historical-fund-performance.appreciation-tooltip':
    'Fund property appreciation is evaluated once a quarter.',

  'product-details.historical-fund-performance.monthly-dividend': 'Monthly Dividend',
  'product-details.historical-fund-performance.annualized-dividend': 'Annualized Dividend',

  'product-details.historical-fund-performance.quarterly-performance': 'Quarterly Performance',
  'product-details.historical-fund-performance.historic-performance': 'Historic Performance',
  'product-details.historical-fund-performance.historic-annual-appreciation': 'Historic Annual Appreciation',
  'product-details.historical-fund-performance.historic-dividend-income': 'Historic Annual Dividend Income',
  'product-details.historical-fund-performance.rental-income': 'Rental Income',

  'product-details.historical-fund-perforamnce.ytd': 'YTD',

  'product-details.fund-performance.request-redemption': 'Request Redemption',
};

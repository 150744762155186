import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postTradeBrokerApprovalStatus } from '@arrived/api_v2';
import { TradeBrokerApprovalInfo, TradeBrokerApprovalInfoQuery } from '@arrived/models';

import { UseAwaitedMutationOptions } from '../utils';
import {
  tradeBrokerApprovalInfoFiltersQueryKey,
  tradeBrokerApprovalInfoTradesQueryKey,
  tradeBrokerApprovalInfoTradesQueryKeyFn,
} from './trades.keys';

export function usePostTradeBrokerApprovalStatusMutation(
  options?: UseAwaitedMutationOptions<typeof postTradeBrokerApprovalStatus>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postTradeBrokerApprovalStatus,
    ...options,
    onSuccess: (data, variables, context) => {
      /**
       * If the POST succeeds, we want to manually update that record in our active cache of trades
       * instead of forcing the entire query to refetch.
       *
       * This creates a much more efficient UX for Dalmore and reduces load on abacus, since these
       * queries can be quite heavy (fetching a few hundred trades at a time, at least until we
       * implement query-based pagination).
       *
       * They also rarely change the filters, so being able to work off of the same batch of trades
       * for an extended period of time is a perfect happy path to support.
       */
      const currentFilters = queryClient.getQueryData<TradeBrokerApprovalInfoQuery>(
        tradeBrokerApprovalInfoFiltersQueryKey,
      );

      const currentTradesQueryKey = tradeBrokerApprovalInfoTradesQueryKeyFn(currentFilters);
      const currentTrades = queryClient.getQueryData<TradeBrokerApprovalInfo[]>(currentTradesQueryKey);
      const { brokerApprovalStatus: newBrokerApprovalStatusForTrade, tradeCid: updatedTradeCid } = data;

      // If we're not actively filtering out the new brokerApprovalStatus, update the cached trade
      if (
        !currentFilters ||
        !currentFilters.brokerApprovalStatus ||
        currentFilters.brokerApprovalStatus === newBrokerApprovalStatusForTrade
      ) {
        const mutatedTrades = currentTrades?.map((trade) => {
          const newTrade = { ...trade };

          if (newTrade.tradeCid === updatedTradeCid) {
            newTrade.tradeBrokerApprovalStatus = newBrokerApprovalStatusForTrade;
          }

          return newTrade;
        });

        queryClient.setQueryData(currentTradesQueryKey, mutatedTrades);
      } else {
        // Else, remove that entry from the cached trades list
        const filteredTrades = currentTrades?.filter((trade) => trade.tradeCid !== updatedTradeCid);

        queryClient.setQueryData(currentTradesQueryKey, filteredTrades);
      }

      // Now that we've mutated the active array of trades, we need to clear out the others
      // We *could* iteratively push these mutations into those arrays as well, but this is safer
      queryClient.removeQueries({ queryKey: tradeBrokerApprovalInfoTradesQueryKey, type: 'inactive' });

      options?.onSuccess?.(data, variables, context);
    },
  });
}

import { setColorAlpha } from '../utils/setColorAlpha';
import { colors } from './colors';

// TODO: Type these as const and retain key information
export const gradients = {
  'gradient.neutral.yin': [
    setColorAlpha(colors['neutral.light.800'], 0.9) as string,
    setColorAlpha(colors['neutral.light.960'], 0.9) as string,
  ],
  'gradient.neutral.light': [
    colors['neutral.light.60'] as string,
    colors['neutral.light.0'] as string,
    colors['primary.light.100'] as string,
  ],
  'gradient.primary.default': [colors['primary.light.800'] as string, colors['primary.light.920'] as string],
  'gradient.primary.decorative': [setColorAlpha(colors['primary.light.700'], 0.2) as string, '$transparent'],
  'gradient.primary.decorativeAlt': [
    setColorAlpha(colors['neutral.light.40'], 0.5) as string,
    colors['neutral.light.0'] as string,
    colors['primary.dark.1000'] as string,
  ],
  'gradient.artifact.default': [colors['primary.light.0'] as string, colors['artifact.inlet.light.200'] as string],
  'gradient.artifact.inlet.light': [
    colors['artifact.inlet.light.100'] as string,
    colors['artifact.inlet.light.200'] as string,
  ],
  'gradient.artifact.lilac.light': [
    colors['artifact.lilac.light.100'] as string,
    colors['artifact.lilac.light.200'] as string,
  ],
  'gradient.artifact.musty.light': [
    colors['artifact.musty.light.100'] as string,
    colors['artifact.musty.light.200'] as string,
  ],
};

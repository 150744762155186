import { useId, useLayoutEffect } from 'react';

import { Stack, composeEventHandlers, styled, withStaticProperties } from '@tamagui/core';

import { TitleText } from '../../atoms';
import { CloseIcon } from '../../icons';
import { Button } from '../button';
import { useModalContext } from './ModalContext';

const ModalHeaderContainer = styled(Stack, {
  name: 'ModalHeaderContainer',
});

const ModalHeaderTitleContainer = styled(Stack, {
  name: 'ModalHeaderTitleContainer',
});

const ModalHeading = TitleText.styleable((props, ref) => {
  const { setLabelId } = useModalContext();
  const id = useId();

  // Only sets `aria-labelledby` on the Modal root element
  // if this component is mounted inside it.

  useLayoutEffect(() => {
    setLabelId(id);
    return () => setLabelId(undefined);
  }, [id, setLabelId]);

  return <TitleText tag="h3" token="title.heading.large" {...props} ref={ref} id={id} />;
});

export const ModalClose = Button.styleable(({ onPress, ...rest }, ref) => {
  const { onClose } = useModalContext();
  return (
    <Button
      ref={ref}
      Icon={CloseIcon}
      variant="ghost"
      condensed
      position="absolute"
      right={0}
      zIndex={1}
      onPress={composeEventHandlers(onPress, () => onClose?.())}
      {...rest}
    />
  );
});

const ModalHeaderTitle = withStaticProperties(ModalHeaderTitleContainer, { Text: ModalHeading });

export const ModalHeader = withStaticProperties(ModalHeaderContainer, {
  Title: ModalHeaderTitle,
  CloseIcon: ModalClose,
});

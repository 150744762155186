import { useMemo } from 'react';

import { CONFIG } from '@arrived/config';

import {
  BricksLayout,
  Button,
  CallToAction,
  Header,
  Icon,
  InfoGrid,
  ResponsiveImage,
  RichTextContent,
  Stack,
  Text,
  UtilityText,
  VideoComponent,
} from './components';
import { Content as BuilderContent, RegisteredComponent } from './sdk';

/**
 * This is the equivalent of Builders `registerComponent` function,
 * we are doing this as of their v2 release as they deprecated the `registerComponent` function.
 * https://github.com/BuilderIO/builder/blob/4fd24d4d917e218646623ad3b59e59410b42428d/examples/react-native/App.jsx
 */
const GenericRegisteredComponents = [
  Button.registerComponent,
  ResponsiveImage.registerComponent,
  RichTextContent.registerComponent,
  VideoComponent.registerComponent,
  CallToAction.registerComponent,
  Stack.registerComponent,
  InfoGrid.registerComponent,
  Header.registerComponent,
  Text.registerComponent,
  Icon.registerComponent,

  // @depreciated -- This is a temporary fix to allow for the legacy components to be used
  UtilityText.registerComponent,
  BricksLayout.registerComponent,
] satisfies RegisteredComponent[];

export type EssentialBuilderContentProps = Omit<Parameters<typeof BuilderContent>[0], 'apiKey'>;

/**
 * This component wraps the Builder.io Content component with
 * our API key and generic components that all instances of
 * Builder.io Content have access to.
 */
export const EssentialBuilderContent = ({ customComponents, ...props }: EssentialBuilderContentProps) => {
  const builderComponents = useMemo(
    () => [...GenericRegisteredComponents, ...(customComponents ?? [])],
    [customComponents],
  );

  return (
    <BuilderContent
      apiKey={CONFIG.builderApiKey}
      blocksWrapper={Stack}
      customComponents={builderComponents}
      {...props}
    />
  );
};

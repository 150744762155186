import { useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { format } from 'date-fns';
import _merge from 'lodash/merge';
import { parse } from 'papaparse';

import { Box, Typography, useTheme } from '@mui/material';

import { BodyText, Stack } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { getTimezoneAgnosticDate } from '@arrived/common';
import { CompanyKpis, CustomerCount } from '@arrived/models';
import { useGetHistoricalReturnsData } from '@arrived/queries';

import { CompanyKpiDisplay } from '../CompanyKpiDisplay';
import { Square, Triangle } from '../images';
import { Loading } from '../Loading';
import { HistoricalPerformance } from './historicalPerformance';
import { PerformanceTable } from './PerformanceTable';

export interface PerformancePageProps {
  customerCount: CustomerCount;
  kpis: CompanyKpis;
}

export const PerformancePage = ({ customerCount, kpis }: PerformancePageProps) => {
  const { palette } = useTheme();
  const intl = useIntl();

  const historicalReturnsDataState = useGetHistoricalReturnsData();

  const historicalPerformanceData = useMemo(
    () =>
      (historicalReturnsDataState.data
        ? parse(historicalReturnsDataState.data.historicalPerformanceData, { dynamicTyping: true, header: true }).data
        : []) as HistoricalPerformance[],
    [historicalReturnsDataState.data],
  );

  // The date returned from the API is in UTC so when we created a date on the client it converts it to the user's local
  // timezone, this function returns a date converted back to UTC.
  const updatedAtDate = useMemo(() => {
    if (historicalReturnsDataState.data?.updatedAt) {
      return getTimezoneAgnosticDate(historicalReturnsDataState.data?.updatedAt);
    }
  }, [historicalReturnsDataState.data?.updatedAt]);

  return (
    <Box role="main">
      <Box
        position="relative"
        px={{ xs: 4, md: 12 }}
        py={{ xs: 8, md: 12 }}
        sx={{ backgroundColor: 'white', overflowX: 'hidden' }}
      >
        <Triangle
          color={palette.background.iceGray}
          style={{
            position: 'absolute',
            top: 0,
            right: '90px',
            transformOrigin: 'top',
            transform: 'rotate(-135deg) scale(3)',
          }}
        />
        <Square
          color={palette.mint[500]}
          style={{
            position: 'absolute',
            top: '70px',
            right: '15px',
            transformOrigin: 'center',
            transform: 'rotate(45deg) scale(0.2)',
          }}
        />
        <Box
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          alignItems="flex-start"
          gap={{ xs: 2, lg: 8 }}
          position="relative"
          maxWidth="1240px"
          width="100%"
          my={0}
          mx="auto"
        >
          <Box display="flex" alignItems="flex-start" flexDirection="column" flex="1 1 50%" gap={2.5} height="100%">
            <Typography
              variant="h5.bold"
              textTransform="uppercase"
              sx={{
                // Backup in case gradient text not supported
                color: 'primary',
                background: ({ gradients }) => gradients.sea.horizontal,
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                MozTextFillColor: 'transparent',
              }}
            >
              <FormattedMessage id="historical-returns.sub-header" />
            </Typography>
            <Typography variant="h2.semibold">
              <FormattedMessage id="historical-returns.header" />
            </Typography>
          </Box>
          <Box flex="1 1 50%">
            <Box borderLeft={({ palette }) => `2px solid ${palette.tertiary[500]}`} pl={2}>
              <Stack gap="$4">
                <Markdown Text={BodyText}>{intl.formatMessage({ id: 'historical-returns.description' })}</Markdown>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
      <CompanyKpiDisplay
        kpis={_merge(kpis, customerCount)}
        kpi1="total-aum"
        kpi2="active-markets"
        kpi3="properties-funded"
        align="left"
        maxWidth="unset"
      />
      <Box height="400px" width="100%" display={historicalReturnsDataState.isLoading ? 'block' : 'none'}>
        <Loading />
      </Box>
      <Box
        display={historicalReturnsDataState.isLoading ? 'none' : 'flex'}
        flexDirection="column"
        gap={{ xs: 1, md: 2 }}
        px={{ xs: 0, md: 8, lg: 12 }}
        pt={{ xs: 4, md: 12 }}
        pb={{ xs: 0, md: 12 }}
        sx={{ backgroundColor: 'background.iceGray' }}
      >
        <Box
          maxWidth="1240px"
          width="100%"
          my={0}
          mx="auto"
          textAlign={{ xs: 'left', md: 'right' }}
          pr={{ xs: 2, md: 4 }}
          pl={{ xs: 2, md: 0 }}
          display={historicalReturnsDataState.isSuccess ? 'block' : 'none'}
        >
          <Typography variant="body1" color="text.800">
            <FormattedMessage
              id="returns-page.last-update-at"
              values={{
                date: updatedAtDate ? format(updatedAtDate, 'MMMM yyyy') : undefined,
              }}
            />
          </Typography>
        </Box>
        <Box
          maxWidth="1240px"
          width="100%"
          my={0}
          mx="auto"
          px={{ xs: 0, md: 2 }}
          pt={2}
          sx={{ backgroundColor: 'white' }}
        >
          <PerformanceTable
            historicalPerformanceData={historicalPerformanceData}
            sharePriceUpdatedOfferingIds={historicalReturnsDataState.data?.sharePriceUpdatedOfferingIds ?? []}
          />
        </Box>
      </Box>
    </Box>
  );
};

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint } from '@arrived/bricks';
import { Offering } from '@arrived/models';

import { ProductInfoBox, ProductInfoBoxProps } from '../../../../productInfoBox';
import { DetailDataPoint } from './DetailDataPoint';
import { InfoBoxRow } from './InfoBoxRow';

export type PropertyAmountBreakdownProps = ProductInfoBoxProps & { offering: Offering };

export const PropertyAmountBreakdown = ({ offering, ...rest }: PropertyAmountBreakdownProps) => {
  return (
    <ProductInfoBox {...rest}>
      <InfoBoxRow>
        <DetailDataPoint
          tooltip={{
            content: <FormattedMessage id="product-details.property-purchase-price.tooltip" />,
            title: <FormattedMessage id="product-details.property-purchase-price" />,
          }}
        >
          <DataPoint.Value>
            <FormattedNumber
              style="currency"
              currency="USD"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={offering.totalPurchasePrice}
            />
          </DataPoint.Value>
          <DataPoint.Label>
            <FormattedMessage id="product-details.property-purchase-price" />
          </DataPoint.Label>
        </DetailDataPoint>
        <InfoBoxRow.Divider />
        <DetailDataPoint
          tooltip={{
            content: <FormattedMessage id="product-details.property-improvements-cash-reserves.tooltip" />,
            title: <FormattedMessage id="product-details.property-improvements-cash-reserves" />,
          }}
        >
          <DataPoint.Value>
            <FormattedNumber
              style="currency"
              currency="USD"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={offering.propertyImprovementsAndCashReserves}
            />
          </DataPoint.Value>
          <DataPoint.Label>
            <FormattedMessage id="product-details.property-improvements-cash-reserves" />
          </DataPoint.Label>
        </DetailDataPoint>
      </InfoBoxRow>
      <InfoBoxRow>
        <DetailDataPoint
          tooltip={{
            content: <FormattedMessage id="product-details.closing-offering-holding-costs.tooltip" />,
            title: <FormattedMessage id="product-details.closing-offering-holding-costs" />,
          }}
        >
          <DataPoint.Value>
            <FormattedNumber
              style="currency"
              currency="USD"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={offering.closingOfferingAndHoldingCosts}
            />
          </DataPoint.Value>
          <DataPoint.Label>
            <FormattedMessage id="product-details.closing-offering-holding-costs" />
          </DataPoint.Label>
        </DetailDataPoint>
        <InfoBoxRow.Divider />
        <DetailDataPoint
          tooltip={{
            content: <FormattedMessage id="product-details.arrived-sourcing-fee.tooltip" />,
            title: <FormattedMessage id="product-details.arrived-sourcing-fee" />,
          }}
        >
          <DataPoint.Value>
            <FormattedNumber
              style="currency"
              currency="USD"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={offering.arrivedOneTimeProceeds}
            />
          </DataPoint.Value>
          <DataPoint.Label>
            <FormattedMessage id="product-details.arrived-sourcing-fee" />
          </DataPoint.Label>
        </DetailDataPoint>
      </InfoBoxRow>
    </ProductInfoBox>
  );
};

import { useMemo } from 'react';

import { GetProps, styled, useMedia, withStaticProperties } from '@tamagui/core';

import { Dropdown as DropdownAtom, useDropdownContext } from '../../../atoms';
import { CaretDownIcon } from '../../../icons';
import { AnimateHeight } from '../../../utils';
import { useMainNavContext } from '../MainNavContext';
import { NavItem } from '../navItem';
import { NavItemStyledContext } from '../navItem/NavItemStyledContext';
import { Resource } from '../types';

const Down = styled(CaretDownIcon, {
  context: NavItemStyledContext,

  animation: 'quick',

  '$group-NavItem-press': {
    // @ts-ignore color isn't recognized, but it DOES work!
    color: '$onSurface.neutral.defaultInverted',
  },

  variants: {
    open: {
      true: {
        rotate: '180deg',
      },
    },
  } as const,
});

const Icon = Down.styleable<GetProps<typeof Down>>((props, ref) => {
  const media = useMedia();

  return (
    // @ts-ignore color isn't recognized, but it DOES work!
    <Down
      ref={ref}
      {...(media.gtXs && {
        '$group-NavItem-hover': {
          color: '$interactive.primary.hovered',
        },
        '$group-NavItem-press': {
          color: '$interactive.primary.focus',
        },
      })}
      {...props}
    />
  );
});

export type DropdownProps = GetProps<typeof NavItem> & { resources: Resource[] };

const Content = ({ focusable, resources }: Pick<DropdownProps, 'focusable' | 'resources'>) => {
  const context = useDropdownContext();
  const { close, Resource } = useMainNavContext();

  const media = useMedia();

  const tabIndex = useMemo(() => {
    if (!focusable) {
      return -1;
    }
    return context?.open ? 0 : -1;
  }, [context?.open, focusable]);

  return !media.gtXs ? (
    <AnimateHeight expand={context?.open}>
      {resources.map(({ label, ...rest }, idx) => (
        <Resource key={idx} {...rest}>
          <NavItem tag="a" onPress={close} tabIndex={tabIndex}>
            <NavItem.Text pl="$2">{label}</NavItem.Text>
          </NavItem>
        </Resource>
      ))}
    </AnimateHeight>
  ) : (
    <DropdownAtom.Content>
      {resources.map(({ label, ...rest }, idx) => {
        return (
          <Resource key={idx} {...rest}>
            <DropdownAtom.Item tag="a">
              <DropdownAtom.Item.Text>{label}</DropdownAtom.Item.Text>
            </DropdownAtom.Item>
          </Resource>
        );
      })}
    </DropdownAtom.Content>
  );
};

const NavDropdownIcon = Icon.styleable<Omit<GetProps<typeof Icon>, 'open'>>((props, ref) => {
  const context = useDropdownContext();

  return <Icon open={context?.open} ref={ref} {...props} />;
});

export const NavDropdown = withStaticProperties(
  NavItem.styleable<DropdownProps>(({ children, focusable, resources, ...rest }, ref) => (
    <DropdownAtom>
      <DropdownAtom.Trigger asChild>
        <NavItem ref={ref} focusable={focusable} {...rest}>
          {children}
          <NavDropdownIcon />
        </NavItem>
      </DropdownAtom.Trigger>
      <Content focusable={focusable} resources={resources} />
    </DropdownAtom>
  )),
  {
    Icon,
    Text: NavItem.Text,
  },
);

import { useCallback, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { GetProps } from '@tamagui/core';

import { Divider, Image, RootTextProps, Stack, TitleText, UtilityText } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import {
  CommonQuestionsSection,
  DocumentsSection,
  FundDetailsSection,
  FundLiquidityAndFeesSection,
  ProductDetailContent,
  ProductDetailsView,
  ProductHeader,
  Video,
  getMetadataCommonQuestions,
} from '@arrived/features-product-details';
import { ikClient } from '@arrived/imagekit';
import {
  AccountOfferingBalance,
  Offering,
  OfferingDividend,
  OfferingSharePrices,
  PrivateCreditFundMetadata,
} from '@arrived/models';

import { HelpCenterCallout } from '../components/callouts';
import { AnnualCashFlow } from '../components/sections/funds/investmentStrategy';
import { HeaderDetailsStackProps } from '../ProductDetailParts';
import { useProductDetailsViewContext } from '../ProductDetailsViewContext';

export type PrivateCreditFundProps = {
  offering: Offering;
  pcfMetadata?: PrivateCreditFundMetadata;
  dividends?: OfferingDividend[];
  offeringBalance?: AccountOfferingBalance;
  sharePrices?: OfferingSharePrices[];
};

export type PrivateCreditFundHeaderProps = {
  offering: Offering;
};

export const PrivateCreditFundHeader = ({
  offering,
  ...rest
}: PrivateCreditFundHeaderProps & HeaderDetailsStackProps) => (
  <ProductHeader {...rest}>
    <ProductHeader.Title>{offering.name}</ProductHeader.Title>
    <ProductHeader.Tag offering={offering} />
  </ProductHeader>
);

export const PrivateCreditFundHeaderDetails = () => <></>;

const LegalFootnotesText = ({ children, ...rest }: RootTextProps) => {
  const Text = useCallback(
    ({ children, ...passedRest }: GetProps<typeof UtilityText>) => (
      <UtilityText token="utility.legal" {...rest} {...passedRest}>
        {children}
      </UtilityText>
    ),
    [rest],
  );

  return <Markdown Text={Text}>{children}</Markdown>;
};

export const PrivateCreditFundContent = ({ offering, pcfMetadata, dividends, sharePrices }: PrivateCreditFundProps) => {
  const { view } = useProductDetailsViewContext();

  const isPCFAnnualizedReturnsEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.PCF_ANNUALIZED_RETURNS);

  const commonQuestions = useMemo(
    () => getMetadataCommonQuestions(pcfMetadata?.commonQuestions ?? []),
    [pcfMetadata?.commonQuestions],
  );

  switch (view) {
    case ProductDetailsView.DETAILS:
      return (
        <>
          {pcfMetadata?.about && (
            <ProductDetailContent>
              <ProductDetailContent.Header>
                <FormattedMessage id="common.about" />
              </ProductDetailContent.Header>
              <ProductDetailContent.Body>
                <Markdown>{pcfMetadata.about}</Markdown>
              </ProductDetailContent.Body>
              <ProductDetailContent.Body gap={0} pt={0}>
                <Markdown>{pcfMetadata.fundHighlights}</Markdown>
              </ProductDetailContent.Body>
              <ProductDetailContent.Body pt={0}>
                <Markdown>{pcfMetadata.dividends}</Markdown>
              </ProductDetailContent.Body>
            </ProductDetailContent>
          )}

          <FundDetailsSection dividends={dividends} offering={offering} sharePrices={sharePrices} />

          {pcfMetadata?.investmentStrategy && (
            <ProductDetailContent>
              <ProductDetailContent.Header>
                <FormattedMessage id="product-details.investment-strategy" />
              </ProductDetailContent.Header>
              <ProductDetailContent.Body>
                <Markdown>{pcfMetadata.investmentStrategy.intro}</Markdown>
                {pcfMetadata.investmentStrategy.video && (
                  <Video youtubeId={pcfMetadata.investmentStrategy.video.youtubeId}>
                    {pcfMetadata.investmentStrategy.video.imageUri && (
                      <Video.Image imageUri={pcfMetadata.investmentStrategy.video.imageUri} />
                    )}
                    <Video.Title>{pcfMetadata.investmentStrategy.video.title}</Video.Title>
                    {pcfMetadata.investmentStrategy.video.subTitle && (
                      <Video.SubTitle>{pcfMetadata.investmentStrategy.video.subTitle}</Video.SubTitle>
                    )}
                  </Video>
                )}
                <Markdown>{pcfMetadata.investmentStrategy.strategy}</Markdown>
                {pcfMetadata.investmentStrategy.webinarVideo && (
                  <Video youtubeId={pcfMetadata.investmentStrategy.webinarVideo.youtubeId}>
                    {pcfMetadata.investmentStrategy.webinarVideo.imageUri && (
                      <Video.Image imageUri={pcfMetadata.investmentStrategy.webinarVideo.imageUri} />
                    )}
                    <Video.Title>{pcfMetadata.investmentStrategy.webinarVideo.title}</Video.Title>
                    {pcfMetadata.investmentStrategy.webinarVideo.subTitle && (
                      <Video.SubTitle>{pcfMetadata.investmentStrategy.webinarVideo.subTitle}</Video.SubTitle>
                    )}
                  </Video>
                )}
              </ProductDetailContent.Body>
            </ProductDetailContent>
          )}

          {pcfMetadata?.returns && (
            <ProductDetailContent>
              <ProductDetailContent.Header>
                <FormattedMessage id="product-details.returns" />
              </ProductDetailContent.Header>
              <ProductDetailContent.Body>
                <Markdown>{pcfMetadata.returns}</Markdown>
                {pcfMetadata?.anticipatedCashFlow && <AnnualCashFlow pcfMetadata={pcfMetadata} />}
              </ProductDetailContent.Body>
            </ProductDetailContent>
          )}
          {commonQuestions.length > 0 && (
            <CommonQuestionsSection questions={commonQuestions}>
              <HelpCenterCallout />
            </CommonQuestionsSection>
          )}
          {pcfMetadata?.footnotes && <LegalFootnotesText>{pcfMetadata.footnotes}</LegalFootnotesText>}
        </>
      );

    case ProductDetailsView.OFFERING:
      return (
        <>
          {pcfMetadata?.liquidityAndRedemptions &&
            pcfMetadata?.assetManagementFees &&
            pcfMetadata?.liquidityAndRedemptions && (
              <FundLiquidityAndFeesSection
                annualizedReturnScenarios={
                  isPCFAnnualizedReturnsEnabled ? pcfMetadata.annualizedReturnScenarios : undefined
                }
                assetManagementFees={pcfMetadata.assetManagementFees}
                liquidityAndRedemptions={pcfMetadata.liquidityAndRedemptions}
                offeringFees={pcfMetadata.offeringFees}
              />
            )}
          <DocumentsSection offering={offering} />
          {commonQuestions.length > 0 && (
            <CommonQuestionsSection questions={commonQuestions}>
              <HelpCenterCallout />
            </CommonQuestionsSection>
          )}
        </>
      );

    case ProductDetailsView.INTRO_TO_DEBT:
      return (
        <>
          {pcfMetadata?.introToRealEstateDebt && (
            <ProductDetailContent>
              <ProductDetailContent.Header>
                <FormattedMessage id="product-details.intro-to-debt.title" />
              </ProductDetailContent.Header>
              <ProductDetailContent.Body>
                <Markdown>{pcfMetadata?.introToRealEstateDebt.description}</Markdown>
              </ProductDetailContent.Body>
            </ProductDetailContent>
          )}
          {pcfMetadata?.realEstateBackedDebtVsEquity && (
            <ProductDetailContent>
              <ProductDetailContent.Header>
                <FormattedMessage id="product-details.debt-vs-equity" />
              </ProductDetailContent.Header>
              <ProductDetailContent.Body>
                <Markdown>{pcfMetadata?.realEstateBackedDebtVsEquity.description}</Markdown>
                <Stack gap="$6" $gtXxs={{ gap: '$8' }}>
                  {pcfMetadata?.realEstateBackedDebtVsEquity.comparison.map((item) => (
                    <Stack key={item.title}>
                      <TitleText token="title.heading.small">{item.title}</TitleText>
                      <Stack mt="$2">
                        <Markdown key={item.debt}>{item.debt}</Markdown>
                        <Markdown key={item.equity}>{item.equity}</Markdown>
                      </Stack>
                    </Stack>
                  ))}
                  <Divider pt={0} />
                  <Stack>
                    <TitleText token="title.heading.small">{pcfMetadata?.capitalStack.title}</TitleText>
                    <Markdown>{pcfMetadata?.capitalStack.description}</Markdown>
                  </Stack>
                </Stack>

                {pcfMetadata?.images?.[0] && (
                  <Image
                    source={{
                      uri: ikClient.url({ path: pcfMetadata?.images?.[0].uri, transformation: [{ width: '1300' }] }),
                    }}
                    aspectRatio={pcfMetadata?.images?.[0].aspectRatio}
                    alt={pcfMetadata?.images?.[0].alt}
                    accessibilityLabel={pcfMetadata?.images?.[0].alt}
                  />
                )}
                {pcfMetadata?.capitalStackBlogCallout && <Markdown>{pcfMetadata?.capitalStackBlogCallout}</Markdown>}
              </ProductDetailContent.Body>
            </ProductDetailContent>
          )}
          {commonQuestions.length > 0 && (
            <CommonQuestionsSection questions={commonQuestions}>
              <HelpCenterCallout />
            </CommonQuestionsSection>
          )}
        </>
      );
  }
};

import { useQuery } from '@tanstack/react-query';

import { CONFIG } from '@arrived/config';
import { UseAwaitedQueryOptions } from '@arrived/queries';

import { ArrivedBuilderModels } from '../models';
import { FetchOneEntryParams, fetchOneEntry } from '../sdk';
import { builderBannersQueryKeyFn } from './keys';

export const fetchBuilderBanner = (params?: FetchOneEntryParams) =>
  fetchOneEntry({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.BANNER,
    enrich: true,
    ...params,
  });

export const useGetBuilderBannerQuery = (
  params?: FetchOneEntryParams,
  options?: UseAwaitedQueryOptions<typeof fetchBuilderBanner>,
) =>
  useQuery({
    queryKey: builderBannersQueryKeyFn({ ...params }),
    queryFn: () => fetchBuilderBanner(params),
    ...options,
  });

import { useQuery } from '@tanstack/react-query';

import { getOfferingDocumentRaw } from '@arrived/api_v2';
import { OfferingDocumentId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { offeringDocumentRawKeyFn } from './offerings.keys';

export function useGetOfferingDocumentRawQuery(
  offeringDocumentId: OfferingDocumentId,
  options?: UseAwaitedQueryOptions<typeof getOfferingDocumentRaw>,
) {
  return useQuery({
    queryKey: offeringDocumentRawKeyFn(offeringDocumentId),
    queryFn: () => getOfferingDocumentRaw(offeringDocumentId),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}

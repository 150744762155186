import { Offering } from '@arrived/models';

import { sortByFundedPercent } from '../sortByFundedPercent';
import { isOfferingSoldOut } from './isOfferingSoldOut';

export const sortBySoldOut = (offeringOne: Offering, offeringTwo: Offering) => {
  if (isOfferingSoldOut(offeringOne) && !isOfferingSoldOut(offeringTwo)) {
    return 1;
  } else if (!isOfferingSoldOut(offeringOne) && isOfferingSoldOut(offeringTwo)) {
    return -1;
  }
  return sortByFundedPercent(offeringOne, offeringTwo);
};

import { FC } from 'react';

import { GetProps, styled } from '@tamagui/core';

import { Skeleton, Stack } from '../../atoms';
import { IconProps } from '../../icons';
import { DATA_POINT_ICON_NAME } from './constants';
import { DataPointStyledContext, useDataPointStyledContext } from './DataPointStyledContext';

const IconFrame = styled(Stack, {
  name: DATA_POINT_ICON_NAME,

  context: DataPointStyledContext,
  display: 'none',

  flexShrink: 0,

  variants: {
    inline: {
      true: {
        display: 'flex',
      },
    },
  } as const,
});

const IconSkeleton = styled(Skeleton.Rect, {
  h: '$4',
  w: '$4',
  br: '$0.5',
});

export const Icon = IconFrame.styleable<GetProps<typeof IconFrame> & { Icon: FC<IconProps> }>(
  ({ Icon, ...rest }, ref) => {
    const { colors, loading, variant } = useDataPointStyledContext();
    return loading ? (
      <IconSkeleton />
    ) : (
      <IconFrame ref={ref} {...rest}>
        <Icon
          color={colors === 'dark' ? '$onSurface.neutral.default' : '$onSurface.neutral.defaultInverted'}
          size={variant === 'minimized' ? '$4' : '$5'}
        />
      </IconFrame>
    );
  },
);

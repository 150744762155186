import { ForwardedRef, PropsWithChildren, ReactElement, ReactNode, Ref, useMemo } from 'react';

import { GetProps, TamaguiElement, styled, useProps, withStaticProperties } from '@tamagui/core';

import { Skeleton, Stack, Tooltip } from '../../atoms';
import { IconProps, InfoIcon } from '../../icons';
import { parseRootChildren } from '../../utils';
import {
  DATA_POINT_FRAME_NAME,
  DATA_POINT_ICON_NAME,
  DATA_POINT_LABEL_NAME,
  DATA_POINT_PILL_NAME,
  DATA_POINT_TAG_NAME,
  DATA_POINT_VALUE_NAME,
} from './constants';
import { DataPointStyledContext } from './DataPointStyledContext';
import { Icon } from './Icon';
import { Label } from './Label';
import { Pill } from './Pill';
import { Tag } from './Tag';
import { Value } from './Value';

const DataPointFrame = styled(Stack, {
  name: DATA_POINT_FRAME_NAME,

  context: DataPointStyledContext,

  alignItems: 'flex-start',
  columnGap: '$2',
  rowGap: '$0.5',

  variants: {
    alignment: {
      left: {},
      right: { alignItems: 'flex-end' },
      center: { alignItems: 'center' },
    },
    colors: {
      light: {},
      dark: {},
      muted: {},
    },
    hasTooltip: {
      true: {
        cursor: 'pointer',
      },
    },
    inline: {
      true: {
        alignItems: 'center',
        row: true,
      },
      false: {},
    },
    loading: {
      true: {
        rowGap: '$2',
      },
      false: {},
    },
    variant: {
      default: {},
      minimized: {
        columnGap: '$1',
      },
      elevate: {},
      elevateAlt: {},
    },
  } as const,
  defaultVariants: {
    alignment: 'left',
    colors: 'light',
    inline: false,
    variant: 'default',
  },
});

const Section = styled(Stack, {
  context: DataPointStyledContext,

  alignItems: 'center',
  row: true,
  gap: '$2',

  variants: {
    alignment: {
      left: {},
      right: { justifyContent: 'flex-end' },
      center: { justifyContent: 'center' },
    },
    variant: {
      minimized: {
        gap: '$1',
      },
    },
  },
});

type DataPointFrameProps = GetProps<typeof DataPointFrame>;

interface DataPointTypedProps<Inline extends boolean> {
  alignment?: Inline extends true ? never : DataPointFrameProps['alignment'];
  inline?: Inline;
  variant?: Inline extends true ? 'minimized' : DataPointFrameProps['variant'];
}

export type DataPointProps<Inline extends boolean> = DataPointTypedProps<Inline> &
  Omit<DataPointFrameProps, 'alignment' | 'inline' | 'variant'> & {
    tooltip?: { content: ReactNode; title: ReactNode };
  };

const Wrapper = ({ children, tooltip }: PropsWithChildren<{ tooltip?: { content: ReactNode; title: ReactNode } }>) => {
  return tooltip ? (
    <Tooltip>
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
      <Tooltip.Content tooltipTitle={tooltip.title}>
        <Tooltip.Text>{tooltip.content}</Tooltip.Text>
      </Tooltip.Content>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

const DataPointStatics = {
  Icon,
  Label,
  Pill,
  Value,
  Tag,
};

const TooltipIcon = (props: Omit<IconProps, 'color'>) => {
  const { colors, loading } = DataPointStyledContext.useStyledContext();

  const color = useMemo(() => {
    switch (colors) {
      case 'dark':
        return '$onSurface.neutral.default';
      case 'muted':
        return '$onSurface.neutral.muted';
      case 'light':
      default:
        return '$onSurface.neutral.defaultInverted';
    }
  }, [colors]);

  return loading ? <Skeleton.Circle size="$3" /> : <InfoIcon color={color} {...props} />;
};

export const DataPoint = withStaticProperties(
  DataPointFrame.styleable(
    <Inline extends boolean>(propsIn: DataPointProps<Inline>, ref: ForwardedRef<TamaguiElement>) => {
      const { children, tooltip, ...rest } = useProps(propsIn);

      const {
        [DATA_POINT_ICON_NAME]: Icon,
        [DATA_POINT_LABEL_NAME]: Label,
        [DATA_POINT_PILL_NAME]: Pill,
        [DATA_POINT_VALUE_NAME]: Value,
        [DATA_POINT_TAG_NAME]: Tag,
      } = useMemo(
        () =>
          parseRootChildren(children, [
            DATA_POINT_ICON_NAME,
            DATA_POINT_LABEL_NAME,
            DATA_POINT_PILL_NAME,
            DATA_POINT_VALUE_NAME,
            DATA_POINT_TAG_NAME,
          ]),
        [children],
      );

      const hasBottomSection = useMemo(
        () => Boolean(Pill) || Boolean(Label) || Boolean(tooltip),
        [Pill, Label, tooltip],
      );

      const hasTopSection = useMemo(() => Boolean(Icon) || Boolean(Value) || Boolean(Tag), [Icon, Value, Tag]);

      return (
        <Wrapper tooltip={tooltip}>
          <DataPointFrame hasTooltip={Boolean(tooltip)} ref={ref} aria-busy={!!rest?.loading} {...rest}>
            {hasTopSection && (
              <Section>
                {Icon}
                {Value}
                {Tag}
              </Section>
            )}
            {hasBottomSection && (
              <Section>
                {Pill}
                {Label}
                {tooltip && <TooltipIcon style={{ flexShrink: 0 }} size="sm" />}
              </Section>
            )}
          </DataPointFrame>
        </Wrapper>
      );
    },
  ),
  DataPointStatics,
) as (<Inline extends boolean>(props: DataPointProps<Inline> & { ref?: Ref<TamaguiElement> }) => ReactElement) &
  typeof DataPointStatics;

import { useEffect } from 'react';

import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

import { getPrimaryAccount } from '@arrived/api_v2';
import { ApiError } from '@arrived/models';

import { accountsKeyFn } from '../accounts';
import { UseAwaitedQueryOptions } from '../utils';
import { fetchCurrentUserQuery, useGetCurrentUserQuery } from './useGetCurrentUserQuery';
import { accountsPrimaryKeyFn } from './users.keys';

export function useGetPrimaryAccountQuery(options?: UseAwaitedQueryOptions<typeof getPrimaryAccount>) {
  const currentUserState = useGetCurrentUserQuery();
  const queryClient = useQueryClient();

  const primaryAccountState = useQuery({
    queryKey: accountsPrimaryKeyFn(currentUserState.data?.id!),
    queryFn: () => getPrimaryAccount(currentUserState.data?.id),
    retry: (_, error) => error?.status !== 404,
    ...options,
    enabled: (options?.enabled ?? true) && currentUserState.data?.id != null,
  });

  useEffect(() => {
    if (primaryAccountState.isSuccess) {
      const accountQueryState = queryClient.getQueryState(accountsKeyFn(primaryAccountState.data.id));
      if (!(accountQueryState?.dataUpdatedAt && accountQueryState.dataUpdatedAt >= primaryAccountState.dataUpdatedAt)) {
        queryClient.setQueryData(accountsKeyFn(primaryAccountState.data.id), primaryAccountState.data);
      }
    }
  }, [primaryAccountState.data, primaryAccountState.dataUpdatedAt, primaryAccountState.isSuccess, queryClient]);

  return primaryAccountState;
}

export async function fetchPrimaryAccountQuery(queryClient: QueryClient) {
  const currentUser = await fetchCurrentUserQuery(queryClient);
  return await queryClient.fetchQuery({
    queryKey: accountsPrimaryKeyFn(currentUser.id),
    queryFn: () => getPrimaryAccount(currentUser.id),
    retry: (_, error) => error?.status !== 404,
  });
}

/**
 * @param error `ApiError` returned from our fetch call.
 * @returns `boolean` based on if the resulting error is because of no account being found/created.
 */
export function isAccountNotFound(error: ApiError | null) {
  return error?.code === 'NotFoundException';
}

import { useQuery } from '@tanstack/react-query';

import { getHistoricalReturnsData } from '@arrived/api_v2';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { historicalReturnsKey } from './historicalReturns.keys';

export function useGetHistoricalReturnsData(options?: UseAwaitedQueryOptions<typeof getHistoricalReturnsData>) {
  return useQuery({
    queryKey: historicalReturnsKey,
    queryFn: () => getHistoricalReturnsData(),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}

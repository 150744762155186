import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { getCDNOptimizedImage } from '@arrived/common';

const houseImage = getCDNOptimizedImage({
  imgUrl: 'https://cdn.arrivedhomes.com/images/signup/house_cutoff_left.png',
  width: '500px',
  height: '390px',
});
const texturedTriangle = getCDNOptimizedImage({
  imgUrl: 'https://cdn.arrivedhomes.com/images/signup/textured_triangle.png',
  width: '190px',
  height: '124px',
});

export const Variation1 = () => {
  return (
    <>
      <Typography color="font.500" variant="h1.medium" pl={{ md: '5%', lg: 8 }} pr={{ md: 0, lg: 8 }} pt={9}>
        <FormattedMessage
          id="new-properties-available-now"
          defaultMessage="<new>New</new>{br}Properties{br}Available Now!"
          values={{
            new: (parts) => (
              <Typography
                component="span"
                variant="h1.bold"
                sx={{
                  background: ({ gradients }) => gradients.sea.horizontal,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  MozBackgroundClip: 'text',
                  MozTextFillColor: 'transparent',
                }}
              >
                {parts}
              </Typography>
            ),
            br: <br />,
          }}
        />
      </Typography>
      <Box position="absolute" bottom={0} left={0} width="500px">
        <img alt="house" src={houseImage} />
      </Box>
      <Box position="absolute" bottom={0} left={0}>
        <img alt="triangle" src={texturedTriangle} />
      </Box>
    </>
  );
};

import { SVGProps } from 'react';

export interface TriangleProps extends SVGProps<SVGSVGElement> {
  className?: string;
  color: string;
  scale?: number;
}

export const Triangle = ({ className, color, scale, ...rest }: TriangleProps) => {
  const size = (scale ?? 1) * 100;

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d={`M0 0L${size} 0L0 ${size}L0 0`} fill={color} />
    </svg>
  );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchUserAccount } from '@arrived/api_v2';
import { useTrackArchetype } from '@arrived/marketing';
import { Account } from '@arrived/models';

import { accountsPrimaryKeyFn, currentUserKeyFn, useGetInvestorArchetype, userKeyFn } from '../users';
import { UseAwaitedMutationOptions } from '../utils';
import { accountsKeyFn } from './accounts.keys';

export function usePatchAccountMutation(options?: UseAwaitedMutationOptions<typeof patchUserAccount>) {
  const queryClient = useQueryClient();
  const trackArchetype = useTrackArchetype();
  const currentArchetype = useGetInvestorArchetype();

  return useMutation({
    mutationFn: (request) => patchUserAccount(request),
    ...options,
    onSuccess: (data) => {
      const user = queryClient.getQueryData<Account>(currentUserKeyFn());

      if (user) {
        const primaryAccount = queryClient.getQueryData<Account>(accountsPrimaryKeyFn(user.id));

        if (primaryAccount?.id === data.id) {
          queryClient.invalidateQueries({ queryKey: currentUserKeyFn() });
          queryClient.invalidateQueries({ queryKey: accountsPrimaryKeyFn(user.id) });
        }
      }

      queryClient.setQueryData(accountsKeyFn(data.id), data);
      queryClient.setQueryData(userKeyFn(data.primaryUser.id), data.primaryUser);

      queryClient.invalidateQueries({ queryKey: userKeyFn(data.primaryUser.id) });
      queryClient.invalidateQueries({ queryKey: accountsKeyFn(data.id) });

      if (currentArchetype) {
        trackArchetype(currentArchetype);
      }
    },
  });
}

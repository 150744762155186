import { ButtonProps } from '@mui/material';

import { ApplyFn, NAV_TYPE, NavItemProps } from '../navigation.types';
import { BuildProfileLabelArgs, buildProfileLabel } from './buildProfileLabel';
import {
  adminItem,
  cashAccountItem,
  handleApplySubItems,
  investNavItem,
  learnNavPrivateMultipleItem,
  logoutItem,
  myAccountItemFunds,
} from './items';
import { handleApplySubPath } from './items/handleApplySubPath';

export const PrivateNavItemsFunds = (apply: ApplyFn, subPath?: string): NavItemProps[] => {
  return [investNavItem, cashAccountItem, learnNavPrivateMultipleItem]
    .map(handleApplySubPath(subPath))
    .map(handleApplySubItems(apply));
};

export const PrivateSecondaryItemsFunds = (
  apply: ApplyFn,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- will be deprecated
  logoutButtonProps: ButtonProps<any>,
  profileArgs: Omit<BuildProfileLabelArgs, 'isMobile'>,
  subPath?: string,
): NavItemProps[] => {
  const subItems = [myAccountItemFunds, logoutItem(logoutButtonProps)].map(handleApplySubPath(subPath));
  if (profileArgs.isAdmin) {
    subItems.push(adminItem);
  }

  const ProfileDropdownItem: NavItemProps = apply({
    type: NAV_TYPE.MULTIPLE,
    text: buildProfileLabel({ ...profileArgs, includeBetaMode: false }),
    subItems: subItems.map(apply),
  });

  return [ProfileDropdownItem];
};

export const PrivateNavItemsFundsMobile = (
  apply: ApplyFn,
  profileArgs: Omit<BuildProfileLabelArgs, 'isMobile'>,
  subPath?: string,
): NavItemProps[] => {
  const ProfileItem: NavItemProps = {
    text: buildProfileLabel({ ...profileArgs, includeBetaMode: true }),
  };

  const startArray: NavItemProps[] = [ProfileItem, investNavItem, cashAccountItem];

  profileArgs.isAdmin && startArray.push(adminItem);

  return startArray
    .concat([learnNavPrivateMultipleItem])
    .map(handleApplySubPath(subPath))
    .map(handleApplySubItems(apply));
};

import { useInfiniteQuery } from '@tanstack/react-query';

import { getLearnBlogPostsOld } from '@arrived/api_v2';
import { WordpressPostSearchOptions } from '@arrived/models';

import { MAX_STALE_TIME } from '../utils';
import { learnBlogKey } from './learnBlog.keys';

export function useGetLearnBlogPostsQuery(
  query?: WordpressPostSearchOptions,
  options?: {
    staleTime?: number;
  },
) {
  return useInfiniteQuery({
    queryKey: learnBlogKey(query),
    queryFn: ({ pageParam }) => getLearnBlogPostsOld({ pageParam, ...query }),
    initialPageParam: 1,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
    getNextPageParam: (lastPage) => lastPage.nextCursor ?? undefined, // Return undefined when no more pages
    getPreviousPageParam: (firstPage) => firstPage.prevCursor ?? undefined,
  });
}

import { memo, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { DataPoint } from '@arrived/bricks';

import { useProductWidgetContext } from '../ProductWidgetContext';
import { FormattedPrimaryDataPoint } from './FormattedPrimaryDataPoint';
import { StyledDataPoint, StyledDataPointProps } from './StyledDataPoint';

export const FundDataPoint = memo((props: StyledDataPointProps) => {
  const { offering } = useProductWidgetContext();

  const value = useMemo(
    () => Math.round(offering.sharePrice * offering.totalSharesTransacted),
    [offering.sharePrice, offering.totalSharesTransacted],
  );

  return (
    <StyledDataPoint
      tooltip={{
        content: <FormattedMessage id="product-details.total-net-assets.tooltip" />,
        title: <FormattedMessage id="product-details.total-net-assets" />,
      }}
      {...props}
    >
      <DataPoint.Value>
        <FormattedPrimaryDataPoint value={value} />
      </DataPoint.Value>
      <DataPoint.Label>
        <FormattedMessage id="product-details.total-net-assets" />
      </DataPoint.Label>
    </StyledDataPoint>
  );
});

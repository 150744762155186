import { VariantSpreadFunction, styled } from '@tamagui/core';

import { Skeleton, ValueText } from '../../atoms';
import { DATA_POINT_VALUE_NAME } from './constants';
import { DataPointStyledContext, DataPointStyledContextType } from './DataPointStyledContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const valueVariantFn: VariantSpreadFunction<any, DataPointStyledContextType['variant']> = (variant, config) => {
  let token = 'value.large';

  if (config.props.inline) {
    token = variant === 'minimized' ? 'value.small' : 'value.medium';
  } else {
    if (variant === 'minimized') {
      token = 'value.medium';
    } else if (variant === 'elevate') {
      token = 'value.xlarge';
    } else if (variant === 'elevateAlt') {
      token = 'value.xxlarge';
    }
  }

  return {
    token,
  };
};

const ValueStyled = styled(ValueText, {
  name: DATA_POINT_VALUE_NAME,

  context: DataPointStyledContext,

  color: '$onSurface.neutral.defaultInverted',
  token: 'value.large',

  variants: {
    colors: {
      dark: {
        color: '$onSurface.neutral.default',
      },
      muted: {
        color: '$onSurface.neutral.default',
      },
    },
    inline: {
      true: {},
      false: {},
    },
    variant: valueVariantFn,
  } as const,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const valueSkeletonVariantFn: VariantSpreadFunction<any, DataPointStyledContextType['variant']> = (variant, config) => {
  let w = '$12';
  let h = '$4';

  if (config.props.inline) {
    if (variant === 'minimized') {
      w = '$4';
      h = '$2';
    } else {
      w = '$6';
      h = '$3';
    }
  } else {
    if (variant === 'minimized') {
      w = '$10';
      h = '$3';
    } else if (variant === 'elevate') {
      w = '$15';
      h = '$5';
    } else if (variant === 'elevateAlt') {
      w = '$16';
      h = '$6';
    }
  }

  return {
    w,
    h,
  };
};

const ValueSkeleton = styled(Skeleton.Rect, {
  context: DataPointStyledContext,

  w: '$12',
  h: '$4',
  br: '$2',

  variants: {
    variant: valueSkeletonVariantFn,
  } as const,
});

export const Value = ValueStyled.styleable((props, ref) => {
  const { loading } = DataPointStyledContext.useStyledContext();

  return loading ? <ValueSkeleton /> : <ValueStyled ref={ref} {...props} />;
});

import { alpha } from '@mui/material';
// eslint-disable-next-line no-restricted-imports -- we can only import from here
import { createTheme } from '@mui/material/styles';
import { createBreakpoints, createSpacing } from '@mui/system';

import Tokens from '../../tokens';
import {
  TypographyHeadings,
  TypographyVariants,
  createTypographyVariants,
  muiFontWeights,
} from '../../tokens/typography';
import {
  BaseButtonStyles,
  ContainedButtonStyles,
  ContainedSecondaryButtonStyles,
  DefaultButtonProps,
  OutlinedButtonStyles,
  TextButtonStyles,
} from './buttons';

const typographyBreakpoints = createBreakpoints({ values: Tokens.breakpoints });

// @NOTE: MUI does not support our tokens,
// however JoyUI does. Maybe we leverage that when it is ready?
// https://mui.com/joy-ui/customization/theme-tokens/
// TODO: This should be 4 to keep consistent with other design tokens
const spacing = createSpacing(8);

/**
 * This is just a copy of the other headings, a day
 * of trial and error has lead me here. :(
 *
 * You can find the original reference of these values below and
 * the layout of the new tokens in `../tokens/typography.ts`
 */
const typographyVariants = createTypographyVariants({
  h1: {
    letterSpacing: Tokens.letterSpacings.lg,
    fontSize: Tokens.fontSizes['4xl'],
    lineHeight: Tokens.lineHeights['5xl'],
    [typographyBreakpoints.up('md')]: {
      fontSize: Tokens.fontSizes['8xl'],
      lineHeight: Tokens.lineHeights['9xl'],
    },
  },
  h2: {
    letterSpacing: Tokens.letterSpacings.lg,
    fontSize: Tokens.fontSizes['5xl'],
    lineHeight: Tokens.lineHeights['4xl'],
    [typographyBreakpoints.up('md')]: {
      fontSize: Tokens.fontSizes['7xl'],
      lineHeight: Tokens.lineHeights['7xl'],
    },
  },
  h3: {
    letterSpacing: Tokens.letterSpacings.lg,
    fontSize: Tokens.fontSizes['4xl'],
    lineHeight: Tokens.lineHeights['3xl'],
    [typographyBreakpoints.up('md')]: {
      fontSize: Tokens.fontSizes['6xl'],
      lineHeight: Tokens.lineHeights['5xl'],
    },
  },
  h4: {
    letterSpacing: Tokens.letterSpacings.lg,
    fontSize: Tokens.fontSizes['xl'],
    lineHeight: Tokens.lineHeights['2xl'],
    [typographyBreakpoints.up('md')]: {
      fontSize: Tokens.fontSizes['3xl'],
    },
  },
  h5: {
    letterSpacing: Tokens.letterSpacings.lg,
    fontSize: Tokens.fontSizes['lg'],
    lineHeight: Tokens.lineHeights['2.5xl'],
    [typographyBreakpoints.up('md')]: {
      fontSize: Tokens.fontSizes['2xl'],
    },
  },
  h6: {
    letterSpacing: Tokens.letterSpacings.lg,
    fontSize: Tokens.fontSizes.md,
    lineHeight: Tokens.lineHeights.lg,
    [typographyBreakpoints.up('md')]: {
      fontSize: Tokens.fontSizes['xl'],
    },
  },
  body1: {
    letterSpacing: Tokens.letterSpacings.xl,
    fontSize: Tokens.fontSizes.md,
    lineHeight: Tokens.lineHeights.lg,
    strong: {
      fontWeight: 'semibold',
    },
    [typographyBreakpoints.up('md')]: {
      fontSize: Tokens.fontSizes.lg,
    },
  },
  body2: {
    letterSpacing: Tokens.letterSpacings.xl,
    fontSize: Tokens.fontSizes.sm,
    lineHeight: Tokens.lineHeights.sm,
  },
  button: {
    letterSpacing: Tokens.letterSpacings.lg,
    fontWeight: Tokens.fontWeights.bold,
    fontSize: Tokens.fontSizes.md,
    lineHeight: Tokens.lineHeights.sm,
    textTransform: 'none',
    [typographyBreakpoints.up('md')]: {
      fontSize: Tokens.fontSizes['xl'],
    },
  },
  caption: {
    letterSpacing: Tokens.letterSpacings.xl,
    fontSize: Tokens.fontSizes.xs,
    lineHeight: Tokens.lineHeights['3xs'],
  },
});

const variantSubMap = {
  button: 'span',
  caption: 'span',
  body1: 'p',
  body2: 'p',
} as const;

const isVariantSub = (variant: keyof TypographyHeadings): variant is keyof typeof variantSubMap =>
  variant in variantSubMap;

/**
 * This fixes the issue where `h3.semibold` or whatever variant was being set to be a `span`. This
 * will map the respective variant to its correct element.
 */
const variantMapping = (Object.keys(typographyVariants) as [keyof TypographyVariants]).reduce(
  (acc, key) => {
    const realKey = key.split('.')[0] as keyof TypographyHeadings;

    if (isVariantSub(realKey)) {
      acc[key] = variantSubMap[realKey];
    } else {
      acc[key] = realKey;
    }

    return acc;
  },
  {} as {
    [key in keyof TypographyVariants]: string;
  },
);

const {
  // This doesn't like that we are overriding text, we also
  // need a pass from design on colors and naming
  colors,
  fontWeights,
  fontSizes,
  lineHeights,
  letterSpacings,
  zIndices: zIndex,
  breakpoints,
  fonts,
  webGradients: gradients,
} = Tokens;

const fontFamily = [`"${fonts.body}"`, 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(', ');

const baseTheme = createTheme({
  palette: {
    ...colors,
    primary: {
      main: colors.aqua[500],
    },
    /**
     * When to use:
     * - CTAs
     * - links
     */
    secondary: {
      main: colors.mint[500],
    },

    success: {
      main: colors.success,
    },
    /**
     * When to use:
     * - Sold out properties
     */
    error: {
      main: colors.danger,
    },
    /**
     * When to use:
     * - Trending properties
     */
    warning: {
      main: colors.warning,
    },
    /**
     * When to use:
     * - Rare, use minimally
     */
    info: {
      main: colors.aqua[600],
    },

    background: colors.lightened,

    text: {
      primary: colors.darkened.midnight,
      secondary: colors.darkened.steel,
      disabled: colors.darkened.seal,
    },
  },
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  gradients,
  zIndex,
  spacing,

  // Lets us set our own breakpoint sizes: https://material-ui.com/customization/breakpoints/#custom-breakpoints
  breakpoints: {
    values: breakpoints,
  },

  // Modify Mui's default typography settings: https://next.material-ui.com/customization/typography/#typography
  // Establish default font family and add our typography variant definitions to MUI
  // @ts-expect-error -- MUI doesn't like our custom font family
  typography: {
    fontFamily,
    ...muiFontWeights,
    ...typographyVariants,
  },
});

export const MuiTheme = createTheme(baseTheme, {
  // Override default component styling
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Calibre';
          font-style: normal;
          font-weight: 300;
          font-display: block;
          src: local('Calibre Light'), url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-light.woff2') format('woff2'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Light.woff') format('woff'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Light.ttf') format('truetype');
        }

        @font-face {
          font-family: 'Calibre';
          font-style: normal;
          font-weight: 400;
          font-display: block;
          src: local('Calibre Regular'), url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-regular.woff2') format('woff2'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Regular.woff') format('woff'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Regular.ttf') format('truetype');
        }

        @font-face {
          font-family: 'Calibre';
          font-style: normal;
          font-weight: 500;
          font-display: block;
          src: local('Calibre Medium'), url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-medium.woff2') format('woff2'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Medium.woff') format('woff'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Medium.ttf') format('truetype');
        }

        @font-face {
          font-family: 'Calibre';
          font-style: normal;
          font-weight: 600;
          font-display: block;
          src: local('Calibre Semiboid'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-semibold.woff2') format('woff2'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Semibold.woff') format('woff'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Semibold.ttf') format('truetype');
        }

        @font-face {
          font-family: 'Calibre';
          font-style: normal;
          font-weight: 700;
          font-display: block;
          src: local('Calibre Bold'), url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-bold.woff2') format('woff2'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Bold.woff') format('woff'),
            url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Bold.ttf') format('truetype');
        }

        @font-face {
          font-family: 'DancingScript-Regular';
          font-style: normal;
          src: url('https://cdn.arrivedhomes.com/fonts/dancing-script/DancingScript-Regular.ttf') format('truetype');
        }
      `,
    },
    MuiTypography: {
      defaultProps: {
        fontFamily,
        variantMapping,
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.background.iceGray,
          boxShadow: 'none',
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.tooltip + 1,
          backgroundColor: alpha(baseTheme.palette.background.iceGray, 0.5),
        },
        invisible: {
          backgroundColor: 'transparent',
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: baseTheme.palette.lightened.bare,
          color: baseTheme.palette.text.primary,
          padding: spacing(2),
          fontSize: baseTheme.fontSizes.md,
          fontWeight: baseTheme.fontWeights.normal,
          boxShadow: `0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)`,
        },
      },
      defaultProps: {
        enterTouchDelay: 0,
        leaveTouchDelay: 5000,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: spacing(1),
        },
        head: {
          color: baseTheme.palette.neutrals.gray,
          borderBottom: `1px solid ${baseTheme.palette.neutrals.gray}`,
          fontSize: baseTheme.fontSizes.sm,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      ...DefaultButtonProps(),
      styleOverrides: {
        ...BaseButtonStyles(baseTheme),
        ...OutlinedButtonStyles(baseTheme),
        ...ContainedButtonStyles(baseTheme),
        ...ContainedSecondaryButtonStyles(baseTheme),
        ...TextButtonStyles(baseTheme),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: baseTheme.fontSizes.sm,
          color: baseTheme.palette.text.secondary,
          borderRadius: 0,
          backgroundColor: baseTheme.palette.lightened.bare,
          fontWeight: baseTheme.fontWeights.medium,
          textTransform: 'uppercase',
        },
        outlined: {
          border: `1px solid ${baseTheme.palette.text.secondary}`,
        },
        colorPrimary: {
          color: baseTheme.palette.primary,
        },
        colorSecondary: {
          color: baseTheme.palette.secondary,
        },
        outlinedPrimary: {
          border: `1px solid ${baseTheme.palette.primary.main}`,
        },
        outlinedSecondary: {
          border: `1px solid ${baseTheme.palette.secondary.main}`,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: baseTheme.palette.lightened.iceGray,
          },
        },
        colorPrimary: {
          color: baseTheme.palette.neutrals.gray,
          backgroundColor: baseTheme.palette.lightened.iceGray,
          '&:hover': {
            color: baseTheme.palette.lightened.bare,
            backgroundColor: baseTheme.palette.primary,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          borderRadius: 0,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        variant: 'body1',
      },
      styleOverrides: {
        root: {
          color: colors.newPrimary[800],
          verticalAlign: 'inherit',
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        desktopModeMediaQuery: `(min-width: ${breakpoints.md}px)`,
      },
    },
  },
});

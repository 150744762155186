import { useQuery } from '@tanstack/react-query';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { getTransactions } from '../../api/transactions';
import { Transaction } from '../../types';
import { CashAccountTransactionsQueryFilters } from '../../types/cashAccountTransactionQueryFilters';
import { transactionsKeyFn } from './transactions.keys';

/**
 * Recursively calls `getTransactions()`, using the last item in the previous result's timestamp
 * as the "end" ('created before X') of the next query.
 *
 * @param queryFilters -- the filters to be applied to the query
 * @param acc ----------- the accumulated results
 */
const getTransactionsRecursive = (
  queryFilters: CashAccountTransactionsQueryFilters,
  acc: Transaction[],
): Promise<Transaction[]> =>
  getTransactions(queryFilters).then((transactions: Transaction[]) => {
    acc.push(...transactions);

    if (!transactions || transactions.length < 100) {
      return acc;
    } else {
      const newEndTimestampEdge = transactions[99]?.effectiveAt ?? transactions[99]?.createdAt;

      // If for whatever reason we don't have a new edge, return early to avoid endless recursion.
      // At this point we already have their most recent 100 transactions, so UX isn't horrible.
      if (!newEndTimestampEdge) return acc;

      const newQueryFilters: CashAccountTransactionsQueryFilters = {
        ...queryFilters,
        end: newEndTimestampEdge,
      };

      return getTransactionsRecursive(newQueryFilters, acc);
    }
  });

export function useTransactions(
  filters: CashAccountTransactionsQueryFilters,
  options?: UseAwaitedQueryOptions<typeof getTransactions>,
) {
  return useQuery({
    queryKey: transactionsKeyFn(filters.cashAccountCid, filters),
    queryFn: () => {
      const { fetchRecursively, ...queryFilters } = filters;

      if (!queryFilters.cashAccountCid) {
        throw Error();
      }

      if (!fetchRecursively) {
        return getTransactions(queryFilters);
      }

      /**
       * The most fun "filter" you've ever seen... in short:
       *
       * Modern Treasury has a 100 item limit on their API, whether you're trying to paginate or not.
       * They do, however support pagination via date ranges down to the millisecond.
       *
       * i.e., the ideal UX for an MT-related query is to keep grabbing new pages of data until the
       * returned list is smaller than your pagesize.
       *
       * Until our UX supports that, this "filter" enables the query to recursively 'paginate' through
       * all available MT transactions meeting the other filters by using the oldest returned item's
       * timestamp as the "start" date.
       *
       * For example:
       * - "all pending" = join batches of 100 pending items into a single list and return that
       * - "all posted <30d old" = join batches of 100 pending items into the list until you run out or hit the 30d mark
       */
      return getTransactionsRecursive(queryFilters, []);
    },
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
    enabled: (options?.enabled ?? true) && !!filters.cashAccountCid,
  });
}

globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"0d876b6fddf12fd4ddb1c2075b3684e072ace2bb"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/next/production-arrived/0595c362-4883-5af5-a5e9-7eef773b4c0d";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { CONFIG } from '@arrived/config';

import Package from './package.json';

const SAMPLE_RATES_BY_ENV: Record<string, number> = {
  development: 0.1,
  staging: 0.01,
  preview: 0.005,
  production: 0.03,
};

Sentry.init({
  dsn: CONFIG.sentry.nextJsDsn,

  environment: CONFIG.sentry.env,
  release: `arrived-public@${Package.version}`,

  // Controls the % of transactions to send to Sentry; helps usage quotas
  tracesSampleRate: SAMPLE_RATES_BY_ENV[CONFIG.sentry.env] ?? 1,

  tracePropagationTargets: [/^(?!.cdn.builder.io).$/],

  debug: CONFIG.sentry.env === 'development' ? true : false,

  replaysSessionSampleRate: 0.005,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.5,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.httpClientIntegration({
      // This array can contain tuples of `[begin, end]` (both inclusive),
      // single status codes, or a combination of both.
      // default: [[500, 599]]
      failedRequestStatusCodes: [[500, 599], 400, 422],

      // This array can contain Regexes, strings, or a combination of both.
      // default: [/.*/]
      failedRequestTargets: [
        new RegExp(`${CONFIG.abacusApiRoot}.*`),
        new RegExp(`${CONFIG.cashAccountsApiRoot}.*`),
        new RegExp(`${CONFIG.rewardsApiRoot}.*`),
      ],
    }),
  ],
});

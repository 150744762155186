import { useQuery } from '@tanstack/react-query';

import { CONFIG } from '@arrived/config';
import { UseAwaitedQueryOptions } from '@arrived/queries';

import { ArrivedBuilderModels } from '../models';
import { BuilderNavItem } from '../models/navItems';
import { FetchEntriesParams, fetchEntries } from '../sdk';
import { builderNavItemsQueryKeyFun } from './keys';

export const fetchBuilderNavItems = (params?: FetchEntriesParams) =>
  fetchEntries<BuilderNavItem>({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.NAV_ITEMS,
    enrich: true,
    ...params,
  });

export const useGetBuilderNavItemsQuery = (
  params?: FetchEntriesParams,
  options?: UseAwaitedQueryOptions<typeof fetchBuilderNavItems>,
) =>
  useQuery({
    queryKey: builderNavItemsQueryKeyFun({ ...params }),
    queryFn: () => fetchBuilderNavItems(params),
    ...options,
  });

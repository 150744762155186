import { AnimatePresence, Loading, Stack, StackProps } from '@arrived/bricks';
import { OfferingIdOrShortName } from '@arrived/models';
import { useOfferingQuery } from '@arrived/queries';

import { ReturnsCalculatorSectionProps } from '../returns';
import { Content, Controls, Divider, Header, HeaderDetails, Wrapper } from './ProductDetailParts';

export type ProductDetailsProps = Omit<StackProps, 'children'> & {
  ControllerStackProps?: StackProps;
  HeaderStackProps?: StackProps;
  offeringIdOrShortName: OfferingIdOrShortName;
  // TODO: Remove this once we update the Calculator to use a new bricks table
  Table?: ReturnsCalculatorSectionProps['TableComponent'];
};

export const ProductDetails = ({
  ControllerStackProps,
  HeaderStackProps,
  offeringIdOrShortName,
  Table,
  ...rest
}: ProductDetailsProps) => {
  const offeringState = useOfferingQuery(offeringIdOrShortName);

  return (
    <AnimatePresence>
      <Stack
        {...rest}
        key="container"
        animation="quick"
        enterStyle={{ opacity: 0 }}
        exitStyle={{ opacity: 0 }}
        opacity={1}
      >
        {!offeringState.isSuccess ? (
          <Stack flex={1} centered>
            <Loading.Indicator variant="colored" />
          </Stack>
        ) : (
          <Wrapper offering={offeringState.data}>
            <Header offering={offeringState.data} {...HeaderStackProps} />
            <Divider offering={offeringState.data} {...HeaderStackProps} />
            <Controls offering={offeringState.data} {...ControllerStackProps} />
            <HeaderDetails offering={offeringState.data} {...HeaderStackProps} />
            <Content offering={offeringState.data} Table={Table ?? (() => <></>)} />
          </Wrapper>
        )}
      </Stack>
    </AnimatePresence>
  );
};

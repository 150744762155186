import { FC, ReactNode, useMemo } from 'react';
import { ActivityIndicator } from 'react-native';

import { GetProps, ThemeKeys, getTokens, getVariable, isWeb, styled, useTheme } from '@tamagui/core';

import { Stack } from '../../atoms/layout';
import { UtilityText } from '../../atoms/text';
import { IconProps, IconSizeProps } from '../../icons/IconProps';
import { ButtonStyledContext } from './ButtonStyledContext';
import { getButtonTextVariant } from './getButtonTextVariant';
import { getButtonVariant } from './getButtonVariant';
import { getCondensedVariant } from './getCondensedVariant';

const ButtonMain = styled(Stack, {
  tag: 'button',
  px: '$4',
  h: '$10',
  br: '$2',
  borderWidth: 0,
  cursor: 'pointer',
  centered: true,
  context: ButtonStyledContext,
  flexDirection: 'row',

  variants: {
    variant: getButtonVariant,
    inverted: {
      true: {},
    },
    loading: {
      true: {},
    },
    disabled: {
      true: {},
    },
    isIconOnly: {
      true: {
        h: '$10',
        w: '$10',
        px: 0,
      },
    },
    condensed: getCondensedVariant,
    emphasized: {
      true: {},
    },
  } as const,
  defaultVariants: {
    variant: 'default',
  },
});

const ButtonText = styled(UtilityText, {
  token: 'utility.label.button.small',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$onSurface.neutral.defaultInverted',
  textDecorationLine: 'none',
  context: ButtonStyledContext,

  variants: {
    variant: getButtonTextVariant,
    inverted: {
      true: {},
    },
    loading: {
      true: {},
    },
    disabled: {
      true: {},
    },
    emphasized: {
      true: {},
    },
  } as const,
});

const LoadingIndicator = styled(Stack, {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export interface ExtraButtonProps {
  iconPosition?: 'before' | 'after';
  Icon?: FC<IconProps>;
  iconSize?: IconSizeProps;
  children?: ReactNode;
}

const ButtonInner = ({ iconPosition, Icon, iconSize, children }: ExtraButtonProps) => {
  const theme = useTheme();
  const { variant, inverted, disabled, loading, emphasized } = ButtonStyledContext.useStyledContext();
  const sizes = getTokens().size;

  const iconColor = useMemo(() => {
    if (isWeb) return 'currentColor';

    return (
      // @ts-ignore Below is needed for native as 'currentColor' does not work
      getButtonTextVariant(variant, { props: { loading, disabled, inverted, emphasized } })?.color ?? 'currentColor'
    );
  }, [variant, loading, disabled, inverted, emphasized]);

  const loadingIndicatorColor = useMemo(() => {
    return isWeb ? iconColor : theme[getVariable(iconColor.substring(1)) as ThemeKeys].val;
  }, [theme, iconColor]);

  const iconContent = Icon ? <Icon color={iconColor} size={iconSize ?? 'sm'} /> : undefined;

  const content = isWeb ? (
    <>
      {iconPosition === 'before' && iconContent}
      {children}
      {(iconPosition === 'after' || !iconPosition) && iconContent}
    </>
  ) : (
    children
  );

  // Required to get correct formatting on the icon on native
  if (iconContent && !children && !isWeb) return iconContent;

  return (
    <>
      {!loading && !isWeb && iconPosition === 'before' && iconContent}
      {/* gap on a text element only works on web, extra steps are taken to split out the icon on mobile */}
      <ButtonText gap="$2">
        {loading ? (
          <>
            {isWeb && (
              <UtilityText token="utility.label.button.small" display="flex" opacity={0}>
                {content}
              </UtilityText>
            )}
            <LoadingIndicator>
              <ActivityIndicator size={sizes['$5'].val} color={loadingIndicatorColor} />
            </LoadingIndicator>
          </>
        ) : (
          content
        )}
      </ButtonText>
      {!loading && !isWeb && (iconPosition === 'after' || !iconPosition) && iconContent}
    </>
  );
};

export type ButtonProps = GetProps<typeof ButtonMain> & ExtraButtonProps;

export const Button = ButtonMain.styleable<GetProps<typeof ButtonMain> & ExtraButtonProps>((props, ref) => {
  const { children, iconPosition, Icon, iconSize, ...buttonProps } = props;

  const isIconOnly = Boolean(Icon && !children);

  return (
    <ButtonMain ref={ref} isIconOnly={isIconOnly} gap="$2" {...buttonProps}>
      <ButtonInner iconPosition={iconPosition} iconSize={iconSize} Icon={Icon}>
        {children}
      </ButtonInner>
    </ButtonMain>
  );
});

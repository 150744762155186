import { memo } from 'react';

import { Defs, LinearGradient, Path, Stop, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function ArrivedIconBlueInternal({ size, ...props }: IconProps) {
  return (
    <Svg width={size ?? 20} height={size ?? 20} viewBox="0 0 21 28" {...props}>
      <Path
        d="m11.17 15.132 2.453-2.474a.5.5 0 0 0 0-.704l-2.454-2.473a.5.5 0 0 0-.71 0l-2.453 2.473a.5.5 0 0 0 0 .704l2.453 2.474a.5.5 0 0 0 .71 0Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#prefix__paint0_linear_1954_21312)"
      />
      <Path
        d="m12.558 7.364 2.454-2.473a.5.5 0 0 1 .71 0l2.453 2.473a.5.5 0 0 1 0 .705l-2.454 2.473a.5.5 0 0 1-.71 0L12.559 8.07a.5.5 0 0 1 0-.705Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#prefix__paint0_linear_1954_21189)"
      />
      <Path
        d="m12.558 16.544 2.453-2.473a.5.5 0 0 1 .71 0l5.017 5.078a.5.5 0 0 1-.355.851h-4.887a.5.5 0 0 1-.355-.148l-2.583-2.604a.5.5 0 0 1 0-.704Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#prefix__paint0_linear_1954_21847)"
      />
      <Path
        d="m17.11 11.954 2.45-2.47a.5.5 0 0 1 .713.005l1.215 1.255a.5.5 0 0 1 .14.348V16a.5.5 0 0 1-.854.352l-3.664-3.694a.5.5 0 0 1 0-.704Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#prefix__paint0_linear_1954_21109)"
      />
      <Path
        d="M13.62 3.482.855 16.352A.5.5 0 0 1 0 16v-4.896a.5.5 0 0 1 .145-.352L10.463.355a.5.5 0 0 1 .706-.004l2.447 2.424a.5.5 0 0 1 .004.707Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#prefix__paint0_linear_1954_21886)"
      />
      <Path
        d="m.895 19.121 5.013-5.052a.5.5 0 0 1 .71 0l5.031 5.052a.5.5 0 0 1-.354.853H1.25a.5.5 0 0 1-.355-.853Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#prefix__paint0_linear_1954_21676)"
      />
      <Defs>
        <LinearGradient
          id="prefix__paint0_linear_1954_21312"
          x1="-6.212"
          y1="-7.454"
          x2="20.292"
          y2="19.878"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint0_linear_1954_21189"
          x1="-6.212"
          y1="-7.454"
          x2="20.292"
          y2="19.878"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint0_linear_1954_21847"
          x1="-6.212"
          y1="-7.454"
          x2="20.292"
          y2="19.878"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint0_linear_1954_21109"
          x1="-6.212"
          y1="-7.454"
          x2="20.292"
          y2="19.878"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint0_linear_1954_21886"
          x1="-6.212"
          y1="-7.454"
          x2="20.292"
          y2="19.878"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient
          id="prefix__paint0_linear_1954_21676"
          x1="-6.212"
          y1="-7.454"
          x2="20.292"
          y2="19.878"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export const ArrivedIconBlue = memo<IconProps>(withIconTheme(ArrivedIconBlueInternal));

import { useQuery } from '@tanstack/react-query';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { getCounterParties } from '../../api/counter-parties';
import { counterPartiesKeyFn } from './counterParties.keys';

export function useCounterParties(
  brokerageAccountCid?: string,
  options?: UseAwaitedQueryOptions<typeof getCounterParties>,
) {
  return useQuery({
    queryKey: counterPartiesKeyFn(brokerageAccountCid),
    queryFn: () => {
      if (!brokerageAccountCid) {
        throw Error('No brokerage account CID specified');
      }

      return getCounterParties({
        entityId: brokerageAccountCid,
        counterpartyType: 'BROKERAGE_LINKED_COUNTERPARTY',
        includeCounterpartyDetails: true,
        includeUserAuthorizationLevel: true,
        includeExternalBankAccounts: true,
      });
    },
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
    enabled: (options?.enabled ?? true) && !!brokerageAccountCid,
  });
}

import { useMemo } from 'react';

import { Box } from '@mui/material';

import { getCDNOptimizedImage } from '@arrived/common';

import { Variation1 } from './Variation1';
import { Variation2 } from './Variation2';

const logoImage = getCDNOptimizedImage({
  imgUrl: 'https://cdn.arrivedhomes.com/logos/logo_arrived.png',
  width: '186px',
  height: '40px',
});

export enum SignupContentVariation {
  VAR_1,
  VAR_2,
}

export interface SignupContentProps {
  variation: SignupContentVariation;
}

export const SignupContent = ({ variation }: SignupContentProps) => {
  const content = useMemo(() => {
    switch (variation) {
      case SignupContentVariation.VAR_1:
        return <Variation1 />;
      case SignupContentVariation.VAR_2:
        return <Variation2 />;
    }
  }, [variation]);

  return (
    <>
      <Box height="40px" width="186px" position="relative" ml={{ md: '5%', lg: 8 }} mt={5}>
        <img alt="Arrived Logo" src={logoImage} />
      </Box>
      {content}
    </>
  );
};

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint, Divider, Stack } from '@arrived/bricks';
import { Offering } from '@arrived/models';

import { ProductInfoBox, ProductInfoBoxProps } from '../../../../productInfoBox';
import { DetailDataPoint } from './DetailDataPoint';

export type LoanDetailsProps = ProductInfoBoxProps & { offering: Offering };

export const LoanDetails = ({ offering, ...rest }: LoanDetailsProps) => {
  return (
    <ProductInfoBox {...rest}>
      <DetailDataPoint
        tooltip={{
          content: <FormattedMessage id="product-details.property-loan-amount.tooltip" />,
          title: <FormattedMessage id="product-details.property-loan-amount" />,
        }}
        flex={false}
      >
        <DataPoint.Value>
          <FormattedNumber
            style="currency"
            currency="USD"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            value={offering.debtAmount}
          />
        </DataPoint.Value>
        <DataPoint.Label>
          <FormattedMessage id="product-details.property-loan-amount" />
        </DataPoint.Label>
      </DetailDataPoint>
      <Divider />
      <Stack row>
        <DataPoint
          colors="dark"
          variant="minimized"
          flexGrow={1}
          flexShrink={1}
          flexBasis={0}
          tooltip={{
            content: (
              <FormattedMessage
                id={
                  offering.hasFlexFinancing
                    ? 'product-details.financing.tooltip.strategic'
                    : 'product-details.financing.tooltip'
                }
              />
            ),
            title: <FormattedMessage id="product-details.financing" />,
          }}
        >
          <DataPoint.Value>
            <FormattedNumber
              style="percent"
              minimumFractionDigits={0}
              maximumFractionDigits={2}
              value={offering.debtPercent / 100}
            />
          </DataPoint.Value>
          <DataPoint.Label>
            <FormattedMessage id="product-details.financing" />
          </DataPoint.Label>
        </DataPoint>
        <DataPoint
          colors="dark"
          variant="minimized"
          flexGrow={1}
          flexShrink={1}
          flexBasis={0}
          tooltip={{
            content: <FormattedMessage id="product-details.interest-rate.tooltip" />,
            title: <FormattedMessage id="product-details.interest-rate" />,
          }}
        >
          <DataPoint.Value>
            <FormattedNumber
              style="percent"
              minimumFractionDigits={0}
              maximumFractionDigits={2}
              value={offering.debtInterestPercent / 100}
            />
          </DataPoint.Value>
          <DataPoint.Label>
            <FormattedMessage id="product-details.interest-rate" />
          </DataPoint.Label>
        </DataPoint>
      </Stack>
    </ProductInfoBox>
  );
};

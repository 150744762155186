import { useQuery } from '@tanstack/react-query';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';

import { UseAwaitedQueryOptions } from '../utils';
import { useGetCurrentUserQuery } from './useGetCurrentUserQuery';

export function usePermissionsQuery(
  options?: UseAwaitedQueryOptions<ReturnType<typeof useArrivedAuth0>['getPermissions']>,
) {
  const currentUserState = useGetCurrentUserQuery();
  const { isAuthenticated, getPermissions } = useArrivedAuth0();

  return useQuery({
    queryKey: ['currentUser', currentUserState.data?.id, 'permissions', isAuthenticated.toString()],
    queryFn: getPermissions,
    ...options,
    enabled: (options?.enabled ?? true) && isAuthenticated,
  });
}

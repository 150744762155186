import { useQuery } from '@tanstack/react-query';

import { getOfferingHistoryByPropertyId } from '@arrived/api_v2';
import { PropertyStatusHistoryByPropertyIdQuery } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { offeringHistoryPropertyIdKey } from './offeringHistory.keys';

export const useGetOfferingHistoryByPropertyIdQuery = (
  query: PropertyStatusHistoryByPropertyIdQuery,
  options?: UseAwaitedQueryOptions<typeof getOfferingHistoryByPropertyId>,
) => {
  return useQuery({
    queryKey: offeringHistoryPropertyIdKey(query.propertyId),
    queryFn: () => getOfferingHistoryByPropertyId(query.propertyId),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
};

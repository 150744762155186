import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createFixedRateInvestment } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';
import { fixedRateInvestmentsKey } from './fixedRateInvestments.keys';

export function useCreateFixedRateInvestmentMutation(
  options?: UseAwaitedMutationOptions<typeof createFixedRateInvestment>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createFixedRateInvestment,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: fixedRateInvestmentsKey,
      });

      options?.onSuccess?.(...args);
    },
  });
}

import * as yup from 'yup';

/**
 * Regex to accept most city/state names worldwide.
 * - A through Z and U+00C0 through U+024F which includes most viable characters for city/state names
 * - A period followed by a space ". " for names like St. Paul
 * - Hyphens
 * - Single spaces
 * - Single quotes
 * - And must end with A through Z or one of the valid higher unicode characters.
 *
 * Adapted from https://stackoverflow.com/a/19574997
 */
const nonNumericCharacterString = /^(?:[a-zA-Z\u00C0-\u024F]+(?:. |-| |'))*[a-zA-Z\u00C0-\u024F]*$/;

export const AddressSchema = yup.object({
  street: yup.string().trim().required('address.street.required'),
  street2: yup.string().trim().nullable(),
  city: yup
    .string()
    .trim()
    .matches(nonNumericCharacterString, 'address.city.invalidChars')
    .required('address.city.required'),
  country: yup.string(),
  lat: yup.number().nullable(),
  long: yup.number().nullable(),
  province: yup
    .string()
    .trim()
    .matches(nonNumericCharacterString, 'address.state.invalidChars')
    .required('address.state.required'),
  zipCode: yup
    .string()
    .trim()
    .matches(/^\d{5}(?:-\d{4})?$/, 'address.zipCode.invalidFormat')
    .required('address.zipCode.required'),
});

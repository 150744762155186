import { useQuery } from '@tanstack/react-query';

import { getUserMFADetails } from '@arrived/api_v2';
import { UserId } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../../utils';
import { userMFADetailsKeyFn } from './users.mfa.keys';

export function useGetMFADetailsQuery(
  userId?: UserId,
  options?: Pick<UseAwaitedQueryOptions<typeof getUserMFADetails>, 'refetchOnWindowFocus' | 'enabled'>,
) {
  return useQuery({
    queryKey: userMFADetailsKeyFn(userId!),
    queryFn: () => getUserMFADetails(userId!),
    refetchOnWindowFocus: false,
    ...options,
    enabled: (options?.enabled ?? true) && userId != null,
  });
}

import * as yup from 'yup';

import {
  ArrivedNCOfferingStatus,
  AssetType,
  OfferingStatus,
  OfferingTradeProcessingStatus,
  OfferingType,
  SecurityType,
  TradeFlow,
} from '@arrived/models';

const REQUIRED_STRING = 'This is required';
const REQUIRED_NUMBER = 'Must not be empty or a negative number';
const isNaNCheck = (value: number) => (isNaN(value) ? undefined : value);

// Modularized Schemas
const basicInfoSchema = yup.object({
  type: yup.mixed<OfferingType>().oneOf(Object.values(OfferingType)).required(REQUIRED_STRING),
  name: yup.string().trim().required(REQUIRED_STRING),
  tradeFlow: yup.mixed<TradeFlow>().oneOf(Object.values(TradeFlow)),
  status: yup.mixed<OfferingStatus>().oneOf(Object.values(OfferingStatus)).required(REQUIRED_STRING),
  tradeProcessingStatus: yup
    .mixed<OfferingTradeProcessingStatus>()
    .oneOf(Object.values(OfferingTradeProcessingStatus))
    .required(REQUIRED_STRING),
  ncStatus: yup.mixed<ArrivedNCOfferingStatus>().oneOf(Object.values(ArrivedNCOfferingStatus)),
  issuerId: yup.number().required(REQUIRED_STRING),
  assetType: yup.mixed<AssetType>().oneOf(Object.values(AssetType)).required(REQUIRED_STRING),
});

const financialInfoSchema = yup.object({
  targetRaiseAmount: yup.number().transform(isNaNCheck).nullable(),
  qualifiedRaiseAmount: yup
    .number()
    .transform(isNaNCheck)
    .nullable()
    .positive(REQUIRED_NUMBER)
    .when('targetRaiseAmount', ([targetRaiseAmount]: [number], schema) =>
      schema.test({
        test: (qualifiedRaiseAmount: number) =>
          targetRaiseAmount === null || targetRaiseAmount === undefined || qualifiedRaiseAmount >= targetRaiseAmount,
        message: 'Qualified should be greater than or equal to target amount',
      }),
    ),
  sharePrice: yup.number().transform(isNaNCheck).nullable().positive(REQUIRED_NUMBER),
  totalShares: yup.number().transform(isNaNCheck).nullable().positive(REQUIRED_NUMBER),
});

/**
 * Conditional fields for when the offering type is FUND,
 * if there is a cleaner way to do this or use `partial` I would love to know.
 */
const conditionalFieldsSchema = yup.object({
  properties: yup
    .array()
    .nullable()
    .when('type', {
      is: OfferingType.FUND,
      then: (schema) => schema.required(REQUIRED_STRING),
    }),

  endAt: yup.lazy((value) =>
    typeof value === 'string'
      ? yup.string().when('type', {
          is: OfferingType.FUND,
          then: (schema) => schema.notRequired(),
        })
      : yup.date().when('type', {
          is: OfferingType.FUND,
          then: (schema) => schema.notRequired(),
        }),
  ),

  marketId: yup
    .number()
    .nullable()
    .when('type', {
      is: (type: OfferingType) => type === OfferingType.FUND || type === OfferingType.DEBT,
      then: (schema) => schema.notRequired(),
    }),

  debtAmount: yup
    .number()
    .transform(isNaNCheck)
    .nullable()
    .when('type', {
      is: (type: OfferingType) => type === OfferingType.FUND || type === OfferingType.DEBT,
      then: (schema) => schema.notRequired(),
    }),

  debtInterestPercent: yup
    .number()
    .transform(isNaNCheck)
    .nullable()
    .when('type', {
      is: (type: OfferingType) => type === OfferingType.FUND || type === OfferingType.DEBT,
      then: (schema) => schema.notRequired(),
    }),

  debtPercent: yup.number().transform(isNaNCheck).nullable(),

  closingOfferingAndHoldingCosts: yup
    .number()
    .transform(isNaNCheck)
    .nullable()
    .when('type', {
      is: (type: OfferingType) => type === OfferingType.FUND || type === OfferingType.DEBT,
      then: (schema) => schema.notRequired(),
    }),

  propertyImprovementsAndCashReserves: yup
    .number()
    .transform(isNaNCheck)
    .nullable()
    .when('type', {
      is: (type: OfferingType) => type === OfferingType.FUND || type === OfferingType.DEBT,
      then: (schema) => schema.notRequired(),
    }),

  holdPeriodYearsMin: yup
    .number()
    .integer()
    .positive(REQUIRED_NUMBER)
    .transform(isNaNCheck)
    .nullable()
    .when('type', {
      is: (type: OfferingType) => type === OfferingType.FUND || type === OfferingType.DEBT,
      then: (schema) => schema.notRequired(),
    }),

  holdPeriodYearsMax: yup
    .number()
    .integer()
    .positive(REQUIRED_NUMBER)
    .transform(isNaNCheck)
    .nullable()
    .when('type', {
      is: (type: OfferingType) => type === OfferingType.FUND || type === OfferingType.DEBT,
      then: (schema) => schema.notRequired(),
    })
    .when('holdPeriodYearsMin', ([holdPeriodYearsMin]: [number], schema) =>
      holdPeriodYearsMin != null ? schema.min(holdPeriodYearsMin) : schema,
    ),

  arrivedOneTimeProceeds: yup
    .number()
    .transform(isNaNCheck)
    .nullable()
    .when('type', {
      is: (type: OfferingType) => type === OfferingType.FUND || type === OfferingType.DEBT,
      then: (schema) => schema.notRequired(),
    }),

  ipoDate: yup.lazy((value) => (typeof value === 'string' ? yup.string().nullable() : yup.date().nullable())),
});

const additionalInfoSchema = yup.object({
  shortName: yup.string().trim().required(REQUIRED_STRING),
  llcName: yup.string().trim().required(REQUIRED_STRING),
  dropId: yup.number().min(0).transform(isNaNCheck).nullable(),
  description: yup.string().trim().nullable(),
  startAt: yup.lazy((value) =>
    typeof value === 'string' ? yup.string().required(REQUIRED_STRING) : yup.date().required(REQUIRED_STRING),
  ),
  minTransactionAmount: yup.number().positive(REQUIRED_NUMBER).required(REQUIRED_STRING),
  maxSharesPerAccount: yup.number().positive(REQUIRED_NUMBER).transform(isNaNCheck).nullable(),
  securityType: yup.mixed<SecurityType>().oneOf(Object.values(SecurityType)).required(REQUIRED_STRING),
  isEarlyAccess: yup.boolean().notRequired(),
  isOpenForSec: yup.boolean().notRequired(),
  quarterlyAumFeeAmount: yup.number().transform(isNaNCheck).nullable(),
  projectedFirstDividendDate: yup.string().trim().nullable(),
  projectedFirstDividendAmount: yup.string().trim().transform(isNaNCheck).nullable(),
  projectedAnnualDividendYield: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .transform(isNaNCheck)
    .nullable(),
});

// Composing the final schema
export const OfferingPostSchema = yup
  .object()
  .concat(basicInfoSchema)
  .concat(financialInfoSchema)
  .concat(conditionalFieldsSchema)
  .concat(additionalInfoSchema)
  .required();

export type OfferingPostSchema = yup.InferType<typeof OfferingPostSchema>;

export const OfferingPatchSchema = yup
  .object({
    id: yup.number().integer().positive(REQUIRED_NUMBER).required(),
  })
  .concat(OfferingPostSchema)
  .defined();

export type OfferingPatchSchema = yup.InferType<typeof OfferingPatchSchema>;

export const PropertyIdSchema = yup
  .object({
    propertyId: yup
      .number()
      .integer()
      .positive(REQUIRED_NUMBER)
      .when('type', {
        is: OfferingType.DEBT,
        then: (schema) => schema.notRequired(),
      }),
  })
  .defined();

export type PropertyIdSchema = yup.InferType<typeof PropertyIdSchema>;

import { useQuery } from '@tanstack/react-query';

import { getOfferingTransactionDocumentsPreviewAsPdf } from '@arrived/api_v2';
import { OfferingTransactionId } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';
import { tradeDocumentsPdfQueryKeyFn } from './trades.keys';

export function useTradeDocumentsPreviewAsPdfQuery(
  tradeId?: OfferingTransactionId,
  options?: UseAwaitedQueryOptions<typeof getOfferingTransactionDocumentsPreviewAsPdf>,
) {
  return useQuery({
    queryKey: tradeDocumentsPdfQueryKeyFn(tradeId!),
    queryFn: () => getOfferingTransactionDocumentsPreviewAsPdf(tradeId!),
    ...options,
    enabled: (options?.enabled ?? true) && tradeId != null,
  });
}

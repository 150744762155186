export * from './AboutSection';
export * from './assetDividend';
export * from './CommonQuestionsSection';
export * from './documents';
export * from './funds';
export * from './HowAreDividendsDetermined';
export * from './investmentStrategyHistoricalReturns';
export * from './assetValue';
export * from './LocationSection';
export * from './MarketSection';
export * from './MaxInvestmentCallout';
export * from './offeringDetails';
export * from './OfferingHistory';
export * from './propertyLeverageVolatility';
export * from './PropertyManagementPartnerSection';
export * from './RentalDataSection';
export * from './tenantLeasing';
export * from './TransactionHistorySection';
export * from './WhichPropertySection';
export * from './yourPosition';

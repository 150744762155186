import { SVGProps } from 'react';

export interface SquareProps extends SVGProps<SVGSVGElement> {
  className?: string;
  color: string;
  scale?: number;
}

export const Square = ({ className, color, scale, ...rest }: SquareProps) => {
  const size = (scale ?? 1) * 100;

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="0" y="0" width={size} height={size} fill={color} />
    </svg>
  );
};

import { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { LI, UL } from '@expo/html-elements';

import { BodyText, DataPoint, Stack, UtilityText } from '@arrived/bricks';
import { useGetSortedOfferingHistoryEvents } from '@arrived/features-shared';
import { useHasOfferingPaidDividend } from '@arrived/hooks';
import {
  AccountOfferingBalance,
  AirDnaApiResponse,
  Offering,
  OfferingStatus,
  OfferingTransaction,
  RegDIPOMetadata,
  STRMetadata,
  SecurityType,
} from '@arrived/models';

import { OfferingHistorySection, ProductDetailsView } from '../..';
import { ProductDetailContent } from '../../content';
import { ProductHeader, ProductHeaderProps } from '../../productHeader';
import { ReturnsCalculatorSection, ReturnsCalculatorSectionProps } from '../../returns';
import {
  AboutSection,
  AssetDividendSection,
  AssetValueSection,
  CommonQuestionsProps,
  CommonQuestionsSection,
  DocumentsSection,
  HowAreDividendsDetermined,
  InvestmentStrategyHistoricalReturnsSection,
  LocationSection,
  MarketSection,
  MaxInvestmentCallout,
  OfferingDetailsSection,
  PropertyManagementPartnerSection,
  RentalDataSection,
  TransactionHistorySection,
  WhichPropertySection,
  YourPositionSection,
  getMetadataCommonQuestions,
} from '../components';
import { HeaderDetailsStackProps } from '../ProductDetailParts';
import { useProductDetailsViewContext } from '../ProductDetailsViewContext';
import { commonQuestions } from './commonQuestions';
import { VacationRentalPerformanceChart } from './VacationRentalPerformanceChart';

export type VacationRentalHeaderProps = ProductHeaderProps & {
  offering: Offering;
  strMetadata?: STRMetadata;
};

export const VacationRentalHeader = ({ children, offering, strMetadata, ...rest }: VacationRentalHeaderProps) => (
  <ProductHeader {...rest}>
    <ProductHeader.Title>{offering.name}</ProductHeader.Title>
    <ProductHeader.Tag offering={offering} />
    {offering.properties[0] && <ProductHeader.Details property={offering.properties[0]} />}
    {strMetadata?.airBnbLink && <ProductHeader.Booking airBnbLink={strMetadata?.airBnbLink} />}
    {children}
  </ProductHeader>
);

export type VacationRentalHeaderDetailsProps = HeaderDetailsStackProps & {
  offering: Offering;
};

export const VacationRentalHeaderDetails = ({ offering, ...rest }: VacationRentalHeaderDetailsProps) => {
  const { view } = useProductDetailsViewContext();

  const showMaxInvesmentCallout = useMemo(
    () => offering.maxSharesPerAccount != null && offering.status !== OfferingStatus.FUNDED,
    [offering.maxSharesPerAccount, offering.status],
  );

  return (
    <>
      {(showMaxInvesmentCallout || view === ProductDetailsView.DETAILS) && (
        <Stack {...rest}>
          {showMaxInvesmentCallout && <MaxInvestmentCallout offering={offering} />}
          {view === ProductDetailsView.DETAILS && (
            <>
              <AssetValueSection offering={offering} />
              {offering.properties[0] && <LocationSection property={offering.properties[0]} />}
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export type VacationRentalContentProps = {
  averageDailyRevenueData?: AirDnaApiResponse;
  monthlyOccupancyData?: AirDnaApiResponse;
  monthlyRevenueData?: AirDnaApiResponse;
  offering: Offering;
  offeringBalance?: AccountOfferingBalance;
  regDIPOMetadata?: RegDIPOMetadata;
  strMetadata?: STRMetadata;
  transactions?: OfferingTransaction[];
  Table: ReturnsCalculatorSectionProps['TableComponent'];
};

// Leaving as a constant for now, but in theory we could expose the ability for users to look at other percentile options for the charts
const percentile = '75';

export const VacationRentalContent = ({
  averageDailyRevenueData,
  monthlyOccupancyData,
  monthlyRevenueData,
  offering,
  offeringBalance,
  regDIPOMetadata,
  strMetadata,
  transactions,
  Table,
}: VacationRentalContentProps) => {
  const { view } = useProductDetailsViewContext();
  const hasOfferingPaidDividend = useHasOfferingPaidDividend(offering.id);
  const { sortedEvents: events } = useGetSortedOfferingHistoryEvents(offering);

  const questions = useMemo((): CommonQuestionsProps['questions'] => {
    if (offering.securityType === SecurityType.REG_D) {
      return getMetadataCommonQuestions(regDIPOMetadata?.commonQuestions ?? []);
    } else {
      return commonQuestions;
    }
  }, [offering.securityType, regDIPOMetadata?.commonQuestions]);

  switch (view) {
    case ProductDetailsView.DETAILS:
      return (
        <>
          <AboutSection offering={offering}>
            {strMetadata?.amenities && (
              <>
                <ProductDetailContent.Divider />
                <Stack gap="$4">
                  <ProductDetailContent.SubHeader>
                    <FormattedMessage id="product-details.amenities" />
                  </ProductDetailContent.SubHeader>
                  <UL>
                    {strMetadata.amenities.map((amenity, idx) => (
                      <LI key={idx}>
                        <BodyText token="body.compact.medium">{`\u2022 ${amenity}`}</BodyText>
                      </LI>
                    ))}
                  </UL>
                </Stack>
              </>
            )}
            {(strMetadata?.estimatedLaunchDate || offering.projectedFirstDividendDate) && (
              <>
                <ProductDetailContent.Divider />
                <Stack row alignItems="flex-end" justifyContent="space-between" gap="$4">
                  {strMetadata?.estimatedLaunchDate && (
                    <DataPoint
                      colors="dark"
                      variant="minimized"
                      tooltip={{
                        content: <FormattedMessage id="product-details.rent-ready-date.tooltip" />,
                        title: <FormattedMessage id="product-details.rent-ready-date" />,
                      }}
                    >
                      <DataPoint.Value>{strMetadata.estimatedLaunchDate}</DataPoint.Value>
                      <DataPoint.Label>
                        <FormattedMessage id="product-details.rent-ready-date" />
                      </DataPoint.Label>
                    </DataPoint>
                  )}
                  {!hasOfferingPaidDividend && (
                    <UtilityText
                      flex={1}
                      maxWidth={250}
                      token="utility.helper.medium"
                      color="$onSurface.neutral.defaultAlt"
                      textAlign="right"
                    >
                      <FormattedMessage id="product-details.dividend-eligibility-vr" />
                    </UtilityText>
                  )}
                </Stack>
              </>
            )}
          </AboutSection>
          {strMetadata?.propertyManagement && (
            <PropertyManagementPartnerSection propertyManagement={strMetadata.propertyManagement} />
          )}
          {offering.market && (
            <MarketSection
              description={strMetadata?.market.descriptionMarkdown ?? offering.market.description}
              name={offering.market.title}
              offering={offering}
            />
          )}
          <DocumentsSection offering={offering} />
          {questions.length > 0 && <CommonQuestionsSection questions={questions} />}
          <WhichPropertySection offering={offering} />
        </>
      );
    case ProductDetailsView.FINANCIALS:
      return (
        <>
          <InvestmentStrategyHistoricalReturnsSection offering={offering} />
          {offering.projectedAnnualDividendYield && (
            <ReturnsCalculatorSection
              offering={offering}
              sx={{
                overflow: 'hidden',
                px: 3,
                py: 3.5,
                borderRadius: 1,
              }}
              TableComponent={Table}
            />
          )}
          {Boolean(averageDailyRevenueData || monthlyOccupancyData || monthlyRevenueData) && (
            <RentalDataSection bg="$onSurface.neutral.defaultInverted">
              {averageDailyRevenueData && (
                <VacationRentalPerformanceChart
                  chartData={averageDailyRevenueData}
                  percentile={percentile}
                  numberType="currency"
                  tooltipLabel="common.average-rate.label"
                  title="airdna.chart.title.average-daily-rate"
                />
              )}
              {monthlyRevenueData && (
                <VacationRentalPerformanceChart
                  chartData={monthlyRevenueData}
                  percentile={percentile}
                  numberType="currency"
                  tooltipLabel="common.revenue.label"
                  title="airdna.chart.title.monthly-revenue"
                />
              )}
              {monthlyOccupancyData && (
                <VacationRentalPerformanceChart
                  chartData={monthlyOccupancyData}
                  percentile={percentile}
                  numberType="percent"
                  tooltipLabel="common.occupancy.label"
                  title="airdna.chart.title.monthly-occupancy"
                />
              )}
            </RentalDataSection>
          )}
          <OfferingDetailsSection offering={offering} strMetadata={strMetadata} />
        </>
      );
    case ProductDetailsView.PERFORMANCE:
      return (
        <>
          <OfferingHistorySection offering={offering} events={events} />
          <HowAreDividendsDetermined offering={offering} />
          {offeringBalance && <YourPositionSection offering={offering} offeringBalance={offeringBalance} />}
          {offeringBalance && <AssetDividendSection offering={offering} offeringBalance={offeringBalance} />}
          {transactions && Boolean(transactions.length) && <TransactionHistorySection transactions={transactions} />}
        </>
      );
  }
};

import { useQuery } from '@tanstack/react-query';

import { getFixedRateInvestments } from '@arrived/api_v2';

import { UseAwaitedQueryOptions } from '../utils';
import { fixedRateInvestmentsKey } from './fixedRateInvestments.keys';

export function useGetFixedRateInvestmentsQuery(options?: UseAwaitedQueryOptions<typeof getFixedRateInvestments>) {
  return useQuery({
    queryKey: fixedRateInvestmentsKey,
    queryFn: getFixedRateInvestments,
    ...options,
  });
}

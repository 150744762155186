import { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { Offering, OfferingDocumentFormat, STNMetadata } from '@arrived/models';
import { useOfferingDocumentsQuery } from '@arrived/queries';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../content';
import { OfferingDocument } from './Item';
import { ProductDocumentSource, ProductDocumentType } from './ItemDocument.types';

export type DocumentsSectionProps = ProductDetailContentProps & {
  assetMetadataDocuments?: STNMetadata['documents'];
  offering: Offering;
};

export const DocumentsSection = ({ offering, assetMetadataDocuments, ...rest }: DocumentsSectionProps) => {
  const offeringDocumentsState = useOfferingDocumentsQuery(offering.id);

  const documents = useMemo((): ProductDocumentType[] => {
    return [
      ...(offeringDocumentsState.data ?? []).map((document) => {
        if (document.format === OfferingDocumentFormat.PDF) {
          return {
            source: ProductDocumentSource.ABACUS,
            format: OfferingDocumentFormat.PDF,
            fileName: document.fileName,
            id: document.id,
            type: document.type,
          };
        } else {
          return {
            source: ProductDocumentSource.ABACUS,
            format: OfferingDocumentFormat.URL,
            link: document.fileUrl,
            id: document.id,
            type: document.type,
          };
        }
      }),
      ...(assetMetadataDocuments ?? []).map((document) => ({
        source: ProductDocumentSource.SST,
        format: OfferingDocumentFormat.URL,
        type: document.type,
        link: document.link,
      })),
    ];
  }, [assetMetadataDocuments, offeringDocumentsState.data]);

  if (!documents.length) {
    return null;
  }

  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.documents" />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body gap="$2">
        {documents.map((document, idx) => (
          <OfferingDocument
            key={`product-details-document-${document.source}-${document.source === ProductDocumentSource.ABACUS ? document.id : idx}`}
            document={document}
          />
        ))}
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};

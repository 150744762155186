import { useQuery } from '@tanstack/react-query';

import { getMarketRoi } from '@arrived/api_v2';
import { MarketId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { marketsRoiKeyFn } from './markets.keys';

export function useMarketROIQuery(marketId?: MarketId, options?: UseAwaitedQueryOptions<typeof getMarketRoi>) {
  return useQuery({
    queryKey: marketsRoiKeyFn(marketId!),
    queryFn: () => getMarketRoi(marketId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && marketId != null,
  });
}

import { ReactNode } from 'react';

import { Box, Grid, Stack, StackProps, Typography } from '@mui/material';

import { Constants, getCDNOptimizedImage } from '@arrived/common';

import { NavigationArrivedLogo } from '../navigation';
import { DefaultOptions } from './DefaultOptions';
import { OptionCard, OptionCardProps } from './OptionCard';

interface ErrorPageProps extends StackProps {
  titleText: ReactNode;
  subTitleText: ReactNode;
  bodyText: ReactNode;
  options?: OptionCardProps[];
}

export const ErrorPage = ({ titleText, subTitleText, bodyText, options, ...rest }: ErrorPageProps) => {
  const renderedOptions = options ?? DefaultOptions;

  return (
    <Stack
      position="absolute"
      top={0}
      width="100%"
      height="100%"
      overflow="hidden"
      direction={{ xs: 'column', md: 'row' }}
      {...rest}
    >
      <Stack
        position="relative"
        zIndex={1}
        direction="column"
        alignItems="flex-start"
        my={0}
        mr={{ xs: 0, md: '100px' }}
        ml={{ xs: 0, md: '100px' }}
        px={{ xs: 2.5, md: 0 }}
        width="100%"
      >
        <Stack flex="0 1 auto" mt={{ xs: 2.5, md: 7 }} height="auto">
          <NavigationArrivedLogo />
          <Stack
            width={{ xs: 'auto', md: '60%' }}
            minWidth="250px"
            mt={6}
            gap={1}
            sx={{
              a: {
                color: ({ palette }) => palette.primary.main,
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
            }}
          >
            <Typography variant="h1.semibold">{titleText}</Typography>
            <Typography variant="h4.semibold" mt={3}>
              {subTitleText}
            </Typography>
            <Typography>{bodyText}</Typography>
          </Stack>
        </Stack>
        <Grid flex="0 1 auto" mt={3} container rowSpacing={1.5} columnSpacing={4}>
          {renderedOptions.map((option, idx) => (
            <Grid key={`option-${idx}`} item xs={12} sm={12} md={4}>
              <OptionCard {...option} />
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Box
        zIndex={0}
        position="absolute"
        top={{ xs: 'auto', md: 0 }}
        right={0}
        bottom={0}
        height={{ xs: 'auto', md: '100%' }}
        width={{ xs: '524px', md: 'auto' }}
        sx={{
          img: {
            mt: { xs: 2.5, md: 0 },
          },
        }}
      >
        <img
          src={getCDNOptimizedImage({ imgUrl: `${Constants.awsCdnUrl}/images/painting.png` })}
          alt=""
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
    </Stack>
  );
};

import { forwardRef, useCallback } from 'react';

import dynamic from 'next/dynamic';

import { baseMediaBreakpoints } from '@arrived/bricks';
import { DisclaimerAccordion, Footer, FooterProps } from '@arrived/components';
import { Offering } from '@arrived/models';

import { NextMuiLink } from '../Link';

// Because the DisclaimerAccordion uses local storage to determine how it is displayed (open/closed) it can't be
// rendered server side.
const DisclaimerAccordionNoSSR = dynamic(() => Promise.resolve(DisclaimerAccordion), { ssr: false });

export interface PublicFooterProps extends Omit<FooterProps, 'RouteLink'> {
  offering?: Offering;
}

export const PublicFooter = forwardRef(({ offering, ...rest }: PublicFooterProps, ref) => {
  const RouteLink = useCallback(
    (link: string, text: JSX.Element) => (
      <NextMuiLink href={link} color="inherit">
        {text}
      </NextMuiLink>
    ),
    [],
  );

  return (
    <Footer RouteLink={RouteLink} ref={ref} {...rest}>
      <DisclaimerAccordionNoSSR
        offering={offering}
        bg="$onSurface.neutral.zebraAlt"
        px="$4"
        mx="auto"
        width="100%"
        maxWidth={baseMediaBreakpoints['lg'].maxWidth as number}
      />
    </Footer>
  );
});

import { useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Props as FormattedMessageProps } from 'react-intl/src/components/message';

import { Box, Grid, GridProps, Typography, TypographyProps, useTheme } from '@mui/material';

import { Constants } from '@arrived/common';
import { CompanyKpis, CustomerCount, KpiHighlightKey } from '@arrived/models';

import { ArrivedLogoSVG } from '../images';

interface KpiHighlight {
  key: KpiHighlightKey;
  title: FormattedMessageProps;
  description: FormattedMessageProps;
  kpiExtractFn: number | ((arrivedKpis?: CompanyKpis & CustomerCount) => number);
  isMonetaryValue?: boolean;
}

const KPI_HIGHLIGHTS: Record<KpiHighlightKey, KpiHighlight> = {
  'property-values': {
    key: 'property-values',
    title: {
      id: 'property-value-funded-title',
      defaultMessage: 'Property Value Funded',
    },
    description: {
      id: 'property-value-funded-description',
      defaultMessage:
        'Thousands of Arrived investors are fully funding new single family rental properties and investing in building their long-term wealth.',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.fundedPropertyValue ?? 0,
    isMonetaryValue: true,
  },
  'total-aum': {
    key: 'total-aum',
    title: {
      id: 'total-aum-title',
      defaultMessage: 'Assets Under Management',
    },
    description: {
      id: 'total-aum-description',
      defaultMessage:
        'Tens of thousands of people on Arrived have fully funded hundreds of properties, building their long-term wealth through real estate investing.',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.totalAum ?? 0,
    isMonetaryValue: true,
  },
  'active-markets': {
    key: 'active-markets',
    title: {
      id: 'active-markets',
      defaultMessage: 'Active Markets',
    },
    description: {
      id: 'active-markets-description',
      defaultMessage:
        'Arrived has properties and operations across the country, helping investors invest remotely in diversified markets.',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.cityCount ?? 0,
  },
  'company-funding': {
    key: 'company-funding',
    title: {
      id: 'company-funding-title',
      defaultMessage: 'Company Funding',
    },
    description: {
      id: 'company-funding-description',
      defaultMessage:
        'Arrived is backed by a world class group of investors and partnered with top tier financial institutions that believe in our mission of democratizing real estate investing.',
    },
    kpiExtractFn: Constants.equityFinancingAmount,
    isMonetaryValue: true,
  },
  'properties-funded': {
    key: 'properties-funded',
    title: {
      id: 'properties-funded',
      defaultMessage: 'Properties Funded',
    },
    description: {
      id: 'properties-funded-description',
      defaultMessage:
        'All Arrived properties are hand-picked and individually analyzed by our investment team, ensuring that our investors choose from an exceptional selection of homes.',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.fundedPropertiesCount ?? 0,
  },
  'active-investors': {
    key: 'active-investors',
    title: {
      id: 'active-investors',
      defaultMessage: 'Active Investors',
    },
    description: {
      id: 'active-investors-description',
      defaultMessage: '',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.investorsCount ?? 0,
  },
  'user-count': {
    key: 'user-count',
    title: {
      id: 'user-count',
      defaultMessage: 'Registered Users',
    },
    description: {
      id: 'user-count-description',
      defaultMessage: '',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.count ?? 0,
  },
};

export interface CompanyKpiDisplayProps {
  kpis: CompanyKpis & CustomerCount;
  kpi1: KpiHighlightKey;
  kpi2: KpiHighlightKey;
  kpi3: KpiHighlightKey;
  showDescriptions?: boolean;
  align?: TypographyProps['textAlign'];
  maxWidth?: GridProps['maxWidth'];
}

export const CompanyKpiDisplay = ({
  kpis,
  kpi1,
  kpi2,
  kpi3,
  showDescriptions = true,
  align = 'center',
  maxWidth = 'lg',
}: CompanyKpiDisplayProps) => {
  const highlights = useMemo(
    () => [KPI_HIGHLIGHTS[kpi1], KPI_HIGHLIGHTS[kpi2], KPI_HIGHLIGHTS[kpi3]],
    [kpi1, kpi2, kpi3],
  );
  const { palette } = useTheme();

  return (
    <Box
      py={{ xs: 2.5, md: 8 }}
      px={{ xs: 5, md: 12 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      overflow="hidden"
      sx={{
        background: `linear-gradient(90deg, ${palette.aqua[600]}, ${palette.aqua[700]} 100%)`,
        '& .logo': {
          position: 'absolute',
          transformOrigin: 'center',
          transform: 'rotate(180deg) scale(20)',
          top: {
            xs: '-100px',
            md: 'unset',
          },
          left: {
            xs: '60px',
            md: 'unset',
          },
        },
      }}
    >
      <ArrivedLogoSVG className="logo" colorOne={palette.aqua[600]} colorTwo={palette.aqua[700]} />
      <Grid container columnSpacing={4} rowSpacing={4} maxWidth={maxWidth} position="relative">
        {highlights.map((kpiHighlight) => {
          const kpi =
            typeof kpiHighlight.kpiExtractFn === 'function'
              ? kpiHighlight.kpiExtractFn(kpis)
              : kpiHighlight.kpiExtractFn;

          return (
            <Grid key={kpiHighlight.key} item xs={12} lg={4} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
              <Grid container spacing={0.5} flexDirection="column" alignItems={{ xs: 'center', md: 'unset' }}>
                <Grid item fontSize="150%">
                  <Typography
                    component="h3"
                    variant="h1.semibold"
                    textAlign={align}
                    color={({ palette }) => palette.neutrals.white}
                    mb={{ xs: 1.5, md: 0 }}
                    sx={{
                      '& .dollar-sign': {
                        fontSize: '0.5em',
                        lineHeight: '1em',
                        verticalAlign: 'baseline',
                      },
                      '& .plus-sign': {
                        fontSize: '0.5em',
                        lineHeight: '1em',
                        verticalAlign: 'top',
                      },
                    }}
                  >
                    {kpiHighlight.isMonetaryValue && <span className="dollar-sign">$</span>}
                    <FormattedNumber notation="compact" value={kpi} />
                    <span className="plus-sign">+</span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className="kpi-title"
                    variant="h5.medium"
                    component="div"
                    textAlign={align}
                    color={({ palette }) => palette.neutrals.white}
                    mb={0.5}
                  >
                    <FormattedMessage {...kpiHighlight.title} />
                  </Typography>
                </Grid>
                {showDescriptions && (
                  <Grid item>
                    <Typography variant="body1" color={({ palette }) => palette.neutrals.white}>
                      <FormattedMessage {...kpiHighlight.description} />
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

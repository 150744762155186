import { useQuery } from '@tanstack/react-query';

import { getMarket } from '@arrived/api_v2';
import { MarketId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { marketsKeyFn } from './markets.keys';

export function useGetMarketQuery(marketId?: MarketId | null, options?: UseAwaitedQueryOptions<typeof getMarket>) {
  return useQuery({
    queryKey: marketsKeyFn(marketId!),
    queryFn: () => getMarket(marketId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && marketId != null,
  });
}

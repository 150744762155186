import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';
import _without from 'lodash/without';

import { Market } from './markets';
import { AssetType, OfferingType } from './offerings';
import { AvailabilityFilterTerm, LeverageFilterTerm, OfferingSearchQuery } from './offeringSearchQuery';
import { LeaseStatus } from './properties';

/**
 * This is a representation of our top level investment category filters. Note these will NOT
 * always be 1:1 with InvestmentProductType as these are the categories that we are using
 * for top-level filters, not necessarily the investment products themselves.
 */
export const InvestmentCategory = {
  SINGLE_FAMILY: 'SINGLE_FAMILY',
  VACATION: 'VACATION',
  FUNDS: 'FUNDS',
  SHORT_TERM_NOTE: 'SHORT_TERM_NOTE',
  REAL_ESTATE_DEBT: 'REAL_ESTATE_DEBT',
} as const;

export type InvestmentCategory = (typeof InvestmentCategory)[keyof typeof InvestmentCategory];

export const OwnershipFilter = {
  OWNED: 'OWNED',
  NOT_OWNED: 'NOT_OWNED',
} as const;

export type OwnershipFilter = (typeof OwnershipFilter)[keyof typeof OwnershipFilter];

export const AvailabilityFilter = {
  FOR_SALE: 'FOR_SALE',
  SOLD_OUT: 'SOLD_OUT',
  COMING_SOON: 'COMING_SOON',
} as const;

export type AvailabilityFilter = (typeof AvailabilityFilter)[keyof typeof AvailabilityFilter];

export const RentStatusFilter = {
  RENTED: 'RENTED',
  SEEKING_TENANT: 'SEEKING_TENANT',
} as const;

export type RentStatusFilter = (typeof RentStatusFilter)[keyof typeof RentStatusFilter];

export const LeverageFilter = {
  NONE: 'NONE',
  LEVERAGED: 'LEVERAGED',
} as const;

export type LeverageFilter = (typeof LeverageFilter)[keyof typeof LeverageFilter];

/**
 * The client-side representation of an OfferingFilter, this is agnostic to the shape of the query
 * sent to abacus to search for Offerings. It is meant to be a layer that is easily translatable
 * to/from app-specific query params.
 */
export interface OfferingFilter {
  availability?: AvailabilityFilter[];
  investmentCategory?: InvestmentCategory;
  leverage?: LeverageFilter[];
  markets?: Market['id'][];
  ownership?: OwnershipFilter[];
  rentStatus?: RentStatusFilter[];
}

export const convertOfferingFilterToSearchQuery = ({
  availability: availabilityFilter,
  investmentCategory,
  leverage: leverageFilter,
  markets,
  ownership,
  rentStatus,
}: OfferingFilter): OfferingSearchQuery => {
  let assetType: OfferingSearchQuery['assetType'];
  let type: OfferingSearchQuery['type'];

  let leverageDisabled = false;
  let marketsDisabled = false;
  let rentStatusDisabled = false;

  switch (investmentCategory) {
    case InvestmentCategory.SINGLE_FAMILY:
      assetType = { eq: [AssetType.LTR] };
      break;
    case InvestmentCategory.FUNDS:
      type = { eq: [OfferingType.FUND] };
      leverageDisabled = true;
      marketsDisabled = true;
      rentStatusDisabled = true;
      break;
    case InvestmentCategory.SHORT_TERM_NOTE:
      assetType = { eq: [AssetType.STN] };
      type = { eq: [OfferingType.DEBT] };
      leverageDisabled = true;
      marketsDisabled = true;
      rentStatusDisabled = true;
      break;
    case InvestmentCategory.VACATION:
      assetType = { eq: [AssetType.STR] };
      type = { eq: [OfferingType.EQUITY] };
      rentStatusDisabled = true;
      break;
    case InvestmentCategory.REAL_ESTATE_DEBT:
      assetType = { eq: [AssetType.STN, AssetType.PRIVATE_CREDIT] };
      leverageDisabled = true;
      marketsDisabled = true;
      rentStatusDisabled = true;
      break;
  }

  const availability = availabilityFilter?.length
    ? {
        eq: availabilityFilter.map((availabilityFilter) => {
          switch (availabilityFilter) {
            case AvailabilityFilter.COMING_SOON:
              return AvailabilityFilterTerm.COMING_SOON;
            case AvailabilityFilter.FOR_SALE:
              return AvailabilityFilterTerm.FOR_SALE;
            case AvailabilityFilter.SOLD_OUT:
              return AvailabilityFilterTerm.SOLD_OUT;
          }
        }),
      }
    : undefined;

  const leverage = leverageFilter?.length
    ? {
        eq: leverageFilter.reduce((filters, leverageFilter) => {
          switch (leverageFilter) {
            case LeverageFilter.LEVERAGED:
              return [...filters, ..._without(Object.values(LeverageFilterTerm), LeverageFilterTerm.NONE)];
            case LeverageFilter.NONE:
              return [...filters, LeverageFilterTerm.NONE];
          }
        }, []),
      }
    : undefined;

  const isOwned = ownership?.length
    ? {
        eq: ownership.map((ownership) => {
          switch (ownership) {
            case OwnershipFilter.NOT_OWNED:
              return false;
            case OwnershipFilter.OWNED:
              return true;
          }
        }),
      }
    : undefined;

  const leaseStatus = rentStatus?.length
    ? {
        eq: rentStatus.reduce((filters, rentStatus) => {
          switch (rentStatus) {
            case RentStatusFilter.RENTED:
              return [...filters, LeaseStatus.OCCUPIED];
            case RentStatusFilter.SEEKING_TENANT:
              return [...filters, ..._without(Object.values(LeaseStatus), LeaseStatus.OCCUPIED)];
          }
        }, []),
      }
    : undefined;

  return _omitBy(
    {
      assetType,
      availability,
      isOwned,
      leaseStatus: !rentStatusDisabled ? leaseStatus : undefined,
      leverage: !leverageDisabled ? leverage : undefined,
      marketId: !marketsDisabled && markets?.length ? { eq: markets } : undefined,
      type,
    },
    _isNil,
  );
};

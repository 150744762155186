import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { getCDNOptimizedImage } from '@arrived/common';

const valueRising = getCDNOptimizedImage({
  imgUrl: 'https://cdn.arrivedhomes.com/images/signup/value_rising.png',
  width: '455px',
  height: '650px',
});

export const Variation2 = () => (
  <>
    <Typography color="font.500" variant="h3.semibold" pl={{ md: '5%', lg: 8 }} pr={{ md: 0, lg: 8 }} pt={7}>
      <FormattedMessage
        id="earn-income-and-appreciation"
        defaultMessage="Earn income &{br} appreciation"
        values={{ br: <br /> }}
      />
    </Typography>
    <Box position="absolute" bottom={0} left={{ md: '5%', xl: '100px' }}>
      <img alt="house" src={valueRising} />
    </Box>
  </>
);

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchUser } from '@arrived/api_v2';

import { offeringsKeyFn } from '../offerings';
import { UseAwaitedMutationOptions } from '../utils';
import { currentUserKeyFn } from './users.keys';

export function usePatchUserMutation(options?: UseAwaitedMutationOptions<typeof patchUser>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchUser,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(currentUserKeyFn(), data);

      queryClient.invalidateQueries({ queryKey: currentUserKeyFn() });

      // We need to invalidate the offerings query because the user's offerings may have changed
      queryClient.invalidateQueries({ queryKey: offeringsKeyFn() });

      options?.onSuccess?.(data, variables, context);
    },
  });
}

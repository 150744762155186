import { OfferingId, UserId } from '@arrived/models';

export const SheetRoutes = {
  AccreditationStatus: 'accreditation-status',
  DiversifyWithFunds: 'diversify-with-funds',
  InvestorsLikeYou: 'investors-like-you',
  PrivateCreditFundPromo: 'private-credit-fund-promo',
} as const;

export type SheetRoutes = (typeof SheetRoutes)[keyof typeof SheetRoutes];

export type SheetRouteParams = {
  [SheetRoutes.AccreditationStatus]: {
    userId: UserId;
    reject?: '1';
  };
  [SheetRoutes.DiversifyWithFunds]: {
    fundId: OfferingId;
  };
  [SheetRoutes.InvestorsLikeYou]: {
    fundId: OfferingId;
    ipoIds?: OfferingId[];
  };
  [SheetRoutes.PrivateCreditFundPromo]: {
    fundId: OfferingId;
  };
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postCreateOfferingTransaction } from '@arrived/api_v2';
import { Account } from '@arrived/models';

import { balanceKeyFn, offeringBalancesKeyFn } from '../accounts';
import { offeringKeyFn, offeringsKeyFn } from '../offerings';
import {
  accountSharesAvailableKeyFn,
  offeringSharesAvailableKeyFn,
  userSharesAvailableKeyFn,
} from '../sharesAvailable';
import { accountsPrimaryKeyFn } from '../users';
import { UseAwaitedMutationOptions } from '../utils';
import { accountTradesQueryKeyFn, tradeQueryKeyFn, userTradesQueryKeyFn } from './trades.keys';

export function usePostTradeMutation(options?: UseAwaitedMutationOptions<typeof postCreateOfferingTransaction>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postCreateOfferingTransaction,
    ...options,
    onSuccess: (data, variables, context) => {
      const { id: tradeId, accountId, offeringId, userId } = data;

      queryClient.setQueryData(tradeQueryKeyFn(tradeId), data);

      queryClient.invalidateQueries({ queryKey: accountSharesAvailableKeyFn(offeringId, accountId) });
      queryClient.invalidateQueries({ queryKey: userSharesAvailableKeyFn(offeringId, userId) });
      queryClient.invalidateQueries({ queryKey: offeringSharesAvailableKeyFn(offeringId) });

      queryClient.invalidateQueries({ queryKey: userTradesQueryKeyFn(userId) });
      queryClient.invalidateQueries({ queryKey: accountTradesQueryKeyFn(accountId) });

      queryClient.invalidateQueries({ queryKey: balanceKeyFn(accountId) });
      queryClient.invalidateQueries({ queryKey: offeringBalancesKeyFn(accountId) });

      queryClient.invalidateQueries({ queryKey: offeringsKeyFn() });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(offeringId) });

      const account = queryClient.getQueryData<Account>(accountsPrimaryKeyFn(userId));

      if (account) {
        queryClient.invalidateQueries({
          queryKey: ['cash-accounts'].concat(account.cashAccountCid ? [account.cashAccountCid] : []),
        });
      }

      options?.onSuccess?.(data, variables, context);
    },
  });
}

import { FormattedMessage } from 'react-intl';

import { AccountType, EntityType } from '@arrived/models';

import { maskTaxId } from '../utils';
import { formatEntityType } from './entityType';

export const AccountDisplayName = {
  [AccountType.ENTITY]: {
    id: 'individual-display-name',
    defaultMessage: 'Entity ({entityType}) - Brokerage Account{taxId}',
  },
  [AccountType.INDIVIDUAL]: {
    id: 'individual-display-name',
    defaultMessage: 'Individual - Brokerage Account',
  },
  [AccountType.IRA]: {
    id: 'individual-display-name',
    defaultMessage: 'Individual IRA - Brokerage Account',
  },
  [AccountType.ROTH]: {
    id: 'individual-display-name',
    defaultMessage: 'Individual Roth - Brokerage Account',
  },
  [AccountType.JOINT]: {
    id: 'individual-display-name',
    defaultMessage: 'Individual Joint - Brokerage Account',
  },
  [AccountType.JTWROS]: {
    id: 'individual-display-name',
    defaultMessage: 'Individual Joint w/ROS - Brokerage Account',
  },
  [AccountType.SEP_IRA]: {
    id: 'individual-display-name',
    defaultMessage: 'Individual SEP IRA - Brokerage Account',
  },
  [AccountType.TIC]: {
    id: 'individual-display-name',
    defaultMessage: 'Individual TIC - Brokerage Account',
  },
  // This exists because `as const`
  CHECKBOOK_IRA: null,
} as const;

// @TODO: discussion open for how we handle things like this moving forward, should use just support the `useIntl` hook by default?
export const formatAccountDisplayName = ({
  accountType,
  type,
  entityType,
  ssn,
  ein,
}: {
  accountType?: AccountType;
  type?: AccountType; //Note: The web app stores this as accountType
  entityType?: EntityType | null;
  ssn?: string;
  ein?: string;
}) => {
  const taxId = ein ?? ssn;
  const accountTypeCommon = accountType || type;

  if (!accountTypeCommon || !AccountDisplayName[accountTypeCommon]) {
    return null;
  }

  return (
    <FormattedMessage
      {...AccountDisplayName[accountTypeCommon]}
      values={{
        entityType: entityType ? formatEntityType(entityType) : undefined,
        taxId: taxId ? ` [${maskTaxId(taxId)}]` : undefined,
      }}
    />
  );
};

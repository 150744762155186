import { ComponentType, PropsWithChildren, Ref, useEffect, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';

import Animated, { useAnimatedProps, useDerivedValue, withSpring } from 'react-native-reanimated';

import { GetProps, TamaguiElement, composeRefs, styled, useMedia, withStaticProperties } from '@tamagui/core';

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  autoUpdate,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import { useDebounce } from 'usehooks-ts';

import { BlurView, Divider, Stack, StackProps } from '../../atoms';
import { ArrivedIconBlue, ArrivedIconWithText, CloseIcon, MenuIcon } from '../../icons';
import { Button } from '../../molecules';
import { smoothSpring } from '../../utils';
import { MainNavContext, useMainNavContext } from './MainNavContext';
import { MainNavFrame } from './MainNavFrame';
import { NavDropdown } from './navDropdown';
import { NavItem as NavItemDisplay } from './navItem';
import { NavSection } from './navSection/NavSection';
import {
  SecondarySpotButtonsWrapper,
  SecondarySpotDropdownWrapper,
  SecondarySpotSkeletonLoader,
} from './secondarySpot';
import { SectionOverlay, useSectionNavItemOverlay } from './sectionOverlay';
import { NavItem, NavItemType, Resource } from './types';

export type ResourceProps = PropsWithChildren<Omit<Resource, 'label'>>;

export type HomeProps = Omit<Resource, 'label'> & {
  /**
   * Allows for the rendering of a custom component in the home section of the MainNav. The
   * provided component needs to spread props onto the root component and should be rendered as an
   * anchor tag in the DOM as it should behave as a link.
   */
  Component?: ComponentType<StackProps>;
};

export type MainNavExtraProps = Omit<GetProps<typeof MainNavFrame>, 'children'> & {
  /**
   * The resource to navigate to when the "home" button (the Arrived logo) is clicked. By default,
   * the link will wrap the Arrived Icon (without text) when the viewport is smaller than XS,
   * however, if you'd like to render something custom you can pass a `Component`, if you choose
   * to do that, make sure you pass all props that are passed to Component so that it receives the
   * necessary props from the Resource wrapper.
   *
   * ```tsx
   * <MainNav
   *   home={{
   *     link: '...',
   *     Component: (props) => (
   *       <Stack {...rest}>
   *         {...}
   *       </Stack>
   *     ),
   *   }}
   * />
   * ```
   */
  home: HomeProps;
  /**
   * The items to display in the main content of the navigation bar.
   */
  primaryItems: NavItem[];

  /**
   * Renders an actual Resource, because we need to render these differently for our two different
   * web platforms (SPA vs. NextJS), this needs to be specified by the consuming component.
   */
  Resource: ComponentType<ResourceProps>;
  /**
   * Component that will be rendered in the "Secondary" spot of the navigation bar. This is the
   * right side of the nav bar when in XS or smaller viewports or the bottom of the overlay when
   * the viewport is greater than XS.
   *
   * If you are using non-MainNav components, then you should use the `MainNavContext` to get the
   * `close` function such that when one of your elements is pressed the overlay is closed when
   * using the condensed (viewports XS or smaller) MainNav.
   */
  SecondarySpot?: ComponentType;

  /**
   * Optional mobile CTA that shows on the primary nav bar next to the menu button
   */
  MobilePrimaryCta?: ComponentType;
};

export type MainNavProps = Omit<GetProps<typeof MainNavFrame>, 'children'> & MainNavExtraProps;

const MainNavDivider = styled(Divider.Vertical, {
  solid: true,
  h: 32,
});

const MenuButton = styled(Button, {
  Icon: MenuIcon,
  iconSize: 'lg',
  variant: 'ghost',
  ml: 'auto',

  $gtXs: {
    display: 'none',
  },

  variants: {
    open: {
      true: {
        Icon: CloseIcon,
      },
    },
  } as const,
});

const MenuList = styled(Stack, {
  position: 'absolute',
  bg: '$onSurface.neutral.defaultInverted',
  width: '100%',
});

interface PrimaryNavItemProps {
  focusable?: boolean;
  item: NavItem;
}

const PrimaryNavItem = ({ focusable, item }: PrimaryNavItemProps) => {
  const { close, Resource, setSelectedSectionNavItemKey } = useMainNavContext();

  switch (item.type) {
    case NavItemType.DROPDOWN:
      return (
        <NavDropdown focusable={focusable} resources={item.resources}>
          <NavDropdown.Text>{item.label}</NavDropdown.Text>
        </NavDropdown>
      );
    case NavItemType.LINK:
      return (
        <Resource isExternal={item.isExternal} link={item.link}>
          <NavItemDisplay focusable={focusable} active={item.active} tag="a" onPress={close}>
            <NavItemDisplay.Text>{item.label}</NavItemDisplay.Text>
          </NavItemDisplay>
        </Resource>
      );
    case NavItemType.SECTION:
    default:
      return (
        <NavSection
          key={item.key}
          focusable={focusable}
          item={item}
          onPress={() => setSelectedSectionNavItemKey(item.key)}
        >
          <NavSection.Text>{item.label}</NavSection.Text>
        </NavSection>
      );
  }
};

const Home = ({ home: { Component, ...rest } }: { home: MainNavProps['home'] }) => {
  const { Resource } = useMainNavContext();

  if (Component) {
    return (
      <Resource {...rest}>
        <Component />
      </Resource>
    );
  } else {
    return (
      <>
        <Stack $gtXs={{ display: 'none' }}>
          <Resource {...rest}>
            <Stack tag="a" cursor="pointer">
              <ArrivedIconBlue />
            </Stack>
          </Resource>
        </Stack>
        <Stack display="none" $gtXs={{ display: 'flex' }}>
          <Resource {...rest}>
            <Stack tag="a" cursor="pointer">
              <ArrivedIconWithText />
            </Stack>
          </Resource>
        </Stack>
      </>
    );
  }
};

const AnimatedBlurView = Animated.createAnimatedComponent(BlurView);

export const MainNav = withStaticProperties(
  MainNavFrame.styleable<MainNavExtraProps>(
    ({ children, home, primaryItems, Resource, SecondarySpot, MobilePrimaryCta, ...rest }, ref) => {
      const [isOpen, setIsOpen] = useState(false);
      const media = useMedia();
      const [height, setHeight] = useState<number>();
      const [floatingMenuIds, setFloatingMenuIds] = useState<string[]>([]);

      const { selectedSectionNavItem, setSelectedSectionNavItemKey } = useSectionNavItemOverlay(primaryItems);

      /**
       * This useFloating hook is used to control the menu in mobile viewports where the menu takes
       * over the entire screen. None of this will be applicable when in desktop viewports.
       */
      const { context, refs, floatingStyles } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'bottom',
        strategy: 'absolute',
        whileElementsMounted: autoUpdate,
        middleware: [
          size({
            apply({ availableHeight, elements, rects }) {
              flushSync(() => setHeight(availableHeight));
              elements.floating.style.width = `${rects.reference.width}px`;
            },
          }),
        ],
      });

      const click = useClick(context);
      const dismiss = useDismiss(context);
      const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

      const mainNavFrameRef = useMemo(
        () => composeRefs(refs.setPositionReference, ref),
        [ref, refs.setPositionReference],
      );

      useEffect(() => {
        if (media.gtXs) {
          setIsOpen(false);
          setSelectedSectionNavItemKey(null);
          setFloatingMenuIds([]);
        }
      }, [media.gtXs]);

      const mainNavContextValue = useMemo(
        () => ({
          close: () => {
            setIsOpen(false);
            setSelectedSectionNavItemKey(null);
          },
          Resource,
          setSelectedSectionNavItemKey,
          setFloatingMenuIds,
        }),
        [Resource],
      );

      const { isMounted, styles: floatingTransitionStyles } = useTransitionStyles(context, {
        duration: 200,
        initial: { opacity: 0, height: 0 },
        open: { opacity: 1, height },
      });

      useEffect(() => {
        if (!isMounted) {
          setSelectedSectionNavItemKey(null);
        }
      }, [isMounted]);

      // By using a debounced value and a non-debounced value derived the same way, this
      // effectively becomes a "delayed" value in that it will immediately be `true` as soon as
      // floatingMenuIds.length > 0, but won't be reverted to `false` until a 150ms delay. This is
      // ideal because we want the blur view to show up immediately when the floating element
      // appears, but would like it to persist if hovering/focusing between nav elements and/or
      // delay the disappearance of it just slightly.
      const showBlur = useDebounce(floatingMenuIds.length > 0, 150) || floatingMenuIds.length > 0;

      const showBlurDerived = useDerivedValue(() => showBlur, [showBlur]);
      const intensityAnimation = useAnimatedProps(
        () => ({ intensity: withSpring(showBlurDerived.value ? 80 : 0, smoothSpring) }),
        [showBlurDerived],
      );

      return (
        <MainNavContext.Provider value={mainNavContextValue}>
          <MainNavFrame ref={mainNavFrameRef as (node: TamaguiElement | null) => void} {...rest}>
            <Home home={home} />
            <MainNavDivider display="none" $gtXs={{ display: 'flex' }} />
            <Stack
              display="none"
              $gtXs={{ display: 'flex' }}
              row
              alignItems="center"
              justifyContent="flex-start"
              gap="$3"
            >
              {primaryItems.map((item) => (
                <PrimaryNavItem key={item.key} item={item} />
              ))}
            </Stack>
            {children}
            {SecondarySpot && (
              <Stack display="none" $gtXs={{ display: 'flex' }} ml="auto">
                <SecondarySpot />
              </Stack>
            )}
            {MobilePrimaryCta && (
              <Stack $gtXs={{ display: 'none' }} flex={1} justifyContent="flex-end">
                <MobilePrimaryCta />
              </Stack>
            )}
            <MenuButton open={isMounted} ref={refs.setReference as Ref<TamaguiElement>} {...getReferenceProps()} />
          </MainNavFrame>
          <FloatingPortal>
            <AnimatedBlurView
              animatedProps={intensityAnimation}
              tint="dark"
              position={'fixed' as 'absolute'}
              top={0}
              left={0}
              zIndex="$dropdown"
              pointerEvents={showBlur ? 'auto' : 'none'}
            />
            {showBlur && <FloatingOverlay lockScroll />}
            {isMounted && (
              <>
                <FloatingOverlay lockScroll />
                <FloatingFocusManager context={context}>
                  <Stack ref={refs.setFloating} style={floatingStyles} zIndex="$overlay" {...getFloatingProps()}>
                    <MenuList style={floatingTransitionStyles}>
                      <Stack flex={1} position="relative" overflow="hidden">
                        <Stack height="100%" overflow={'auto' as 'scroll'}>
                          {primaryItems.map((item) => (
                            <PrimaryNavItem focusable={selectedSectionNavItem == null} key={item.key} item={item} />
                          ))}
                        </Stack>
                        <SectionOverlay selectedSectionNavItem={selectedSectionNavItem} />
                      </Stack>
                      {SecondarySpot && <SecondarySpot />}
                    </MenuList>
                  </Stack>
                </FloatingFocusManager>
              </>
            )}
          </FloatingPortal>
        </MainNavContext.Provider>
      );
    },
  ),
  {
    Divider: MainNavDivider,
    Dropdown: NavDropdown,
    SecondarySpot: {
      ButtonsWrapper: SecondarySpotButtonsWrapper,
      DropdownWrapper: SecondarySpotDropdownWrapper,
      SkeletonLoader: SecondarySpotSkeletonLoader,
    },
  },
);

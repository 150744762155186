import { useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle, PixelRatio } from 'react-native';

import { FormattedMessage, useIntl } from 'react-intl';

import { useDebounce } from 'usehooks-ts';

import { AppreciationIcon, GetProps, Image, RentalIncomeIcon, Stack, ValueText } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { useInvestmentProductType } from '@arrived/hooks';
import { cdnToImageUrl, ikClient } from '@arrived/imagekit';
import { InvestmentProductType, Offering } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../content';
import { Graphic } from '../../returnsGraphic';
import { RentalIncomeSection } from './rentalIncomeSection/RentalIncomeSection';

export type InvestmentStrategyHistoricalReturnsSectionProps = ProductDetailContentProps & {
  offering: Offering;
};

const StrategyHeader = ProductDetailContent.SubHeader.styleable<
  GetProps<typeof ProductDetailContent.SubHeader> & { offering: Offering }
>(({ offering, ...rest }, ref) => {
  const investmentProductType = useInvestmentProductType(offering);

  const title = useMemo(() => {
    switch (investmentProductType) {
      case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO:
        return <FormattedMessage id="common.single.family.residential" />;
      case InvestmentProductType.VACATION_RENTAL_IPO:
        return <FormattedMessage id="common.vacation.rental" />;
      default:
        return null;
    }
  }, [investmentProductType]);

  return (
    <>
      {title && (
        <ProductDetailContent.SubHeader ref={ref} {...rest}>
          {title}
        </ProductDetailContent.SubHeader>
      )}
    </>
  );
});

export const InvestmentStrategyHistoricalReturnsSection = ({
  offering,
  ...rest
}: InvestmentStrategyHistoricalReturnsSectionProps) => {
  const intl = useIntl();
  const investmentProductType = useInvestmentProductType(offering);

  const [layoutRectangle, setLayoutRectangle] = useState<LayoutRectangle>();
  const onLayout = useCallback((event: LayoutChangeEvent) => setLayoutRectangle(event.nativeEvent.layout), []);

  const width = useDebounce(layoutRectangle?.width);
  const uri = useMemo(
    () =>
      width != null &&
      ikClient.url({
        path: cdnToImageUrl('https://cdn.arrivedhomes.com/images/pdp/vacation-rental-j-curve.png'),
        transformation: [{ width: `${PixelRatio.getPixelSizeForLayoutSize(width)}px` }],
      }),
    [width],
  );

  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.investment-strategy-hypothetical-returns" />
      </ProductDetailContent.Header>

      <ProductDetailContent.Body onLayout={onLayout}>
        <StrategyHeader offering={offering} />

        <Stack gap="$4" $gtMd={{ alignItems: 'center', flexDirection: 'row' }}>
          <Graphic />

          <Stack gap="$4" $gtMd={{ flex: 1 }}>
            <Markdown Text={ProductDetailContent.Text}>
              {intl.formatMessage({ id: 'product-details.investment-strategy.strategy-return-description' })}
            </Markdown>
          </Stack>
        </Stack>

        {investmentProductType === InvestmentProductType.VACATION_RENTAL_IPO && (
          <>
            <ProductDetailContent.Divider />
            <ProductDetailContent.SubHeader>
              <FormattedMessage id="product-details.investment-strategy.vacation-rental-j-curve-strategy.header" />
            </ProductDetailContent.SubHeader>

            <Markdown Text={ProductDetailContent.Text}>
              {intl.formatMessage({
                id: 'product-details.investment-strategy.vacation-rental-j-curve-strategy.content',
              })}
            </Markdown>

            <ValueText token="value.medium">
              <FormattedMessage id="product-details.investment-strategy.vacation-rental-j-curve-stages.header" />
            </ValueText>

            <Markdown Text={ProductDetailContent.Text}>
              {intl.formatMessage({ id: 'product-details.investment-strategy.vacation-rental-j-curve-stages.content' })}
            </Markdown>

            {uri && (
              <Image
                aspectRatio="1304/1013"
                alt={intl.formatMessage({ id: 'product-details.investment-strategy.vacation-rental-j-curve.alt-tag' })}
                source={{ uri }}
              />
            )}
          </>
        )}

        {offering.projectedAnnualDividendYield && (
          <>
            <ProductDetailContent.Divider />
            <ProductDetailContent.SubHeader Icon={RentalIncomeIcon}>
              <FormattedMessage id="product-details.investment-strategy.anticipated-annual-cash-flow" />
            </ProductDetailContent.SubHeader>
            <RentalIncomeSection offering={offering} />
          </>
        )}

        <ProductDetailContent.Divider />

        <ProductDetailContent.SubHeader Icon={AppreciationIcon}>
          <FormattedMessage id="product-details.investment-strategy.annual-appreciation" />
        </ProductDetailContent.SubHeader>

        <Stack gap="$6" $gtSm={{ flexDirection: 'row' }}>
          <Stack $gtSm={{ flexBasis: 0, flexGrow: 1 }}>
            <Markdown Text={ProductDetailContent.Text}>
              {intl.formatMessage({ id: 'product-details.annual-appreciation.part-1' })}
            </Markdown>
          </Stack>

          <Stack $gtSm={{ flexBasis: 0, flexGrow: 1 }}>
            <Markdown Text={ProductDetailContent.Text}>
              {intl.formatMessage({ id: 'product-details.annual-appreciation.part-2' })}
            </Markdown>
          </Stack>

          <Stack $gtSm={{ flexBasis: 0, flexGrow: 1 }}>
            <Markdown Text={ProductDetailContent.Text}>
              {intl.formatMessage({ id: 'product-details.annual-appreciation.part-3' })}
            </Markdown>
          </Stack>
        </Stack>
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};

import { CONFIG } from '@arrived/config';

import accountSettingsIntl from './account-settings.intl';
import blogIntl from './blog.intl';
import checkoutIntl from './checkout.intl';
import investIntl from './invest.intl';
import landingPageIntl from './landing-page.intl';
import navigationIntl from './navigation.intl';
import portfolioIntl from './portfolio.intl';
import productDetailsIntl from './product-details.intl';
import redemptionIntl from './redemption.intl';
import registerIntl from './register.intl';
import sheetsIntl from './sheets.intl';
import standardDisclaimerIntl from './standard-disclaimer.intl';
import webinarIntl from './webinar.intl';

export default {
  updated: 'Updated',
  loading: 'Loading',

  login: 'Login',
  logout: 'Logout',
  cancel: 'Cancel',
  unlock: 'Unlock',

  'validation.this.required': 'This field is required.',
  'validation.this.type': 'Please input a correct type and format.',
  'validation.required': '{field} is required',
  'validation.numbersOnly': 'Only numeric values are allowed.',
  'validation.mfa.exactCharacters': 'Must be exactly 6 characters.',

  'auth.mfa.title': '2-factor Authentication',
  'auth.mfa.description': 'Please provide your one time password provided by your authenticator application.',
  'auth.mfa.error.title': 'Problem Logging In',
  'auth.mfa.invalidCode.title': 'Problem Logging In',
  'auth.mfa.invalidCode.description': 'The provided code was not valid.',

  'admin.users.security.header': 'Security',
  'legal.important-information': 'Important Information',

  'admin.questionnaire-id': 'Questionnaire ID',
  'admin.questionnaire-create.error': 'Error creating questionnaire: {error}',
  'admin.questionnaire-create.success': 'Created questionnaire!',
  'admin.questionnaire-patch.error': 'Error updating questionnaire: {error}',
  'admin.questionnaire-patch.success': 'Updated questionnaire!',
  'admin.questionnaire-create.description':
    'The name of the questionnaire, this is not surfaced to the user and is primarily used for organization purposes.',
  'admin.question-create.error': 'Error creating question: {error}',
  'admin.question-create.success': 'Created question!',
  'admin.question-delete.error': 'Error deleting question: {error}',
  'admin.question-delete.success': 'Deleted question!',
  'admin.question-patch.error': 'Error updating question: {error}',
  'admin.question-patch.success': 'Updated question!',
  'admin.response-option-create.success': 'Created response option!',
  'admin.response-option-create.error': 'Error creating response option: {error}',
  'admin.response-option-patch.success': 'Updated response option!',
  'admin.response-option-patch.error': 'Error updating response option: {error}',
  'admin.question-create.text-description': 'The question you want to ask to the user.',
  'admin.question-create.description-description':
    'Description of the question to provide additional context to the user.',
  'admin.question-create.type-description':
    'The type of question, whether the user can select a single or multiple options. This cannot be edited after question creation.',
  'admin.response-option-create.type.title': 'Response Option Type',
  'admin.response-option-create.text.title': 'Response Option Text',
  'admin.response-option-create.description.title': 'Response Option Description',
  'admin.response-option-delete.error': 'Error deleting response option: {error}',
  'admin.response-option-delete.success': 'Deleted response option!',
  'admin.response-option-create.type.description':
    'The type of response, freeform means that the user will be provided with a text input to provide additional information regarding their response, selection means that no text input option will be provided to the user.',
  'admin.response-option-create.text.description':
    'The text of the option that the user can select as an answer to the question.',
  'admin.response-option-create.description.description': 'Some extra context for the response option.',
  'admin.question-response-option-order-patch.error': 'Error updating response option order: {error}',
  'admin.question-response-option-order-patch.success': 'Updated response option order!',
  'admin.questionnaire-question-order-patch.error': 'Error updating question order: {error}',
  'admin.questionnaire-question-order-patch.success': 'Updated question order!',
  'admin.property-photos.edit-order': 'Edit Photo Order',
  'admin.offering-history.manual-update-success': 'Offering History Event Updated',
  'admin.offering-history.error': 'Error updating Offering History',
  'admin.offering-history.property': 'Property',
  'admin.offering-history.date': 'Date',
  'admin.offering-history.title': 'Title',
  'admin.offering-history.description': 'Description',
  'admin.offering-history.price': 'Rent',
  'admin.offering-history.price.change': 'Rent to Proforma',
  'admin.offering-history.internal-notes': 'Internal Notes',
  'admin.offering-history.edit': 'Approve',
  'admin.offering-history.delete': 'Ignore',
  'admin.offering-history.manual-update-subtitle': 'Add a manual update to the offering history',
  'admin.offering-history.actions': 'Actions',
  'admin.user.redemptions.eligibility.subtitle': 'Eligibility per owned-offering',
  'admin.user.redemptions.elibitility.empty-state': 'No owned Fund offerings to redeem.',
  'admin.user.redemptions.elibitility.card.owned-shares': 'Owned Shares',
  'admin.user.redemptions.elibitility.card.eligible-shares': 'Eligible Shares',
  'admin.user.redemptions.elibitility.card.purchased-on': 'Oldest share purchased on',
  'admin.user.redemptions.elibitility.card.eligible-after': 'Eligible for redemption after',
  'admin.user.redemptions.elibitility.card.request-cutoff': 'Current period, request cutoff',
  'admin.user.redemptions.elibitility.card.payment-date': 'Current period, est. payment date',
  'admin.user.redemptions.table.subtitle': 'Individual Redemptions/Sales for their account',

  'auth.dontHaveAnAccount': "Don't have an account?",
  'auth.alreadyHaveAnAccount': 'Already have an account?',
  'auth.login': 'Login',
  'auth.login.error': 'Error logging in, please try again!',
  'auth.email': 'Email',
  'auth.email.error': 'Invalid email address!',
  'auth.email.error.required': 'Email address is required',
  'auth.password': 'Password',
  'auth.password.rules':
    'Must be at least 8 characters, and contain 3 of the following: <ul><li>Lowercase letters (a-z)</li><li>Uppercase letters (A-Z)</li><li>Numbers (0-9)</li><li>Special characters (!@#$%^&*)</li></ul>',
  'auth.password.error': 'Invalid password!',
  'auth.password.error.required': 'Password is required',
  'auth.forgot-password': 'Forgot Password?',
  'auth.reset-password': 'Reset Password',
  'auth.signup': 'Sign Up',
  'auth.signup.error': 'Error signing up, please try again!',
  'auth.signup.error.invalid': 'Invalid signup, please try different credentials.',
  'auth.portfolio.title': 'Login to access your portfolio',
  'auth.account.title': 'Login to access your account',

  'account.header': 'Account',
  'account.subheader.portfolio': 'Portfolio',
  'account.subheader.wallet': 'Wallet',
  'account.subheader.activity': 'Activity',
  'account.subheader.settings': 'Settings',
  'account.subheader.transactions': 'Transactions',
  'account.current-user.name': "{currentUser}'s avatar",
  'account.accredited-status': 'Accredited Status',
  'account.account-type': 'Account Type',
  'account.account-type.individual.description': 'Personal Account (Most Users)',
  'account.address': 'Address',
  'account.business-address': 'Business Address',
  'account.can-trade-reg-d-offerings': 'Can Trade Reg-D Offerings',
  'account.can-view-reg-d-offerings': 'Can View Reg-D Offerings',
  'account.country.country-code.label': 'Country Code',
  'account.entity-type': 'Entity Type',
  'account.entityTitle': 'Signatory Title',
  'account.entityTitle.required': 'Signatory Title is required',
  'account.entityTitle.length': 'Signatory Title must be 120 characters or less',
  'account.entityName': 'Entity Name',
  'account.entityName.required': 'Entity Name is required',
  'account.entityType.required': 'Entity Type is required',
  'account.ssn.invalid': 'Social security number must be 9 digits',
  'account.ssn.required': 'Social security number is required',
  'account.ein.invalid': 'Employer Identification Number must be 9 digits',
  'account.suitability-questionnaire-created-at': 'Suitability Questionnaire Created',
  'account.created-more-than-one-year-ago': 'Created more than 1 year ago',
  'account.created-less-than-three-months-ago': 'Created less than 3 months ago',
  'account.first-settled-trade-created-at': 'First Settled Trade Created',
  'account.phoneNumber.label': 'Phone Number',
  'account.phoneNumber.required': 'Phone number is required',
  'account.phoneNumber.invalid': 'Phone number must be in a valid format',
  'account.phone': 'Phone',
  'account.reg-d-check-exempt': 'Exempt from Reg-D Check',
  'account.reg-d-check-exempt.legal-only': 'Exempt from Reg-D Check (Legal team only)',
  'account.id': 'Account ID',
  'account.tax-id': 'Tax ID',
  'account.finish-setup': 'Finish account setup',
  'account.ill-do-this-later': "I'll do this later",
  'account.admin.block-audits.block-source': 'Block source: {blockSource}',
  'account.admin.block-audits.created': 'Created: {createdAt}',
  'account.admin.block-audits.empty': 'No account block history',
  'account.admin.block-audits.header': 'Account blocks history',
  'account.admin.block-audits.internal-notes': 'Manual update reason: {internalNotes}',
  'account.admin.block-audits.suspicious-behavior': 'Automated update reason: {susBehavior}',
  'account.admin.button.update-existing-blocks': 'Update blocks',
  'account.admin.button.view-block-history': 'View block history',
  'account.admin.depositing-blocked': 'Depositing blocked: {depositingBlocked}',
  'account.admin.depositing-blocked-label': 'Depositing blocked',
  'account.admin.external-account-update-blocked': 'External account updates blocked: {externalAccountUpdatesBlocked}',
  'account.admin.external-account-update-blocked-label': 'External account updates blocked',
  'account.admin.redemption-request-blocked': 'Redemption requests blocked: {redemptionRequestsBlocked}',
  'account.admin.redemption-request-blocked-label': 'Redemption requests blocked',
  'account.admin.trade-creation-blocked': 'Trade creation blocked: {tradeCreationBlocked}',
  'account.admin.trade-creation-blocked-label': 'Trade creation blocked',
  'account.admin.update-blocks-sheet.required': 'All fields required to update blocks',
  'account.admin.update-blocks-sheet.header': 'Update account blocks',
  'account.admin.update-blocks-sheet.internal-notes.label': 'Reason for update',
  'account.admin.update-blocks-sheet.button.cancel': 'Cancel',
  'account.admin.update-blocks-sheet.button.deselect-all': 'Deselect all',
  'account.admin.update-blocks-sheet.button.select-all': 'Select all',
  'account.admin.update-blocks-sheet.button.update': 'Update',
  'account.admin.withdrawing-blocked': 'Withdrawing blocked: {withdrawingBlocked}',
  'account.admin.withdrawing-blocked-label': 'Withdrawing blocked',
  'account.admin.counterparty.existing-lock.timestamp': 'Counterparty locked at: {timestamp}',
  'account.admin.counterparty.existing-lock.reason': 'Reason: {reason}',
  'account.admin.counterparty.existing-lock.notes': 'Notes: {notes}',
  'account.admin.counterparty.no-existing-lock': 'No existing counterparty lock for user',
  'account.admin.counterparty.error-fetching-counterparty-state':
    "Error fetching counterparty for user. Make sure you're on the VPN and have access to this.",
  'account.admin.counterparty.button.remove-existing-lock': 'Remove existing counterparty lock',
  'account.admin.counterparty.button.create-new-lock': 'Lock counterparty for user',
  'account.admin.counterparty.create-lock-sheet.header': 'Create counterparty lock',
  'account.admin.counterparty.create-lock-sheet.reason.label': 'Reason for lock',
  'account.admin.counterparty.create-lock-sheet.reason.placeholder': 'Select a reason',
  'account.admin.counterparty.create-lock-sheet.reason.required': 'Reason is required to create a lock',
  'account.admin.counterparty.create-lock-sheet.internal-notes.label': 'Additional internal notes',
  'account.admin.counterparty.create-lock-sheet.button.cancel': 'Cancel',
  'account.admin.counterparty.create-lock-sheet.button.create': 'Create',
  'account.admin.counterparty.create-lock.error': 'Failed to create lock: {error}',
  'account.admin.counterparty.create-lock.success': 'Counterparty lock created',

  'account.create.progress-bar.label': 'Account creation {number} percent complete',
  'account.create.accredited-investor-more-info':
    "The below questions will help us determine whether you qualify as an 'accredited investor' or whether you are an affiliated person with FINRA or any publicly traded company. Accredited investors may be subject to different investment limits than non-accredited investors.",
  'account.create.non-us-citizen-resident': 'Non U.S. Citizen or Green Card Holder',
  'account.create.citizenship-us-citizen': 'U.S. Citizen',
  'account.create.citizenship-us-green-card-holder': 'U.S. Green Card Holder',
  'account.create.error':
    'There was an error creating your account, please try again! If the problem persists, please contact support.',
  'account.citizenship.us.only':
    'Sorry, at this time, Arrived is only available to U.S. Citizens and Green Card holders.',

  'account.document-type.drivers-license': "Driver's License (Real ID Compliant)",
  'account.document-type.passport': 'Passport',
  'account.document-type.green-card': 'Green Card',
  'account.document-type.certificate-of-formation': 'Certificate of Formation',
  'account.document-type.id': 'ID',
  'account.document-type.bank-verification': 'Bank Verification',
  'account.document-type.other': 'Other',
  'account.document-upload': 'Upload a Document',
  'account.document-upload.document': 'Select document',
  'account.document-upload.document.required': 'File is required',
  'account.document-upload.document.clear-selection': 'Clear selected file',
  'account.document-upload.document.file-size-limit': 'Max file size is {fileSize}MB',
  'account.document-upload.documentType.label': 'What type of document are you uploading?',
  'account.document-upload.documentType': 'Document type',
  'account.document-upload.documentType.required': 'Document type is required',
  'account.document-upload.submit': 'Upload document',
  'account.document-upload.success': 'Document successfully uploaded!',
  'account.document-upload.error': 'Error uploading your document: {error}',
  'account.document-upload.no-file-selected': 'No files were selected',

  'admin.offering.description.help':
    "The Offering description supports Markdown syntax, quick reference: link = '[text](url)', bold = '**bolded text**', italics = '_italicized_'",

  'address.city.invalidChars': 'City includes invalid characters',
  'address.state.invalidChars': 'State/Province includes invalid characters',
  'address.zipCode.invalidFormat': 'Zip Code must be 5 digits or 9 digits (hyphenated)',

  'address.street.required': 'Street Address is required',
  'address.city.required': 'City is required',
  'address.state.required': 'State/Province is required',
  'address.zipCode.required': 'Zip Code is required',

  'user.display-name.required': 'Display name is required',
  'user.email.required': 'Email Address is required',
  'user.password.required': 'Password is required',
  'user.password.specialCharacter': 'Password requires a special character (!@#$%^&*)',
  'user.password.lowerCase': 'Password requires at least one lower case character (a-z)',
  'user.password.upperCase': 'Password requires at least one upper case character (A-Z)',
  'user.password.number': 'Password requires at least one numeric character (0-9)',
  'user.password.length': 'Password must be at least 8 characters in length',
  'user.firstName.required': 'First Name is required',
  'user.lastName.required': 'Last Name is required',
  'user.plannedInvestmentAmount.required': 'Please select a planned investment amount',
  'user.referrerSource.required': 'Please select a referrer source',
  'user.tosPrivacy.required': 'Please read and agree to our terms of service',

  'emailList.subscription.schema.error': 'Email address not formatted correctly',

  'common.all-properties': 'All Properties',
  'common.about': 'About',
  'common.about.arrived': 'About Arrived',
  'common.account': 'Account',
  'common.add': 'Add',
  'common.airbnb-listing': 'Airbnb Listing',
  'common.arrived': 'Arrived',
  'common.admin-only': 'Admin Only',
  'common.all': 'All',
  'common.allocation': 'Allocation',
  'common.all-locations': 'All Locations',
  'common.all-locations-count': 'All ({count}) Locations',
  'common.almost-gone': 'Almost Gone',
  'common.annual-appreciation': 'Annual Appreciation',
  'common.annual-cashflow': 'Annual Cash Flow',
  'common.annual-rent': 'Annual Rent',
  'common.annualized-yield': 'Annualized Yield',
  'common.anticipated-annual-cashflow': 'Anticipated Annual Cash Flow',
  'common.appreciation': 'Appreciation',
  'common.asset.type': 'Asset Type',
  'common.archetype': 'Archetype',
  'common.availability': 'Availability',
  'common.available.remaining-investment-amount': 'Remaining Investment Amount',
  'common.available': 'Available',
  'common.available-on': 'Available on {ipoDate}',
  'common.average.label': 'Average: {value}',
  'common.average-rate.label': 'Average Rate: {value}',
  'common.awaiting-valuation': 'Awaiting Valuation',
  'common.enabled': 'Enabled',
  'common.back': 'Back',
  'common.search': 'Search',
  'common.back-to-properties': 'Back to all investments',
  'common.bank-account': 'Bank Account',
  'common.beneficiary': 'Beneficiary',
  'common.blog': 'Blog',
  'common.bonds': 'Bonds',
  'common.browse-investments': 'Browse Investments',
  'common.browse-homes': 'Browse Homes',
  'common.browse-other-investments': 'Browse Other Investments',
  'common.cancel': 'Cancel',
  'common.cancelled.refunded.transactions': 'Cancelled & Refunded Transactions',
  'common.contact-us': 'Contact Us',
  'common.city': 'City',
  'common.chat-with-support': 'Chat with support',
  'common.clear': 'Clear',
  'common.close': 'Close',
  'common.closed': 'Closed',
  'common.coming-soon': 'Coming Soon',
  'common.completed.transactions': 'Completed Transactions',
  'common.confirm': 'Confirm',
  'common.continue': 'Continue',
  'common.continue-review': 'Continue to Review',
  'common.copied-to-clipboard': 'Copied to clipboard!',
  'common.create-new': 'Create New',
  'common.current-leverage': 'Current Leverage',
  'common.date': 'Date',
  'common.description': 'Description',
  'common.details': 'Details',
  'common.diversification': 'Diversification',
  'common.documents': 'Documents',
  'common.download.csv': 'Download CSV',
  'common.easily-invest': 'Easily invest in rental homes',
  'common.edit': 'Edit',
  'common.email': 'Email',
  'common.email.placeholder': 'Your email',
  'common.email.verification': 'Email Verification',
  'common.entity': 'Entity',
  'common.entity.description': 'LLC, Trust or Corporation',
  'common.equity': 'Equity',
  'common.filter': 'Filter',
  'common.filters': 'Filters',
  'common.filters.clear': 'Clear Filters',
  'common.financials': 'Financials',
  'common.first-name': 'First Name',
  'common.flexible.future.financing': 'Strategic Refinance Candidate',
  'common.for.sale': 'For Sale',
  'common.fund': 'Fund',
  'common.funds': 'Funds',
  'common.funded': 'Funded',
  'common.fully.funded': 'Fully Funded',
  'common.fully.funded.time': 'Funded {timePeriodSinceLastTransaction}',
  'common.get-started': 'Get Started',
  'common.gold': 'Gold',
  'common.gross-yield': 'Gross Yield',
  'common.grow-your-portfolio': 'Grow your real estate portfolio',
  'common.help-and-faq': 'Help & FAQ',
  'common.historical-data': 'Historical Data',
  'common.historical.returns': 'Historical Returns',
  'common.hold-period': 'Hold Period',
  'common.how.it.works': 'How it Works',
  'common.hypothetical-return': 'Hypothetical Return: {returnAmount}',
  'common.identity.verified': 'Identity Verified',
  'common.incomplete.transactions': 'Incomplete Transactions',
  'common.individual': 'Individual',
  'common.invest': 'Invest',
  'common.invested': 'Invested',
  'common.invest.now': 'Invest Now',
  'common.investors': 'Investors',
  'common.investment.strategy': 'Investment Strategy',
  'common.investment-account': 'Investment Account',
  'common.investment-amount': 'Investment Amount',
  'common.investor.count': '{count} Investors',
  'common.last-name': 'Last Name',
  'common.latest-updates': 'Latest Updates',
  'common.learn': 'Learn',
  'common.learn-more': 'Learn more',
  'common.lets-do-it': "Let's do it!",
  'common.leverage': 'Leverage',
  'common.leveraged': 'Leveraged',
  'common.levered': 'Levered',
  'common.link': 'Link',
  'common.load-more': 'Load More',
  'common.location': 'Location',
  'common.log-in': 'Log In',
  'common.maxed.out': 'Maxed Out',
  'common.max-investment': '{maxInvestmentAmount} Max',
  'common.max.label': 'Max: {value}',
  'common.maybe-later': 'Maybe Later',
  'common.min.label': 'Min: {value}',
  'common.missing.label': 'Missing',
  'common.most.popular': 'Most Popular',
  'common.name': 'Name',
  'common.new': 'New',
  'common.new-property': 'New Property',
  'common.new-properties': 'New Properties',
  'common.net-worth': 'Net Worth',
  'common.next': 'Next',
  'common.next-page': 'Next Page',
  'common.none': 'None',
  'common.no-more-results': 'No more results',
  'common.no.tax-documents': 'No Tax Documents',
  'common.not.approved': 'Not Approved',
  'common.not-applicable': 'N/A',
  'common.note': 'Note',
  'common.notes': 'Notes',
  'common.not-owned': 'Not Owned',
  'common.notify.me': 'Notify Me',
  'common.notify.me.prompt.title': 'Log in or sign up to receive new property notifications',
  'common.notify.me.prompt.description':
    "You're one step away from getting notified when new properties are available.",
  'common.notify.me.prompt.cta': 'Log in or Sign up',
  'common.notify.me.prompt.not-now': 'NOT NOW',
  'common.occupancy.label': 'Occupancy: {value}',
  'common.open': 'Open',
  'common.owned': 'Owned',
  'common.owner-since': 'Owner Since',
  'common.ownership': 'Ownership',
  'common.other-questions': 'Other Questions?',
  'common.paused': 'Paused',
  'common.processing': 'Processing',
  'common.pending.verification': 'Pending Verification',
  'common.pending': 'Pending',
  'common.percentage': 'Percentage',
  'common.performance': 'Performance',
  'common.per-year': 'Per Year',
  'common.phone-number': 'Phone Number',
  'common.phone-number.optional': 'Phone Number (optional)',
  'common.postal-code': 'Postal Code',
  'common.portfolio': 'Portfolio',
  'common.press': 'Press',
  'common.press.enter': 'Press Enter',
  'common.previous': 'Previous',
  'common.previous-page': 'Previous Page',
  'common.privacy.policy': 'Privacy Policy',
  'common.private-credit': 'Debt Fund',
  'common.property.equity': 'Property Equity',
  'common.property.equity.volatility': 'Property Equity Volatility',
  'common.property.photo.alt-text': 'Photo of {propertyName}',
  'common.question': 'Question',
  'common.question-edit': 'Edit Question',
  'common.question-type': 'Question Type',
  'common.questions': 'Questions',
  'common.questionnaires': 'Questionnaires',
  'common.questionnaires-edit': 'Edit Questionnaire',
  'common.raise-amount': 'Raise Amount',
  'common.read-more': 'Read more',
  'common.refer-friend': 'Refer a Friend',
  'common.register': 'Register',
  'common.rental-status': 'Rental Status',
  'common.rental-type': 'Rental Type',
  'common.rental-dividend-yield-per-year': 'Rental Dividend Yield / Year',
  'common.retirement': 'Retirement',
  'common.retirement.description': 'Checkbook IRA',
  'common.historic-annual-appreciation': 'Historic Annual Appreciation',
  'common.real-estate': 'Real Estate',
  'common.relative.volatility': 'Relative Volatility',
  'common.remove': 'Remove',
  'common.rent-status': 'Rent Status',
  'common.rented': 'Rented',
  'common.reset-order': 'Reset Order',
  'common.response-options': 'Response Options',
  'common.response-type': 'Response Type',
  'common.returns': 'Returns',
  'common.rental-data': 'Rental Data',
  'common.return-range': '{lower} to {upper}',
  'common.returns-calculator': 'Investment Strategy Returns Calculator',
  'common.revenue.label': 'Revenue: {value}',
  'common.save': 'Save',
  'common.save-order': 'Save Order',
  'common.seeking-tenant': 'Seeking Tenant',
  'common.select-document-type': 'Select Document Type',
  'common.share': 'Share',
  'common.shares': 'Shares',
  'common.short-term-note': 'Short Term Note',
  'common.show-results': 'Show Results',
  'common.show-x-results': 'Show {count} results',
  'common.skip': 'Skip',
  'common.skip-for-now': 'Skip for now',
  'common.skip-this-for-now': 'Skip This for Now',
  'common.single-family': 'Single Family',
  'common.single.family.residential': 'Single Family Residential',
  'common.sold-out': 'Sold Out',
  'common.ssn': 'Social Security Number',
  'common.ssn-and-ein': 'Social Security Number and Employer Identification Number',
  'common.ssn-and-ein.optional': 'Social Security Number and Employer Identification Number (if applicable)',
  'common.ein': 'Employer Identification Number',
  'common.ssn-label': 'Social Security Number (SSN)',
  'common.ein-label': 'Employer Identification Number (EIN)',
  'common.ssn-invalid': 'Social Security Number must be 9 digits',
  'common.ssn-required': 'Social Security Number is required',
  'common.ein-invalid': 'Employer Identification Number must be 9 digits',
  'common.ein-required': 'Employer Identification Number is required',
  'common.stakeholder.commitments': 'Stakeholder Commitments',
  'common.stocks': 'Stocks',
  'common.submit': 'Submit',
  'common.target-investment-period': 'Target Investment Period',
  'common.target-investment-period.range': '{lower}-{upper} Years',
  'common.tax.year': 'Tax Year',
  'common.terms.of.service': 'Terms of Service',
  'common.time': 'Time',
  'common.topic': 'Topic',
  'common.total-allocation': 'Total Allocation',
  'common.transactions': 'Transactions',
  'common.trending': 'Trending',
  'common.type': 'Type',
  'common.unknown': 'Unknown',
  'common.unlevered': 'Unlevered',
  'common.update': 'Update',
  'common.upload-documents': 'Upload Documents',
  'common.variable': 'Variable',
  'common.vacation': 'Vacation',
  'common.vacation.rental': 'Vacation Rental',
  'common.verified': 'Verified',
  'common.without-leverage': 'Without Leverage',
  'common.with-leverage': 'With Leverage',
  'common.years': '{range} years',
  'common.year-count': '{range, plural, =1 {# Year} other {# Years}}',
  'common.yield': 'Yield',
  'tooltip.anti-money-laundering.label': 'Anti-money Laundering',
  'tooltip.anti-money-laundering.description':
    'Anti-money laundering (AML) refers to the laws, regulations and procedures intended to prevent criminals from disguising illegally obtained funds as legitimate income. Though anti-money laundering laws cover a limited range of transactions and criminal behavior, their implications are far-reaching. For example, AML regulations require banks and other financial institutions that issue credit or accept customer deposits to follow rules that ensure they are not aiding money-laundering.',
  'tooltip.know-your-customer.title': 'Know Your Customer',
  'tooltip.know-your-customer.description':
    'The Know Your Customer or KYC process is a required standard that financial services verify their clients are genuinely who they claim to be and assess their suitability.{br}{br}The KYC check is the mandatory process of identifying and verifying the identity of a client when opening an account, assessing their suitability, and risks involved with creating a business relationship. These processes are part of broader anti-money laundering compliance (AML) and help prevent and identify money laundering, terrorism financing, and other illegal corruption schemes.',
  'tooltip.number-of-investors': 'The number of people investing together in this specific property.',
  'tooltip.monthly-rent': 'The gross monthly rent for the property.',
  'tooltip.purchase-price':
    'The price paid to acquire the property. This does not include the offering fees or cash reserve.',
  'tooltip.tax-id-more-info':
    'We use your tax IDs to verify your identity and for creating your tax documents. We do not run credit checks using your information.',
  'tooltip.flexible-financing-more-info':
    'This property may seek to add up to 70% in leverage through a strategic refinancing. <a>Learn More</a>.',
  'tooltip.flexible-financing-more-info.with-financing-percent':
    'The percent of the purchase price that is funded by the loan. This property may seek to add up to 70% in leverage through a strategic refinancing.',
  'tooltip.mission-statement-citation':
    'The Rate of Return on Everything, 1870–2015 ({documentLink}){br}{br} The first key finding is that residential real estate, not equity, has been the best long-run investment over the course of modern history. Although returns on housing and equities are similar, the volatility of housing returns is substantially lower, as Table II shows. Returns on the two asset classes are in the same ballpark—around 7%—but the standard deviation of housing returns is substantially smaller than that of equities (10% for housing versus 22% for equities). Predictably, with thinner tails, the compounded return (using the geometric average) is vastly better for housing than for equities—6.6% for housing versus 4.7% for equities. This finding appears to contradict one of the basic tenets of modern valuation models: higher risks should come with higher rewards.',
  'tooltip.signatory-title':
    'This is the title of the person authorized to sign for your <b>{accountType}</b> account.',
  'tooltip.reapply-promotion-redemption':
    'Undo a canceled redemption. Only use if a trade is not canceled and the redemption is canceled.',
  'tooltip.cancel-promotion-redemption':
    'Cancel this redemption. Only use if the trade is also canceled and the redemption is applied.',
  'tooltip.asset-management-fee':
    'The Asset Management fee is a quarterly fee paid to Arrived out of the rental income, and helps cover the preparation of  tax forms for investors, the distribution of dividends to all investors, procuring insurance policies and filing claims when applicable, ensuring property taxes and loan payments are paid, overseeing financial accounting for properties, and overseeing the third party property manager (competitive analysis of rental rate, review of property improvements, rehab & turn work, and expense management & approval).',
  'tooltip.property-management-fee':
    'Property Management Fees are paid monthly to the property management partner out of the rental income, and helps cover the operational expenses of the rental unit. Arrived negotiates competitive rates with our property management partners for each offering individually.',
  'tooltip.estimated-rent-ready-date':
    'The estimated launch date when this property is expected to begin accepting bookings from guests.',
  'tooltip.hold-period.long-term-rental':
    'Each property has a unique anticipated hold period, but we typically estimate a timeline of 5-7 years for long term rentals. During the hold period, we intend to distribute any Free Cash Flow in the form of dividend payments.{br}{br}The determination of when to sell a property is made based on a series of relevant factors, including the prevailing and projected economic conditions, whether the property is anticipated to appreciate or decline substantially, and how any existing lease may impact the sales price we may receive. As a result, we may decide that it’s in the best interest of shareholders to sell a property earlier than five years or to hold a property for more than seven years.',
  'tooltip.hold-period.short-term-rental':
    'Each property has a unique anticipated hold period, but we typically estimate a timeline of 5-15 for vacation rentals. During the hold period, we intend to distribute any Free Cash Flow in the form of dividend payments.{br}{br}The determination of when to sell a property is made based on a series of relevant factors, including the prevailing and projected economic conditions, whether the property is anticipated to appreciate or decline substantially, and how any existing lease may impact the sales price we may receive. As a result, we may decide that it’s in the best interest of shareholders to sell a property earlier than five years or to hold a property for more than seven years.',
  'tooltip.cash-reserves':
    'The amount of money set aside for improvements to the property and a cash reserve balance for supporting long-term operations.',
  'tooltip.closing-offering-holding-costs':
    'These expenses include the costs associated with closing on the property, offering the shares for investment, and financing the property alongside or after closing.',
  'tooltip.arrived-sourcing-fee':
    'This is the fee Arrived charges for our work involved with sourcing the property and preparing it for investment. It’s a one-time fee that is already included in the Equity Raise from Investors and share price.',
  'tooltip.offering-share-price': 'The price per share at the first time of sale to investors.',
  'tooltip.offering-share-number': 'The total number of shares being offered to investors.',
  'tooltip.property-loan-amount':
    'The amount of the Total Cost funded by a long-term mortgage loan. Mortgages are placed on the property to provide investors with leverage.',
  'tooltip.property-total-amount': 'The total costs for the property, improvements, cash reserve, and fees.',
  'tooltip.equity-from-investors':
    'The amount of capital being contributed by investors. It is equal to the total cost minus the loan amount.',
  'tooltip.financing-percent': 'The percent of the purchase price that is funded by the loan.',
  'tooltip.interest-rate':
    'The interest rate for the Home Loan used to acquire the property. The loan is non-recourse, which means investors are not liable for the debt. The interest expense gets paid out of rental income from the property.',
  'tooltip.property-leverage':
    'Leverage uses borrowed capital or debt to increase the potential return of an investment. Leverage works to your advantage when real estate values rise, but it can also lead to losses if values decline.',
  'tooltip.relative-volatility':
    'Investment volatility refers to the range of values that a particular asset might move between. As an example, higher volatility might mean higher potential movements up or down in the value of the investment over time.',
  'tooltip.appreciation-historical-average':
    'This is the estimated historical average annual property appreciation in the {marketName} market for the last 10 & 20 years. The data is provided by independent data providers, including HouseCanary or Zillow.',
  'tooltip.rent-historical-average':
    'The average annualized dividend yield paid by Arrived properties in the {marketName} market since Arrived has offered properties in the market.',
  'tooltip.current-rent':
    'The current dividend yield is the return on investment based on either the first dividend payment for the property or the most recent dividend payment if there have been more than one. The dividend percentage is calculated by dividing the dividend payment by the raise amount and annualizing that figure.',
  'tooltip.rent-info-dividend-yield':
    "The annualized dividend is the return on investment based on the first dividend payment. It's calculated by dividing the first dividend payment by the raise amount and annualizing that figure.",
  'tooltip.calculator-property-appreciation':
    'This is the estimated growth of investor share value after 1 year at the corresponding property appreciation selected. The calculation takes into account the prorated real estate investment costs (Closing Costs, Offering Costs, Holding Costs & the Arrived Sourcing Fee). The calculation factors in leverage of {leverage} on this property. For short term rentals, Property Improvements & Cash Reserves are excluded from this calculation.',
  'tooltip.appreciation-returns-description':
    "We use House Canary, Redfin, and CoreLogic data to estimate the share value of the properties you've invested in. These are unrealized gains.",
  'tooltip.equity-description':
    'Equity is the total value of all shares owned at the current share price. Share prices are determined by taking the current property value estimate and factoring in the LLC’s assets and liabilities, such as cash reserves and loan balance. Reduction in cash reserves from starting operations and servicing loans can negatively impact the share valuation initially. We also take into account the pro-rated upfront fees involved in the investment, including the Closing, Offering, Holding costs, & Arrived sourcing fee. The Share Price value may go up or down from quarter to quarter, and the actual investment returns will largely depend on the property’s eventual sale price at the end of the investment period. {br}{br} Learn more about how share prices are calculated <learnLink>in our Learn blog</learnLink>.',
  'tooltip.historic-annual-appreciation-description':
    'This range was calculated from the nationwide 20-year compound annual growth rates calculated using the Zillow Home Value Index.',

  'profile.items.header.aria-label': 'Account settings and info for {displayName}',
  'profile.items.basic-account-info': 'Basic Account Info',
  'profile.items.investmentAccount': 'Investment Account',
  'profile.items.linkedBankAccount': 'Linked Bank Account',
  'profile.items.redeemCode': 'Redeem Referral Code',
  'profile.items.inviteFriend': 'Refer a Friend',
  'profile.items.transactions': 'Transactions',
  'profile.items.documents': 'Documents',
  'profile.items.security': 'Security',
  'profile.items.support': 'Support',
  'profile.items.about': 'About',
  'profile.items.admin': 'Admin',
  'profile.items.logout': 'Logout',
  'profile.items.documents.close-document-viewer': 'Close document viewer',

  'profile.account.title': 'Account Information',

  'profile.account.name.title': 'Account Information - Name',

  'profile.account.referrals.share': 'Share',
  'profile.account.referrals.share.success': 'Thanks for supporting Arrived 💙',
  'profile.account.referrals.share.error': 'Something went wrong. Please try again.',
  'profile.account.referrals.share.title': 'Arrived - Give {referralAmount}, Get {referralAmount}! 💙',
  'profile.account.referrals.share.description':
    'Check out Arrived! You will get {referralAmount} towards your first investment. 🎉',

  'profile.account.referrals.anonymous-title': 'User',

  'profile.account.referrals.clipboard.success': 'Copied referral link to the clipboard!',

  'profile.account.referrals.disclaimer': 'Your referral vouchers can be applied at checkout.',

  'profile.account.referrals.your-balance': 'Your Balance',
  'profile.account.referrals.total-redeemed-description':
    "You've earned a total of {referralEarnings} from{br}<green>{referralCount}</green> {referralCount, plural, one {referral} other {referrals}} 👏",

  'profile.account.referrals.step-1.title': 'Send invitation',
  'profile.account.referrals.step-2.title': 'Get your friend to connect',
  'profile.account.referrals.step-3.title': 'Get {referralAmount} of free shares',

  'profile.account.referrals.step-1.body':
    'Send your referral link to your friends and let them know how cool Arrived is!',
  'profile.account.referrals.step-2.body':
    'Get your friends to create an Arrived account and connect their bank account.',
  'profile.account.referrals.step-3.body':
    'Once your friend connects their bank account, you will get $50 towards your first Arrived investment.',

  'profile.account.referrals.title': 'Give {referralAmount}, Get {referralAmount}!',
  'profile.account.referrals.subtitle':
    'Once your friend connects their bank account, you will get {referralAmount} towards your first Arrived investment.',
  'profile.account.referrals.error':
    'We seem to have a proble loading your referral code, please reach out to our team to resolve this issue!',
  'profile.account.referrals.error.button': 'Contact Support',

  'profile.account.referrals.redeem.terms': 'Terms and conditions apply',
  'profile.account.referrals.redeem.defaultSuccess': 'Code redeemed successfully!',
  'profile.account.referrals.redeem.inputReferralCode': 'Input referral code to redeem',
  'profile.account.referrals.redeem.redeemError': 'An error occurred: {error}',
  'profile.account.referrals.redeem.invalidCode': 'Invalid Code',
  'profile.account.referrals.redeem.tradeAlreadyPlaced':
    'Trade already placed. You are ineligible to redeem referral code',
  'profile.account.referrals.redeem.successLinkBank': 'Code redeemed! Link your bank account to get rewarded.',
  'profile.account.referrals.redeem.alreadyRedeemed': 'Already redeemed code. Link your bank account to claim reward',
  'profile.account.referrals.redeem.alreadyClaimedReward': "You've already claimed your referral reward",
  'profile.account.referrals.earn.connectedBank': 'Connected bank on {date}',

  'profile.account.net-worth.update': 'Update Net Worth',
  'profile.account.net-worth.update.failure': 'Failed to update net worth: {error}',
  'profile.account.net-worth.update.success': 'Net worth updated!',

  'profile.account.beneficiaries.title': 'Beneficiaries',
  'profile.account.beneficiaries.your-beneficiaries.title': 'Your Beneficiaries',
  'profile.account.beneficiaries.relationship.label': 'Relationship',
  'profile.account.beneficiaries.relationship.error.required': 'Relationship is required',
  'profile.account.beneficiaries.phone-number.helper-text':
    'Your phone number will be auto-formatted to (XXX) XXX-XXXX',
  'profile.account.beneficiaries.empty.description': 'You do not have any beneficiaries added, please add one below.',
  'profile.account.beneficiaries.add.title': 'Add a Beneficiary',
  'profile.account.beneficiaries.edit.title': 'Edit Beneficiary',
  'profile.account.beneficiaries.allocation.title': 'Update Allocation',
  'profile.account.beneficiaries.allocation.subtitle': 'Adjust Allocation',
  'profile.account.beneficiaries.allocation.description':
    'The allocation determines how much of your investment will go to each beneficiary. The total allocation must add up to 100%.',
  'profile.account.beneficiaries.allocation.error.required': 'Allocation is required',
  'profile.account.beneficiaries.allocation.error.not-100-percent': 'Allocation must add up to 100%',
  'profile.account.beneficiaries.success.message': 'Beneficiaries have been updated!',
  'profile.account.beneficiaries.error.update-failed.message': 'Failed to update beneficiary: {error}',

  'referrals.termsAndConditions.heading': 'Referral Program Terms and Conditions',
  'referrals.termsAndConditions.p1':
    'The Arrived Referral Program (“Program”) is subject to certain Terms and Conditions. To participate, an existing customer (“Referrer”) must invite a new customer (“Invitee”). The Referrer is not required to be a current investor in any issuer (each, an “Arrived Issuer”) managed by Arrived Holdings, Inc. (“us” or “we”), and must have a linked bank account on the Arrived platform.',
  'referrals.termsAndConditions.p2':
    'The Referrer may invite an Invitee by giving the Invitee a special URL, or website link (the “link”). The Invitee must click the link and follow the subsequent instructions to validate and activate their own Arrived account and must further pass all Know Your Customer and Anti-Money Laundering checks conducted by Arrived and verify and link their bank account (the, “verification procedures”). We will credit both the Referrer’s and Invitee’s account with a restricted credit in an amount in effect at the time of completion of the verification procedures within 30 days after their completion, such amount subject to change at any time in the sole discretion of Arrived. The link may not be posted on public forums, including, but not limited to, any form of social media or online bulletin boards. Additionally, the referrer may not offer any form of compensation to any Invitee in exchange for Invitee’s completion of the verification procedures. Any violation of these restrictions may result in rescission of the restricted credit at the sole discretion of Arrived and such rescission is not subject to review or appeal. Further Arrived reserves the right to withhold or deduct restricted credits obtained through the Program in the event that Arrived determines or believes that the receipt of the restricted credit was in error, fraudulent, illegal, or in violation of any restrictions or any other applicable agreement between you and Arrived.',
  'referrals.termsAndConditions.p3':
    'The restricted credit can only be used towards the subscription of any Arrived Issuer investment. Only one restricted credit may be used per subscription. The restricted credit cannot be withdrawn as cash. If the restricted credit is not used within one year of issuance, it will expire and be removed from your Arrived account. Participation in this program may result in the receipt of taxable income for both the Referrer and the Invitee, and we may be required to send both parties, and file with the IRS, a Form 1099-MISC (miscellaneous income). Both Referrer and Invitee are responsible for any tax liability, including disclosure requirements, related to participating in the Program. Please consult a tax advisor if you have any questions about your personal tax situation. This offer is subject to change and may be canceled at any time without notice. In cases where we have a reasonable belief of fraud, we reserve the right to rescind any restricted credits that may have been given, at any time.',

  'profile.security.title': 'Security',
  'profile.security.items.email': 'Email',
  'profile.security.items.changePassword': 'Password',
  'profile.security.items.changePassword.button': 'Change Password',
  'profile.security.items.changePassword.button.copy-success': 'Password reset link has been copied to your clipboard!',
  'profile.security.items.mfa': '2-Step Verification',
  'profile.security.items.mfa.tooltip-content':
    '2-Step verification is an enhanced security measure. Once enabled, you’ll be required to give two types of identification when you log into Arrived.',
  'profile.security.items.mfa.enrolledSecondary': '2-Step Verification: On',
  'profile.security.items.mfa.notEnrolledSecondary': '2-Step Verification: Off',
  'profile.security.items.mfa.disable': 'Disable',
  'profile.security.items.mfa.disableSuccess': 'MFA has been successfully disabled',
  'profile.security.items.mfa.disableError': 'There was an issue with disabling MFA',
  'profile.security.items.mfa.enrollCounter': 'Please wait {counter} seconds before sending another enrollment email',
  'profile.security.items.mfa.resend': 'Resend email',
  'profile.security.items.mfa.enroll': 'Get started',
  'profile.security.items.mfa.enroll-modal.title': 'Boost your Arrived account security',
  'profile.security.items.mfa.enroll-modal.description.1':
    'To enhance the security of your Arrived account, you will now be required to enter both your password and a verification code generated by an authentication app, SMS or email, every time you sign in.',
  'profile.security.items.mfa.enroll-modal.description.2':
    'This additional layer of security will prevent unauthorized access to your account, even if someone manages to obtain your password.',
  'profile.security.items.mfa.enroll-modal.button': 'Enable',
  'profile.security.items.mfa.enroll-modal.button.learn-more': 'Learn more',
  'profile.security.items.mfa.enroll-modal.success': 'Email Submitted',
  'profile.security.items.mfa.enroll-modal.success.description':
    'We have sent you an email to enable 2-step verification. It may take a few minutes to appear in your inbox.',
  'profile.security.items.mfa.enroll-modal.error': 'We encountered an issue sending your MFA enrollment email',
  'profile.security.items.mfa.enroll-modal.blocked-counter':
    'Please wait {counter} seconds before sending another enrollment email',

  'profile.account.verification.status.verified': 'Verified',
  'profile.account.verification.status.incomplete': 'Needs Verification',
  'profile.account.verification.status.error': 'Issue with verification',
  'profile.account.name.label': 'Name',
  'profile.account.email.label': 'Email',
  'profile.account.email.resendButton': 'Resend Email',
  'profile.account.email.resendButton.counter': 'Resend Email ({counter}s)',
  'profile.account.email.toggleVerification': 'Toggle Verification',
  'profile.account.email.verifiedNotification.true': '{email} is now verified',
  'profile.account.email.verifiedNotification.false': '{email} is now not verified',
  'profile.account.email.verifiedNotification.failed': 'Failed to update verification status {email}',
  'profile.account.email.verification.description': 'Please verify your email address.',

  'webinarComponent.title.question': 'Questions?',
  'webinarComponent.title.help': "We're Here to Help",
  'webinarComponent.description':
    '{personListText}, will be hosting webinars to talk about how to get started with rental property investing.',
  'webinarComponent.time': 'Live sessions are held each week on {time}.',
  'webinarComponent.register': 'Register for a FREE webinar',
  'webinarComponent.privateMeeting': 'Prefer a 1-1 meeting? Click here to schedule some time with us.',

  'leadReferralList.news-or-press': 'News or Press',
  'leadReferralList.friend-or-collegues': 'Friends or Colleagues',
  'leadReferralList.email-newsletter': 'Email Newsletter',
  'leadReferralList.facebook-or-instagram': 'Facebook or Instagram',
  'leadReferralList.linkedin': 'LinkedIn',
  'leadReferralList.twitter': 'Twitter',
  'leadReferralList.quora': 'Quora',
  'leadReferralList.youtube': 'Youtube',
  'leadReferralList.podcast': 'Podcast',
  'leadReferralList.tiktok': 'TikTok',
  'leadReferralList.other': 'Other',
  'leadReferralList.newsletter': 'Newsletter',
  'leadReferralList.podcast-spotify-radio': 'Podcast / Spotify / Radio',
  'leadReferralList.facebook-instagram': 'Facebook / Instagram',
  'leadReferralList.benzinga': 'Benzinga',
  'leadReferralList.moneywise': 'Moneywise',
  'leadReferralList.investopedia': 'Investopedia',
  'leadReferralList.biggest-pockets': 'Biggest Pockets',
  'leadReferralList.yahoo-news': 'Yahoo News',
  'leadReferralList.search-engines': 'Search Engine (Google, Bing, etc...)',
  'leadReferralList.friends-family': 'Friends/Family',

  'emailVerificationComponent.title': 'A Verification Email Was Sent',
  'emailVerificationComponent.description':
    'Help us keep your account safe! We sent an email to {email} with a link to verify your account.',
  'emailVerificationComponent.linkExpires': 'The link expires in 24 hours.',
  'emailVerificationComponent.resend': "Didn't receive an email? <resend>Resend email</resend>.",
  'emailVerificationComponent.resend.success': 'Email verification email sent! Please check your email shortly.',
  'emailVerificationComponent.resend.failed': 'Failed to resend email verification email.',
  'emailVerificationComponent.blocked':
    'An email has been sent! {br} You can send another email in {timeInSeconds} seconds.',

  'verificationBanner.unauthenticated': 'View Only. Please Log In or Sign Up to start investing!',

  'verification-banner.main.label': 'Account verification banner',
  'verificationBanner.progress.ready.title': 'Get ready to invest',
  'verificationBanner.progress.ready.description': 'Check off all four steps to invest in your first Arrived property.',
  'verificationBanner.progress.halfway.title': 'Halfway ready to invest',
  'verificationBanner.progress.halfway.description':
    "We're still in the process of verifying your identity. Check your status in 3-5 business days.",
  'verificationBanner.progress.almost.title': 'Almost ready to invest',
  'verificationBanner.progress.almost.description':
    "We're still in the process of verifying your bank account. Check your status in 2-3 business days.",
  'verificationBanner.progress.done.title': "You're ready to invest",
  'verificationBanner.progress.done.description':
    'Congratulations! You are now ready to invest in your first property!',
  'verificationBanner.progress.error.title': "We've hit a wall",
  'verificationBanner.progress.error.description':
    'We were unable to verify your identity, one of our team members will reach out to you for further instructions.',

  'verificationBanner.email': 'Verify your email address',
  'verificationBanner.bank': 'Link your bank account',
  'verificationBanner.verify-bank': 'Verify your bank account',
  'verificationBanner.investmentAccount': 'Create an investment account',
  'verificationBanner.identity': 'Identity verification',
  'verificationBanner.identity.documents': 'Upload verification documents',
  'verificationBanner.identity.ssn': 'Verify your SSN',
  'verificationBanner.identity.error': 'Unable to verify your identity',
  'verificationBanner.identify.error-header': "We've hit a wall",

  'verificationBanner.email.banner.incomplete': 'A verification email has been sent to {email}',

  'verificationBanner.investmentAccount.banner.incomplete':
    'Create an investment account and link your bank to invest in properties.',

  'verificationBanner.identity.banner.bankPending':
    "We're still in the process of reviewing your documents and verifying your bank account. Check your status in 2-3 business days.",

  'verificationBanner.identity.banner.error':
    'We were unable to verify your identity, one of our team members will reach out to you for further instructions.',
  'verificationBanner.identity.banner.error.upload':
    'We were unable to verify your identity, please upload some identifying documentation.',
  'verificationBanner.identity.banner.error.ssn': 'There was an issue verifying your identity, please verify your SSN.',
  'verificationBanner.identity.banner.incomplete':
    "Upload some documents for further verification. We're still in the process of verifying your bank account. Check your status in 2-3 business days.",
  'verificationBanner.identity.banner.pending':
    "We're still in the process of verifying your identity. Check your status in 3-5 business days.",
  'verificationBanner.identity.banner.pending-compliance-verification':
    'Your documents are now with the compliance team. We will update you on your status once your documents have been reviewed.',
  'verificationBanner.identify.banner.pending-bank':
    'Once you link your bank account we can finish verifying your identity.',

  'verificationBanner.bank.banner.error': 'There was an issue with linking your bank account.',
  'verificationBanner.bank.banner.incomplete': 'Link and verify your bank account to start making investments.',
  'verificationBanner.bank.banner.pending':
    "We're still in the process of verifying your bank account. Check your status in 2-3 business days.",
  'verificationBanner.bank.banner.pending.deposits':
    'Your bank account will be getting deposits from Plaid for verification. Check your bank account 1-2 business days after linking.',

  'buySharesButton.verified': 'Buy Shares',
  'buySharesButton.verified.invest': 'Invest',
  'buySharesButton.notVerified': 'Complete account setup',

  'signup.schema.password.requirements':
    'Your password should have at least 8 characters and 3 of the following 4 items: lower case letters (a-z), upper case letters (A-Z), numbers (0-9), special characters (e.g. !@#$%^&*).',
  'signup.schema.password.userInfo': 'Password cannot contain user information',
  'signup.schema.passwordStrength.good': 'Good password. For extra security make it longer',
  'signup.schema.passwordStrength.strong': 'Very strong password - nice work',
  'signup.schema.passwordStrength.weak': 'Weak password',
  'signup.schema.birthDate.invalid': 'Please enter a valid birthday',
  'signup.schema.birthDate.minAge': 'You must be at least 18 years old to use Arrived',

  'bank.account.unlinked': 'Link your bank account to start investing in properties.',
  'bank.account.unlinked.button': 'Link your bank',
  'bank.account.verify.button': 'Verify your bank account',
  'bank.account.verify.success': 'Successfully verified your bank account!',
  'bank.account.verify.missing-token':
    'We encountered an error preparing your bank verification attempt, please try again. Contact support if this continues to happen!',
  'bank.account.verify.failed': 'Failed to verify your bank account: {error}',
  'bank.account.verify.plaid-error': 'Error initializing plaid: {error}',
  'bank.account.pending-verification':
    'Plaid may still be in the process of sending your deposit(s); check back within 1-2 business days after linking your bank account. Once your deposit(s) are visible, you can verify your bank account.',
  'bank.account.linked': '',
  'bank.account.linked.success': 'Successfully linked your bank account!',
  'bank.account.linked.error.existing-account':
    'An existing bank account is already connected. Go to your account settings to delete it if you wish to connect a different account.',
  'bank.account.linked.delete.button': 'Delete linked bank',
  'bank.account.linked.contact-us': 'Contact us to unlink your bank',
  'bank.account.linked.delete.confirmation': 'Are you sure you’d like to delete this bank account?',
  'bank.account.linked.delete.confirmation.no-trades':
    'A new bank account will need to be linked to fund future trades and transfer money in and out of your Arrived Cash Balance.',
  'bank.account.linked.delete.confirmation.pending-trades':
    'You have active trades in process that may get canceled if a new bank account is not linked.',
  'bank.account.linked.delete.confirmation.settled-trades':
    'A new bank account will need to be linked to fund future trades and transfer money in and out of your Arrived Cash Balance.',
  'bank.account.linked.delete.success': 'Linked bank account removed.',
  'bank.account.linked.delete.failed': 'Failed to remove linked bank account: {error}',
  'bank.account.linking.title': 'Link your bank',
  'bank.account.linking.description':
    'Link your bank account to fund your investments and transfer money in and out of your Arrived Cash Balance in the future.',
  'bank.account.linking.plaid.with-credentials.label': 'Link bank with Plaid with credentials',
  'bank.account.linking.plaid.with-credentials.description':
    'Instantly link your bank <strong>(immediate verification)</strong>',
  'bank.account.linking.plaid.manually.label': 'Link bank with Plaid manually',
  'bank.account.linking.plaid.manually.description':
    'Manually enter bank account info <strong>(2-3 business days to verify)</strong>',
  'bank.account.linking.document-upload.label': 'Link bank by uploading documents manually',
  'bank.account.linking.document-upload.description':
    'Upload a picture of a voided check <strong>(2-5 business days to verify)</strong>',
  'bank.linking.plaid.title': 'Link bank with Plaid',
  'bank.linking.plaid.description': 'Instantly link your bank <strong>(immediate verification)</strong>',
  'bank.linking.plaid-manual.title': 'Link bank with Plaid manually',
  'bank.linking.plaid-manual.description':
    'Manually enter bank account info <strong>(2-3 business days to verify)</strong>',
  'bank.linking.manual.title': 'Link bank by uploading documents manually',
  'bank.linking.manual.description':
    'Upload a picture of a voided check <strong>(2-5 business days to verify)</strong>',
  'bank.plaid.multipleAccounts.error':
    'Multiple accounts are connected via Plaid, and we only support one account per bank. Please reach out to support for assistance.',
  'bank.plaid.missingLinkToken.error': 'Missing link token, please try again.',
  'bank.plaid.link.failure': 'Failed to link bank account - {error}',
  'bank.plaid.link.exit.error': 'Received error from Plaid - {error}',

  'brokerage.account.kyc.pending':
    "We're still in the process of reviewing your account. Check your status in 3-5 business days.",
  'brokerage.account.kyc.not.run':
    'We ran into an issue creating your account, one of our team members will reach out to you for further instructions.',
  'brokerage.account.kyc.upload-docs':
    'We were unable to verify your investment account. Upload your documents for identification.',
  'brokerage.account.kyc.rejected':
    'Unfortunately, we were unable to approve your identification. We will reach out to you for further instructions but feel free to send an email to {email} if you have any questions.',
  'brokerage.account.pending-verification':
    'We are still in the process of verifying your identity. Check back on the status within 3-5 business days.',
  'brokerage.account.create-account': 'Create an investment account to start investing in properties.',
  'brokerage.account.create-account.button': 'Create your account',

  'financing.percent.with.flexible.future.financing.description':
    'The percent of the purchase price that is funded by the loan. This property may seek to add up to 70% in leverage through a strategic refinancing.',

  'share.purchase.popular-tag': 'Popular',
  'share.purchase.agreement':
    'I understand I am investing with the intention of holding my securities for the target investment period, and that Arrived will not offer refunds on my investment.',
  'share.purchase.agreement-note':
    'Note: you will be able to review the investment details before finalizing the purchase.',

  'transaction.signed.date': 'Signed on {date}',
  'transaction.not.signed': 'Not signed',
  'transaction.view.signed.documents': 'View signed documents',
  'transaction.no-transactions-yet': 'You have not made any transactions yet.',

  'transaction.promotions.title': 'Add Gift Code',
  'transaction.promotions.button-text.no-voucher': 'Add gift code',
  'transaction.promotions.button-text': 'Add gift code or voucher',

  'transaction.success.confirmation.description':
    "You'll receive an order confirmation email shortly and should see a withdrawal from Arrived Homes in your linked bank account in <strong>1-3 business days</strong>.",

  'invest.properties-list.all-properties.description':
    "Always hand-picked by Arrived's team of experts. No matter how you decide to invest, you're selecting from the top 1% of properties that we source.",

  'invest.filters.featured.all': 'All',
  'invest.filters.featured.all.description': 'View all properties',
  'invest.filters.featured.all.imageAltText': 'Header image representing all properties',
  'invest.filters.featured.vacation-rentals': 'Vacation Rentals',
  'invest.filters.featured.vacation-rentals.description': 'View only vacation rental properties',
  'invest.filters.featured.vacation-rentals.imageAltText': 'Header image representing vacation rental properties',
  'invest.filters.featured.single-family-residential': 'Single Family Residential',
  'invest.filters.featured.single-family-residential.description': 'View only single family residential properties',
  'invest.filters.featured.single-family-residential.imageAltText':
    'Header image representing single-family residential properties',
  'invest.filters.featured.leveraged': 'Leveraged',
  'invest.filters.featured.leveraged.description': 'View only leveraged properties',
  'invest.filters.featured.leveraged.imageAltText': 'Header image representing leveraged properties',
  'invest.filters.featured.rented': 'Rented',
  'invest.filters.featured.rented.description': 'View only rented properties',
  'invest.filters.featured.rented.imageAltText': 'Header image representing rented properties',
  'invest.filters.featured.newly-built': 'Newly Built',
  'invest.filters.featured.newly-built.description': 'View only newly built properties',
  'invest.filters.featured.newly-built.imageAltText': 'Header image representing newly-built properties',

  'invest.filters.open-sheet-button': 'Open filters sheet for properties',
  'invest.filters.close-sheet-button': 'Close filters sheet',
  'invest.filters.category.featured': 'Featured',
  'invest.filters.category.featured.description': 'Featured property filters',
  'invest.filters.category.ownership': 'Ownership',
  'invest.filters.category.ownership.description': 'Property filters related to ownership',
  'invest.filters.category.availability': 'Availability',
  'invest.filters.category.availability.description': 'Property filters related to availability',
  'invest.filters.category.location': 'Locations',
  'invest.filters.category.location.description': 'Property filters related to locations',
  'invest.filters.category.rental-status': 'Rent Status',
  'invest.filters.category.rental-status.description': 'Property filters related to rent status',
  'invest.filters.category.investment-strategy': 'Investment Strategy',
  'invest.filters.category.investment-strategy.description': 'Property filters related to investment strategy',
  'invest.filters.category.leverage': 'Leverage',
  'invest.filters.category.leverage.description': 'Property filters related to leverage',

  'filters.what-is-a.str': 'What is a Vacation Rental?',
  'filters.what-is-a.str-description':
    'Furnished properties rented out for shorter stays (days, weeks, or months) on platforms like Airbnb & VRBO.',
  'filters.what-is-a.sfr': 'What is a Single Family Residential?',
  'filters.what-is-a.sfr-description': 'Single family rental homes leased to long-term tenants for 1-2 year periods.',
  'filters.what-is-a.fund': 'What is a Fund?',
  'filters.what-is-a.fund-description-old':
    'Funds offer a curated pool of properties, allowing you to diversify across a portfolio of carefully selected real estate assets.',
  'filters.what-is-a.fund-description-new':
    'Funds provide diversified access to a pool of real estate-backed assets through residential properties or private credit debt investments.',
  'filters.what-is-a.real-estate-debt': 'What is Real Estate Debt?',
  'filters.what-is-a.real-estate-debt-description':
    'Investments in short-term financing for professional real estate projects that provide higher expected yields and are secured by the underlying residential real estate.',

  'invest.property-card.status-icon.alt-text': 'Icon representing the property funding status',

  'property-history.no-events': "We don't have any property updates to share yet",
  'offering-history.no-events': "We don't have any offering updates to share yet",

  'offering-history.dividend-yield': 'Dividend Yield',
  'offering-history.dividends-paid.description': 'Learn more about how dividend amounts are determined in our {link}',
  'offering-history.dividends-paused.description':
    'The dividends for this property have been paused either due to vacancy or as a result of higher than anticipated expenses that have been paid out of the rental income. The dividends will resume once the rental income can be paid out as dividends again. For more information on the reasons why dividends may be adjusted, check out our {link} on how dividends are calculated.',
  'offering-history.share-price-updated.description':
    'The share price range provided is intended to give an idea of the potential current value based on third-party data. Learn more about share prices and how they are calculated in our {link}.',
  'offering-history.change-in-marketing-rent.description': 'The marketed rent price for this rental has changed',
  'offering-history.view-investment-details': 'View Investment Details',

  'offering-history.title.ipo_date': 'Initial Public Offering',
  'offering-history.title.marketed_for_rent': 'Marketed for Rent',
  'offering-history.title.occupied_seller_pco': 'Occupied Seller PCO',
  'offering-history.title.market_preparation': 'Market Preparation',
  'offering-history.title.change_in_marketing_rent': 'Marketing Rent Changed',
  'offering-history.title.approved_application': 'Approved Application',
  'offering-history.title.first_lease_starts': 'Lease Started',
  'offering-history.title.full_lease_renewal': 'Lease Renewal',
  'offering-history.title.m2m_lease_renewal': 'Lease Extended',
  'offering-history.title.lease_cancelled': 'Lease Cancelled',
  'offering-history.title.lease_ends': 'Lease Completed',
  'offering-history.title.lease_starts': 'Lease Started',
  'offering-history.title.early_lease_break': 'Early Lease Break',
  'offering-history.title.notice': 'Notice',
  'offering-history.title.property_issue': 'Property Issue',
  'offering-history.title.property_issue_resolved': 'Property Issue Resolved',
  'offering-history.title.eviction': 'Eviction',
  'offering-history.title.eviction_resolved': 'Eviction Resolved',
  'offering-history.title.vacant_offline': 'Property Issue',
  'offering-history.title.vacant_offline_resolved': 'Property Issue Resolved',
  'offering-history.title.manual_status_entry': 'Status Update',
  'offering-history.title.dividends_paid': 'Dividends Paid',
  'offering-history.title.dividends_paused': 'Dividends Paused',
  'offering-history.title.share_price_updated': 'Share Price Updated',
  'offering-history.title.property_management_update': 'Property Management Update',
  'offering-history.title.currently_live_accepting_bookings': 'Currently Live & Accepting Bookings',
  'offering-history.title.temporarily_unavailable': 'Temporarily Unavailable for Bookings',
  'offering-history.title.onboarding_update': 'Onboarding Update',
  'offering-history.title.status_update': 'Status Update',
  'offering-history.title.disposition_vacant_listed': 'Listed for Sale',
  'offering-history.title.disposition_under_contract': 'Accepted Offer',
  'offering-history.title.disposition_occupied_not_ready': 'Sale Candidate',
  'offering-history.title.disposition_occupied_listed': 'Listed for Sale',
  'offering-history.title.disposed': 'Property Sold',

  'product-details.invest': 'Invest',

  'property-details.number-of-investors': 'Investors',
  'property-details.monthly-rent': 'Monthly Rent',
  'property-details.monthly-rent-anticipated': 'Anticipated Monthly Rent',
  'property-details.purchase-price': 'Purchase Price',
  'property-details.property-leverage': 'Property Leverage',
  'property-details.aum-fee-quarterly': 'Asset Management Fee',
  'property-details.rental-status-title': 'Rental Status',
  'property-details.rent-ready-title': 'Rent-Ready Date',

  'property-details.stn.ineligible.title': 'You’re ineligible to invest',
  'property-details.stn.ineligible.description': `At this time, only self-accredited investors are eligible to invest in short-term notes. We appreciate your understanding and invite you to explore other investment opportunities available on our platform.\nFeel free to reach out if you need assistance or have any questions. We're here to help!`,

  'property-details.term': 'Term',
  'property-details.term-details': '{noteDuration} Months ({startMonth} - {endMonth} {endYear})',
  'property-details.term-tooltip': 'Expected lifespan of the investment.',
  'property-details.maturity-date': 'Maturity Date',
  'property-details.maturity-date-tooltip':
    'Expected date at which point investors will receive principal repayment and deferred interest payment.',
  'property-details.minimum-investment': 'Minimum Investment',
  'property-details.minimum-investment-tooltip':
    'Lowest investment amount possible to participate in the investment offering; investment increments of {minimumInvestmentValue} up to a max investment of {maximumInvestmentValue} are allowed',
  'property-details.status': 'Status',
  'property-details.status-tooltip':
    'Investment offering open until {closingDate}; thereafter, no more subscriptions will be allowed.',
  'property-details.view-documents': 'View Documents',
  'property-details.no-documents-to-show':
    'Uh oh, seems there is an issue with the link to this documentation. Please reach out to our team to assist you further.',
  'property-details.common-questions': 'Common Questions',

  'property-details.annual-rent.description.sfr':
    'Annual Rent is calculated using the Anticipated Monthly Rent amount if the property is leased for a full 12 months.',
  'property-details.annual-rent.description.vr':
    'Annual Rent is calculated using the anticipated annual bookings if the property is bookable for a full 12 months.',

  'property-details.section-header.returns': 'Investment Strategy Hypothetical Returns',
  'property-details.section-header.market-returns': 'Historical Returns in the Market',
  'property-details.section-header.market-returns.description':
    'Investing in Arrived rental homes can deliver returns to investors in two different ways: 1) Equity growth from property value appreciation and 2) Rental income. While we cannot predict future returns for our investors, below is some historic data around property value appreciation over 10 years & 20 years and Arrived rental property dividends that may be helpful. <strong>It is important to note that past performance may not be indicative of future results.</strong>',
  'property-details.section-header.tenant-leasing-process.title': 'Tenant Leasing Process',
  'property-details.section-header.tenant-leasing-process.description':
    'Preparing a home for the rental market is a multi-tiered process that begins as soon as the property is acquired. Our experienced investment team works closely with the local property managers to get it show-ready, determine optimal market rent, take high-quality photos and videos, market the rental through various online and offline channels, screen and thoroughly vet tenant applications, and finalize the lease terms. Below you can find more information regarding the current rental status, the expected timeline, and the rental income.',
  'property-details.section-header.leverage-and-volatility': 'Property Leverage & Volatility',
  'property-details.section-header.leverage-and-volatility.description':
    'The potential financial returns you can earn are often linked to the potential risk and volatility. Adding leverage or debt to properties can amplify the potential return in exchange for higher potential volatility.',
  'property-details.section-header.market': 'The Market',
  'property-details.section-header.market.description':
    'Real estate values and returns are highly dependent on location. In general, properties in more affordable markets will have higher cash flow (potential dividends), and properties in more expensive markets will have higher appreciation. Arrived strives to give investors options to choose how much they invest in appreciation markets, cash flow markets, or balanced markets.{br}{br}The economy of the local city and market will dictate the potential returns of an investment. In general, some markets see high appreciation of home values, some have higher cash flow (dividends), and some have a mix of the two.',
  'property-details.section-header.offering-details': 'Offering Details',
  'property-details.section-header.offering-details.description':
    "The Offering Details provide a breakdown of the financials for a specific property offering. The Offering Details show the operating plan for the property, including how the Raise Amount proceeds will be used. For transparency, we also like to share a breakdown of the Arrived fees. And if you're interested in more information, we also link to other resource documents that go into more depth around each offering.",
  'property-details.section-header.property-management': 'Property Management Partner',
  'property-details.section-header.property-management.description':
    "Preparing a home for the short term rental market is a multi-tiered process that begins as soon as the property is acquired. Our experienced investment team works closely with the local property managers to get it show-ready, determine optimal market rent, take high-quality photos and videos, market the rental through various online and offline channels, screen and thoroughly vet tenant applications, and finalize the lease terms. Below you can find more information regarding this offering's property management partner and the expected rent-ready date.",

  'property-details.returns-data.historical-returns':
    'Based on historical data, our Single Family Residential asset class has an estimated annual return range between {lowerBound} and {upperBound}.',
  'property-details.returns-data.return-split':
    'Investing with Arrived can generate returns in 2 ways:<ol><li><strong>Annual cash flow</strong> from the rental income on each property. The property intends to pay out excess cash to investors quarterly.</li><li><strong>Annual appreciation returns</strong> from any changes in property value. Any appreciation returns net of disposition costs will be paid out upon the sale of the property.</li></ol>',
  'property-details.returns-data.appreciation-description.part-1.sfr':
    'Based on data from the Zillow Value Home Index (ZHVI) Single Family Homes Time Series, single family homes have appreciated <strong>4.5%</strong> per year on average for the <yearTooltip>last 20 years</yearTooltip>.',
  'property-details.returns-data.appreciation-description.part-1.vr':
    "Based on data from the Zillow Value Home Index (ZHVI) Single Family Homes Time Series, single family homes have appreciated <strong>4.5%</strong> per year on average for the <yearTooltip>last 20 years</yearTooltip>. Arrived's vacation rental strategy is focused on single family homes.",
  'property-details.returns-data.appreciation-description.part-2':
    "This data is based on a national average for Single family homes in the 35th to 65th percentile range by home prices according to the Zillow Home Value Index. This is a national average and may not represent actual performance of this property's zipcode. In addition to property appreciation, equity returns also depend on real estate investment costs, hold period, and leverage. For more information, refer to the <link>Offering Circular</link>.",
  'property-details.returns-data.appreciation-description.part-3':
    'Use our returns calculator below to see how hypothetical property performance may impact your investment.',
  'property-details.returns-data.appreciation-description.date-range': 'March 31, 2003 - March 31, 2023',
  'property-details.returns-data.rental-dividend-yield.tooltip':
    'This represents the Pro Forma estimate for annualized free cash flow for this property after fees. We aim to pay out dividends monthly based on generated cash flow.',
  'property-details.returns-data.expenses-line-item': 'Operating, Financing, Legal, Asset Management Fee Expenses',
  'property-details.returns-data.cash-flow-dividends': 'Cash Flow',

  'property-details.returns-data.part-1.ltr-unlevered':
    'A diversified portfolio of unlevered Single Family Residential properties has an estimated historical annual return range between {sfrUnleveredReturnLower} and {sfrUnleveredReturnUpper}.',
  'property-details.returns-data.part-1.ltr-refi':
    'A diversified portfolio of unlevered Single Family Residential properties has an estimated historical annual return range between {sfrUnleveredReturnLower} and {sfrUnleveredReturnUpper}. As a strategic refinance candidate, we intend to add leverage if rates become favorable. With leverage this investment strategy has an estimated historical return range of {sfrLeveredReturnLower} and {sfrLeveredReturnUpper}.',
  'property-details.returns-data.part-1.ltr-levered':
    'A diversified portfolio of Single Family Residential properties has an estimated historical annual return range between {sfrLeveredReturnLower} and {sfrLeveredReturnUpper} with leverage. The addition of leverage carries higher potential for returns but also higher volatility.',

  'property-details.returns-data.part-1.str-unlevered':
    'A diversified portfolio of unlevered vacation rentals has an estimated historical annual return range between {vrUnleveredReturnLower} and {vrUnleveredReturnUpper}. Vacation Rentals have a more volatile return profile than single family residential homes.',
  'property-details.returns-data.part-1.str-refi':
    'A diversified portfolio of unlevered vacation rentals has an estimated historical annual return range between {vrUnleveredReturnLower} and {vrUnleveredReturnUpper}. Vacation Rentals have a more volatile return profile than single family residential homes. As a strategic refinance candidate, we intend to add leverage if rates become favorable. With leverage this investment strategy has an estimated historical return range of {vrLeveredReturnLower} and {vrLeveredReturnUpper}.',
  'property-details.returns-data.part-1.str-levered':
    'A diversified portfolio of vacation rentals has an estimated historical annual return range between {vrLeveredReturnLower} and {vrLeveredReturnUpper} with leverage. Vacation Rentals have a more volatile return profile than single family residential homes, which is further magnified by the addition of leverage.',

  'property-details.returns-data.part-1.disclaimer':
    'Actual returns will vary and may under or over perform these ranges.',

  'property-details.returns-data.expenses-tooltip.sfr':
    'Estimated expenses include property taxes, property insurance, management services, tax/audit expenses, LLC registration expenses, and interest if leveraged. Additionally, our model accounts for estimated repairs and maintenance costs equal to 6% of rent collected, and a vacancy expense allocation that assumes 15 days per year, whether incurred or not.',
  'property-details.returns-data.expenses-tooltip.vr':
    'Estimated expenses include property taxes, property insurance, management services, tax/audit expenses, LLC registration expenses, and interest if leveraged. Additionally, our model accounts for estimated repairs and maintenance costs equal to 5% of rent collected.',

  'property-details.returns-data-vr.airdna-information':
    'The below data is from AirDNA and shows performance for similar sized homes in the 75th percentile for this market.',

  'property-details.calculator.annualized-return': 'Annualized Total Return',
  'property-details.calculator.hypothetical-value': 'Hypothetical Value after Hold Period',
  'property-details.calculator.input.leverage.description':
    'Hypothetical leverage of {leverage} and interest rate of {interest}',
  'property-details.calculator.input.no-leverage-description': 'This property will not have leverage',
  'property-details.calculator.rental-dividend-yield.history': 'Rental Dividend Yield History',
  'property-details.calculator.rental-dividend-yield.accessibility': 'Rental Dividend Yield Slider',
  'property-details.calculator.annual-appreciation.history': 'Annual Appreciation History',
  'property-details.calculator.annual-appreciation.history-description':
    'The data is provided by independent data providers, including Zillow',
  'property-details.calculator.annual-appreciation.accessibility': 'Annual Appreciation Slider',
  'property-details.calculator.average-quarterly-dividend-description':
    'Average quarterly rental dividend yield for Arrived properties in the last 12 months',
  'property-details.calculator.10-year-appreciation-description': '10 Year Average Annual Property Appreciation',
  'property-details.calculator.20-year-appreciation-description': '20 Year Average Annual Property Appreciation',
  'property-details.calculator.metro-area': 'Metro: {metro}',
  'property-details.calculator.zip-code': 'Zip Code: {zipCode}',
  'property-details.calculator.us-avg-appreciation': '{years} Year U.S. Avg - {percent}',
  'property-details.calculator.zip-code-avg-appreciation': '{years} Year Zip Code Avg ({zipCode}) - {percent}',
  'property-details.calculator.irr-tooltip':
    'The Annualized Total Return is calculated using the IRR (Internal Rate of Return) and includes all estimated expenses. IRR accounts for the effect of compounding and helps determine the average annual growth rate an investment may achieve.',
  'property-details.calculator.feedback':
    'We updated our property historical returns section to provide our investors more transparency and better insights.',
  'property-details.calculator.feedback-button': 'Provide us Feedback',
  'property-details.calculator.feedback-thank-you': 'Thank you for your feedback!',

  'property-details.calculator.returns-chart.label':
    'A chart describing the hypothetical returns based on your predetermined investment strategy. All chart points are readable.',

  'property-details.calculator.leverage-tooltip.levered':
    'This property has leverage of {leverage} and an interest rate of {interestRate}',
  'property-details.calculator.leverage-tooltip.refi':
    'This leverage toggle shows hypothetical returns if we were to add leverage of {leverage} and an interest rate of {interestRate}. There is no guarantee that we will be able to add financing under these terms.',
  'property-details.calculator.leverage-tooltip.unlevered':
    'This property does not have leverage and is not a strategic refinance candidate so no leverage can be added.',
  'property-details.calculator.leverage-checkbox.levered.label': 'View hypothetical returns with leverage',
  'property-details.calculator.leverage-checkbox.unlevered.label': 'View hypothetical returns without leverage',

  'property-details.calculator.returns-table.label-header': 'Hypothetical Returns at Historical Appreciation Scenarios',
  'property-details.calculator.returns-table.annual-return-percent': 'Annualized Return %',
  'property-details.calculator.returns-table.return': 'Return at End of Hold Period',
  'property-details.calculator.returns-table.zillow-source':
    'This data is based on a national average for Single Family homes in the 35th to 65th percentile range by home prices according to the Zillow Home Value Index.',

  'property-details.calculator.disclaimer-part-one':
    'This calculator is for illustrative purposes only and displays a range of hypothetical investment outcomes based on the inputs you have provided. The actual investment outcomes will depend on a multitude of factors and cannot be determined in advance of the end of the investment period. <emphasized>Historical performance is not indicative of future results.</emphasized>',
  'property-details.calculator.disclaimer-part-two':
    'Our calculation assumes a {investmentTerm} year hold period, property disposition costs, and an average annual net operating income increase of {yearlyRentalIncrease}. The 10 and 20 year averages provided are based on data from the ZHVI Single Family Homes Time Series, which is based on an average for single family homes in the 35th to 65th percentile range by home prices.',

  'property-details.maxInvestmentAmount':
    'Due to high demand, we have limited the maximum investment amount in {offeringName} to {maxInvestment} so more people have the opportunity to invest. As a reminder, this will only be the case for some properties moving forward. We will have more properties available in the coming weeks.',

  'property-details.common-questions.how-arrived-works.header': 'How Arrived works',
  'property-details.common-questions.how-arrived-works.description':
    'Arrived acquires rental properties into an LLC and sells shares in that LLC to the general public. Arrived then manages the day to day operations including finding tenants and completing repairs. Investors receive dividends from rental income each quarter and capture any property value appreciation.',
  'property-details.common-questions.expected-returns.header': 'What returns can I expect',
  'property-details.common-questions.expected-returns.description.part-1':
    'Investing in Arrived rental properties can deliver returns to investors in two different ways:{br}{br}<strong>1)</strong> Dividends from the rental income on each property; currently paid out to investors monthly{br}<strong>2)</strong> Appreciation from the change in property value that will be realized at the end of the investment hold period',
  'property-details.common-questions.expected-returns.description.part-2':
    'Return ranges are inclusive of all estimated costs, including property Operating, Finance, Legal, and Management Expenses, the Arrived Sourcing Fee, the Asset Management Fee, other upfront expenses, and costs associated with selling the property. Returns are calculated using the IRR (Internal Rate of Return) formula.',
  'property-details.common-questions.seeking-tenant.header': 'What to expect if my property is still seeking a tenant',
  'property-details.common-questions.seeking-tenant.description':
    "We are currently focused on marketing the rental homes to prospective tenants and will email you when a new lease has been signed. Arrived's strategy for seeking tenants is focused on signing 2 year leases, achieving market rent, and thoroughly vetting applicants. Though it may take a bit more time to lease out the homes, we believe these standards provide our investors the best way to maximize returns over the long term.",
  'property-details.common-questions.diversification.description':
    'With real estate, it can be beneficial to invest in multiple properties and markets to achieve portfolio diversification. Diversifying your portfolio can be a good way to reduce exposure to risk from an individual property, tenant, or market forces.',
  'property-details.common-questions.investing-horizon.header': 'Investing horizon & liquidity',
  'property-details.common-questions.investing-horizon.description':
    'Currently investors will need to plan to hold their shares for the full investment period until the property is sold and investors are paid their proportional proceeds from the sale. We anticipate establishing a mechanism to facilitate secondary trading in accordance with the rules and regulations of the U.S. Securities & Exchange Commission (SEC) as an option for liquidity during the investment period, however there can be no guarantee when that will be available. Please <link>submit your email</link> if you would like to be notified of future developments.{br}{br}Arrived strives to give investors the opportunity to build wealth through real estate. Historically, real estate returns have been maximized when treated as a long-term investment over multiple years. Arrived property offerings typically have a 5 year minimum investment period before a property would be sold.{br}{br}<strong>Disclaimer:</strong>{br}{br}There is currently no public trading market for our Interests, and an active market may not develop or be sustained. There is no guarantee that appropriate regulatory approval to permit such secondary trading will ever be obtained. If an active public trading market for our securities does not develop or is not sustained, it may be difficult or impossible for you to resell your shares at any price before the end of the investment period. Even if a public market does develop, the market price could decline below the amount you paid for your shares, and there may be fees involved. Please refer to our offering circular for more details regarding potential distributions.',

  'property-details.common-questions.preparing-vacation-rental.header':
    'What is the process to prepare a vacation rental for bookings',
  'property-details.common-questions.preparing-vacation-rental.description':
    'Preparing a newly-funded vacation rental for bookings can take an average of 1-3 months depending on a number of factors for each property. We work with experienced design & furnishing partners that\n      have a strong track record of standing up and operating top performing vacation rentals quickly and efficiently. Together with these partners we develop a property theme, set a budget and timeline, and work with local contractors, furniture suppliers, and our property managers to ready not only the property but also all the additional services (cleaning, customer support, pricing optimization, …etc).',

  'property-details.common-questions.expected-returns.table.header':
    'Estimated Historical Annual Return Range for Diversified Portfolios',

  'property-details.which-property.question': "Don't know which property to choose?",
  'property-details.which-property.content':
    'No need to worry, all Arrived rental properties go through our rigorous selection process and have been pre-vetted for their investment potential by our acquisitions team (<popover>more info</popover>). Rather than focusing on selecting individual properties to invest in, many Arrived investors simply distribute their investment across several available properties to achieve portfolio diversification.',

  'property-details.other-questions.visit-help-center': 'Visit our help center',

  'property-details.offering-details.sourcing-fee.label': 'Arrived Sourcing Fee (One-time)',
  'property-details.offering-details.total-amount.label': 'Total Property Amount',

  'property-details.offering-terms.property-amount-breakdown.property-loan-amount.title': 'Property Loan Amount',
  'property-details.offering-terms.property-amount-breakdown.financing-percentage.label': 'Financing: {rate}%',
  'property-details.offering-terms.property-amount-breakdown.interest-rate.label': 'Interest Rate: {rate}%',
  'property-details.offering-terms.property-amount-breakdown.equity-from-investors.label':
    'Equity Raised from Investors',

  'property-details.documents.offering-circular': 'Offering Circular',
  'property-details.documents.offering-circular-supplement': 'Offering Circular Supplement',
  'property-details.documents.risk-factors': 'Risk Factors',
  'property-details.documents.series-overview': 'Series Overview',
  'property-details.documents.use-of-proceeds': 'Use of Proceeds',
  'property-details.documents.form-1k': 'Full-Year Property Financials',
  'property-details.documents.form-1sa': 'Half-Year Property Financials',
  'property-details.documents.purchase-agreement': 'Purchase Agreement',
  'property-details.documents.purchase-agreement-supplement': 'Purchase Agreement Supplement',
  'property-details.documents.offering-memorandum-supplement': 'Offering Memorandum Supplement',
  'property-details.documents.note-purchase-agreement': 'Note Purchase Agreement',
  'property-details.documents.npa-note-supplement': 'NPA Note Supplement',
  'property-details.documents.om-note-supplement': 'OM Note Supplement',
  'property-details.documents.private-placement-memorandum': 'Private Placement Memorandum',

  'property-details.timeline-to-rent.description':
    'The time needed to sign the first tenant lease is variable and can depend on several factors including seasonality and whether the property requires any sort of renovation. Historically the average time to lease has been 45 days and has ranged from a minimum of 7 days to over 90 days.',
  'property-details.timeline-to-rent.learn-more':
    'For more details on each status, check out <link>the different rental statuses here</link>.',

  'property-history-title': 'Property History',
  'property-history-button': 'View Full Property Timeline',
  'offering-history-title': 'Offering History',

  'property-manager-description.roseus':
    'Roseus is redefining hospitality within the vacation home rental market.  Through exemplary guest service standards, Roseus Hospitality Group offers owners and guests the service experienced at a luxury hotel for over 50 properties in several markets. With administrative background from both Disney & Universal hospitality groups, it’s no wonder Roseus maintains excellent ratings and reviews on Airbnb and VRBO. Personalized service, adaptability, and socially-conscious management elevate Roseus from its peers and cements its status as the Modern Mark of Hospitality™. Additionally, a portion of all rental proceeds is donated to a local charity allowing our owners and guests to vacation for good.',
  'property-manager-description.old-town-rental':
    'Ric & Jen, founders of Old Town Rental, have traveled the world from Tulum to Germany and have come to the conclusion that immersing fully in a destination is the foundation of an incredible experience. Since 2017, they run a boutique-on-purpose operation where they are personally involved in managing every property on their platform. This is how they’ve built their portfolio of amazing Arizona properties, currently managing over 60 properties in the Phoenix metro and earning Airbnb Superhost and VRBO Premier Partners titles. Ric sits on the board of the Scottsdale Short Term Rental Alliance and their team was one of few PMs in the area selected to partner with Marriot Bonvoy Homes & Villas.',
  'property-manager-description.misfit-homes':
    'Misfit Homes is a vacation rental company based in Nashville, TN specializing in what they refer to as “fun luxury stays”. An Airbnb Superhost with over 670 reviews and VRBO Premier Partner, Misfit homes manages over 25 properties, each decorated with high-end furnishings, Music City inspired art, and spaces designed to elevate your stay to a uniquely memorable experience. When you stay at one of these homes, you know you will be treated to thoughtful and fun amenities, such as skyline views, games, and karaoke rooms. Stand Out and Stay In with Misfit Homes.',
  'property-manager-description.southern-comfort':
    'Southern Comfort has over 10 years of experience managing cabins in the Blue Ridge region. With over 180 properties, they have a full in-house dedicated team covering everything from maintenance to turnovers and everything in between. With over 70% of their reservations handled through direct bookings, it’s clear the experience they provide is one-of-a-kind.',
  'property-manager-description.alpha-geek':
    "Tony J. Robinson is the founder and managing partner of Alpha Geek Capital. He's widely regarded as one of the leading experts on investing in vacation rentals. He's the co-host of the BiggerPockets Real Estate Rookie Podcast, a top business podcast with over 11,000,000 downloads. He and his wife, Sara, run the Real Estate Robinsons YouTube channel, with 25,000 subscribers. Along with his partners, he's created a strong track record of creating solid returns within the short-term rental asset.",
  'property-manager-description.avant-stay':
    'AvantStay is a premium vacation rental manager of high-end homes creating experiences that are comfortable, fun, relaxing, exciting that always make you feel right at home. Their team takes care of everything so getaways worry-free, creating unforgettable vacations, adventures and retreats, ultimately providing the perfect setting for people to come together and make memories away from the daily grind. AvantStay currently manages 400+ properties in 39 strong vacation markets and publishes to 65+ popular booking sites.',
  'property-manager-description.cabins-in-broken-bow':
    'Cabins in Broken Bow started in 2001 owning multiple and managing many more cabins and properties in the Broken Bow area. The team has over 31 years of experience in property and hotel management and prides itself with a small town, cutting edge spirit. With full in-house maintenance and cleaning teams it’s no wonder they’ve accumulated 250+ great reviews on Google.',
  'property-manager-description.boutiq':
    'Boutiq is a tech-first property management team spanning 15 markets, over 12 years owning & operating high-yield STRs and team backgrounds including Expedia, Home Away, and Sonder. With a near 5-star rating and both Vrbo Premier & Airbnb Superhost statuses, the Boutiq team prioritizes guest experience at all their properties and maximizes revenue with proprietary AI and machine-learning pricing algorithms to outperform consistently.',

  'property-grid.banner.single-family-residential.description':
    'Standalone homes built to accommodate a single family or household and attract long-term tenants in desirable rental markets. Historically lower seasonality than vacation rentals.',
  'property-grid.card.thumbnail.alt-text': 'Picture of {offeringName}',

  'market-description.broken-bow':
    'Located just 3 hrs from Dallas and 3.5 hrs from Oklahoma City, Broken Bow is an accessible haven away from the city with plenty to do in both summer and winter. Whether it’s going for boat ride on Broken Bow Lake, horse back riding through Beavers Bend State Park, hitting the old log roads on an ATV, or even a train ride through Beavers Bend. Broken Bow Lake covers 14,000 acres and has 180 miles of lush tree covered natural shoreline, perfect for boating, fishing, swimming and water sports.  The forested terrain surrounding Broken Bow Lake is a haven for hikers and nature lovers.  Mountain Fork River, which winds through Beavers Bend State Park, is hailed for its year-round trout fishing and countless water activities up and down the river.  Whether you love great food, hiking in the outdoors, kids activities, or simply relaxing in your cabin, Broken Bow & Beavers Bend State Park has so much to make a memorable vacation for you and your family.',
  'market-description.poconos':
    "The Poconos, located in northeastern Pennsylvania, is a picturesque region known for its natural beauty and endless recreational opportunities just a 2 hour drive from New York City. With over 2,400 square miles of lush forests, sparkling lakes, and stunning mountain vistas, the Poconos provides the perfect setting for outdoor enthusiasts and nature lovers. The area is home to over 150 lakes and rivers and over 185 miles of hiking trails, including sections of the famous Appalachian Trail. In the winter months, the Poconos transforms into a winter wonderland, with skiing, snowboarding, and snowshoeing being popular activities. The area also boasts several top-rated golf courses, making it a golfer's paradise. In addition to its natural attractions, the Poconos offers a variety of family-friendly activities, including water parks, zoos, and amusement parks making it the perfect destination for a family vacation, romantic getaway, or outdoor adventure.",
  'market-description.scottsdale':
    "Scottsdale, Arizona is a vibrant and upscale city located in the Sonoran Desert. The area is known for its luxurious resorts, world-class golf courses, and stunning desert landscapes. Visitors can explore the beautiful McDowell Sonoran Preserve, hike the iconic Camelback Mountain, or take a scenic hot air balloon ride. The city also boasts an array of high-end shopping destinations, including the Scottsdale Fashion Square and the Kierland Commons. Art enthusiasts will love the numerous galleries and museums in Old Town Scottsdale, while foodies can indulge in the city's thriving culinary scene. The city is also home to several spas and wellness centers, offering a variety of treatments and services. In addition, Scottsdale is a popular destination for events and festivals, including the annual Scottsdale Arabian Horse Show and the Barrett-Jackson Collector Car Auction. With over 300 days of sunshine a year, Scottsdale is a top destination for outdoor recreation, from hiking and biking to golfing and horseback riding.",
  'market-description.panama-city-beach':
    "Panama City Beach, FL is a picturesque destination located on the Gulf of Mexico with over 27 miles of beautiful, white-sand beaches. The city boasts a warm and sunny climate year-round, making it the perfect spot for beach vacations. The area is also home to numerous attractions, such as water parks, golf courses, and amusement parks, making it a great destination for families. Visitors can enjoy fresh seafood and local cuisine at the many restaurants and cafes, or indulge in shopping and nightlife at nearby attractions. Whether you're looking for relaxation or adventure, Panama City Beach offers something for everyone.",
  'market-description.gatlinburg':
    "Nestled in the heart of the Great Smoky Mountains, Gatlinburg, TN is a charming mountain town that offers a unique blend of natural beauty, outdoor adventure, and Southern hospitality. Visitors can explore the nearby hiking trails, fishing streams, and scenic drives in the Smoky Mountains National Park, or enjoy family-friendly attractions like Dollywood and the Gatlinburg SkyLift Park. The town is also home to a variety of restaurants and shops, featuring local crafts and mountain cuisine. Whether you're seeking a romantic getaway or a fun-filled family vacation, Gatlinburg is the perfect destination for a memorable mountain retreat.",
  'market-description.angel-fire':
    'Angel Fire, New Mexico is a picturesque mountain town that draws visitors year-round with its stunning natural beauty and abundance of outdoor activities. In the winter months, skiers and snowboarders flock to the Angel Fire Ski Resort, which offers over 80 ski runs and attracts thousands of visitors each year. In the summer, visitors can enjoy hiking, mountain biking, fishing, and golfing amidst the majestic Sangre de Cristo Mountains. The town also offers a range of cultural events and festivals, including music concerts and art shows. With its scenic beauty and diverse range of activities, Angel Fire is a must-visit destination for outdoor enthusiasts and nature lovers alike.',
  'market-description.asheville':
    "Asheville, North Carolina is a popular destination for tourists looking for a blend of arts and culture, outdoor recreation, and culinary experiences. Known for its vibrant arts scene, visitors can explore numerous galleries and museums, attend music festivals, and tour the historic Biltmore Estate. Asheville is also a foodie destination, offering a range of local breweries, farm-to-table restaurants, and specialty shops. In addition to the city's cultural offerings, Asheville is surrounded by the Blue Ridge Mountains, providing ample opportunities for hiking, biking, and other outdoor activities.",
  'market-description.murrells-inlet':
    'Murrells Inlet, SC is a charming seaside town located in the southern part of the state. Visitors can enjoy a range of outdoor activities, including boating, fishing, kayaking, and paddleboarding in the Atlantic Ocean or the scenic Murrells Inlet marsh. The town is also known for its culinary offerings, with a variety of waterfront dining options that feature fresh seafood and local cuisine. Its laid-back coastal charm, combined with its range of recreational and culinary offerings, make Murrells Inlet a great destination for visitors looking to relax and enjoy a slower pace of life.',
  'market-description.st-augustine':
    "Saint Augustine, FL is a charming and historic city located in northeast Florida. Visitors can explore the city's well-preserved colonial-era district, which features a range of historic landmarks and buildings, including the Castillo de San Marcos fortress. In addition to its rich history, the city boasts a vibrant arts and culture scene, with numerous art galleries, museums, and performing arts venues. Its coastal location also offers plenty of opportunities for outdoor activities, including beach-going, kayaking, and boating, making it a must-visit destination for travelers looking to experience the best of Florida.",
  'market-description.hudson-valley':
    "The Hudson Valley, New York, is a picturesque region renowned for its natural beauty and rich history. Situated just a short distance from the majestic Catskills Mountains, the area offers visitors unparalleled access to outdoor adventures. Hiking enthusiasts can explore the Catskills' extensive trail network, which includes breathtaking vistas, pristine waterfalls, and serene forests. Additionally, the Hudson Valley boasts charming towns and villages, each with its own unique character and attractions, such as historic sites, art galleries, and farm-to-table dining experiences. With its perfect blend of scenic landscapes and cultural offerings, the Hudson Valley serves as an ideal gateway to the awe-inspiring beauty of the Catskills Mountains.",
  'market-description.great-smoky-mountains':
    "The Great Smoky Mountains is America’s most visited national park, attracting over 10 million visitors per year! Called the Smokies due to the ever-present morning fog, this mountain range is world renowned for the diversity of its plant and animal life, the beauty of its ancient mountains, and its history of southern Appalachian mountain culture. Located along the Tennessee-North Carolina border and home to the country's most visited national park, the Smokies provide over 800 miles of hiking trails, rivers, and a good chance at a black bear sighting. Hike, camp, and experience one of America’s oldest mountain ranges. If you love spending time in nature, staying active, and celebrating wildlife, a trip to the Great Smoky Mountains is a must.",

  'ukraine.page.paragraph.1':
    'Ukraine is currently facing a crisis due to the ongoing invasion by Russia that began on February 24th, 2022. The conflict has resulted in the displacement of countless Ukrainians and the loss of civilian lives and infrastructure.',
  'ukraine.page.paragraph.2':
    'We believe that it is essential to support the Ukrainian people in this difficult time. Donating to the National Bank of Ukraine is a meaningful way to contribute to the provision of much-needed humanitarian aid.',
  'ukraine.page.paragraph.4': 'Slava Ukraini.',
  'ukraine.page.donate.button': 'Donate to Ukraine',

  'document.download.failed': 'Failed to download document.',

  'onboarding.welcome-message.loading': "Hi, let's simplify real estate investing!",
  'onboarding.welcome-message.loading.v2': "You're just a few steps away from your first investment!",
  'onboarding.welcome-message': "Hi {firstName}, let's simplify real estate investing!",
  'onboarding.welcome-message.v2': "You're just a few steps away from your first investment, {firstName}!",
  'onboarding.welcome-description.1':
    'Before you dive in, we would like to understand your financial experience and goals. This information will help us provide a personalized investment experience and tailored educational resources so you can get the most out of Arrived.',
  'onboarding.welcome-description.2':
    'The questions are quick and easy to answer. Any information you provide is confidential.',
  'onboarding.welcome-description.v2':
    'Before you dive in, we have a few quick questions to personalize your investment experience & help you get the most out of Arrived.\n\nAny information you provide is confidential.',

  'onboarding.privacy-and-security.title.v2': 'Verify your identity to start purchasing shares',
  'onboarding.privacy-and-security.description.v2':
    "We need to confirm you're actually you! Everything that we'll ask is required by Federal laws surrounding {kyc}(<abbr>KYC</abbr>) and {aml}(<abbr>AML</abbr>) regulation.",

  'onboarding.account-type.title': 'Choose Your Account Type',
  'onboarding.account-type.description':
    'Please note that you can only make this choice once when creating your account and cannot change it later. Choose wisely.',

  'onboarding.residential-address.title': "What's your residential address?",
  'onboarding.residential-address.description':
    "We'll use your street address for any tax reporting or mailing requirements for your state. We are not accepting U.S. territory addresses at this time.",
  'onboarding.residential-address.street-autocomplete.label': 'Street (Autocomplete)',
  'onboarding.residential-address.street-2.label': 'Apt, Suite. (optional)',
  'onboarding.residential-address.state-autocomplete.label': 'State',
  'onboarding.residential-address.same-mailing-address.label': 'My mailing address is the same',
  'onboarding.residential-address.same-business-address.label': 'My business address is the same',

  'onboarding.tax-id.description':
    'Since we are registered with an SEC regulated broker-dealer, we are required by federal law to ask for your {text}. Your information is encrypted and securely transmitted using SSL. {learnMoreTooltip}',
  'onboarding.tax-id.ssn.title': 'What is your Social Security Number?',
  'onboarding.tax-id.multiple.title': 'What are your tax IDs?',
  'onboarding.tax-id.multiple.description': 'What are your tax IDs?',
  'onboarding.tax-id.show-tax-id.label': 'Show tax ID',
  'onboarding.tax-id.hide-tax-id.label': 'Hide tax ID',

  'onboarding.suitability-questionnaire.title': 'Do any of these statements apply to you?',
  'onboarding.suitability-questionnaire.description':
    'You can still invest even if none of these apply to you. {learnMoreTooltip}',

  'onboarding.confirm-details.title': 'Confirm your details',
  'onboarding.confirm-details.description': 'Please take a moment to make sure all of your information is correct.',
  'onboarding.confirm-details.full-name.label': 'Full Name',
  'onboarding.confirm-details.entity-type.label': 'Entity Type',
  'onboarding.confirm-details.entity-name.label': 'Entity Name',
  'onboarding.confirm-details.signatory-title.label': 'Signatory Title',
  'onboarding.confirm-details.resident-type.label': 'Resident Type',
  'onboarding.confirm-details.phone-number.label': 'Phone Number',
  'onboarding.confirm-details.address.label': 'Address',
  'onboarding.confirm-details.mailing-address.label': 'Mailing Address',
  'onboarding.confirm-details.business-address.label': 'Business Address',
  'onboarding.confirm-details.date-of-birth.label': 'Date of Birth',

  'onboarding.are-you-sure-you-want-to-quit.title': 'Are you sure you want to quit?',
  'onboarding.are-you-sure-you-want-to-quit.description':
    "Your progress will be saved and you can pick it up when you're ready!",
  'onboarding.error': 'An error occurred while creating your account, please reach out to support for assistance!',

  'onboarding-modal.liftoff': 'Liftoff!',
  'onboarding-modal.liftoff.header.beginner':
    '{firstName}, as a beginner to the world of investing, let Arrived fuel your goals.',
  'onboarding-modal.liftoff.header.proficient':
    '{firstName}, as a proficient investor, let Arrived accelerate your goals.',
  'onboarding-modal.liftoff.header.expert':
    '{firstName}, as an expert investor, let Arrived fuel your ambitious goals.',
  'onboarding-modal.liftoff.header.na': '{firstName}, let Arrived fuel your goals.',
  'onboarding-modal.liftoff.description.diversify':
    '<strong>Diversify</strong> your investment portfolio and minimize risk by investing in a range of properties',
  'onboarding-modal.liftoff.description.earnIncome':
    '<strong>Earn income</strong> through rental payment distributions every quarter',
  'onboarding-modal.liftoff.description.buildWealth':
    '<strong>Build wealth</strong> through property appreciation, rental income, and tax benefits',
  'onboarding-modal.liftoff.description.retire':
    '<strong>Retire</strong> with a steady income stream and property value appreciation',
  'onboarding-modal.liftoff.description.ownProperty':
    '<strong>Own property</strong> without the hassle of owning a property',
  'onboarding-modal.liftoff.description.learn':
    '<strong>Learn</strong> about real estate and investment strategies, so you can make informed financial decisions',

  'onboarding.tracking-permissions.tagline': "We're always striving to enhance our app for investors like you!",
  'onboarding.tracking-permissions.tagline-user':
    "We're always striving to enhance our app for investors like you, {firstName}!",
  'onboarding.tracking-permissions.description':
    'By sharing usage data, you help us understand which features are most valuable and where we can make improvements.{br}{br}Rest assured, we do not access or store any personal data. Your privacy is important to us.{br}{br}If at any point you want to opt out of sharing, you can do so in your devices settings under Arrived.',

  'suitability-questionnaire.net-worth-over-1m.label':
    'My <strong>net worth is $1M+</strong>\n(excluding primary residence)',
  'suitability-questionnaire.net-worth-over-1m-entity.label':
    'The <strong>net worth of each owner is $1M+</strong>\n(excluding primary residence)',
  'suitability-questionnaire.income-over-200k.label':
    'My <strong>individual income was $200k+</strong> for each of the past two years',
  'suitability-questionnaire.income-over-200k-entity.label':
    'The <strong>individual income of each owner was $200k+</strong> for each of the past two years',
  'suitability-questionnaire.household-income-over-300k.label':
    'My joint <strong>household income was $300k+</strong> for each of the past two years',
  'suitability-questionnaire.household-income-over-300k-entity.label':
    'For each owner, their joint <strong>income including their spouse was $300k+</strong> for each of the past two years',
  'suitability-questionnaire.organization-assets-over-5m.label':
    'The entity has <strong>total assets exceeding $5,000,000</strong>',
  'suitability-questionnaire.associated-with-finra.label':
    'Are you or anyone in your household associated with a <strong>FINRA Member?</strong>',
  'suitability-questionnaire.finra-members-names.label': 'List member names (separate with a comma)',
  'suitability-questionnaire.net-worth.label':
    'Are you or anyone in your household or immediate family a 10% shareholder, officer, or member of the board of directors of a <strong>publicly traded company?</strong>',
  'suitability-questionnaire.public-company-tickers.label': 'List company tickers (separate with a comma)',

  'invest-responsibly.title': 'Investing Responsibly',
  'invest-responsibly.description':
    'I understand and agree that I will not invest more than 10% of my net worth or income in Arrived Homes offerings.',
  'invest-responsibly.agreement.label': 'I agree',

  'webinar.join.title': 'Join a Webinar',
  'webinar.join.investors-like-you': 'We love talking to investors like you!',
  'webinar.join.investors-like-you.personalized': 'We love talking to investors like you, {name}!',
  'webinar.join.subtitle': 'Get your questions answered by industry experts',
  'webinar.join.content.part-1':
    "Join Arrived's weekly webinars on rental property investing. Gain valuable insights and strategies from our CEO and VP of Investments, Ryan Frazier and Cameron Wu. Engage with industry experts and get your questions answered.",
  'webinar.join.content.part-2': 'Take the first step towards investment success with Arrived.',
  'webinar.join.description':
    'Ryan Frazier (CEO), Cameron Wu (VP of Investments), and Korin Hedlund (Senior Customer Marketing Manager) host weekly webinars to talk about getting started with rental property investing.\n\nLive sessions are held weekly on <strong>Tuesdays at 9:00AM PST</strong> and <strong>Fridays at 1:00PM EST</strong>.',
  'webinar.register.pick-a-date': 'Pick a date',
  'webinar.register.pick-a-date.description':
    'Join the discussion and have your questions answered by our industry experts.',
  'webinar.register.error': 'Error registering for webinar: {error}',
  'webinar.register-confirmation.title': "You're Registered",
  'webinar.register-confirmation': "We're excited for you to join us!",
  'webinar.register-confirmation.personalized': "We're excited for you to join us, {name}!",
  'webinar.register-confirmation.email-link':
    "You'll also receive the details above via the email you registered with.",
  'webinar.continue-signup': 'Continue Sign Up',
  'webinar.none-upcoming': 'No upcoming webinars.',

  'subscribe-footer.text': 'Join {count}+ registered users to earn passive income',
  'subscribe-footer.landing-text': 'Grow your real estate portfolio',

  'landing.subheader':
    'Buy shares of investment properties, earn rental income & appreciation — let Arrived take care of the rest.',

  'landing.pcf.header': 'Income fund with 7-9% annual dividend expected',
  'landing.pcf.subheader':
    "Capture a once-in-a-decade opportunity amidst today's high-interest-rate environment. Monthly payments, flexible liquidity, and backed by residential real estate.",

  'warm-welcome.sheet.header': "You've Arrived",
  'warm-welcome.sheet.subheader.v2': 'Create your account to start investing in just 5 minutes.',
  'warm-welcome.sheet.description.v2':
    'See why our community of 390,000+ users has funded over $100M of real estate with Arrived:<ul><li>We source the <strong>top 1% of properties</strong> to make your investment decision easier</li><li><strong>410+ properties</strong> and more dropping regularly</li><li>Get started with <strong>as little as $100</strong></li></ul>',
  'warm-welcome.sheet.browse-properties-first': 'Browse Properties First',
  'warm-welcome.sheet.signup-to-invest': 'Sign Up to Invest',
  'warm-welcome.sheet.proceed-cta': 'Create Your Account To Invest',

  'share-price-updates.description':
    'Share Price values are updated beginning 6+ months (Single Family Residential) or 12+ months (Vacation Rental) after initial property funding, at the next quarterly update. After that point share prices are updated each quarter going forward. <a>Check out this article on how share prices are calculated.</a>',
  'share-price-updates.description.markdown': `Share Price values are updated beginning 6+ months (Single Family Residential) or 12+ months (Vacation Rental) after initial property funding, at the next quarterly update. After that point share prices are updated each quarter going forward.\n\n[Check out this article on how share prices are calculated](${CONFIG.helpUrl}/articles/6909386-how-are-share-prices-calculated).`,

  'about-us.why.part-1':
    "Residential real estate has proven to be the best long term investment in modern history, providing returns in line with stocks, but with half the volatility. The problem is, the majority of people who want to invest in real estate aren't able to do it. Most people are prevented from participating due to the high initial investment needed for down-payments and the many operational requirements of managing a property.",
  'about-us.why.part-2':
    "That's why we're building a new way to invest in real estate that makes it easier for people to start their investing journey today. With Arrived anyone can buy shares in income producing rental properties starting at just $100. Arrived takes care of all the real estate operations so that investors can sit back and collect net rental income and their share of the home's appreciation.",

  'returns-page.last-update-at': 'The following values were last updated {date}.',

  'vacation-rentals.why-rationale.part-1':
    "Our goal has always been to democratize access to the wealth-building potential of real estate investment. Arrived's expansion into vacation rentals is another great step in the right direction toward fulfilling that vision.",
  'vacation-rentals.why-rationale.part-2':
    'Short-term rentals have historically shown high returns and the market is growing as domestic travel patterns change and more people choose to stay in vacation homes. The cost of purchasing a home and the time, effort, and expertise needed to run a successful rental property has traditionally put this out of reach for the average person.',
  'vacation-rentals.why-rationale.part-3':
    "That's why we are adding this exciting new asset class to our platform. With Arrived anyone can buy shares in income-producing rental properties starting at just $100. Arrived takes care of all the real estate operations so that investors can sit back and collect net rental income and their share of the home's appreciation.",

  'cashAccounts.card.balance.title': 'Cash',
  'cashAccounts.card.balance.buyingPower': 'Your Cash Balance',
  'cashAccounts.card.balance.buyingPower.tooltip':
    'Total cash balance that is currently available for purchases or withdrawals',
  'cashAccounts.card.balance.pendingInbound': 'Pending Inbound',
  'cashAccounts.card.balance.pendingInbound.tooltip':
    'Total amount of cash that is transitioning into your Arrived Cash Balance (deposits, dividends, etc.) that will soon be eligible for purchases or withdrawals',
  'cashAccounts.card.balance.pendingOutbound': 'Pending Outbound',
  'cashAccounts.card.balance.pendingOutbound.tooltip':
    'Total amount of cash that is transitioning out of your Arrived Cash Balance (withdrawals, purchases, etc.)',
  'cashAccounts.card.button.withdraw': 'Withdraw',
  'cashAccounts.card.button.invest': 'Invest Cash',
  'cashAccounts.card.dividend.title': 'Dividend',
  'cashAccounts.card.dividend.overTime': 'Over Time',
  'cashAccounts.card.dividend.overTimeTooltip': 'Cumulative dividend value over time',
  'cashAccounts.card.dividend.nextPayment': 'Next Payment',
  'cashAccounts.card.error-states.incomplete-account':
    'Finish the signup process to access your Investment Account. Once you have made investments, this is where your dividends will be deposited.',
  'cashAccounts.card.error-states.kyc-failure':
    "We're unable to give you access to your Investment Account. Please contact us with any questions.",

  'cashAccounts.mainPage.title': 'Overview',
  'cashAccounts.mainPage.transactionHistory.title': 'Transaction History',

  'cashAccounts.moveMoney.main.title': 'Withdraw',
  'cashAccounts.moveMoney.main.transactionsTitle': 'Latest History',
  'cashAccounts.moveMoney.main.selectPlaceholder': 'Select an option',
  'cashAccounts.moveMoney.main.walletOption': 'Arrived Cash',
  'cashAccounts.moveMoney.main.bankError.missing':
    'Deposit and withdraw funds by connecting an external bank account in your Account Settings. Contact us at support@arrived.com for assistance.',
  'cashAccounts.moveMoney.main.bankError.unverified':
    "You'll need to verify your external bank in your Account Settings prior to using it for withdrawals or deposits. Contact us at support@arrived.com for assistance.",
  'cashAccounts.moveMoney.main.bankError.other':
    "We're having trouble syncing your external bank account to your cash balance. Please try refreshing the page, or contact us at support@arrived.com for assistance.",
  'cashAccounts.moveMoney.main.toLabel': 'To',
  'cashAccounts.moveMoney.main.to.error.empty': 'Please select a target account.',
  'cashAccounts.moveMoney.main.fromLabel': 'From',
  'cashAccounts.moveMoney.main.from.error.empty': 'Please select a source account.',
  'cashAccounts.moveMoney.main.amountLabel': 'How much would you like to transfer?',
  'cashAccounts.moveMoney.main.amountAvailable': 'Amount Available',
  'cashAccounts.moveMoney.main.bankSource.error.required': 'Please specify a source account.',
  'cashAccounts.moveMoney.main.bankDestination.error.required': 'Please specify a destination account.',
  'cashAccounts.moveMoney.main.amountAvailable.error.required': 'Please specify an amount for transfer.',
  'cashAccounts.moveMoney.main.amountAvailable.error.positive': 'Please specify an amount above 0.',
  'cashAccounts.moveMoney.main.amountAvailable.error.lessThan': 'Please specify an amount below your total balance.',
  'cashAccounts.moveMoney.main.amountAvailableTooltip.main':
    "If you've recently deposited money, purchased investments with your cash balance, or received a transfer, note that some of it may still be processing and not available for withdrawal yet.",
  'cashAccounts.moveMoney.main.amountAvailableTooltip.pending': 'Cash pending - {amount}',
  'cashAccounts.moveMoney.main.amountAvailableTooltip.available': 'Cash available - {amount}',
  'cashAccounts.moveMoney.main.submitButton': 'Continue',

  'cashAccounts.moveMoney.review.arrivedWallet': 'Cash Balance',
  'cashAccounts.moveMoney.review.arrivedWallet.description': 'For {name}',
  'cashAccounts.moveMoney.review.fromLabel': 'From',
  'cashAccounts.moveMoney.review.toLabel': 'To',
  'cashAccounts.moveMoney.review.dateLabel': '{action} Date',
  'cashAccounts.moveMoney.review.typeLabel': 'Type',
  'cashAccounts.moveMoney.review.typeValue': 'One Time',
  'cashAccounts.moveMoney.review.processingTimeLabel': 'Processing Time',
  'cashAccounts.moveMoney.review.processingTimeTooltip': '{action} Date',
  'cashAccounts.moveMoney.review.processingTimeValue': '3 to 5 Business Days',
  'cashAccounts.moveMoney.review.processingTimeValue.description': 'Includes ACH transfer and processing times',
  'cashAccounts.moveMoney.review.disclaimer':
    'By clicking “{confirmButtonText}” button, I authorize Arrived to debit the account indicated for the amount noted on the date displayed. Additionally, I agree I will not dispute Arrived debiting my account, so long as the transaction corresponds to the terms in this confirmation view. For any questions or to cancel this transaction, please contact us at {supportEmail} before the next business day.',
  'cashAccounts.moveMoney.review.confirmText': 'Confirm {action} details',
  'cashAccounts.moveMoney.review.confirmText.withdraw': 'withdraw',
  'cashAccounts.moveMoney.review.confirmText.deposit': 'deposit',
  'cashAccounts.moveMoney.review.confirmButton': 'Confirm {action}',

  'cashAccounts.moveMoney.success.mainText.deposit': 'Deposit',
  'cashAccounts.moveMoney.success.mainText.withdraw': 'Withdraw',
  'cashAccounts.moveMoney.success.mainText': 'Success!',
  'cashAccounts.moveMoney.success.timelineText':
    'You should see your funds deposit in your account within 1 to 3 business days.',
  'cashAccounts.moveMoney.success.doneButtonText': 'Done',

  'cashAccounts.transactionActivity.subheader.pending': 'Pending',
  'cashAccounts.transactionActivity.subheader.pending.all': 'All Pending Activity',
  'cashAccounts.transactionActivity.subheader.posted': 'Posted',
  'cashAccounts.transactionActivity.table.see-all-pending-button': 'See all',
  'cashAccounts.transactionActivity.table.all-time': 'All time',
  'cashAccounts.transactionActivity.table.dateRange': 'Last {numberOfDays} days',
  'cashAccounts.transactionActivity.table.dateRange.ariaLabel': 'Select number of days of transaction activity to show',
  'cashAccounts.transactionActivity.table.dateLabel': 'Date',
  'cashAccounts.transactionActivity.table.typeLabel': 'Type',
  'cashAccounts.transactionActivity.table.titleLabel': 'Title',
  'cashAccounts.transactionActivity.table.fromLabel': 'From',
  'cashAccounts.transactionActivity.table.statusLabel': 'Status',
  'cashAccounts.transactionActivity.table.amountLabel': 'Amount',
  'cashAccounts.transactionActivity.table.redemption-title': '{offeringName} - Redemption',
  'cashAccounts.transactionActivity.table.liquidity-title': '{offeringName} - Payment',
  'cashAccounts.transactionActivity.table.viewDetailsLabel': 'Details',
  'cashAccounts.transactionActivity.table.viewDetailsButton': 'View',
  'cashAccounts.transactionActivity.table.viewDetailsButton.description': 'View transaction details',
  'cashAccounts.transactionActivity.table.signTradeDocumentsButton': 'Sign',
  'cashAccounts.transactionActivity.table.signTradeDocumentsButton.description': 'Sign transaction documents',
  'cashAccounts.transactionActivity.table.emptyState.description':
    'No transactions during this time period. Please choose a different time frame or explore our available options to start investing.',
  'cashAccounts.transactionActivity.table.emptyState.button': 'Browse Investments',

  'cashAccounts.transactionCategory.withdraw': 'Withdrawal',
  'cashAccounts.transactionCategory.deposit': 'Deposit',
  'cashAccounts.transactionCategory.correction': 'Correction',
  'cashAccounts.transactionCategory.buy': 'Buy {units}',
  'cashAccounts.transactionCategory.sell': 'Sell {units}',
  'cashAccounts.transactionCategory.dividend': 'Dividend payment',
  'cashAccounts.transactionCategory.brokerFee': 'Broker Fee',
  'cashAccounts.transactionCategory.rewardRedemption': 'Reward Redemption',
  'cashAccounts.transactionCategory.return': 'Return',

  'cashAccounts.transactionCategory.redemption': 'Redeem {units}',
  'cashAccounts.transactionCategory.payment': 'Short Term Note Payment',

  'cashAccounts.transactionDetails.openSheetDescription': 'Open transaction details sheet for {transactionCategory}',
  'cashAccounts.transactionDetails.sheetTitle': 'Transaction Details',
  'cashAccounts.transactionDetails.sheetTitle.redemption': 'Redemption Details',
  'cashAccounts.transactionDetails.sheetTitle.payment': 'Payment Details',
  'cashAccounts.transactionDetails.subtitle.trade': '{formattedTransactionAmount} in {offeringName}',
  'cashAccounts.transactionDetails.subtitle.sale': '{formattedTransactionAmount} of {offeringName}',
  'cashAccounts.transactionDetails.label.transactionStatus': 'Transaction Status',
  'cashAccounts.transactionDetails.label.transactionStatus.rejected': 'Rejected',
  'cashAccounts.transactionDetails.label.transactionStatus.approved': 'Approved',
  'cashAccounts.transactionDetails.label.transactionStatus.cancelled': 'Cancelled',
  'cashAccounts.transactionDetails.label.transactionStatus.pending': 'Pending',
  'cashAccounts.transactionDetails.label.transactionStatus.requested': 'Requested',
  'cashAccounts.transactionDetails.label.transactionStatus.processing': 'Processing',
  'cashAccounts.transactionDetails.label.transactionStatus.signatureNeeded': 'Signature needed',
  'cashAccounts.transactionDetails.label.transactionStatus.posted': 'Posted',
  'cashAccounts.transactionDetails.label.transactionType': 'Type',
  'cashAccounts.transactionDetails.label.paymentStatus': 'Payment Status',
  'cashAccounts.transactionDetails.label.redemptionStatus': 'Redemption Status',
  'cashAccounts.transactionDetails.label.redemptionStatus.details.edits': 'You have until {date} to edit your request.',
  'cashAccounts.transactionDetails.label.redemptionStatus.details.pro-rata':
    'To enable redemptions for all users, we have made pro-rata adjustments with a limit of shares per investors. For more information, visit our FAQ.',
  'cashAccounts.transactionDetails.label.redemptionStatus.details.waivedFees':
    'Because you requested redemption for "{reason}" reason, we waived the redemption cost for you. For more information, visit our FAQ.',
  'cashAccounts.transactionDetails.label.redemptionStatus.details.rejection':
    'Your redemption request has been rejected. Please contact us if you have any questions.',
  'cashAccounts.transactionDetails.label.requestWindowEnd': 'End of request period',
  'cashAccounts.transactionDetails.label.reviewWindowEnd': 'End of review period',
  'cashAccounts.transactionDetails.label.paymentWindowEnd': 'Expected payment date',

  'cashAccounts.transactionDetails.label.tradeStatus': 'Trade Status',
  'cashAccounts.transactionDetails.label.fundingStatus': 'Funding Status',
  'cashAccounts.transactionDetails.label.fundingError': 'Funding Error',
  'cashAccounts.transactionDetails.label.sharePrice': 'Share Price',
  'cashAccounts.transactionDetails.label.shareAmount': 'Share Amount',
  'cashAccounts.transactionDetails.label.purchaseAmount': 'Purchase Amount',
  'cashAccounts.transactionDetails.label.discountAmount': 'Discount Amount',
  'cashAccounts.transactionDetails.label.totalAmount': 'Total Amount',
  'cashAccounts.transactionDetails.label.totalInvestmentAmount': 'Initial Investment Amount',
  'cashAccounts.transactionDetails.label.feeAmount': 'Redemption Cost',
  'cashAccounts.transactionDetails.label.feeAmount.tooltip':
    'Redemptions are subject to fees based on length of ownership. To see the fee schedule, view our FAQ.',
  'cashAccounts.transactionDetails.label.paymentAmount': 'Total Payment Amount',
  'cashAccounts.transactionDetails.label.interestAmount': 'Interest Paid',
  'cashAccounts.transactionDetails.label.promotionCode': 'Promotion Code Used',
  'cashAccounts.transactionDetails.label.purchaseDate': 'Purchase Date',
  'cashAccounts.transactionDetails.label.date': 'Date',
  'cashAccounts.transactionDetails.label.time': 'Time',
  'cashAccounts.transactionDetails.label.closeDate': 'Close Date',
  'cashAccounts.transactionDetails.label.subscriptionDocs': 'Subscription Documents',
  'cashAccounts.transactionDetails.label.subscriptionDocs.sign': 'Sign Documents',
  'cashAccounts.transactionDetails.label.purchaseTime': 'Purchase Time',
  'cashAccounts.transactionDetails.label.createdAt': 'Created At',
  'cashAccounts.transactionDetails.label.cancelledAt': 'Cancelled At',
  'cashAccounts.transactionDetails.label.transactionDestination': 'To',
  'cashAccounts.transactionDetails.label.transactionSource': 'From',
  'cashAccounts.transactionDetails.label.transactionSource.account': 'External Account',
  'cashAccounts.transactionDetails.label.transactionSource.wallet': 'Cash Balance',
  'cashAccounts.transactionDetails.label.transactionSource.multiple': 'Multiple',
  'cashAccounts.transactionDetails.label.transactionSource.unknown': 'Unknown',
  'cashAccounts.transactionDetails.label.tradeDocuments': 'Documents',
  'cashAccounts.transactionDetails.label.tradeDocuments.unsigned': 'Please sign the subscription documents',
  'cashAccounts.transactionDetails.label.viewDocuments': 'Documents',
  'cashAccounts.transactionDetails.label.viewDocuments.generating': 'Generating Documents',
  'cashAccounts.transactionDetails.label.viewDocuments.button': 'View Document',
  'cashAccounts.transactionDetails.button.details': 'See Offering Details',
  'cashAccounts.transactionDetails.button.cancelTrade': 'Cancel Trade',
  'cashAccounts.transactionDetails.button.cancelRedemption': 'Cancel Request',
  'cashAccounts.transactionDetails.button.editRedemption': 'Edit Request',
  'cashAccounts.transactionDetails.cancelModal.title': 'Cancel Trade',
  'cashAccounts.transactionDetails.cancelModal.description.notSigned': 'Please confirm you want to cancel your trade.',
  'cashAccounts.transactionDetails.cancelModal.description.cancellable':
    'Please confirm you want to cancel your trade. If fund movement has already started, then refunds will process up to 10 business days after money is withdrawn from your account.',
  'cashAccounts.transactionDetails.cancelModal.description.manual': 'Please contact us to cancel this trade.',
  'cashAccounts.transactionDetails.cancelModal.redemption.title': 'Cancel Redemption Request',
  'cashAccounts.transactionDetails.cancelModal.redemption.description':
    'Please confirm that you wish to cancel the redemption request for the {offeringName}. Keep in mind that you cannot undo this action.',

  'cashAccounts.transactionDetails.cancelModal.submit.confirm': 'Confirm',
  'cashAccounts.transactionDetails.cancelModal.submit.notSure': 'Not Sure',
  'cashAccounts.transactionDetails.cancelModal.submit.contactUs': 'Contact Us',

  'cashAccounts.transaction.deposit': 'Deposit from {source} to {destination}',
  'cashAccounts.transaction.withdraw': 'Withdraw from {source} to {destination}',
  'cashAccounts.transaction.dividendPayment': '{date} Dividend for {property}',
  'cashAccounts.transaction.wallet': 'Cash Balance',

  'cameron-quote.name-title': '<strong>Cameron Wu</strong>, VP of Investments',

  'homepage-v2.hero.title': 'Easily invest in real estate',
  'homepage-v2.hero.tagline':
    'Maximize your wealth — unlock the benefits of real estate investing with rental income and appreciation.',
  'homepage-v2.property-cards.browse-all.button': 'Browse All',
  'homepage-v2.return-cards.tagline': 'Real estate takes the crown',
  'homepage-v2.return-cards.title':
    'Diversify and reduce risk with hassle-free real estate investment for consistent returns without the headaches.',
  'homepage-v2.return-cards.caption': 'Total Returns (April 2022 - March 2023)',
  'homepage-v2.return-card.arrived.label': 'All Properties',
  'homepage-v2.return-card.arrived.title': 'Arrived',
  'homepage-v2.return-card.public-reits.label': 'All U.S. REITs',
  'homepage-v2.return-card.public-reits.title': 'Public REITs',
  'homepage-v2.return-card.public-stocks.label': 'S&P 500',
  'homepage-v2.return-card.public-stocks.title': 'Public Stocks',
  'homepage-v2.value-props.dividends-paid.label': 'Dividends + interest paid',
  'homepage-v2.value-props.dividends-paid.value': '+{dividendsValue}',
  'homepage-v2.value-props.registered-investors.label': 'Registered investors',
  'homepage-v2.value-props.investor-owned-property-value.label': 'Property value owned by investors',
  'homepage-v2.value-props.total-aum.label': 'Assets Under Management',
  'homepage-v2.diligence-process.tagline': 'Only the best homes',
  'homepage-v2.diligence-process.title':
    'We estimate that less than 0.2% of homes we review will pass our diligence process.',
  'homepage-v2.diligence-process.quote':
    '“Our team follows a process designed to provide our investors with what we believe are going to be top performing investments.”',
  'homepage-v2.how-arrived-works.tagline': 'Real estate investing, but easy',
  'homepage-v2.how-arrived-works.title':
    'Effortlessly invest in a rental home or vacation rental and start earning passive income.',
  'homepage-v2.how-arrived-works.cta': 'How Does Arrived Work',
  'homepage-v2.how-arrived-works.card-1-title': 'Browse Properties',
  'homepage-v2.how-arrived-works.card-1-description':
    'Browse Arrived properties, each pre-vetted for their investment potential',
  'homepage-v2.how-arrived-works.card-2-title': 'Select Property',
  'homepage-v2.how-arrived-works.card-2-description':
    'Determine how much money you want to invest and select your shares',
  'homepage-v2.how-arrived-works.card-3-title': 'Buy Shares',
  'homepage-v2.how-arrived-works.card-3-description': 'Review the terms, sign electronically, and fund your investment',
  'homepage-v2.how-arrived-works.card-4-title': 'Earn Rental Income & Appreciation',
  'homepage-v2.how-arrived-works.card-4-description': 'All while Arrived manages the properties',
  'homepage-v2.how-arrived-works.description':
    '<strong>Browse Homes</strong>{br}Investors browse available homes and vacation rental properties pre-vetted based on their appreciation and income potential.{br}{br}<strong>Select Shares</strong>{br}Investors determine how much money they want to invest in each property and select shares.{br}{br}<strong>Sign & Invest</strong>{br}Investors review the terms, sign an online contract, and fund the investment by linking their bank account.{br}{br}<strong>Earn Income</strong>{br}Investors sit back and collect their share of net rental income and participate in the property value appreciation.',
  'homepage-v2.top-benefits.tagline': 'Real estate takes the crown',
  'homepage-v2.top-benefits.title':
    'Diversify and reduce risk with hassle-free real estate investment for consistent returns without the headaches.',
  'homepage-v2.top-benefits.cta': 'Top Benefits',
  'homepage-v2.top-benefits.card-1-title': 'Consistent passive income',
  'homepage-v2.top-benefits.card-1-description':
    'Earn rental income and receive deposits monthly, generating additional income streams to supplement your financial goals and build long-term wealth.',
  'homepage-v2.top-benefits.card-2-title': 'Property appreciation',
  'homepage-v2.top-benefits.card-2-description':
    'Watch your investment grow as the home appreciates, providing the potential for capital gains and the opportunity to leverage your investment.',
  'homepage-v2.top-benefits.card-3-title': 'Flexible investment amounts',
  'homepage-v2.top-benefits.card-3-description':
    'Invest anywhere from $100 to approximately $20,000 per house, making real estate investing more accessible to a wider range of investors.',
  'homepage-v2.top-benefits.card-4-title': 'Diversify with real estate',
  'homepage-v2.top-benefits.card-4-description':
    'Access historically consistent returns with low correlation to the stock market, providing potential stability and diversification to your portfolio.',
  'homepage-v2.faqs.who-can-invest-question': 'Who can invest in Arrived?',
  'homepage-v2.faqs.who-can-invest-answer':
    'Currently the Arrived platform is open to U.S. citizens or residents above the age of 18. Individuals do not need to be an accredited investor to participate in the Arrived platform.{br}{br}All investors will receive a US 1099 document yearly for tax purposes.{br}{br}Individuals may also invest through certain entities or self-directed retirement accounts.',
  'homepage-v2.faqs.what-properties-question': 'What types of properties are available on Arrived?',
  'homepage-v2.faqs.what-properties-answer':
    'Arrived offers two types of properties at this time:{br}{br}<strong>Single Family Residences</strong>{br}Rental homes that are leased for 12-24 months and are unfurnished{br}{br}<strong>Vacation Rentals</strong>{br}Furnished short term rentals available to travelers on platforms like Airbnb and VRBO.',
  'homepage-v2.faqs.what-returns-question': 'What returns can I expect?',
  'homepage-v2.faqs.what-returns-answer':
    'Investing in Arrived rental properties can deliver returns to investors in two different ways:<ul><li>Dividends from the rental income on each property; currently paid out to investors quarterly</li><li>Appreciation from the change in property value that will be realized at the end of the investment hold period</li></ul>Actual returns for individual properties will vary and may over or under perform these ranges.{br}{br}Diversifying across several properties and across asset types can be a helpful strategy for minimizing individual property risk.',
  'homepage-v2.email-sign-up.title': 'Ready to build your real estate empire today?',

  'comparison-chart.title': 'Why invest in real estate?',
  'comparison-chart.attractive-dividends.header': 'Attractive Dividends',
  'comparison-chart.low-volatility.header': 'Low Volatility',
  'comparison-chart.access-to-leverage.header': 'Access to Leverage',
  'comparison-chart.hard-asset.header': 'Hard Asset',
  'comparison-chart.inflation-hedge.header': 'Inflation Hedge',
  'comparison-chart.average-annual-return.header': 'Average Annual Return*',
  'comparison-chart.average-annual-return.shortened.header': 'Avg Annual Return*',
  'comparison-chart.housing-market.column.alt': 'Housing Market Column - Image of a house',
  'comparison-chart.stock-market.column.alt': 'Stock Market Column - Image of a stock chart',
  'comparison-chart.bonds.column.alt': 'Bonds Column - Image of two stacks of money',
  'comparison-chart.gold.column.alt': 'Gold Column - Image of gold coins',
  'comparison-chart.benefit.icon.label': 'Is a benefit icon',
  'comparison-chart.detriment.icon.label': 'Is a detriment icon',
  'comparison-chart.disclaimer.text':
    'It is important to note that past performance may not be indicative of future results.',
  'comparison-chart.data-source.text': '* Data Source: {reitsLink} | {stocksLink} | {bondsLink} | {goldLink}',
  'comparison-chart.data-source.reits-link.text': 'REITs from December 1990 - December 2022 using only equity REITs.',
  'comparison-chart.data-source.stocks-link.text': 'Stocks from December 1990 - December 2022.',
  'comparison-chart.data-source.bonds-link.text': 'Bonds from December 1996 - December 2022.',
  'comparison-chart.data-source.gold-link.text': 'Gold from December 1990 - December 2022.',

  'accounts.create.net-worth.title': 'What is your estimated net worth?',
  'accounts.create.net-worth.placeholder': 'Estimated net worth',
  'accounts.create.net-worth.description':
    'We want to make the wealth building potential of owning rental homes & vacation rentals more accessible.',
  'accounts.create.net-worth.label': 'Estimated net worth',

  'vacation-rentals-page.source-description':
    'Based on $4,666 average monthly revenue for a full-time vacation rental according to Airdna and $2,031 average monthly income in July 2022 for a rental home according to Zillow Data',

  'airdna.chart.title.average-daily-rate': 'Average Daily Rate',
  'airdna.chart.title.monthly-revenue': 'Monthly Revenue',
  'airdna.chart.title.monthly-occupancy': 'Monthly Occupancy Rate',
  'airdna.chart.legend-label.average': '{value}{legendIndicator}Average',
  'airdna.chart.legend-label.min': '{value} Min',
  'airdna.chart.legend-label.max': '{value} Max',
  'airdna.chart.link.data-provided': 'Data provided by [AirDNA](https://www.airdna.co)',
  'chart.min': 'Min',
  'chart.max': 'Max',
  'chart.average': 'Average',

  'communications-disclaimer.content-disclaimer.title': 'Content Disclaimer',
  'communications-disclaimer.content-disclaimer.part-1':
    'Arrived does not offer personal legal, financial, or other professional advice. The content on this page is for informational or advertising purposes only and is not a substitute for individualized professional advice.',
  'communications-disclaimer.content-disclaimer.part-2':
    "Data, facts, and figures presented on Arrived.com were current at the time of publication. Arrived is not responsible for the content of third-party website(s), and links to those sites should not be viewed as an endorsement. By clicking links to third-party website(s), users are leaving Arrived's website",
  'communications-disclaimer.content-disclaimer.part-3':
    'Arrived is not affiliated with or making any representation as to the company(ies), services, and/or products referenced in respect of such third-party websites.',

  'lease-status.cancelled-transaction.text': 'Cancelled Transaction',
  'lease-status.eviction.text': 'Tenant Issue',
  'lease-status.eviction.tooltip.text':
    'There is an issue related to an active tenant with a lease at this property. We are working with our property manager to resolve this issue in a timely manner. Refer to the property details page for more information.',
  'lease-status.for-sale.text': 'For Sale',
  'lease-status.notice.text': 'Rented',
  'lease-status.notice.tooltip.text': 'This property has a signed lease and is occupied by a tenant.',
  'lease-status.squatter.text': 'Property issue',
  'lease-status.squatter.tooltip.text':
    'There is an issue at this property which is causing a delay listing the property for rent. We are working with our property manager to resolve this issue in a timely manner. Refer to the property details page for more information.',
  'lease-status.occupied.text': 'Rented',
  'lease-status.occupied.tooltip.text': 'This property has a signed lease and is occupied by a tenant.',
  'lease-status.vacant-offline.text': 'Property issue',
  'lease-status.vacant-offline.tooltip.text':
    'There is an issue at this property which is causing a delay listing the property for rent. We are working with our property manager to resolve this issue in a timely manner. Refer to the property details page for more information.',
  'lease-status.occupied-pco.text': 'Seller post-closing occupancy',
  'lease-status.occupied-pco.tooltip.text':
    'A post-closing occupancy agreement has been negotiated with the sellers. Once this term ends, we will begin preparing the home for a renter.',
  'lease-status.under-contract.text': 'Under contract',
  'lease-status.under-contract.tooltip.text':
    'This property has a signed purchase contract. Once the property has closed, we will begin preparing the home for a renter.',
  'lease-status.vacant-unrented-not-ready.text': 'Market preparation',
  'lease-status.vacant-unrented-not-ready.tooltip.text':
    'The property management team is preparing the property for the market (rehab/turn work, maintenance, photos, etc.)',
  'lease-status.vacant-unrented-ready.text': 'Marketed for rent',
  'lease-status.vacant-unrented-ready.tooltip.text':
    'This property is currently listed for rent on Zillow and other major rental sites.',
  'lease-status.vacant-rented-not-ready.text': 'Approved rental application',
  'lease-status.vacant-rented-not-ready.tooltip.text':
    'The property has an approved lease application and is awaiting the tenant’s move in.',
  'lease-status.vacant-rented-ready.text': 'Approved rental application',
  'lease-status.vacant-rented-ready.tooltip.text':
    'The property has an approved lease application and is awaiting the tenant’s move in.',
  'lease-status.rented.text': 'Rented',
  'lease-status.listed.text': 'Marketed for rent',
  'lease-status.unlisted.text': 'Pre-market preparation',
  'lease-status.disposed.text': 'Property Sold',
  'lease-status.disposition-occupied-listed.text': 'Listed for Sale',
  'lease-status.disposition-occupied-not-ready.text': 'Sale Candidate',
  'lease-status.disposition-under-contract.text': 'Accepted Offer',
  'lease-status.disposition-vacant-listed.text': 'Listed for Sale',
  'lease-status.disposition-vacant-not-ready.text': 'Sale Preparation',

  'landing-page.get-started': 'Get Started',

  'invest-page.empty-state.title': 'No Current Matches',
  'invest-page.empty-state.description':
    "No current matches that match these specific filters at the moment, but we're regularly adding properties.",
  'invest-page.empty-state.cta': 'Adjust Filters',

  'callout.comingSoon.content':
    'We are currently sold out. {link} to register to get notified when new properties are available.',

  'historical-returns.header': 'Historical Performance of Arrived Portfolio',
  'historical-returns.sub-header': "Maintaining transparency and responsible stewardship of our clients' investments",

  'historical-returns.description':
    "How is our rental home portfolio performing? Below, you'll find the current status of our properties, carefully selected by our investment team. For a comprehensive overview of the Arrived portfolio performance, including the recurring dividends, occupancy rate, and share price movements, you can visit our blog to view our [quarterly financial performance updates](https://arrived.com/blog/category/investing-on-arrived/). Please note that past performance is not indicative of future results, and investing involves risk, including the potential loss of principal.\n\nThe data below shows the two ways Arrived rental properties can deliver returns.\n\n1) Dividends from the rental income on each property are currently paid out to investors monthly\n\n2) Appreciation from the potential change in property value realized at the end of the investment hold period\n\nVisit our [product returns deep-dive](https://help.arrived.com/en/articles/4953922-what-returns-can-i-expect-from-arrived-properties) to learn more about our different investment products. Arrived aims to provide a diversified portfolio of properties in each category to align with every client's investment goals.\n\nThis data is provided as a part of our [client commitments](https://arrived.com/blog/arrived-commitments/)—which include providing financial education, ensuring accuracy and integrity in our company reporting, and being responsible stewards of our clients' investments. You can view our [investment principles](https://arrived.com/blog/arrived-investment-principles/) if you want to learn more about the investment process that has driven these returns.",

  'historical-returns.total-returns': 'Total Returns',
  'historical-resturns.total-returns.tooltip':
    'Total returns are a combination of unrealized appreciation and realized rental income.',

  'historical-returns.annualized-rental-income': 'Annualized Rental Income',
  'historical-returns.annualized-rental-income.tooltip':
    'This refers to the annualized dividend rate for the property based on past dividend payments. Please note that past performance is not indicative of future results.',

  'historical-returns.share-price': 'Share Price',
  'historical-returns.share-price.tooltip':
    'Equity is the total value of all shares owned at the current share price. Share prices are determined by taking the current property value estimate and factoring in the LLC’s assets and liabilities, such as cash reserves and loan balance. Reduction in cash reserves from starting operations and servicing loans can negatively impact the share valuation initially. We also take into account the pro-rated upfront fees involved in the investment, including the Closing, Offering, Holding costs, &amp; Arrived sourcing fee. The Share Price value may go up or down from quarter to quarter, and the actual investment returns will largely depend on the property’s eventual sale price at the end of the investment period. Learn more about how share prices are calculated [in our Learn blog](https://arrived.com/blog/property-share-prices)',

  'historical-returns.awaiting-valuation': 'Awaiting Valuation',

  ...navigationIntl,
  ...accountSettingsIntl,
  ...checkoutIntl,
  ...landingPageIntl,
  ...portfolioIntl,
  ...productDetailsIntl,
  ...redemptionIntl,
  ...registerIntl,
  ...standardDisclaimerIntl,
  ...investIntl,
  ...sheetsIntl,
  ...blogIntl,
  ...webinarIntl,
} as const;

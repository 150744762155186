import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getOfferingsSearch } from '@arrived/api_v2';
import { OfferingSearchQuery } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';
import { offeringsKeyFn } from './offerings.keys';

export function useOfferingSearchQuery(
  query?: OfferingSearchQuery,
  options?: UseAwaitedQueryOptions<typeof getOfferingsSearch>,
) {
  return useQuery({
    queryKey: offeringsKeyFn(query),
    queryFn: () => getOfferingsSearch(query),
    staleTime: 30_000,
    placeholderData: keepPreviousData,
    ...options,
  });
}

export interface ArrivedLogoSVGProps {
  className?: string;
  colorOne: string;
  colorTwo: string;
}

export const ArrivedLogoSVG = ({ className, colorOne, colorTwo }: ArrivedLogoSVGProps) => {
  return (
    <svg
      className={className}
      width="84"
      height="76"
      fill="none"
      viewBox="0 0 84 76"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="arrivedLogoGradient" x1={0} x2={1} y1={0} y2={1}>
        <stop offset={0} stopColor={colorOne} />
        <stop offset={1} stopColor={colorTwo} />
      </linearGradient>
      <path
        d="M41.966 34.239l-11.95 11.95 11.95 11.95 11.95-11.95-11.95-11.95zm17.396-17.421l-11.95 11.95 11.95 11.95 11.95-11.95-11.95-11.95zM59.3 51.425l-12 12 11.7 11.7h21.8c.8 0 1.2-1 .6-1.5l-22.1-22.2zm17.3-17.3l-12 12 18.5 18.5v-22.8c0-.7-.3-1.4-.8-1.9l-5.7-5.8zm-75.7 7.7v22.8l53.1-53.1-10-10c-1.1-1.1-2.8-1.1-3.8 0l-38.4 38.4c-.6.5-.9 1.2-.9 1.9zm23.8 9.6l-22.1 22.1c-.6.6-.2 1.5.6 1.5h45.2l-23.7-23.6z"
        fill="url(#arrivedLogoGradient)"
      />
    </svg>
  );
};

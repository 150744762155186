import { Stack, StackProps } from '@mui/material';

import { ArrivedLogoImage } from '@arrived/images';

export const ArrivedFooterLogo = ({ sx, ...rest }: StackProps) => (
  <Stack
    height="40px"
    alignItems="flex-start"
    sx={{
      '& .arrived-footer-logo': {
        width: 'auto',
        height: '30px',
      },
      ...sx,
    }}
    {...rest}
  >
    <ArrivedLogoImage className="arrived-footer-logo" id="footer-arrived-logo" />
  </Stack>
);

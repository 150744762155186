import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postRefereeSignup } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';
import { referralsQueryKeyFn } from './referrals.keys';

export function usePostRefereeSignupMutation(options?: UseAwaitedMutationOptions<typeof postRefereeSignup>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postRefereeSignup,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: referralsQueryKeyFn(variables) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}

import { useQuery } from '@tanstack/react-query';

import { getMarkets } from '@arrived/api_v2';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { marketsKey } from './markets.keys';

// TODO: This returns _all_ markets, including ones without any Offerings, there should maybe be an endpoint or a query
//  param that will allow us to only get markets that are attached to current offerings.
export function useGetMarketsQuery(options?: UseAwaitedQueryOptions<typeof getMarkets>) {
  return useQuery({
    queryKey: marketsKey,
    queryFn: () => getMarkets(),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}

import React, { Ref, forwardRef } from 'react';

import Link, { LinkProps } from 'next/link';

import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';

export type NextLinkProps = Omit<MuiLinkProps, 'as' | 'href' | 'classes'> &
  Pick<LinkProps, 'href' | 'as' | 'prefetch' | 'passHref'>;

export const NextMuiLink = forwardRef<HTMLAnchorElement, NextLinkProps>(
  ({ href, as, prefetch, ...props }: LinkProps, ref: Ref<HTMLAnchorElement>) => (
    <Link className="next-mui-link" href={href} as={as} prefetch={prefetch} ref={ref}>
      <MuiLink component="span" {...props} />
    </Link>
  ),
);

import { useQuery } from '@tanstack/react-query';

import { getCompanyKpis } from '@arrived/api_v2';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { kpisKey } from './kpis.keys';

export function useGetKpisQuery(options?: UseAwaitedQueryOptions<typeof getCompanyKpis>) {
  return useQuery({
    queryKey: kpisKey,
    queryFn: getCompanyKpis,
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}

import { useQuery } from '@tanstack/react-query';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { getTransaction } from '../../api/transactions';
import { CashAccount, Transaction } from '../../types/cash-accounts';
import { transactionKeyFn } from './transactions.keys';

export function useTransaction(
  transactionCid?: Transaction['cid'],
  cashAccountCid?: CashAccount['cid'] | null,
  options?: UseAwaitedQueryOptions<typeof getTransaction>,
) {
  return useQuery({
    queryKey: transactionKeyFn(transactionCid!, cashAccountCid!),
    queryFn: () => {
      if (!transactionCid) {
        throw Error();
      }

      return getTransaction(transactionCid, { cashAccountCid });
    },
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
    enabled: (options?.enabled ?? true) && !!transactionCid && !!cashAccountCid,
  });
}

import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  GetProps,
  TamaguiElement,
  createStyledContext,
  isWeb,
  styled,
  useComposedRefs,
  useProps,
  withStaticProperties,
} from '@tamagui/core';

import { Stack, UtilityText, ValueText } from '../../atoms';
import { useLoopedIndex } from '../../hooks/useLoopedIndex';
import { useTabsControls } from '../../hooks/useTabsControls';
import {
  ControlledIndexContext,
  ControlledIndexOverrideProps,
  parseRootChildren,
  useControlledIndexContext,
} from '../../utils';
import {
  SEGMENTED_BUTTON_ADORNMENT_FRAME_NAME,
  SEGMENTED_BUTTON_BUTTON_NAME,
  SEGMENTED_BUTTON_HELPER_TEXT_NAME,
} from './constants';

const SegmentedButtonContext = createStyledContext({
  active: false,
});

const SegmentedButtonContainer = styled(Stack, {
  tag: 'ul',
  role: 'tablist',
  context: SegmentedButtonContext,
  row: true,
  tabIndex: 0,
  backgroundColor: '$onSurface.neutral.outlineAlt',
  borderWidth: 1,
  borderColor: '$onSurface.neutral.outlineAlt',
  borderRadius: '$1',
  margin: 0,
  px: 0,
  alignItems: 'center',
});

const SegmentedButtonInner = styled(Stack, {
  name: SEGMENTED_BUTTON_BUTTON_NAME,
  tag: 'li',
  role: 'tab',
  flexGrow: 1,
  flexBasis: 0,
  context: SegmentedButtonContext,
  width: '100%',
  gap: '$1',
  backgroundColor: '$onSurface.neutral.outlineAlt',
  borderRadius: '$1',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  variants: {
    active: {
      true: {
        backgroundColor: '$onSurface.neutral.defaultInverted',
      },
    },
    isDisabled: {
      true: {
        pointerEvents: 'none',
      },
    },
  } as const,
});

const SegmentedButtonText = styled(UtilityText, {
  tag: 'span',
  context: SegmentedButtonContext,
  token: 'utility.label.button.small',
  variants: {
    active: {
      true: {
        color: '$interactive.primary.rested',
      },
    },
    isDisabled: {
      true: {
        color: '$interactive.neutral.disabled',
      },
    },
  } as const,
});

const SegmentedButtonValueText = styled(ValueText, {
  tag: 'span',
  context: SegmentedButtonContext,
  token: 'value.medium',
  variants: {
    active: {
      true: {
        color: '$interactive.primary.rested',
      },
    },
    isDisabled: {
      true: {
        color: '$interactive.neutral.disabled',
      },
    },
  } as const,
});

const SegmentedButtonHelperText = styled(UtilityText, {
  name: SEGMENTED_BUTTON_HELPER_TEXT_NAME,

  tag: 'span',
  context: SegmentedButtonContext,
  token: 'utility.helper.small',
  color: '$onSurface.neutral.muted',
  whiteSpace: 'nowrap',
  numberOfLines: 1,
  variants: {
    active: {
      true: {
        color: '$interactive.primary.rested',
      },
    },
    isDisabled: {
      true: {
        color: '$interactive.neutral.disabled',
      },
    },
  } as const,
});

const SegmentedButtonAdornment = styled(Stack, {
  name: SEGMENTED_BUTTON_ADORNMENT_FRAME_NAME,
});

export const SegmentedButton = withStaticProperties(
  SegmentedButtonContainer.styleable<GetProps<typeof SegmentedButtonContainer> & ControlledIndexOverrideProps>(
    (propsIn, ref) => {
      const { children, defaultIndex, onIndexChange, controlledIndex, ...rest } = useProps(propsIn);
      const parentRef = useRef<TamaguiElement>(null);
      const composedParentRef = useComposedRefs(parentRef, ref);
      const tabEls = Array.isArray(children) ? children : [children];

      const [index, setIndex] = useLoopedIndex(tabEls.length, defaultIndex, onIndexChange);
      useTabsControls(parentRef, setIndex);

      useEffect(() => {
        if (typeof controlledIndex === 'number') {
          setIndex(controlledIndex);
        }
      }, [controlledIndex]);

      const contextValues = useMemo(() => ({ index, setIndex }), [index, setIndex]);

      return (
        <ControlledIndexContext.Provider value={contextValues}>
          <SegmentedButtonContainer ref={composedParentRef} {...rest}>
            {children}
          </SegmentedButtonContainer>
        </ControlledIndexContext.Provider>
      );
    },
  ),
  {
    Button: SegmentedButtonInner.styleable<GetProps<typeof SegmentedButtonInner> & { index: number }>(
      (propsIn, ref) => {
        const { children: childrenIn, ...rest } = useProps(propsIn);
        const itemRef = useRef<TamaguiElement>(null);
        const composedItemRef = useComposedRefs(itemRef, ref);
        const index = propsIn.index;
        const { index: activeIndex, setIndex: setActiveIndex } = useControlledIndexContext();
        const handleSelect = useCallback(() => {
          setActiveIndex(index);

          if (isWeb && isActive && itemRef.current) itemRef.current.focus();
        }, [index, setActiveIndex]);

        const {
          [SEGMENTED_BUTTON_ADORNMENT_FRAME_NAME]: Adornment,
          [SEGMENTED_BUTTON_HELPER_TEXT_NAME]: Helper,
          children,
        } = useMemo(
          () =>
            parseRootChildren(childrenIn, [SEGMENTED_BUTTON_ADORNMENT_FRAME_NAME, SEGMENTED_BUTTON_HELPER_TEXT_NAME]),
          [childrenIn],
        );

        const isActive = activeIndex === index;

        const MainTextComponent = Helper ? SegmentedButtonValueText : SegmentedButtonText;
        return (
          <SegmentedButtonInner
            ref={composedItemRef}
            tabIndex={isActive ? 0 : -1}
            onPress={handleSelect}
            active={activeIndex === index}
            aria-selected={isActive}
            px={'$4'}
            py={Helper ? '$3' : '$4'}
            isDisabled={rest.isDisabled || rest.disabled}
            $platform-native={{ px: 0 }}
            {...rest}
          >
            <Stack row alignItems="center" gap="$1">
              <MainTextComponent>{children}</MainTextComponent>
              {Adornment}
            </Stack>
            {Helper}
          </SegmentedButtonInner>
        );
      },
    ),
    Adornment: SegmentedButtonAdornment,
    Helper: SegmentedButtonHelperText,
  },
);

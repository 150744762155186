import { useQuery } from '@tanstack/react-query';

import { getOfferingTransactionDocumentsAsPdf } from '@arrived/api_v2';
import { OfferingTransactionId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';
import { tradeDocumentsPdfQueryKeyFn } from './trades.keys';

export function useGetTradeDocumentsRawQuery(
  tradeId: OfferingTransactionId,
  options?: UseAwaitedQueryOptions<typeof getOfferingTransactionDocumentsAsPdf>,
) {
  return useQuery({
    queryKey: tradeDocumentsPdfQueryKeyFn(tradeId),
    queryFn: () => getOfferingTransactionDocumentsAsPdf(tradeId),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}

import { Fragment, forwardRef, useCallback, useId, useImperativeHandle, useMemo, useState } from 'react';

import { AnimatePresence } from '@tamagui/animate-presence';
import { composeEventHandlers, isWeb, useMedia, useTheme, withStaticProperties } from '@tamagui/core';
import { useControllableState } from '@tamagui/use-controllable-state';
import { useEscapeKeydown } from '@tamagui/use-escape-keydown';

import { Portal } from '@gorhom/portal';

import { parseRootChildren, setColorAlpha, usePortalContext } from '../../utils';
import { SheetFrame } from './sheet-frame';
import { SheetScrollView } from './sheet-scroll-view';
import { SHEET_BACKDROP_NAME, SheetBackdrop } from './SheetBackdrop';
import { SheetBackground } from './SheetBackground';
import { SheetBody } from './SheetBody';
import { SheetContentFrame, SheetProps } from './SheetContentFrame';
import { SheetDivider } from './SheetDivider';
import { SheetFooter } from './SheetFooter';
import { SheetHeader } from './SheetHeader';
import { SheetImperativeContext } from './SheetImperativeContext';
import { SheetStyledContext } from './SheetStyledContext';

/**
 * Variants:
 * - Skeleton
 * - Form (default)
 * - Macro @todo
 * - Marco-alt @todo
 * - Completion
 *
 * These variants are set at the root level Sheet, `<Sheet variant="form">` and all children
 * that use the `SheetStyledContext` will be able to use the variant to render the correct styles.
 */
export const Sheet = withStaticProperties(
  forwardRef<SheetImperativeContext, SheetProps>(
    (
      {
        open: openProp,
        enablePanDownToClose,
        variant = 'form',
        fullscreenSheet,
        zIndex,
        ignoreSafeAreaTop,

        index,
        animateOnMount,

        disablePortal,

        onClose,
        onOpen,
        onToggle,
        onBackdropPress,
        Wrapper,

        children: childrenIn,
        isNotBottomSheet,
        ...props
      },
      ref,
    ) => {
      const id = useId();

      const theme = useTheme();
      const media = useMedia();

      // Potential perf, move this to `useSharedValue` and just animate the values
      // instead of setting the state via `onLayout`

      const [headerDimensions, setHeaderDimensions] = useState({ width: 0, height: 0, x: 0, y: 0 });
      const [footerDimensions, setFooterDimensions] = useState({ width: 0, height: 0, x: 0, y: 0 });

      const { portalHostName } = usePortalContext();

      /**
       * We want to manage the open state of the Sheet internally while
       * still allowing the consumer to control it if they want to.
       */
      const [open, setOpen] = useControllableState({
        prop: openProp,
        defaultProp: false,
        onChange: onOpen,
      });

      const { [SHEET_BACKDROP_NAME]: Backdrop, children } = useMemo(
        () => parseRootChildren(childrenIn, [SHEET_BACKDROP_NAME]),
        [childrenIn],
      );

      const handleOpenToggle = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
      }, []);

      // So we can use this more than once
      const handleClose = composeEventHandlers(() => {
        setOpen(false);
      }, onClose);

      const SheetImperativeContextValues = useMemo(() => {
        return {
          open,

          headerDimensions,
          footerDimensions,

          // internal only
          setHeaderDimensions,
          setFooterDimensions,
          // ----------------

          onToggle: composeEventHandlers(handleOpenToggle, onToggle),
          onBackdropPress: composeEventHandlers(handleClose, onBackdropPress),
          onClose: handleClose,
          onOpen: composeEventHandlers(() => {
            setOpen(true);
          }, onOpen),
          isNotBottomSheet,
        } as SheetImperativeContext;
      }, [open, headerDimensions, footerDimensions, onBackdropPress, onToggle, onClose, onOpen, isNotBottomSheet]);

      /**
       * If you want to use the Sheet context values outside the Sheet, you can use the forwardedRef.
       */
      useImperativeHandle(ref, () => SheetImperativeContextValues, [SheetImperativeContextValues]);

      isWeb &&
        // Handle escape key closing
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEscapeKeydown((e) => {
          if (open) {
            handleClose?.(e);
          }
        });

      const contentFrameContent = (
        <SheetContentFrame
          shadowColor={setColorAlpha(theme['onSurface.neutral.default'].val, 0.25) as '$transparent'}
          fullscreenSheet={fullscreenSheet ?? !media.gtXs}
          zIndex={zIndex ?? '$sheet'}
          flex={1}
          // We can't use `$platform-web` here as it will strip the styles from the web build
          style={
            isWeb && {
              // Fixes overflow bug with blur
              backdropFilter: 'blur(0px)',
              position: 'fixed', // Force position fixed for the Sheet popup, otherwise users can scroll and lose the Sheet
            }
          }
        >
          {children}
        </SheetContentFrame>
      );

      const SheetContent = isNotBottomSheet ? (
        contentFrameContent
      ) : (
        <SheetFrame backdropComponent={!isWeb} index={index ?? -1} animateOnMount={animateOnMount ?? false} {...props}>
          {contentFrameContent}

          {/* On web we need to render this outside the content, on native we need to render it on the bottom sheet */}
          {isWeb && (Backdrop ?? <SheetBackdrop />)}
        </SheetFrame>
      );

      const SheetProviderElement = (
        <SheetStyledContext.Provider
          variant={variant}
          enablePanDownToClose={enablePanDownToClose}
          ignoreSafeAreaTop={ignoreSafeAreaTop}
        >
          <SheetImperativeContext.Provider {...SheetImperativeContextValues}>
            {isWeb ? (
              <AnimatePresence>{open && <Fragment key="sheet-frame">{SheetContent}</Fragment>}</AnimatePresence>
            ) : (
              SheetContent
            )}
          </SheetImperativeContext.Provider>
        </SheetStyledContext.Provider>
      );

      const WrappedSheetContent = Wrapper ? <Wrapper>{SheetProviderElement}</Wrapper> : SheetProviderElement;

      return disablePortal ? (
        WrappedSheetContent
      ) : (
        <Portal key={`bricks-Sheet-${id}`} hostName={portalHostName}>
          {WrappedSheetContent}
        </Portal>
      );
    },
  ),
  {
    Props: SheetStyledContext.Provider,

    /**
     * The header container of the sheet. This takes a `Title`, `Adornment`, `Divider` and ReactNode children.
     *
     * @example
     * ```tsx
     * <Sheet>
     *   <Sheet.Header>
     *     <Sheet.Header.Title>
     *        // You don't need to put this in the `Title`, however this will keep things organized
     *       <Sheet.Header.CloseIcon />
     *       <Sheet.Header.Title.Text>Sheet Title</Sheet.Header.Title.Text>
     *       <Button>Button</Button>
     *     </Sheet.Header.Title>
     *     {children}
     *     <BodyText>Look at that magic</BodyText>
     *     <Sheet.Header.Divider />
     *   </Sheet.Header>
     * </Sheet>
     * ```
     */
    Header: SheetHeader,

    /**
     * Wraps all content inside of sheet.
     * @children
     * - `Sheet.ScrollView`
     * - `Sheet.Footer`
     * - Any children unrelated to sheet content
     *
     * @example
     * ```tsx
     * <Sheet>
     *   <Sheet.Body>
     *     <Sheet.ScrollView>
     *       <Stack>
     *         <Text>Content</Text>
     *       </Stack>
     *     </Sheet.ScrollView>
     *     <Sheet.Footer>
     *       <Button>Close</Button>
     *     </Sheet.Footer>
     *   </Sheet.Body>
     * </Sheet>
     * ```
     */
    Body: SheetBody,

    /**
     * Any content for the sheet that should be scrollable. This takes all props of `ScrollView`.
     * This has a fading effect attached to it that will fade out the bottom of the sheet.
     */
    ScrollView: SheetScrollView,

    /**
     * Footer container that will be rendered below the content, but above the `ScrollView`.
     * You can render anything you like, but generally this is used to host
     * `Button` components or disclaimer actions. This is not scrollable content.
     */
    Footer: SheetFooter,

    /**
     * Backdrop that will be rendered behind the Sheet.
     * This is a BlurView on iOS and Android, and a div on Web.
     * @see {@link https://design.internal.arrived.com/?path=/docs/atoms-blur-view--documentation | BlurView}
     */
    Backdrop: SheetBackdrop,

    /**
     * Content that will be rendered on the surface of the Sheet. You can
     * render anything you like, but generally this is used to host
     * `Background` components.
     * @see SheetBackground.tsx
     * @see Background.tsx
     *
     * @example
     * ```tsx
     * <Sheet>
     *   <Sheet.Background>
     *     <Background.CottonCandy />
     *   </Sheet.Background>
     *   <Sheet.Body>
     *     ...
     *   </Sheet.Body>
     * </Sheet>
     */
    Background: SheetBackground,

    /**
     * Quick pre-styled divider that can be used anywhere in the sheet,
     * keeps it style with different sizes and variants. If you need an unstyled divider,
     * use the `Divider` component from `atoms`.
     */
    Divider: SheetDivider,

    // Illustration,
  },
);

export { type SheetFooterProps } from './SheetFooter';

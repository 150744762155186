import { ReactNode, forwardRef, useCallback } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import Email from '@mui/icons-material/Email';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Phone from '@mui/icons-material/Phone';
import PinDrop from '@mui/icons-material/PinDrop';
import Reddit from '@mui/icons-material/Reddit';
import Twitter from '@mui/icons-material/Twitter';
import { Box, BoxProps, Grid, Link, Typography } from '@mui/material';

import { EVENTS, useIdentify, useTrack } from '@arrived/analytics';
import { useTheme } from '@arrived/bricks';
import { Constants, formatPhoneNumber, getCDNOptimizedImage } from '@arrived/common';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { trackMarketingConversionEvent } from '@arrived/marketing';
import { MARKETING_CONVERSION_EVENTS } from '@arrived/models';

import { ArrivedFooterLogo } from '../ArrivedFooterLogo';
import { Subscribe } from '../Subscribe';
import { FOOTER_LINKS_CONFIG } from './footer.config';

const sharedStyles: BoxProps = {
  px: { xs: 3.5, lg: 10 },
  py: { xs: 5, lg: 9 },
};

export interface FooterProps extends BoxProps {
  isLoggedIn?: boolean;
  RouteLink: (link: string, text: JSX.Element) => ReactNode;
}

export const Footer = forwardRef(({ children, isLoggedIn, RouteLink, ...rest }: FooterProps, ref) => {
  const isPhoneContactEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.PHONE_CONTACT);
  const intl = useIntl();
  const track = useTrack();
  const identify = useIdentify();

  const handleEmailSubscribeSuccess = useCallback((email: string) => {
    track(EVENTS.EMAIL_SUBSCRIPTION, {
      position: 'footer',
    });
    track(EVENTS.LEAD_FORM_COMPLETED, {
      email,
      lead_form_type: 'App - Footer',
      referral_source: document.referrer,
      url: window.location.href,
    });
    identify({
      email,
      user_setup_stage: 'lead',
      lead_created_at: new Date().toISOString(),
    });
    trackMarketingConversionEvent({ email, type: MARKETING_CONVERSION_EVENTS.EMAIL_LEAD });
  }, []);

  const theme = useTheme();

  return (
    <Box
      sx={{ backgroundColor: theme['onSurface.neutral.zebraAlt'].variable }}
      className="footer-wrapper"
      component="footer"
      ref={ref}
      {...rest}
    >
      {!isLoggedIn && (
        <>
          <Box
            {...sharedStyles}
            display="flex"
            flexDirection={{ xs: 'column', lg: 'row' }}
            alignItems="flex-start"
            justifyContent="space-between"
            gap={2}
          >
            <Box maxWidth={{ xs: 'unset', lg: 'sm' }}>
              <Typography key="email-list-signup-title" variant="h5.semibold" component="h4" className="title">
                <FormattedMessage id="social-footer-title-email" defaultMessage="Join Our List" />
              </Typography>
              <Box className="mailing-list-signup">
                <Typography component="div" sx={{ mb: 2 }}>
                  <FormattedMessage
                    id="mailing-list-signup-description"
                    defaultMessage="Add your email below to receive updates from Arrived, including our guides to investing in rental homes, and notifications about new rental properties & markets."
                  />
                </Typography>
                <Subscribe onSubscribe={handleEmailSubscribeSuccess} context="footer" />
              </Box>
              <Box
                key="social-links"
                display="flex"
                flexWrap="nowrap"
                justifyContent="flex-start"
                alignItems="center"
                gap={2}
                mt={2}
              >
                <Link
                  className="social-link"
                  color="inherit"
                  href={Constants.arrivedFacebook}
                  target="_blank"
                  title="Facebook"
                >
                  <Facebook fontSize="large" />
                </Link>
                <Link
                  className="social-link"
                  color="inherit"
                  href={Constants.arrivedTwitter}
                  target="_blank"
                  title="Twitter"
                >
                  <Twitter fontSize="large" />
                </Link>
                <Link
                  className="social-link"
                  color="inherit"
                  href={Constants.arrivedInstagram}
                  target="_blank"
                  title="Instagram"
                >
                  <Instagram fontSize="large" />
                </Link>
                <Link
                  className="social-link"
                  color="inherit"
                  href={Constants.arrivedLinkedIn}
                  target="_blank"
                  title="LinkedIn"
                >
                  <LinkedIn fontSize="large" />
                </Link>
                <Link
                  className="social-link"
                  color="inherit"
                  href={Constants.arrivedReddit}
                  target="_blank"
                  title="Reddit"
                >
                  <Reddit fontSize="large" />
                </Link>
                <Link
                  className="social-link"
                  color="inherit"
                  href={Constants.bbbUrl}
                  target="_blank"
                  title="Better Business Bureau"
                >
                  <img
                    src={getCDNOptimizedImage({
                      imgUrl: 'https://cdn.arrivedhomes.com/images/bbb_logo.png',
                      height: '28px',
                    })}
                    alt="Better Business Bureau"
                  />
                </Link>
              </Box>
            </Box>

            <Box width={{ xs: '100%', lg: '50%' }} maxWidth={{ xs: 'unset', lg: 'md' }}>
              <Grid container spacing={2}>
                {FOOTER_LINKS_CONFIG.map(({ title, breakpoints, items }, index) => (
                  <Grid item {...breakpoints} key={index} className="footer-column">
                    <Typography key={`${index}-title`} variant="h5.semibold" mb={1}>
                      {title}
                    </Typography>
                    {items.map(({ isExternal, shouldFollow, link, text }, typographyIndex) => (
                      <Typography
                        key={`${typographyIndex}-${index}`}
                        variant="body1"
                        component="div"
                        color={({ palette }) => palette.darkened.steel}
                        py={0.5}
                        sx={{ textDecoration: 'none' }}
                      >
                        {isExternal ? (
                          <Link href={link} color="inherit" rel={shouldFollow ? undefined : 'noopener noreferrer'}>
                            {text}
                          </Link>
                        ) : (
                          RouteLink(link, text)
                        )}
                      </Typography>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: 'column', lg: 'row' }}
            border={({ palette }) => `1px solid ${palette.neutrals.platinum}`}
            gap={2}
            {...sharedStyles}
          >
            <Box flex="1 1 auto">
              <ArrivedFooterLogo />
            </Box>

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 2, md: 4 }}>
              <Box display="flex" alignItems="center" gap={1}>
                <Box color={({ palette }) => palette.darkened.seal}>
                  <PinDrop color="inherit" sx={{ verticalAlign: 'top' }} />
                </Box>
                <Typography variant="body1">
                  <FormattedMessage
                    id="footer-arrived-address"
                    defaultMessage={`${Constants.arrivedAddress.street} ${Constants.arrivedAddress.city}`}
                  />
                </Typography>
              </Box>
              {isPhoneContactEnabled && (
                <Box display="flex" alignItems="center" gap={1}>
                  <Box color={({ palette }) => palette.darkened.seal}>
                    <Phone color="inherit" sx={{ verticalAlign: 'top' }} />
                  </Box>
                  <Link
                    variant="body1"
                    color="inherit"
                    href={`tel:${Constants.arrivedPhoneNumber}`}
                    title={intl.formatMessage({
                      id: 'arrived-phone-number-description',
                      defaultMessage: "Questions? Let's chat",
                    })}
                  >
                    {formatPhoneNumber(`+${Constants.arrivedPhoneNumber}`)}
                  </Link>
                </Box>
              )}
              <Box display="flex" alignItems="center" gap={1}>
                <Box color={({ palette }) => palette.darkened.seal}>
                  <Email color="inherit" sx={{ verticalAlign: 'top' }} />
                </Box>
                <Link
                  variant="body1"
                  color="inherit"
                  href={`mailto:${Constants.arrivedSupportEmail}`}
                  title={intl.formatMessage({
                    id: 'arrived-phone-number-description',
                    defaultMessage: "Questions? Let's chat",
                  })}
                >
                  {Constants.arrivedSupportEmail}
                </Link>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {children}
    </Box>
  );
});
